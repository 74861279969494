<template>
  <v-row class="pb-15" id="scrollHere">
    <v-col cols="12">
      <v-btn outlined color="grey" @click="$router.go(-1)">
        <font-awesome-icon
          :icon="faArrowLeft"
          style="color: grey; font-size: 1.1rem"
        ></font-awesome-icon>
      </v-btn>
    </v-col>
    <v-col cols="12" md="6" class="align-self-center">
      <v-img
        max-width="320"
        style="filter: grayscale(100%)"
        src="../../../assets/BlueOrangeLogo.png"
      ></v-img>
    </v-col>
    <v-col cols="12" md="6" style="text-align: right" class="align-self-center">
      STOFERNO ΑΕ
      <br />
      ΛΕΩΦ. ΑΝΔΡΕΑ ΣΥΓΓΡΟΥ 224, ΚΑΛΛΙΘΕΑ 176 72, Ελλάδα
      <br />
      ΑΦΜ: 801047582 - ΔΟΥ: ΚΑΛΛΙΘΕΑΣ - ΑΜ ΕΕΤΤ 20-034
      <br />
      Γενική Άδεια Παροχής Ταχυδρομικών Υπηρεσιών / Ταχυμεταφορών
    </v-col>
  </v-row>
</template>

<script>
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
export default {
  name: "LegalHeader",
  data() {
    return {
      faArrowLeft,
    };
  },
  mounted() {
    let scroll = document.getElementById("scrollHere");
    scroll.scrollIntoView();
  },
};
</script>

<style></style>
