<template>
  <div style="text-align: justify" class="pa-sm-8 pa-md-14">
    <v-container>
      <legal-header></legal-header>

      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Tau;&epsilon;&lambda;&epsilon;&upsilon;&tau;&alpha;ί&alpha;
          &Epsilon;&nu;&eta;&mu;έ&rho;&omega;&sigma;&eta;: 01/06/2021</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        &nbsp;
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >1.
          &Epsilon;&Iota;&Sigma;&Alpha;&Gamma;&Omega;&Gamma;&Iota;&Kappa;&Omicron;&Iota;
          &Omicron;&Rho;&Omicron;&Iota;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        &nbsp;
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >1.1&nbsp;</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Omicron; &Iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;&sigmaf;
          stoferno.gr (&sigma;&tau;&omicron; &epsilon;&xi;ή&sigmaf;
          &laquo;&Iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;&sigmaf;&raquo;)
          &delta;&eta;&mu;&iota;&omicron;&upsilon;&rho;&gamma;ή&theta;&eta;&kappa;&epsilon;
          &kappa;&alpha;&iota;
          &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;&epsilon;ί
          &alpha;&pi;ό &tau;&eta;&nu;
          &iota;&delta;&iota;&omega;&tau;&iota;&kappa;ή
          &kappa;&epsilon;&phi;&alpha;&lambda;&alpha;&iota;&omicron;&upsilon;&chi;&iota;&kappa;ή
          &epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha; &mu;&epsilon;
          &tau;&eta;&nu; &epsilon;&pi;&omega;&nu;&upsilon;&mu;ί&alpha;
          &ldquo;&Sigma;&Tau;&Omicron;&Phi;&Epsilon;&Rho;&Nu;&Omega; IKE&rdquo;
          &kappa;&alpha;&iota; &tau;&omicron;&nu;
          &delta;&iota;&alpha;&kappa;&rho;&iota;&tau;&iota;&kappa;ό
          &tau;ί&tau;&lambda;&omicron; &laquo;Stoferno.gr&raquo; &eta;
          &omicron;&pi;&omicron;ί&alpha;
          &alpha;&pi;&omicron;&tau;&epsilon;&lambda;&epsilon;ί
          &kappa;&alpha;&iota; &tau;&eta;&nu;
          &alpha;&pi;&omicron;&kappa;&lambda;&epsilon;&iota;&sigma;&tau;&iota;&kappa;ή
          &nu;ό&mu;&iota;&mu;&eta;
          &delta;&iota;&kappa;&alpha;&iota;&omicron;ύ&chi;&omicron;
          &tau;&eta;&sigmaf; &pi;&alpha;&rho;&omicron;ύ&sigma;&alpha;&sigmaf;
          &Iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;
          &kappa;&alpha;&iota; &tau;&omicron;&upsilon;
          &omicron;&nu;ό&mu;&alpha;&tau;&omicron;&sigmaf;
          &chi;ώ&rho;&omicron;&upsilon; (domain name)&nbsp;</span
        ><a href="http://www.stoferno.gr" style="text-decoration: none"
          ><span
            style="
              font-size: 11pt;
              font-family: Arial;
              color: #0563c1;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: underline;
              -webkit-text-decoration-skip: none;
              text-decoration-skip-ink: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >www.stoferno.gr</span
          ></a
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >, app.stoferno.gr &kappa;&alpha;&iota; &kappa;ά&theta;&epsilon;
          ά&lambda;&lambda;&eta;
          &pi;&alpha;&rho;&epsilon;&mu;&phi;&epsilon;&rho;ή
          &omicron;&nu;&omicron;&mu;&alpha;&sigma;ί&alpha; &mu;&epsilon;
          &tau;&omicron; &omicron;&pi;&omicron;ί&omicron; &alpha;&upsilon;&tau;ή
          &sigma;&upsilon;&nu;&delta;έ&epsilon;&tau;&alpha;&iota;.
          &Tau;&omicron; &gamma;&epsilon;&nu;&iota;&kappa;ό
          &pi;&epsilon;&rho;&iota;&epsilon;&chi;ό&mu;&epsilon;&nu;&omicron;
          &pi;&rho;&omicron;&sigma;&tau;&alpha;&tau;&epsilon;ύ&epsilon;&tau;&alpha;&iota;
          &alpha;&pi;ό &tau;&eta;&nu;
          &nu;&omicron;&mu;&omicron;&theta;&epsilon;&sigma;ί&alpha;
          &pi;&epsilon;&rho;ί
          &pi;&nu;&epsilon;&upsilon;&mu;&alpha;&tau;&iota;&kappa;ή&sigmaf;
          &iota;&delta;&iota;&omicron;&kappa;&tau;&eta;&sigma;ί&alpha;&sigmaf;,
          &kappa;&alpha;&theta;ώ&sigmaf; &kappa;&alpha;&iota;
          &epsilon;&iota;&delta;&iota;&kappa;ό&tau;&epsilon;&rho;&alpha;
          &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&alpha; &tau;&omega;&nu;
          &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&omega;&nu;
          &pi;&rho;&omicron;&sigma;&tau;&alpha;&tau;&epsilon;ύ&omicron;&nu;&tau;&alpha;&iota;
          &alpha;&pi;ό &nu;ό&mu;&omicron;&upsilon;&sigmaf;
          &pi;&omicron;&upsilon;
          &sigma;&chi;&epsilon;&tau;ί&zeta;&omicron;&nu;&tau;&alpha;&iota;
          &epsilon;&nu;&delta;&epsilon;&iota;&kappa;&tau;&iota;&kappa;ά
          &kappa;&alpha;&iota; ό&chi;&iota;
          &pi;&epsilon;&rho;&iota;&omicron;&rho;&iota;&sigma;&tau;&iota;&kappa;ά
          &mu;&epsilon; &epsilon;&mu;&pi;&omicron;&rho;&iota;&kappa;ό
          &alpha;&pi;ό&rho;&rho;&eta;&tau;&omicron;,
          &epsilon;&mu;&pi;&omicron;&rho;&iota;&kappa;ώ&nu; &amp;
          &beta;&iota;&omicron;&mu;&eta;&chi;&alpha;&nu;&iota;&kappa;ώ&nu;
          &mu;&upsilon;&sigma;&tau;&iota;&kappa;ώ&nu; &kappa;&alpha;&iota;
          &pi;&epsilon;&rho;&iota;&omicron;&upsilon;&sigma;ί&alpha;&sigmaf;,
          &alpha;&theta;έ&mu;&iota;&tau;&omicron;&upsilon;
          &alpha;&nu;&tau;&alpha;&gamma;&omega;&nu;&iota;&sigma;&mu;&omicron;ύ,
          &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;&omicron;ύ
          &epsilon;&mu;&pi;&omicron;&rho;ί&omicron;&upsilon;,
          &alpha;&sigma;&phi;ά&lambda;&epsilon;&iota;&alpha;&sigmaf;
          &omicron;&iota;&kappa;&omicron;&nu;&omicron;&mu;&iota;&kappa;ώ&nu;
          &sigma;&upsilon;&nu;&alpha;&lambda;&lambda;&alpha;&gamma;ώ&nu;
          &nbsp;&kappa;&alpha;&iota; έ&tau;&epsilon;&rho;&epsilon;&sigmaf;
          &sigma;&chi;&epsilon;&tau;&iota;&kappa;έ&sigmaf;
          &nu;&omicron;&mu;&omicron;&theta;&epsilon;&sigma;ί&epsilon;&sigmaf;.
          &Delta;&epsilon;&nu;
          &epsilon;&pi;&iota;&tau;&rho;έ&pi;&epsilon;&tau;&alpha;&iota; &eta;
          &tau;&rho;&omicron;&pi;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;,
          &alpha;&nu;&tau;&iota;&gamma;&rho;&alpha;&phi;ή &eta;
          &mu;ί&mu;&eta;&sigma;&eta; &tau;&omicron;&upsilon;&sigmaf;,
          &kappa;&alpha;&iota; &delta;&epsilon;&nu;
          &pi;&rho;έ&pi;&epsilon;&iota; &nu;&alpha;
          &alpha;&phi;&alpha;&iota;&rho;&epsilon;ί&tau;&alpha;&iota; ή
          &nu;&alpha;
          &tau;&rho;&omicron;&pi;&omicron;&pi;&omicron;&iota;&epsilon;ί&tau;&alpha;&iota;
          &omicron;&pi;&omicron;&iota;&alpha;&delta;ή&pi;&omicron;&tau;&epsilon;
          &epsilon;&iota;&delta;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;
          &pi;&epsilon;&rho;ί
          &pi;&nu;&epsilon;&upsilon;&mu;&alpha;&tau;&iota;&kappa;ώ&nu;
          &delta;&iota;&kappa;&alpha;&iota;&omega;&mu;ά&tau;&omega;&nu; ή
          &epsilon;&mu;&pi;&omicron;&rho;&iota;&kappa;ώ&nu;
          &sigma;&eta;&mu;ά&tau;&omega;&nu;, &omicron;ύ&tau;&epsilon;
          &kappa;ά&pi;&omicron;&iota;&alpha; ά&lambda;&lambda;&eta;
          &epsilon;&iota;&delta;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;
          &gamma;&iota;&alpha; &tau;&eta;&nu;
          &iota;&delta;&iota;&omicron;&kappa;&tau;&eta;&sigma;ί&alpha;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >1.2.</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;&Omicron;
          &epsilon;&pi;&iota;&sigma;&kappa;έ&pi;&tau;&eta;&sigmaf;, &omicron;
          &chi;&rho;ή&sigma;&tau;&eta;&sigmaf; ή &tau;&omicron;
          &epsilon;&gamma;&gamma;&epsilon;&gamma;&rho;&alpha;&mu;&mu;έ&nu;&omicron;
          &mu;έ&lambda;&omicron;&sigmaf; &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;
          (&epsilon;&phi;&epsilon;&xi;ή&sigmaf;
          &kappa;&alpha;&lambda;&omicron;ύ&mu;&epsilon;&nu;&omicron;&iota;
          ά&pi;&alpha;&nu;&tau;&epsilon;&sigmaf; &omicron;&iota;
          &alpha;&nu;&omega;&tau;έ&rho;&omega; &chi;ά&rho;&iota;&nu;
          &sigma;&upsilon;&nu;&tau;&omicron;&mu;ί&alpha;&sigmaf;
          &ldquo;&Alpha;&pi;&omicron;&delta;έ&kappa;&tau;&eta;&sigmaf;&rdquo;
          &kappa;&alpha;&iota; &eta; &chi;&rho;ή&sigma;&eta;
          &tau;&omicron;&upsilon; ό&rho;&omicron;&upsilon;
          &alpha;&upsilon;&tau;&omicron;ύ &sigma;&tau;&omicron;
          &pi;&alpha;&rho;ό&nu; &theta;&alpha;
          &lambda;&omicron;&gamma;ί&zeta;&epsilon;&tau;&alpha;&iota;
          ό&tau;&iota; &alpha;&nu;&alpha;&phi;έ&rho;&epsilon;&tau;&alpha;&iota;
          &sigma;&upsilon;&nu;&omicron;&lambda;&iota;&kappa;ά &sigma;&epsilon;
          ό&lambda;&omicron;&upsilon;&sigmaf; &tau;&omicron;&upsilon;&sigmaf;
          &alpha;&nu;&omega;&tau;έ&rho;&omega;), &omega;&sigmaf;
          &kappa;&alpha;&iota; &omicron;
          &Epsilon;&pi;&alpha;&gamma;&gamma;&epsilon;&lambda;&mu;&alpha;&tau;ί&alpha;&sigmaf;
          &Chi;&rho;ή&sigma;&tau;&eta;&sigmaf; ή
          &epsilon;&gamma;&gamma;&epsilon;&gamma;&rho;&alpha;&mu;&mu;έ&nu;&omicron;
          &mu;έ&lambda;&omicron;&sigmaf; &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;, &omicron;
          &omicron;&pi;&omicron;ί&omicron;&sigmaf;
          &pi;&rho;&omicron;&beta;ά&lambda;&lambda;&epsilon;&tau;&alpha;&iota; ή
          &kappa;&alpha;&tau;&alpha;&chi;&omega;&rho;ί&zeta;&epsilon;&tau;&alpha;&iota;
          &sigma;&tau;&omicron;&nu;
          &Iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;
          (&epsilon;&phi;&epsilon;&xi;ή&sigmaf;
          &kappa;&alpha;&lambda;&omicron;ύ&mu;&epsilon;&nu;&omicron;&sigmaf;
          &chi;ά&rho;&iota;&nu;
          &sigma;&upsilon;&nu;&tau;&omicron;&mu;ί&alpha;&sigmaf;
          &laquo;&Epsilon;&pi;&alpha;&gamma;&gamma;&epsilon;&lambda;&mu;&alpha;&tau;ί&alpha;&sigmaf;
          &Alpha;&pi;&omicron;&delta;έ&kappa;&tau;&eta;&sigmaf;&raquo;)
          &theta;&alpha; &pi;&rho;έ&pi;&epsilon;&iota; &nu;&alpha;
          &delta;&iota;&alpha;&beta;ά&sigma;&epsilon;&iota;
          &pi;&rho;&omicron;&sigma;&epsilon;&kappa;&tau;&iota;&kappa;ά
          &kappa;&alpha;&iota; &lambda;έ&xi;&eta; &pi;&rho;&omicron;&sigmaf;
          &lambda;έ&xi;&eta; &kappa;&alpha;&iota; &nu;&alpha;
          &kappa;&alpha;&tau;&alpha;&nu;&omicron;ή&sigma;&epsilon;&iota;
          &tau;&omicron;&upsilon;&sigmaf; ό&rho;&omicron;&upsilon;&sigmaf;
          &kappa;&alpha;&iota; &tau;&iota;&sigmaf;
          &pi;&rho;&omicron;ϋ&pi;&omicron;&theta;έ&sigma;&epsilon;&iota;&sigmaf;
          &chi;&rho;ή&sigma;&eta;&sigmaf; &tau;&eta;&sigmaf;
          &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;,
          &tau;&omicron; &pi;&rho;&omicron;&sigma;ά&rho;&tau;&eta;&mu;&alpha;
          &pi;&epsilon;&rho;ί
          &pi;&nu;&epsilon;&upsilon;&mu;&alpha;&tau;&iota;&kappa;ή&sigmaf;
          &iota;&delta;&iota;&omicron;&kappa;&tau;&eta;&sigma;ί&alpha;&sigmaf;,
          &tau;&omicron; &pi;&rho;&omicron;&sigma;ά&rho;&tau;&eta;&mu;&alpha;
          &pi;&epsilon;&rho;ί
          &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ώ&nu;
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &kappa;&alpha;&iota; &tau;&omicron;
          &pi;&rho;&omicron;&sigma;ά&rho;&tau;&eta;&mu;&alpha;
          &pi;&epsilon;&rho;ί &tau;&eta;&sigmaf;
          &pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή&sigmaf;
          &alpha;&pi;&omicron;&rho;&rho;ή&tau;&omicron;&upsilon;
          &pi;&omicron;&upsilon;
          &alpha;&kappa;&omicron;&lambda;&omicron;&upsilon;&theta;&omicron;ύ&nu;,
          &tau;&alpha; &omicron;&pi;&omicron;ί&alpha;
          &alpha;&pi;&omicron;&tau;&epsilon;&lambda;&omicron;ύ&nu; έ&nu;&alpha;
          &alpha;&delta;&iota;&alpha;ί&rho;&epsilon;&tau;&omicron;
          &kappa;&alpha;&iota;
          &alpha;&delta;&iota;ά&sigma;&pi;&alpha;&sigma;&tau;&omicron;
          &sigma;ύ&nu;&omicron;&lambda;&omicron; &mu;&epsilon; &tau;&omicron;
          &pi;&alpha;&rho;ό&nu;, &pi;&rho;&iota;&nu; &alpha;&pi;ό &tau;&eta;&nu;
          &epsilon;&pi;ί&sigma;&kappa;&epsilon;&psi;&eta; ή &tau;&eta;&nu;
          &chi;&rho;ή&sigma;&eta; &tau;&omega;&nu;
          &sigma;&epsilon;&lambda;ί&delta;&omega;&nu; &mu;&alpha;&sigmaf;
          &kappa;&alpha;&iota; &tau;&omega;&nu;
          &pi;&alpha;&rho;&epsilon;&chi;ό&mu;&epsilon;&nu;&omega;&nu;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;,
          &kappa;&alpha;&tau;&alpha;&nu;&omicron;ώ&nu;&tau;&alpha;&sigmaf;
          ό&tau;&iota; ό&lambda;&alpha; &tau;&alpha;
          &alpha;&nu;&omega;&tau;έ&rho;&omega;
          &alpha;&pi;&omicron;&tau;&epsilon;&lambda;&omicron;ύ&nu;
          &nu;&omicron;&mu;&iota;&kappa;ά
          &delta;&epsilon;&sigma;&mu;&epsilon;&upsilon;&tau;&iota;&kappa;ή
          &sigma;&upsilon;&mu;&phi;&omega;&nu;ί&alpha;
          &mu;&epsilon;&tau;&alpha;&xi;ύ &alpha;&upsilon;&tau;&omicron;ύ
          &kappa;&alpha;&iota; &tau;&eta;&sigmaf;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;, &eta;
          &alpha;&nu;&alpha;&nu;&tau;ί&rho;&rho;&eta;&tau;&eta;
          &alpha;&pi;&omicron;&delta;&omicron;&chi;ή &tau;&eta;&sigmaf;
          &omicron;&pi;&omicron;ί&alpha;&sigmaf;
          &sigma;&upsilon;&nu;&iota;&sigma;&tau;ά
          &alpha;&pi;&alpha;&rho;&alpha;ί&tau;&eta;&tau;&eta;
          &pi;&rho;&omicron;ϋ&pi;ό&theta;&epsilon;&sigma;&eta;
          &gamma;&iota;&alpha; &tau;&eta;&nu;
          &pi;&lambda;&omicron;ή&gamma;&eta;&sigma;&eta; &sigma;&tau;&eta;&nu;
          &Iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;
          &kappa;&alpha;&iota; &tau;&eta;&nu; &chi;&rho;ή&sigma;&eta;
          &tau;&omega;&nu; &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
          &alpha;&upsilon;&tau;ή&sigmaf;. &nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >1.3</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;&Omicron;
          &Iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;&sigmaf;
          έ&chi;&epsilon;&iota; &omega;&sigmaf; &sigma;&tau;ό&chi;&omicron;,
          &kappa;ά&nu;&omicron;&nu;&tau;&alpha;&sigmaf;,
          &mu;&epsilon;&tau;&alpha;&xi;ύ ά&lambda;&lambda;&omega;&nu;,
          &chi;&rho;ή&sigma;&eta; &tau;&omega;&nu; &nu;έ&omega;&nu;
          &tau;&epsilon;&chi;&nu;&omicron;&lambda;&omicron;&gamma;&iota;ώ&nu;
          &kappa;&alpha;&iota; &tau;&omicron;&upsilon;
          &delta;&iota;&alpha;&delta;&iota;&kappa;&tau;ύ&omicron;&upsilon;
          &kappa;&alpha;&iota; &iota;&delta;ί&omega;&sigmaf;
          &tau;&epsilon;&chi;&nu;&omicron;&lambda;&omicron;&gamma;&iota;ώ&nu;
          &kappa;&alpha;&iota; &mu;έ&sigma;&omega;&nu;
          &pi;&omicron;&lambda;&upsilon;&mu;&epsilon;&sigma;&iota;&kappa;ή&sigmaf;
          &pi;&lambda;&eta;&rho;&omicron;&phi;ό&rho;&eta;&sigma;&eta;&sigmaf;,
          &nu;&alpha;
          &epsilon;&kappa;&mu;&epsilon;&tau;&alpha;&lambda;&lambda;&epsilon;&upsilon;&tau;&epsilon;ί
          &tau;&eta;&nu; &delta;ύ&nu;&alpha;&mu;&eta; &pi;&omicron;&upsilon;
          &pi;&alpha;&rho;έ&chi;&epsilon;&iota; &eta;
          &omicron;&mu;&alpha;&delta;&iota;&kappa;ή
          &alpha;&gamma;&omicron;&rho;ά
          &pi;&rho;&omicron;&kappa;&epsilon;&iota;&mu;έ&nu;&omicron;&upsilon;
          &nu;&alpha;
          &epsilon;&pi;&iota;&tau;&epsilon;&upsilon;&chi;&theta;&epsilon;ί &eta;
          &pi;&alpha;&rho;&omicron;&chi;ή &upsilon;&psi;&eta;&lambda;ή&sigmaf;
          &pi;&omicron;&iota;ό&tau;&eta;&tau;&alpha;&sigmaf;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
          &sigma;&tau;&omicron;&nu; &tau;&epsilon;&lambda;&iota;&kappa;ό
          &chi;&rho;ή&sigma;&tau;&eta;/&kappa;&alpha;&tau;&alpha;&nu;&alpha;&lambda;&omega;&tau;ή
          &mu;&epsilon; &tau;&omicron;
          &mu;&iota;&kappa;&rho;ό&tau;&epsilon;&rho;&omicron;
          &delta;&upsilon;&nu;&alpha;&tau;ό
          &kappa;ό&sigma;&tau;&omicron;&sigmaf;, &sigma;&tau;&alpha;
          &pi;&lambda;&alpha;ί&sigma;&iota;&alpha; &tau;&eta;&sigmaf;
          &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;ί&alpha;&sigmaf;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;,
          ό&pi;&omega;&sigmaf; &alpha;&upsilon;&tau;ή
          &epsilon;&nu;&delta;&epsilon;&lambda;&epsilon;&chi;ώ&sigmaf;
          &pi;&epsilon;&rho;&iota;&gamma;&rho;ά&phi;&epsilon;&tau;&alpha;&iota;
          &sigma;&tau;&omicron; &upsilon;&pi;ό 2
          &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&omicron;
          &tau;&omicron;&upsilon;
          &pi;&alpha;&rho;ό&nu;&tau;&omicron;&sigmaf;.&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >1.4.</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;&Omicron;
          &Iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;&sigmaf;
          έ&chi;&epsilon;&iota; &delta;&iota;&kappa;&alpha;ί&omega;&mu;&alpha;
          &nu;&alpha;
          &tau;&rho;&omicron;&pi;&omicron;&pi;&omicron;&iota;&epsilon;ί
          &omicron;&pi;&omicron;&tau;&epsilon;&delta;ή&pi;&omicron;&tau;&epsilon;
          &tau;&omicron; &kappa;&rho;ί&nu;&epsilon;&iota;
          &alpha;&nu;&alpha;&gamma;&kappa;&alpha;ί&omicron;
          &tau;&omicron;&upsilon;&sigmaf; ό&rho;&omicron;&upsilon;&sigmaf;
          &kappa;&alpha;&iota; &tau;&eta;&sigmaf;
          &pi;&rho;&omicron;ϋ&pi;&omicron;&theta;έ&sigma;&epsilon;&iota;&sigmaf;
          &chi;&rho;ή&sigma;&eta;&sigmaf; &tau;&omicron;&upsilon;
          &kappa;&alpha;&iota; &omicron;
          &alpha;&pi;&omicron;&delta;έ&kappa;&tau;&eta;&sigmaf; &tau;&omega;&nu;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
          &tau;&omicron;&upsilon;
          &omicron;&phi;&epsilon;ί&lambda;&epsilon;&iota; &nu;&alpha;
          &epsilon;&lambda;έ&gamma;&chi;&epsilon;&iota; &kappa;ά&theta;&epsilon;
          &phi;&omicron;&rho;ά &gamma;&iota;&alpha; &tau;&upsilon;&chi;ό&nu;
          &epsilon;&pi;&rsquo; &alpha;&upsilon;&tau;ώ&nu;
          &alpha;&lambda;&lambda;&alpha;&gamma;έ&sigmaf; &pi;&omicron;&upsilon;
          έ&chi;&omicron;&upsilon;&nu;
          &pi;&rho;&alpha;&gamma;&mu;&alpha;&tau;&omicron;&pi;&omicron;&iota;&eta;&theta;&epsilon;ί,
          &kappa;&alpha;&iota; &epsilon;&phi;ό&sigma;&omicron;&nu;
          &epsilon;&xi;&alpha;&kappa;&omicron;&lambda;&omicron;&upsilon;&theta;&omicron;ύ&nu;
          &tau;&eta;&nu; &epsilon;&pi;ί&sigma;&kappa;&epsilon;&psi;&eta; ή
          &tau;&eta;&nu; &chi;&rho;ή&sigma;&eta; &sigma;&tau;&omicron;&nu;
          &delta;&iota;&alpha;&delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;ό
          &mu;&alpha;&sigmaf; &tau;ό&pi;&omicron;
          &tau;&epsilon;&kappa;&mu;&alpha;ί&rho;&epsilon;&tau;&alpha;&iota;
          ό&tau;&iota;
          &alpha;&pi;&omicron;&delta;έ&chi;&epsilon;&tau;&alpha;&iota;
          &alpha;&nu;&epsilon;&pi;&iota;&phi;ύ&lambda;&alpha;&kappa;&tau;&alpha;
          &omicron;&pi;&omicron;&iota;&alpha;&delta;ή&pi;&omicron;&tau;&epsilon;
          &alpha;&lambda;&lambda;&alpha;&gamma;ή έ&chi;&epsilon;&iota;
          &epsilon;&pi;&rsquo; &alpha;&upsilon;&tau;ώ&nu;
          &epsilon;&pi;έ&lambda;&theta;&epsilon;&iota;.&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 7.5pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >1.5.&nbsp;</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Alpha;&kappa;&omicron;&lambda;&omicron;ύ&theta;&omega;&sigmaf;,
          &pi;&alpha;&rho;&alpha;&kappa;&alpha;&lambda;&omicron;ύ&mu;&epsilon;
          ό&pi;&omega;&sigmaf;
          &delta;&iota;&alpha;&beta;ά&sigma;&epsilon;&tau;&epsilon;
          &pi;&rho;&omicron;&sigma;&epsilon;&kappa;&tau;&iota;&kappa;ά
          &alpha;&upsilon;&tau;&omicron;ύ&sigmaf;
          &tau;&omicron;&upsilon;&sigmaf; ό&rho;&omicron;&upsilon;&sigmaf;
          &chi;&rho;ή&sigma;&eta;&sigmaf; &pi;&rho;&omicron;&tau;&omicron;ύ
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;ή&sigma;&epsilon;&tau;&epsilon;
          &tau;&eta;&nu;
          &Iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;
          &mu;&alpha;&sigmaf; &kappa;&alpha;&theta;ώ&sigmaf;
          &alpha;&upsilon;&tau;&omicron;ί &omicron;&iota; ό&rho;&omicron;&iota;
          &chi;&rho;ή&sigma;&eta;&sigmaf;
          &Iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;
          &delta;&iota;έ&pi;&omicron;&upsilon;&nu; &tau;&eta;&nu;
          &pi;&rho;ό&sigma;&beta;&alpha;&sigma;ή &sigma;&alpha;&sigmaf;
          &kappa;&alpha;&iota; &tau;&eta; &chi;&rho;ή&sigma;&eta;
          &alpha;&upsilon;&tau;ή&sigmaf;.&nbsp;</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #242424;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Epsilon;&pi;&omicron;&mu;έ&nu;&omega;&sigmaf;, &eta;
          &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;
          &epsilon;ί&nu;&alpha;&iota;
          &delta;&iota;&alpha;&theta;έ&sigma;&iota;&mu;&eta;
          &pi;&rho;&omicron;&sigmaf; &chi;&rho;ή&sigma;&eta;
          &mu;ό&nu;&omicron;&nu; &epsilon;&phi;ό&sigma;&omicron;&nu; &omicron;
          &chi;&rho;ή&sigma;&tau;&eta;&sigmaf;
          &sigma;&upsilon;&mu;&phi;&omega;&nu;&epsilon;ί &mu;&epsilon;
          &tau;&omicron;&upsilon;&sigmaf; ό&rho;&omicron;&upsilon;&sigmaf;
          &kappa;&alpha;&iota; &tau;&iota;&sigmaf;
          &pi;&rho;&omicron;ϋ&pi;&omicron;&theta;έ&sigma;&epsilon;&iota;&sigmaf;
          &pi;&omicron;&upsilon;
          &alpha;&nu;&alpha;&phi;έ&rho;&omicron;&nu;&tau;&alpha;&iota;
          &sigma;&tau;&eta; &sigma;&upsilon;&nu;έ&chi;&epsilon;&iota;&alpha;.
          &Sigma;&eta;&mu;&epsilon;&iota;ώ&nu;&omicron;&upsilon;&mu;&epsilon;
          ό&tau;&iota; &sigma;&epsilon;
          &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta; &pi;&omicron;&upsilon;
          &delta;&epsilon;&nu;
          &sigma;&upsilon;&mu;&phi;&omega;&nu;&epsilon;ί&tau;&epsilon;
          &mu;&epsilon; ό&lambda;&omicron;&upsilon;&sigmaf;
          &tau;&omicron;&upsilon;&sigmaf; ό&rho;&omicron;&upsilon;&sigmaf;
          &chi;&rho;ή&sigma;&eta;&sigmaf; &delta;&epsilon;&nu;
          &epsilon;&pi;&iota;&tau;&rho;έ&pi;&epsilon;&tau;&alpha;&iota; &eta;
          &pi;&rho;ό&sigma;&beta;&alpha;&sigma;ή &sigma;&alpha;&sigmaf;
          &kappa;&alpha;&iota; &eta; &epsilon;&nu; &gamma;έ&nu;&epsilon;&iota;
          &chi;&rho;ή&sigma;&eta; &tau;&eta;&sigmaf;
          &Iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;
          &mu;&alpha;&sigmaf;.
          &Alpha;&nu;&tau;&iota;&theta;έ&tau;&omega;&sigmaf;, &mu;&epsilon;
          &tau;&eta;&nu; &pi;&rho;ό&sigma;&beta;&alpha;&sigma;ή
          &sigma;&alpha;&sigmaf; &sigma;&tau;&eta;&nu;
          &nbsp;&Iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;,
          &tau;&eta;&nu; &pi;&alpha;&rho;&alpha;&mu;&omicron;&nu;ή
          &kappa;&alpha;&iota; &chi;&rho;ή&sigma;&eta;
          &alpha;&upsilon;&tau;ή&sigmaf;, &omicron;
          &chi;&rho;ή&sigma;&tau;&eta;&sigmaf; &kappa;&alpha;&theta;ώ&sigmaf;
          &kappa;&alpha;&iota; &pi;ά&sigma;&eta;&sigmaf;
          &phi;ύ&sigma;&epsilon;&omega;&sigmaf; έ&tau;&epsilon;&rho;&omicron;
          &pi;&rho;ό&sigma;&omega;&pi;&omicron;
          &epsilon;&xi;&omicron;&upsilon;&sigma;&iota;&omicron;&delta;&omicron;&tau;&eta;&mu;έ&nu;&omicron;
          &alpha;&pi;ό &alpha;&upsilon;&tau;ώ&nu;
          &delta;&eta;&lambda;ώ&nu;&epsilon;&tau;&epsilon; ό&tau;&iota;
          &sigma;&upsilon;&mu;&phi;&omega;&nu;&epsilon;ί&tau;&epsilon;
          &kappa;&alpha;&iota;
          &sigma;&upsilon;&mu;&mu;&omicron;&rho;&phi;ώ&nu;&epsilon;&sigma;&tau;&epsilon;
          &mu;&epsilon; &tau;&omicron;&upsilon;&sigmaf;
          &pi;&alpha;&rho;ό&nu;&tau;&epsilon;&sigmaf;
          ό&rho;&omicron;&upsilon;&sigmaf; &chi;&rho;ή&sigma;&eta;&sigmaf;.
          &nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        &nbsp;
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >2.
          &Lambda;&Epsilon;&Iota;&Tau;&Omicron;&Upsilon;&Rho;&Gamma;&Iota;&Alpha;
          &Iota;&Sigma;&Tau;&Omicron;&Sigma;&Epsilon;&Lambda;&Iota;&Delta;&Alpha;&Sigma;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        &nbsp;
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >2.1&nbsp;</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Eta;
          &Lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;ί&alpha;
          &tau;&eta;&sigmaf;
          &Iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;
          &sigma;&upsilon;&nu;ί&sigma;&tau;&alpha;&tau;&alpha;&iota;,
          &mu;&epsilon;&tau;&alpha;&xi;ύ ά&lambda;&lambda;&omega;&nu;,
          &sigma;&tau;&alpha; &kappa;ά&tau;&omega;&theta;&iota;:</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        &nbsp;
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >2.1.1</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Pi;&rho;&alpha;&gamma;&mu;&alpha;&tau;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;
          &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;&iota;ώ&nu;
          &alpha;&pi;ό
          &sigma;&upsilon;&nu;&epsilon;&rho;&gamma;&alpha;&zeta;ό&mu;&epsilon;&nu;&alpha;
          &kappa;&alpha;&tau;&alpha;&sigma;&tau;ή&mu;&alpha;&tau;&alpha;
          &kappa;&alpha;&iota; &epsilon;&mu;&pi;&omicron;&rho;&iota;&kappa;ά
          &kappa;&alpha;&tau;&alpha;&sigma;&tau;ή&mu;&alpha;&tau;&alpha;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&alpha;) Online
          &pi;&epsilon;&rho;&iota;ή&gamma;&eta;&sigma;&eta;&sigmaf;,
          &nbsp;&epsilon;&nu;&eta;&mu;έ&rho;&omega;&sigma;&eta;&sigmaf;
          &kappa;&alpha;&iota;
          &pi;&rho;&omicron;&beta;&omicron;&lambda;ή&sigmaf;
          &epsilon;&pi;&alpha;&gamma;&gamma;&epsilon;&lambda;&mu;&alpha;&tau;&iota;ώ&nu;
          &kappa;&alpha;&iota;
          &epsilon;&pi;&iota;&chi;&epsilon;&iota;&rho;ή&sigma;&epsilon;&omega;&nu;
          &pi;&omicron;&upsilon; &pi;&alpha;&rho;έ&chi;&omicron;&upsilon;&nu;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;.&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&beta;)
          &Delta;&upsilon;&nu;&alpha;&tau;ό&tau;&eta;&tau;&alpha;&sigmaf;
          &mu;&epsilon;
          &pi;&omicron;&iota;&kappa;ί&lambda;&omicron;&upsilon;&sigmaf;
          &tau;&rho;ό&pi;&omicron;&upsilon;&sigmaf; online
          &alpha;&nu;&alpha;&zeta;ή&tau;&eta;&sigma;&eta;&sigmaf;
          &kappa;&alpha;&iota;
          &sigma;ύ&gamma;&kappa;&rho;&iota;&sigma;&eta;&sigmaf;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
          ή/&kappa;&alpha;&iota; &pi;&rho;&omicron;ϊό&nu;&tau;&omega;&nu;
          &alpha;&nu;ά&lambda;&omicron;&gamma;&alpha; &mu;&epsilon; &tau;&alpha;
          &chi;&alpha;&rho;&alpha;&kappa;&tau;&eta;&rho;&iota;&sigma;&tau;&iota;&kappa;ά
          &tau;&omicron;&upsilon;&sigmaf;
          (&epsilon;&xi;&epsilon;&iota;&delta;ί&kappa;&epsilon;&upsilon;&sigma;&eta;,
          &delta;&iota;&alpha;&theta;&epsilon;&sigma;&iota;&mu;ό&tau;&eta;&tau;&alpha;,
          &tau;&rho;ό&pi;&omicron;&upsilon;&sigmaf;
          &tau;&iota;&mu;&omicron;&lambda;ό&gamma;&eta;&sigma;&eta;&sigmaf;
          &kappa;&lambda;&pi;.).&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&gamma;)
          &Delta;&upsilon;&nu;&alpha;&tau;ό&tau;&eta;&tau;&alpha;&sigmaf; online
          &delta;&iota;&alpha;&beta;ί&beta;&alpha;&sigma;&eta;&sigmaf;
          &epsilon;&nu;&tau;&omicron;&lambda;ή&sigmaf;
          &lambda;ή&psi;&eta;&sigmaf;
          &pi;&rho;&omicron;&sigma;&phi;&omicron;&rho;ώ&nu;.&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&delta;) &Delta;&iota;&alpha;&mu;ό&rho;&phi;&omega;&sigma;&eta;
          &kappa;&alpha;&lambda;&alpha;&theta;&iota;&omicron;ύ
          &kappa;&alpha;&iota; &epsilon;&nu;&tau;&omicron;&lambda;ή&sigmaf;
          &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;&sigmaf;&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&epsilon;) online &pi;&lambda;&eta;&rho;&omega;&mu;ή</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >2.1.2&nbsp;</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >Ά&mu;&epsilon;&sigma;&eta;
          &epsilon;&xi;&upsilon;&pi;&eta;&rho;έ&tau;&eta;&sigma;&eta;
          &kappa;&alpha;&iota; &mu;&epsilon;&tau;&alpha;&phi;&omicron;&rho;ά
          &tau;&omega;&nu;
          &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;&iota;ώ&nu;
          &mu;έ&sigma;&omega; &tau;&eta;&sigmaf;
          &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;ή&sigmaf;
          &pi;&lambda;&alpha;&tau;&phi;ό&rho;&mu;&alpha;&sigmaf;
          &tau;&eta;&sigmaf;
          &epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf; &alpha;&pi;ό
          &delta;ί&kappa;&tau;&upsilon;&omicron;
          &delta;&iota;&alpha;&nu;&omicron;&mu;έ&omega;&nu; &kappa;&alpha;&iota;
          &sigma;&upsilon;&nu;&epsilon;&rho;&gamma;&alpha;&zeta;ό&mu;&epsilon;&nu;&omega;&nu;
          &kappa;&alpha;&tau;&alpha;&sigma;&tau;&eta;&mu;ά&tau;&omega;&nu;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >2.1.3&nbsp;</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Pi;&alpha;&rho;&omicron;&chi;ή
          &pi;&rho;&omicron;&sigma;&phi;&omicron;&rho;ώ&nu;,
          &epsilon;&xi;&upsilon;&pi;&eta;&rho;έ&tau;&eta;&sigma;&eta;
          &kappa;&alpha;&iota;
          &delta;&iota;&epsilon;&kappa;&pi;&epsilon;&rho;&alpha;ί&omega;&sigma;&eta;
          &tau;&omega;&nu; &epsilon;&nu;&tau;&omicron;&lambda;ώ&nu;
          &tau;&alpha;&chi;&upsilon;&mu;&epsilon;&tau;&alpha;&phi;&omicron;&rho;ώ&nu;
          &alpha;&pi;ό &tau;&rho;ί&tau;&epsilon;&sigmaf; ή/&kappa;&alpha;&iota;
          &sigma;&upsilon;&nu;&epsilon;&rho;&gamma;&alpha;&zeta;ό&mu;&epsilon;&nu;&epsilon;&sigmaf;
          &epsilon;&pi;&iota;&chi;&epsilon;&iota;&rho;ή&sigma;&epsilon;&iota;&sigmaf;
          ή/&kappa;&alpha;&iota; &iota;&delta;&iota;ώ&tau;&epsilon;&sigmaf;,
          &mu;έ&sigma;&omega; &tau;&eta;&sigmaf;
          &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;ή&sigmaf;
          &pi;&lambda;&alpha;&tau;&phi;ό&rho;&mu;&alpha;&sigmaf;
          &tau;&eta;&sigmaf;
          &epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf; &alpha;&pi;ό
          &delta;ί&kappa;&tau;&upsilon;&omicron;
          &delta;&iota;&alpha;&nu;&omicron;&mu;έ&omega;&nu; &kappa;&alpha;&iota;
          &sigma;&upsilon;&nu;&epsilon;&rho;&gamma;&alpha;&zeta;ό&mu;&epsilon;&nu;&omega;&nu;
          &kappa;&alpha;&tau;&alpha;&sigma;&tau;&eta;&mu;ά&tau;&omega;&nu;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >2.1.4</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;&Pi;&alpha;&rho;&omicron;&chi;ή
          &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;ή&sigmaf;
          &pi;&lambda;&alpha;&tau;&phi;ό&rho;&mu;&alpha;&sigmaf;
          &gamma;&iota;&alpha; &tau;&eta;&nu;
          &alpha;&xi;&iota;&omicron;&lambda;ό&gamma;&eta;&sigma;&eta;
          &tau;&omega;&nu;
          &pi;&alpha;&rho;&epsilon;&chi;ό&mu;&epsilon;&nu;&omega;&nu;
          &alpha;&pi;ό &tau;&alpha;
          &sigma;&upsilon;&nu;&epsilon;&rho;&gamma;&alpha;&zeta;ό&mu;&epsilon;&nu;&alpha;
          &kappa;&alpha;&tau;&alpha;&sigma;&tau;ή&mu;&alpha;&tau;&alpha;
          &kappa;&alpha;&iota;
          &delta;&eta;&mu;&iota;&omicron;&upsilon;&rho;&gamma;ί&alpha;
          &beta;ά&sigma;&eta;&sigmaf;
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &kappa;&alpha;&iota;
          &alpha;&pi;&omicron;&theta;ή&kappa;&epsilon;&upsilon;&sigma;&eta;&sigmaf;
          &tau;&omega;&nu; &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &alpha;&upsilon;&tau;ώ&nu; &mu;&epsilon; &sigma;&kappa;&omicron;&pi;ό
          &tau;&eta;&nu;
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;
          &tau;&omicron;&upsilon;&sigmaf; &gamma;&iota;&alpha;
          &sigma;&kappa;&omicron;&pi;ό &tau;&eta;&sigmaf;
          &epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;
          ό&pi;&omega;&sigmaf;
          &sigma;&upsilon;&mu;&mu;&omicron;&rho;&phi;ώ&nu;&epsilon;&tau;&alpha;&iota;
          &mu;&epsilon; &tau;&omicron;&upsilon;&sigmaf;
          &Gamma;&Kappa;&Pi;&Delta;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >2.1.5</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;&Sigma;&tau;&alpha;&tau;&iota;&sigma;&tau;&iota;&kappa;ή
          &alpha;&xi;&iota;&omicron;&lambda;ό&gamma;&eta;&sigma;&eta;
          &tau;&omega;&nu; &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &pi;&rho;&omicron;&sigmaf; &chi;&rho;ή&sigma;&eta; &alpha;&pi;ό
          &tau;&alpha;
          &kappa;&alpha;&tau;&alpha;&sigma;&tau;ή&mu;&alpha;&tau;&alpha;
          &pi;&omicron;&upsilon; &alpha;&phi;&omicron;&rho;&omicron;ύ&nu;
          &tau;&omicron;&upsilon;&sigmaf;
          &alpha;&pi;&omicron;&delta;έ&kappa;&tau;&epsilon;&sigmaf; ή
          &kappa;&alpha;&iota; &tau;&rho;ί&tau;&omicron;&upsilon;&sigmaf;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >2.2 &nbsp;</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Sigma;&kappa;&omicron;&pi;ό&sigmaf; &tau;&eta;&sigmaf;
          &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;
          &epsilon;ί&nu;&alpha;&iota; &nu;&alpha;
          &epsilon;&xi;&upsilon;&pi;&eta;&rho;&epsilon;&tau;&epsilon;ί
          &omega;&sigmaf;
          &sigma;&upsilon;&mu;&pi;&lambda;&eta;&rho;&omega;&mu;&alpha;&tau;&iota;&kappa;ό
          &epsilon;&rho;&gamma;&alpha;&lambda;&epsilon;ί&omicron;
          &gamma;&iota;&alpha; &tau;&eta;
          &delta;&rho;&alpha;&sigma;&tau;&eta;&rho;&iota;ό&tau;&eta;&tau;&alpha;
          &tau;&alpha;&chi;&upsilon;&mu;&epsilon;&tau;&alpha;&phi;&omicron;&rho;ώ&nu;/courier
          &pi;&omicron;&upsilon;
          &delta;&iota;&alpha;&theta;έ&tau;&epsilon;&iota; &eta;
          &epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;,
          ό&pi;&omega;&sigmaf;
          &sigma;&upsilon;&mu;&beta;&alpha;&delta;ί&zeta;&epsilon;&iota;
          &kappa;&alpha;&iota;
          &pi;&epsilon;&rho;&epsilon;&tau;&alpha;ί&rho;&omega;
          &alpha;&nu;&alpha;&pi;&tau;ύ&sigma;&sigma;&epsilon;&tau;&alpha;&iota;
          &eta;
          &delta;&rho;&alpha;&sigma;&tau;&eta;&rho;&iota;ό&tau;&eta;&tau;&alpha;
          &alpha;&upsilon;&tau;ή &sigma;&tau;&omicron;&upsilon;&sigmaf;
          ό&rho;&omicron;&upsilon;&sigmaf;
          &ldquo;&Gamma;&epsilon;&nu;&iota;&kappa;&omicron;ύ&sigmaf;
          Ό&rho;&omicron;&upsilon;&sigmaf;
          &Alpha;&nu;ά&lambda;&eta;&psi;&eta;&sigmaf;
          &Mu;&epsilon;&tau;&alpha;&phi;&omicron;&rho;ά&sigmaf;&rdquo;
          &kappa;&alpha;&iota; &sigma;&tau;&omicron;&upsilon;&sigmaf;
          &ldquo;Ό&rho;&omicron;&upsilon;&sigmaf;
          &Chi;&rho;ή&sigma;&eta;&sigmaf;
          &Iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;
          Stoferno Courier&rdquo;&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >2.3&nbsp;</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Eta;
          &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;
          &mu;&pi;&omicron;&rho;&epsilon;ί &nu;&alpha;
          &delta;&iota;&alpha;&theta;έ&sigma;&epsilon;&iota; &alpha;&nu;ά
          &delta;&iota;&alpha;&sigma;&tau;ή&mu;&alpha;&tau;&alpha;
          ή/&kappa;&alpha;&iota; &sigma;&epsilon; &mu;ό&nu;&iota;&mu;&eta;
          &beta;ά&sigma;&eta;
          &delta;&iota;&alpha;&phi;&eta;&mu;&iota;&sigma;&tau;&iota;&kappa;ό
          &chi;ώ&rho;&omicron; &gamma;&iota;&alpha; &sigma;&kappa;&omicron;&pi;ό
          &delta;&iota;&kappa;ό &tau;&eta;&sigmaf; ή/&kappa;&alpha;&iota;
          &tau;&rho;ί&tau;&omicron;&upsilon;&sigmaf; &sigma;&epsilon;
          &omicron;&pi;&omicron;&iota;&alpha;&delta;ή&pi;&omicron;&tau;&epsilon;
          &sigma;&epsilon;&lambda;ί&delta;&alpha;
          &epsilon;&pi;&iota;&theta;&upsilon;&mu;&epsilon;ί ή
          &kappa;&rho;ί&nu;&epsilon;&iota;
          &epsilon;ύ&lambda;&omicron;&gamma;&eta; &eta;
          ί&delta;&iota;&alpha;.&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >3</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;&Eta; &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;
          &delta;&iota;&alpha;&tau;&eta;&rho;&epsilon;ί &tau;&omicron;
          &delta;&iota;&kappa;&alpha;ί&omega;&mu;&alpha; &nu;&alpha;
          &alpha;&phi;&alpha;&iota;&rho;έ&sigma;&epsilon;&iota;, &nu;&alpha;
          &tau;&rho;&omicron;&pi;&omicron;&pi;&omicron;&iota;ή&sigma;&epsilon;&iota;,
          &nu;&alpha;
          &epsilon;&mu;&pi;&lambda;&omicron;&upsilon;&tau;ί&sigma;&epsilon;&iota;
          ή &nu;&alpha;
          &mu;&epsilon;&tau;&alpha;&beta;ά&lambda;&lambda;&epsilon;&iota;
          &kappa;&alpha;&theta;&rsquo;
          &omicron;&iota;&omicron;&nu;&delta;ή&pi;&omicron;&tau;&epsilon;
          &tau;&rho;ό&pi;&omicron; &alpha;&pi;ό &tau;&eta;&nu;
          &Iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;
          &kappa;&alpha;&theta;&epsilon;&mu;ί&alpha; &alpha;&pi;ό
          &tau;&iota;&sigmaf; &alpha;&nu;&omega;&tau;έ&rho;&omega;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;
          &epsilon;&nu; ό&lambda;&omega; ή &epsilon;&nu;
          &mu;έ&rho;&epsilon;&iota; ή &nu;&alpha;
          &pi;&rho;&omicron;&sigma;&theta;έ&sigma;&epsilon;&iota;
          &kappa;&alpha;&iota; &nu;έ&epsilon;&sigmaf;
          &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;
          &sigma;&tau;&alpha; &pi;&lambda;&alpha;ί&sigma;&iota;&alpha;
          &tau;&omicron;&upsilon; &sigma;&kappa;&omicron;&pi;&omicron;ύ
          &tau;&eta;&sigmaf; &kappa;&alpha;&iota; &tau;&omega;&nu;
          &pi;&rho;&omicron;&omicron;&pi;&tau;&iota;&kappa;ώ&nu;
          &alpha;&nu;ά&pi;&tau;&upsilon;&xi;ή&sigmaf; &tau;&eta;&sigmaf;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        &nbsp;
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >3. &Gamma;&Epsilon;&Nu;&Iota;&Kappa;&Epsilon;&Sigma;
          &Upsilon;&Pi;&Omicron;&Chi;&Rho;&Epsilon;&Omega;&Sigma;&Epsilon;&Iota;&Sigma;
          &Epsilon;&Pi;&Iota;&Sigma;&Kappa;&Epsilon;&Pi;&Tau;&Eta;,
          &Chi;&Rho;&Eta;&Sigma;&Tau;&Eta; &Eta;΄
          &Epsilon;&Gamma;&Gamma;&Epsilon;&Gamma;&Rho;&Alpha;&Mu;&Mu;&Epsilon;&Nu;&Omicron;&Upsilon;
          &Mu;&Epsilon;&Lambda;&Omicron;&Upsilon;&Sigma;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        &nbsp;
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >3.1&nbsp;</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >O &epsilon;&pi;&iota;&sigma;&kappa;έ&pi;&tau;&eta;&sigmaf;, &omicron;
          &chi;&rho;ή&sigma;&tau;&eta;&sigmaf; &kappa;&alpha;&theta;ώ&sigmaf;
          &kappa;&alpha;&iota; &tau;&omicron;
          &epsilon;&gamma;&gamma;&epsilon;&gamma;&rho;&alpha;&mu;&mu;έ&nu;&omicron;
          &mu;έ&lambda;&omicron;&sigmaf; &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;
          (&epsilon;&phi;&epsilon;&xi;ή&sigmaf;
          &kappa;&alpha;&lambda;&omicron;ύ&mu;&epsilon;&nu;&omicron;&iota;
          ά&pi;&alpha;&nu;&tau;&epsilon;&sigmaf; &omicron;&iota;
          &alpha;&nu;&omega;&tau;έ&rho;&omega; &chi;ά&rho;&iota;&nu;
          &sigma;&upsilon;&nu;&tau;&omicron;&mu;ί&alpha;&sigmaf;
          &ldquo;&Alpha;&pi;&omicron;&delta;έ&kappa;&tau;&eta;&sigmaf;&rdquo;
          &kappa;&alpha;&iota; &eta; &chi;&rho;ή&sigma;&eta;
          &tau;&omicron;&upsilon; ό&rho;&omicron;&upsilon;
          &alpha;&upsilon;&tau;&omicron;ύ &sigma;&tau;&omicron;
          &pi;&alpha;&rho;ό&nu; &theta;&alpha;
          &lambda;&omicron;&gamma;ί&zeta;&epsilon;&tau;&alpha;&iota;
          ό&tau;&iota; &alpha;&nu;&alpha;&phi;έ&rho;&epsilon;&tau;&alpha;&iota;
          &sigma;&upsilon;&nu;&omicron;&lambda;&iota;&kappa;ά &sigma;&epsilon;
          ό&lambda;&omicron;&upsilon;&sigmaf; &tau;&omicron;&upsilon;&sigmaf;
          &alpha;&nu;&omega;&tau;έ&rho;&omega;), &theta;&alpha;
          &pi;&rho;έ&pi;&epsilon;&iota; &nu;&alpha; &epsilon;ί&nu;&alpha;&iota;
          &epsilon;&nu;ή&lambda;&iota;&kappa;&alpha;&sigmaf;
          &kappa;&alpha;&iota; &nu;&alpha; έ&chi;&epsilon;&iota;
          &pi;&lambda;ή&rho;&eta;
          &delta;&iota;&kappa;&alpha;&iota;&omicron;&pi;&rho;&alpha;&kappa;&tau;&iota;&kappa;ή
          &iota;&kappa;&alpha;&nu;ό&tau;&eta;&tau;&alpha;. &Eta;
          &chi;&rho;ή&sigma;&eta; &tau;&omega;&nu;
          &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon; &theta;&alpha;
          &pi;&rho;έ&pi;&epsilon;&iota; &nu;&alpha;
          &gamma;ί&nu;&epsilon;&tau;&alpha;&iota;
          &alpha;&pi;&omicron;&kappa;&lambda;&epsilon;&iota;&sigma;&tau;&iota;&kappa;ά
          &alpha;&pi;ό &pi;&rho;ό&sigma;&omega;&pi;&alpha;
          &pi;&omicron;&upsilon; έ&chi;&omicron;&upsilon;&nu;
          &sigma;&upsilon;&mu;&pi;&lambda;&eta;&rho;ώ&sigma;&epsilon;&iota;
          &tau;&omicron; 18</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          ><span style="font-size: 0.6em; vertical-align: super"
            >&omicron;</span
          ></span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;έ&tau;&omicron;&sigmaf; &tau;&eta;&sigmaf;
          &eta;&lambda;&iota;&kappa;ί&alpha;&sigmaf;
          &tau;&omicron;&upsilon;&sigmaf;. &Omicron;
          &gamma;&omicron;&nu;έ&alpha;&sigmaf; ή &omicron;
          &nu;ό&mu;&iota;&mu;&omicron;&sigmaf;
          &kappa;&eta;&delta;&epsilon;&mu;ό&nu;&alpha;&sigmaf;
          &tau;&omicron;&upsilon;
          &alpha;&nu;&eta;&lambda;ί&kappa;&omicron;&upsilon;
          &phi;έ&rho;&epsilon;&iota; &tau;&eta;&nu;
          &alpha;&pi;&omicron;&kappa;&lambda;&epsilon;&iota;&sigma;&tau;&iota;&kappa;ή
          &epsilon;&upsilon;&theta;ύ&nu;&eta; &gamma;&iota;&alpha;
          &tau;&eta;&nu; &chi;&rho;ή&sigma;&eta; &tau;&omega;&nu;
          &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu; &tau;&eta;&sigmaf;
          &Iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;
          &alpha;&pi;ό
          &alpha;&nu;&eta;&lambda;ί&kappa;&omicron;&upsilon;&sigmaf;
          &pi;&omicron;&upsilon;
          &beta;&rho;ί&sigma;&kappa;&omicron;&nu;&tau;&alpha;&iota;
          &sigma;&tau;&eta;&nu;
          &kappa;&eta;&delta;&epsilon;&mu;&omicron;&nu;ί&alpha;
          &tau;&omicron;&upsilon;. &Sigma;&epsilon;
          &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta; &pi;&omicron;&upsilon;
          &delta;&epsilon;&nu;
          &kappa;&alpha;&lambda;ύ&pi;&tau;&epsilon;&tau;&epsilon;
          &tau;&iota;&sigmaf;
          &pi;&rho;&omicron;ϋ&pi;&omicron;&theta;έ&sigma;&epsilon;&iota;&sigmaf;
          &alpha;&upsilon;&tau;έ&sigmaf; &delta;&epsilon;&nu;
          &epsilon;&pi;&iota;&tau;&rho;έ&pi;&epsilon;&tau;&alpha;&iota;
          &nu;&alpha;
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&epsilon;ί&tau;&epsilon;
          &tau;&eta;&nu;
          &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;.&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >3.2</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;&Omicron; &chi;&rho;ή&sigma;&tau;&eta;&sigmaf;
          &phi;έ&rho;&epsilon;&iota; &tau;&eta;&nu;
          &alpha;&pi;&omicron;&kappa;&lambda;&epsilon;&iota;&sigma;&tau;&iota;&kappa;ή
          &epsilon;&upsilon;&theta;ύ&nu;&eta; &gamma;&iota;&alpha;
          &tau;&eta;&nu;
          &epsilon;&xi;&alpha;&sigma;&phi;ά&lambda;&iota;&sigma;&eta;
          &kappa;&omega;&delta;&iota;&kappa;ώ&nu; &kappa;&alpha;&iota;
          &tau;&eta;&nu; &alpha;&pi;&omicron;&phi;&upsilon;&gamma;ή
          &kappa;ά&theta;&epsilon;
          &alpha;&nu;ά&rho;&mu;&omicron;&sigma;&tau;&eta;&sigmaf;
          &sigma;&upsilon;&mu;&pi;&epsilon;&rho;&iota;&phi;&omicron;&rho;ά&sigmaf;
          ό&sigma;&omicron;&nu; &alpha;&phi;&omicron;&rho;ά &sigma;&tau;&eta;
          &chi;&rho;ή&sigma;&eta; &tau;έ&tau;&omicron;&iota;&omega;&nu;
          &kappa;&omega;&delta;&iota;&kappa;ώ&nu; &pi;&omicron;&upsilon;
          &pi;&iota;&theta;&alpha;&nu;ό&nu; &nu;&alpha; έ&chi;&epsilon;&iota;
          &lambda;ά&beta;&epsilon;&iota; &gamma;&iota;&alpha;
          &chi;&rho;ή&sigma;&eta; &sigma;&tau;&omicron;
          &delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;ό &tau;ό&pi;&omicron;.
          &Sigma;&tau;&eta;&nu; &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta;
          &pi;&omicron;&upsilon; &omicron; &chi;&rho;ή&sigma;&tau;&eta;&sigmaf;
          &alpha;&nu;&alpha;&kappa;&alpha;&lambda;ύ&psi;&epsilon;&iota;
          ό&tau;&iota; &omicron; &kappa;&omega;&delta;&iota;&kappa;ό&sigmaf;
          &tau;&omicron;&upsilon; έ&chi;&epsilon;&iota;
          &delta;&iota;&alpha;&tau;&epsilon;&theta;&epsilon;ί
          &alpha;&kappa;&omicron;ύ&sigma;&iota;&alpha; &sigma;&epsilon;
          &omicron;&pi;&omicron;&iota;&omicron;&delta;ή&pi;&omicron;&tau;&epsilon;
          &tau;&rho;ί&tau;&omicron; ά&tau;&omicron;&mu;&omicron;
          &pi;&omicron;&upsilon; &delta;&epsilon;&nu; έ&chi;&epsilon;&iota;
          &delta;&iota;&kappa;&alpha;&iota;&omicron;&delta;&omicron;&sigma;ί&alpha;
          ή ό&tau;&iota; &upsilon;&phi;ί&sigma;&tau;&alpha;&tau;&alpha;&iota;
          &kappa;ί&nu;&delta;&upsilon;&nu;&omicron;&sigmaf;
          &kappa;&alpha;&kappa;ή&sigmaf; &chi;&rho;ή&sigma;&eta;&sigmaf;,
          &omicron;&phi;&epsilon;ί&lambda;&epsilon;&iota; &nu;&alpha;
          &epsilon;&nu;&eta;&mu;&epsilon;&rho;ώ&nu;&epsilon;&iota;
          ά&mu;&epsilon;&sigma;&alpha; &tau;&omicron;&nu;
          &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron; &mu;&epsilon;
          &alpha;&pi;&lambda;ό email &sigma;&tau;&omicron; info@stoferno.gr
          έ&tau;&sigma;&iota; ώ&sigma;&tau;&epsilon; &nu;&alpha;
          &alpha;&pi;&omicron;&kappa;&lambda;&epsilon;&iota;&sigma;&tau;&epsilon;ί
          &eta; &pi;&rho;ό&sigma;&beta;&alpha;&sigma;&eta;
          &omicron;&pi;&omicron;&iota;&omicron;&upsilon;&delta;ή&pi;&omicron;&tau;&epsilon;
          &tau;&rho;ί&tau;&omicron;&upsilon; &sigma;&tau;&omicron;&nu;
          &epsilon;&nu; &lambda;ό&gamma;&omega;
          &kappa;&omega;&delta;&iota;&kappa;ό. &Sigma;&epsilon;
          &kappa;ά&theta;&epsilon;
          &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta;
          &pi;ά&nu;&tau;&omega;&sigmaf;, &eta;
          &epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha; &delta;&epsilon;
          &phi;έ&rho;&epsilon;&iota; &kappa;&alpha;&mu;ί&alpha;
          &epsilon;&upsilon;&theta;ύ&nu;&eta; &gamma;&iota;&alpha;
          &kappa;&alpha;&tau;&alpha;&beta;&omicron;&lambda;ή
          &alpha;&pi;&omicron;&zeta;&eta;&mu;ί&omega;&sigma;&eta;&sigmaf;
          &gamma;&iota;&alpha; &tau;&eta;&nu; &kappa;&alpha;&kappa;ή
          &chi;&rho;ή&sigma;&eta;
          &omicron;&pi;&omicron;&iota;&omicron;&upsilon;&delta;ή&pi;&omicron;&tau;&epsilon;
          &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;&omicron;ύ
          &Kappa;&omega;&delta;&iota;&kappa;&omicron;ύ.
          &Epsilon;&pi;&iota;&pi;&lambda;έ&omicron;&nu;, &eta;
          &epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;
          &delta;&iota;&alpha;&tau;&eta;&rho;&epsilon;ί &tau;&omicron;
          &delta;&iota;&kappa;&alpha;ί&omega;&mu;&alpha;,
          &omicron;&pi;&omicron;&iota;&alpha;&delta;ή&pi;&omicron;&tau;&epsilon;
          &sigma;&tau;&iota;&gamma;&mu;ή &kappa;&alpha;&iota;
          &chi;&omega;&rho;ί&sigmaf; &nu;&alpha;
          &upsilon;&pi;&omicron;&chi;&rho;&epsilon;&omicron;ύ&tau;&alpha;&iota;
          &nu;&alpha;
          &delta;&iota;&epsilon;&upsilon;&kappa;&rho;&iota;&nu;ί&sigma;&epsilon;&iota;
          &tau;&omicron;&upsilon;&sigmaf;
          &lambda;ό&gamma;&omicron;&upsilon;&sigmaf;, &nu;&alpha;
          &alpha;&kappa;&upsilon;&rho;ώ&sigma;&epsilon;&iota; ή &nu;&alpha;
          &pi;&epsilon;&rho;&iota;&omicron;&rho;ί&sigma;&epsilon;&iota;
          &tau;&omicron; &delta;&iota;&kappa;&alpha;ί&omega;&mu;&alpha;
          &pi;&rho;ό&sigma;&beta;&alpha;&sigma;&eta;&sigmaf;
          &tau;&omicron;&upsilon; &Chi;&rho;ή&sigma;&tau;&eta;
          &sigma;&tau;&eta;&nu; &pi;&epsilon;&rho;&iota;&omicron;&chi;ή
          &pi;&omicron;&upsilon;
          &pi;&rho;&omicron;&sigma;&tau;&alpha;&tau;&epsilon;ύ&epsilon;&tau;&alpha;&iota;
          &mu;&epsilon; &kappa;&omega;&delta;&iota;&kappa;ό
          &alpha;&pi;&omicron;&kappa;&lambda;&epsilon;ί&omicron;&nu;&tau;&alpha;&sigmaf;
          &tau;&alpha; &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &Chi;&rho;ή&sigma;&tau;&eta;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >3.3</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;&Omicron;
          &Alpha;&pi;&omicron;&delta;έ&kappa;&tau;&eta;&sigmaf;</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&omicron;&phi;&epsilon;ί&lambda;&epsilon;&iota; &nu;&alpha;
          &sigma;&upsilon;&mu;&mu;&omicron;&rho;&phi;ώ&nu;&epsilon;&tau;&alpha;&iota;
          &mu;&epsilon; &tau;&eta;&nu; &kappa;&epsilon;ί&mu;&epsilon;&nu;&eta;
          &epsilon;&lambda;&lambda;&eta;&nu;&iota;&kappa;ή &kappa;&alpha;&iota;
          &Epsilon;&upsilon;&rho;&omega;&pi;&alpha;ϊ&kappa;ή
          &Nu;&omicron;&mu;&omicron;&theta;&epsilon;&sigma;ί&alpha;,
          &kappa;&alpha;&theta;ώ&sigmaf; &kappa;&alpha;&iota; &mu;&epsilon;
          &tau;&iota;&sigmaf;
          &kappa;&upsilon;&rho;&omega;&mu;έ&nu;&epsilon;&sigmaf;
          &Delta;&iota;&epsilon;&theta;&nu;&epsilon;ί&sigmaf;
          &Sigma;&upsilon;&mu;&beta;ά&sigma;&epsilon;&iota;&sigmaf;
          &pi;&omicron;&upsilon;
          &alpha;&pi;&omicron;&tau;&epsilon;&lambda;&omicron;ύ&nu;
          &alpha;&nu;&alpha;&pi;ό&sigma;&pi;&alpha;&sigma;&tau;&omicron;
          &mu;έ&rho;&omicron;&sigmaf; &tau;&omicron;&upsilon;
          &epsilon;&lambda;&lambda;&eta;&nu;&iota;&kappa;&omicron;ύ
          &delta;&iota;&kappa;&alpha;ί&omicron;&upsilon; &kappa;&alpha;&iota;
          &nu;&alpha; &tau;&eta;&rho;&epsilon;ί &tau;&omicron;&nu;
          &Kappa;ώ&delta;&iota;&kappa;&alpha;
          &Delta;&epsilon;&omicron;&nu;&tau;&omicron;&lambda;&omicron;&gamma;&iota;&kappa;ή&sigmaf;
          &sigma;&upsilon;&mu;&pi;&epsilon;&rho;&iota;&phi;&omicron;&rho;ά&sigmaf;
          &sigma;&tau;&omicron;
          &Delta;&iota;&alpha;&delta;ί&kappa;&tau;&upsilon;&omicron;
          ό&pi;&omega;&sigmaf; &iota;&sigma;&chi;ύ&epsilon;&iota; (NETIQUETTE),
          &sigma;&upsilon;&mu;&beta;ά&lambda;&lambda;&omicron;&nu;&tau;&alpha;&sigmaf;
          &kappa;&alpha;&tau;ά &tau;&omicron; &mu;έ&tau;&rho;&omicron;
          &tau;&omicron;&upsilon; &delta;&upsilon;&nu;&alpha;&tau;&omicron;ύ
          &sigma;&tau;&eta;&nu;
          &omicron;&mu;&alpha;&lambda;ό&tau;&epsilon;&rho;&eta;
          &kappa;&alpha;&iota;
          &alpha;&pi;&omicron;&delta;&omicron;&tau;&iota;&kappa;ό&tau;&epsilon;&rho;&eta;
          &alpha;&lambda;&lambda;&eta;&lambda;&epsilon;&pi;ί&delta;&rho;&alpha;&sigma;&eta;
          &mu;&epsilon;&tau;&alpha;&xi;ύ &tau;&omega;&nu;
          &chi;&rho;&eta;&sigma;&tau;ώ&nu; &tau;&omicron;&upsilon;
          &delta;&iota;&alpha;&delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;&omicron;ύ
          &mu;&alpha;&sigmaf; &tau;ό&pi;&omicron;&upsilon; ό&sigma;&omicron;
          &kappa;&alpha;&iota; &mu;&epsilon;&tau;&alpha;&xi;ύ &tau;&omega;&nu;
          &chi;&rho;&eta;&sigma;&tau;ώ&nu; &kappa;&alpha;&iota; &tau;&omega;&nu;
          &delta;&iota;&alpha;&chi;&epsilon;&iota;&rho;&iota;&sigma;&tau;ώ&nu;
          &alpha;&upsilon;&tau;&omicron;ύ.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >3.4&nbsp;</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Omicron; &Alpha;&pi;&omicron;&delta;έ&kappa;&tau;&eta;&sigmaf;
          &omicron;&phi;&epsilon;ί&lambda;&epsilon;&iota; &nu;&alpha;
          &alpha;&pi;έ&chi;&epsilon;&iota; &alpha;&pi;ό &kappa;ά&theta;&epsilon;
          &pi;&alpha;&rho;ά&nu;&omicron;&mu;&eta;
          &sigma;&upsilon;&mu;&pi;&epsilon;&rho;&iota;&phi;&omicron;&rho;ά ή
          &kappa;&alpha;&tau;&alpha;&chi;&rho;&eta;&sigma;&tau;&iota;&kappa;ή
          &epsilon;&nu;έ&rho;&gamma;&epsilon;&iota;&alpha; &kappa;&alpha;&tau;ά
          &tau;&eta;&nu; &epsilon;&pi;ί&sigma;&kappa;&epsilon;&psi;ή
          &tau;&omicron;&upsilon; &sigma;&tau;&omicron;&nu;
          &Iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron; ή &tau;&eta;&nu;
          &chi;&rho;ή&sigma;&eta; &tau;&omega;&nu;
          &pi;&alpha;&rho;&epsilon;&rho;&chi;&omicron;&mu;έ&nu;&omega;&nu;
          &alpha;&pi;ό &alpha;&upsilon;&tau;ό&nu;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;.
          &Alpha;&pi;&alpha;&gamma;&omicron;&rho;&epsilon;ύ&epsilon;&tau;&alpha;&iota;
          &alpha;&kappa;ό&mu;&alpha; &rho;&eta;&tau;ά &eta;
          &upsilon;&iota;&omicron;&theta;έ&tau;&eta;&sigma;&eta; &alpha;&pi;ό
          &tau;&omicron;&nu; &Alpha;&pi;&omicron;&delta;έ&kappa;&tau;&eta;
          &pi;&rho;ά&xi;&epsilon;&omega;&nu; &pi;&omicron;&upsilon;
          &sigma;&upsilon;&nu;&tau;&epsilon;ί&nu;&omicron;&upsilon;&nu;
          &sigma;&epsilon; &alpha;&theta;έ&mu;&iota;&tau;&omicron;
          &alpha;&nu;&tau;&alpha;&gamma;&omega;&nu;&iota;&sigma;&mu;ό ή
          &pi;&alpha;&rho;ά&beta;&alpha;&sigma;&eta; &tau;&omicron;&upsilon;
          &pi;&alpha;&rho;ό&nu;&tau;&omicron;&sigmaf; ή &kappa;ά&theta;&epsilon;
          &epsilon;&iota;&delta;&iota;&kappa;ό&tau;&epsilon;&rho;&eta;&sigmaf;
          &sigma;ύ&mu;&beta;&alpha;&sigma;&eta;&sigmaf; &pi;&omicron;&upsilon;
          &epsilon;&nu;&delta;έ&chi;&epsilon;&tau;&alpha;&iota; &nu;&alpha;
          &sigma;&upsilon;&nu;ά&psi;&epsilon;&iota; &eta;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha; &mu;&epsilon;
          &tau;&omicron;&nu; &Alpha;&pi;&omicron;&delta;έ&kappa;&tau;&eta;
          &kappa;&alpha;&iota; &gamma;&epsilon;&nu;&iota;&kappa;ά
          &alpha;&pi;&alpha;&gamma;&omicron;&rho;&epsilon;ύ&epsilon;&tau;&alpha;&iota;
          &omicron;&pi;&omicron;&iota;&alpha;&delta;ή&pi;&omicron;&tau;&epsilon;
          ά&lambda;&lambda;&eta; &alpha;&theta;έ&mu;&iota;&tau;&eta; ή
          &pi;&alpha;&rho;ά&nu;&omicron;&mu;&eta;
          &pi;&rho;&alpha;&kappa;&tau;&iota;&kappa;ή,
          &epsilon;&pi;&iota;&phi;&upsilon;&lambda;&alpha;&sigma;&sigma;ό&mu;&epsilon;&nu;&eta;&sigmaf;
          &tau;&eta;&sigmaf;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf; &nu;&alpha;
          &alpha;&sigma;&kappa;ή&sigma;&epsilon;&iota; &kappa;ά&theta;&epsilon;
          &nu;ό&mu;&iota;&mu;&omicron; &delta;&iota;&kappa;&alpha;ί&omega;&mu;ά
          &tau;&eta;&sigmaf; &gamma;&iota;&alpha; &tau;&eta;&nu;
          &delta;&iota;&alpha;&sigma;&phi;ά&lambda;&iota;&sigma;&eta;
          &tau;&omega;&nu;
          &sigma;&upsilon;&mu;&phi;&epsilon;&rho;ό&nu;&tau;&omega;&nu;
          &tau;&eta;&sigmaf;.&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >3.5</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp; &Omicron;
          &Alpha;&pi;&omicron;&delta;έ&kappa;&tau;&eta;&sigmaf;
          &delta;&epsilon;&sigma;&mu;&epsilon;ύ&epsilon;&tau;&alpha;&iota;
          ό&tau;&iota; &theta;&alpha; &pi;&alpha;&rho;έ&chi;&epsilon;&iota;
          &pi;&rho;&omicron;&sigmaf; &tau;&omicron;&nu;
          &Iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron; &tau;&alpha;
          &pi;&rho;&alpha;&gamma;&mu;&alpha;&tau;&iota;&kappa;ά
          &tau;&omicron;&upsilon;
          &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
          &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&alpha;, &tau;&omicron;&nu;
          &omicron;&pi;&omicron;ί&omicron;
          &beta;&alpha;&rho;ύ&nu;&epsilon;&iota;
          &alpha;&pi;&omicron;&lambda;ύ&tau;&omega;&sigmaf; &kappa;&alpha;&iota;
          &eta; &sigma;&chi;&epsilon;&tau;&iota;&kappa;ή
          &epsilon;&upsilon;&theta;ύ&nu;&eta;.
          &Epsilon;&iota;&delta;&iota;&kappa;ό&tau;&epsilon;&rho;&alpha;,
          &tau;&alpha; &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&alpha;
          &alpha;&upsilon;&tau;ά, &omega;&sigmaf; &kappa;&alpha;&iota;
          &kappa;ά&theta;&epsilon; ά&lambda;&lambda;&eta;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&alpha; ή
          &psi;&eta;&phi;&iota;&alpha;&kappa;ό
          &alpha;&rho;&chi;&epsilon;ί&omicron; &pi;&omicron;&upsilon;
          &theta;&alpha; &delta;&iota;&alpha;&theta;έ&sigma;&epsilon;&iota;
          &sigma;&tau;&omicron;&nu;
          &Iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron; &delta;&epsilon;&nu;
          &theta;&alpha; &pi;&rho;έ&pi;&epsilon;&iota; &sigma;&epsilon;
          &kappa;&alpha;&mu;ί&alpha;
          &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta; &nu;&alpha;
          &epsilon;ί&nu;&alpha;&iota; &psi;&epsilon;&upsilon;&delta;ή,
          &pi;&alpha;&rho;&alpha;&pi;&lambda;&alpha;&nu;&eta;&tau;&iota;&kappa;ά
          ή &alpha;&nu;&alpha;&kappa;&rho;&iota;&beta;ή,
          &omicron;ύ&tau;&epsilon; &nu;&alpha;
          &omicron;&delta;&eta;&gamma;&omicron;ύ&nu;,
          ά&mu;&epsilon;&sigma;&alpha; ή έ&mu;&mu;&epsilon;&sigma;&alpha;,
          &sigma;&epsilon; &pi;&rho;ό&kappa;&lambda;&eta;&sigma;&eta;
          &alpha;&nu;&alpha;&lambda;&eta;&theta;ώ&nu;
          &pi;&alpha;&rho;&alpha;&sigma;&tau;ά&sigma;&epsilon;&omega;&nu;
          &sigma;&epsilon; &tau;&rho;ί&tau;&omicron;&upsilon;&sigmaf;,
          &omicron;ύ&tau;&epsilon; &mu;&epsilon; &kappa;&alpha;&nu;έ&nu;&alpha;
          &tau;&rho;ό&pi;&omicron; &nu;&alpha;
          &pi;&alpha;&rho;&alpha;&beta;&iota;ά&zeta;&omicron;&upsilon;&nu;
          &tau;&iota;&sigmaf;
          &iota;&sigma;&chi;ύ&omicron;&upsilon;&sigma;&epsilon;&sigmaf;
          &delta;&iota;&alpha;&tau;ά&xi;&epsilon;&iota;&sigmaf;
          &tau;&omicron;&upsilon;
          &Sigma;&upsilon;&nu;&tau;ά&gamma;&mu;&alpha;&tau;&omicron;&sigmaf;
          &kappa;&alpha;&iota; &tau;&omicron;&upsilon;
          &Alpha;&sigma;&tau;&iota;&kappa;&omicron;ύ,
          &Pi;&omicron;&iota;&nu;&iota;&kappa;&omicron;ύ &kappa;&alpha;&iota;
          &Epsilon;&mu;&pi;&omicron;&rho;&iota;&kappa;&omicron;ύ
          &Delta;&iota;&kappa;&alpha;ί&omicron;&upsilon;, ή &nu;&alpha;
          &gamma;&epsilon;&nu;&nu;&omicron;ύ&nu; &kappa;ά&theta;&epsilon;
          &epsilon;ί&delta;&omicron;&upsilon;&sigmaf;
          &delta;&iota;&alpha;&kappa;&rho;ί&sigma;&epsilon;&iota;&sigmaf;
          &pi;&omicron;&upsilon; &beta;&alpha;ί&nu;&omicron;&upsilon;&nu;
          &sigma;&epsilon; &beta;ά&rho;&omicron;&sigmaf; &tau;&eta;&sigmaf;
          &alpha;&xi;ί&alpha;&sigmaf; &tau;&omicron;&upsilon;
          &alpha;&nu;&theta;&rho;ώ&pi;&omicron;&upsilon; &omega;&sigmaf;
          &kappa;&alpha;&iota; &kappa;ά&theta;&epsilon;
          &mu;&omicron;&rho;&phi;ή&sigmaf; &zeta;&omega;ή&sigmaf;,
          &epsilon;&nu;ώ &theta;&alpha; &pi;&rho;έ&pi;&epsilon;&iota;
          &nu;&alpha; &epsilon;ί&nu;&alpha;&iota;
          &sigma;ύ&mu;&phi;&omega;&nu;&alpha; &mu;&epsilon; &tau;&omicron;
          &sigma;ύ&nu;&omicron;&lambda;&omicron; &tau;&eta;&sigmaf;
          &iota;&sigma;&chi;ύ&omicron;&upsilon;&sigma;&alpha;&sigmaf;
          &nu;&omicron;&mu;&omicron;&theta;&epsilon;&sigma;ί&alpha;&sigmaf;
          &sigma;&epsilon; &epsilon;&theta;&nu;&iota;&kappa;ό,
          &epsilon;&upsilon;&rho;&omega;&pi;&alpha;ϊ&kappa;ό
          &kappa;&alpha;&iota; &delta;&iota;&epsilon;&theta;&nu;έ&sigmaf;
          &epsilon;&pi;ί&pi;&epsilon;&delta;&omicron;.&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >3.6</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;&Omicron; &Alpha;&pi;&omicron;&delta;έ&kappa;&tau;&eta;&sigmaf;
          &sigma;&upsilon;&nu;&alpha;&iota;&nu;&epsilon;ί &rho;&eta;&tau;ά
          &delta;&iota;ά &tau;&omicron;&upsilon;
          &pi;&alpha;&rho;ό&nu;&tau;&omicron;&sigmaf; &kappa;&alpha;&iota;
          &mu;&epsilon; &tau;&eta;&nu; &pi;&alpha;&rho;&omicron;&chi;ή
          &tau;&omega;&nu; &omega;&sigmaf; ά&nu;&omega;
          &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&omega;&nu;
          &kappa;&alpha;&iota;
          &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ώ&nu;
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu; ό&tau;&iota;
          &pi;&alpha;&rho;έ&chi;&epsilon;&iota; &pi;&rho;&omicron;&sigmaf;
          &tau;&eta;&nu; &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;
          &tau;&eta;&nu; &rho;&eta;&tau;ή
          &sigma;&upsilon;&nu;&alpha;ί&nu;&epsilon;&sigma;ή
          &tau;&omicron;&upsilon; &alpha;&upsilon;&tau;ά &nu;&alpha;
          &tau;&eta;&rho;&eta;&theta;&omicron;ύ&nu; &sigma;&epsilon;
          &beta;ά&sigma;&eta;
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &pi;&omicron;&upsilon; &eta;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;
          &tau;&eta;&rho;&epsilon;ί &kappa;&alpha;&iota; &nu;&alpha;
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&eta;&theta;&omicron;ύ&nu;
          &tau;ό&sigma;&omicron; &alpha;&pi;ό &tau;&eta;&nu;
          &tau;&epsilon;&lambda;&epsilon;&upsilon;&tau;&alpha;ί&alpha;
          &kappa;&alpha;&iota; &alpha;&pi;ό &kappa;ά&theta;&epsilon;
          &pi;&rho;ό&sigma;&omega;&pi;&omicron; &pi;&omicron;&upsilon;
          έ&lambda;&kappa;&epsilon;&iota;
          &delta;&iota;&kappa;&alpha;&iota;ώ&mu;&alpha;&tau;&alpha; &alpha;&pi;ό
          &alpha;&upsilon;&tau;ή&nu; &gamma;&iota;&alpha;
          &kappa;ά&theta;&epsilon; &sigma;&chi;&epsilon;&tau;&iota;&kappa;ή
          &mu;&epsilon; &tau;&eta;&nu;
          &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;ί&alpha;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;
          &epsilon;&pi;&iota;&kappa;&omicron;&iota;&nu;&omega;&nu;ί&alpha;,
          &omega;&sigmaf; &kappa;&alpha;&iota; &alpha;&pi;ό
          &kappa;ά&theta;&epsilon; &tau;&rho;ί&tau;&omicron;
          &pi;&rho;ό&sigma;&omega;&pi;&omicron;,
          &Epsilon;&pi;&alpha;&gamma;&gamma;&epsilon;&lambda;&mu;&alpha;&tau;ί&alpha;
          ή &Sigma;&upsilon;&nu;&epsilon;&rho;&gamma;ά&tau;&eta;
          &tau;&eta;&sigmaf;, &kappa;&alpha;&tau;ό&pi;&iota;&nu;
          &alpha;&iota;&tau;ή&mu;&alpha;&tau;ό&sigmaf; &tau;&omicron;&upsilon;
          &gamma;&iota;&alpha; &tau;&eta;&nu; &pi;&alpha;&rho;&omicron;&chi;ή
          &kappa;ά&pi;&omicron;&iota;&alpha;&sigmaf;
          &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&alpha;&sigmaf; &alpha;&pi;ό
          &alpha;&upsilon;&tau;ό&nu; ή &tau;&eta;&nu;
          &pi;&alpha;&rho;&omicron;&chi;ή
          &omicron;&iota;&kappa;&omicron;&nu;&omicron;&mu;&iota;&kappa;ή&sigmaf;
          &pi;&rho;&omicron;&sigma;&phi;&omicron;&rho;ά&sigmaf;. &Eta;
          &epsilon;&pi;&iota;&kappa;&omicron;&iota;&nu;&omega;&nu;ί&alpha;
          &theta;&alpha; &mu;&pi;&omicron;&rho;&epsilon;ί &nu;&alpha;
          &pi;&rho;&alpha;&gamma;&mu;&alpha;&tau;&omicron;&pi;&omicron;&iota;&epsilon;ί&tau;&alpha;&iota;
          &mu;&epsilon; &kappa;ά&theta;&epsilon;
          &pi;&rho;ό&sigma;&phi;&omicron;&rho;&omicron; &mu;έ&sigma;&omicron;,
          ό&pi;&omega;&sigmaf; e-mail, sms ή
          &tau;&eta;&lambda;&epsilon;&phi;&omega;&nu;&iota;&kappa;ή
          &kappa;&lambda;ή&sigma;&eta;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >3.7</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;&Omicron; &Alpha;&pi;&omicron;&delta;έ&kappa;&tau;&eta;&sigmaf;
          &delta;&epsilon;&sigma;&mu;&epsilon;ύ&epsilon;&tau;&alpha;&iota;
          ό&tau;&iota; &theta;&alpha;
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&epsilon;ί
          &tau;&omicron;
          &pi;&epsilon;&rho;&iota;&epsilon;&chi;ό&mu;&epsilon;&nu;&omicron;
          &tau;&omicron;&upsilon;
          &delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;&omicron;ύ
          &tau;ό&pi;&omicron;&upsilon;,
          &sigma;&upsilon;&mu;&pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;&alpha;&nu;&omicron;&mu;έ&nu;&omega;&nu;
          &tau;&omega;&nu;
          &pi;&alpha;&rho;&epsilon;&chi;ό&mu;&epsilon;&nu;&omega;&nu;
          &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&omega;&nu; &tau;&omega;&nu;
          &epsilon;&pi;&alpha;&gamma;&gamma;&epsilon;&lambda;&mu;&alpha;&tau;&iota;ώ&nu;
          ή &tau;&omega;&nu; &chi;&rho;&eta;&sigma;&tau;ώ&nu; ή
          &mu;&epsilon;&lambda;ώ&nu; &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;,
          &tau;&omega;&nu; &chi;&alpha;&rho;&tau;ώ&nu;,
          &phi;&omega;&tau;&omicron;&gamma;&rho;&alpha;&phi;&iota;ώ&nu; ή video,
          &mu;ό&nu;&omicron; &gamma;&iota;&alpha; &tau;&eta;&nu;
          &epsilon;&nu;&delta;&epsilon;&delta;&epsilon;&iota;&gamma;&mu;έ&nu;&eta;
          &kappa;&alpha;&iota;
          &epsilon;&gamma;&kappa;&epsilon;&kappa;&rho;&iota;&mu;έ&nu;&eta;
          &alpha;&pi;ό &tau;&eta;&nu;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha; &alpha;&nu;ά
          &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta;
          &chi;&rho;ή&sigma;&eta;.&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >3.8&nbsp;</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >O &Alpha;&pi;&omicron;&delta;έ&kappa;&tau;&eta;&sigmaf;
          &delta;&epsilon;&sigma;&mu;&epsilon;ύ&epsilon;&tau;&alpha;&iota;
          ό&tau;&iota; &theta;&alpha; &mu;&epsilon;&rho;&iota;&mu;&nu;ά
          ώ&sigma;&tau;&epsilon; ό&lambda;&epsilon;&sigmaf; &omicron;&iota;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf; ή
          &tau;&alpha; &psi;&eta;&phi;&iota;&alpha;&kappa;ά
          &alpha;&rho;&chi;&epsilon;ί&alpha; &pi;&omicron;&upsilon;
          &theta;&alpha; &alpha;&nu;&alpha;&rho;&tau;ά ή &theta;&alpha;
          &delta;&iota;&omicron;&chi;&epsilon;&tau;&epsilon;ύ&epsilon;&iota;
          &sigma;&tau;&omicron;&nu;
          &Iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron; &theta;&alpha;
          &epsilon;ί&nu;&alpha;&iota;
          &alpha;&pi;&omicron;&lambda;ύ&tau;&omega;&sigmaf;
          &epsilon;&lambda;&epsilon;&gamma;&mu;έ&nu;&alpha; &mu;&epsilon;
          &delta;&iota;&kappa;ή &tau;&omicron;&upsilon;
          &mu;έ&rho;&iota;&mu;&nu;&alpha; ώ&sigma;&tau;&epsilon; &nu;&alpha;
          &mu;&eta;&nu; &pi;&epsilon;&rho;&iota;έ&chi;&omicron;&upsilon;&nu;
          &omicron;&pi;&omicron;&iota;&omicron;&delta;ή&pi;&omicron;&tau;&epsilon;
          &kappa;&alpha;&kappa;ό&beta;&omicron;&upsilon;&lambda;&omicron; ή
          &epsilon;&nu; &gamma;έ&nu;&epsilon;&iota;
          &beta;&lambda;&alpha;&beta;&epsilon;&rho;ό
          &lambda;&omicron;&gamma;&iota;&sigma;&mu;&iota;&kappa;ό (malware)
          &pi;&omicron;&upsilon; &mu;&pi;&omicron;&rho;&epsilon;ί &nu;&alpha;
          &pi;&rho;&omicron;&kappa;&alpha;&lambda;έ&sigma;&epsilon;&iota;
          &omicron;&pi;&omicron;&iota;&alpha;&delta;ή&pi;&omicron;&tau;&epsilon;
          &zeta;&eta;&mu;ί&alpha; ή
          &kappa;&alpha;&theta;&upsilon;&sigma;&tau;έ&rho;&eta;&sigma;&eta;
          &sigma;&tau;&omicron;&upsilon;&sigmaf; servers ή &tau;&alpha;
          &sigma;&upsilon;&sigma;&tau;ή&mu;&alpha;&tau;&alpha;
          &tau;&eta;&sigmaf;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;
          &kappa;&alpha;&theta;ώ&sigmaf; &kappa;&alpha;&iota;
          &omicron;&iota;&omicron;&upsilon;&delta;ή&pi;&omicron;&tau;&epsilon;
          &tau;&rho;ί&tau;&omicron;&upsilon; &mu;&epsilon; &tau;&omicron;&nu;
          &omicron;&pi;&omicron;ί&omicron; &alpha;&upsilon;&tau;ή
          &sigma;&upsilon;&nu;&epsilon;&rho;&gamma;ά&zeta;&epsilon;&tau;&alpha;&iota;,
          &kappa;&alpha;&iota; &sigma;&tau;&omicron;&upsilon;&sigmaf;
          &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;&omicron;ύ&sigmaf;
          &upsilon;&pi;&omicron;&lambda;&omicron;&gamma;&iota;&sigma;&tau;έ&sigmaf;,
          &tau;&iota;&sigmaf;
          &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;έ&sigmaf;
          &sigma;&upsilon;&sigma;&kappa;&epsilon;&upsilon;έ&sigmaf; ή
          &tau;&omicron;&upsilon;&sigmaf; servers &tau;&omega;&nu;
          &lambda;&omicron;&iota;&pi;ώ&nu;
          &epsilon;&pi;&iota;&sigma;&kappa;&epsilon;&pi;&tau;ώ&nu;,
          &chi;&rho;&eta;&sigma;&tau;ώ&nu; ή &mu;&epsilon;&lambda;ώ&nu;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;,
          ό&pi;&omega;&sigmaf;
          &epsilon;&nu;&delta;&epsilon;&iota;&kappa;&tau;&iota;&kappa;ά
          &iota;&omicron;ύ&sigmaf;, trojans, worms &kappa;.&lambda;&pi;.,
          &omicron;ύ&tau;&epsilon; &kappa;ά&pi;&omicron;&iota;&omicron;
          ά&lambda;&lambda;&omicron; &alpha;&rho;&chi;&epsilon;ί&omicron; ή
          &lambda;&omicron;&gamma;&iota;&sigma;&mu;&iota;&kappa;ό
          &pi;&omicron;&upsilon; &theta;&alpha;
          &pi;&alpha;&rho;&alpha;&beta;&iota;ά&zeta;&epsilon;&iota;
          &tau;&eta;&nu;
          &iota;&delta;&iota;&omega;&tau;&iota;&kappa;ό&tau;&eta;&tau;&alpha; ή
          &tau;&alpha; &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha; &tau;&eta;&sigmaf;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;,
          &tau;&omega;&nu;
          &epsilon;&rho;&gamma;&alpha;&zeta;&omicron;&mu;έ&nu;&omega;&nu;
          &sigma;&epsilon; &alpha;&upsilon;&tau;ή&nu;, &tau;&omega;&nu;
          &sigma;&upsilon;&nu;&epsilon;&rho;&gamma;&alpha;&tau;ώ&nu;
          &tau;&eta;&sigmaf; &kappa;&alpha;&iota; &kappa;ά&theta;&epsilon;
          &epsilon;&pi;&iota;&sigma;&kappa;έ&pi;&tau;&eta;,
          &chi;&rho;ή&sigma;&tau;&eta; ή
          &epsilon;&gamma;&gamma;&epsilon;&gamma;&rho;&alpha;&mu;&mu;έ&nu;&omicron;&upsilon;
          &mu;έ&lambda;&omicron;&upsilon;&sigmaf; &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;.&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >3.9</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;&Alpha;&nu; &kappa;&alpha;&iota; &eta;
          &epsilon;&tau;&alpha;&iota;&rho;ί&alpha; &kappa;ά&nu;&epsilon;&iota;
          &kappa;ά&theta;&epsilon;
          &pi;&rho;&omicron;&sigma;&pi;ά&theta;&epsilon;&iota;&alpha;
          &nu;&alpha; &delta;&iota;&alpha;&tau;&eta;&rho;ή&sigma;&epsilon;&iota;
          &tau;&omicron;&nu; &iota;&sigma;&tau;ό&tau;&omicron;&pi;ό
          &tau;&eta;&sigmaf; &kappa;&alpha;&theta;&alpha;&rho;ό &alpha;&pi;ό
          &iota;&omicron;ύ&sigmaf;,
          &alpha;&delta;&upsilon;&nu;&alpha;&tau;&epsilon;ί &nu;&alpha;
          &pi;&alpha;&rho;έ&chi;&epsilon;&iota;
          &omicron;&pi;&omicron;&iota;&alpha;&delta;ή&pi;&omicron;&tau;&epsilon;
          &epsilon;&gamma;&gamma;ύ&eta;&sigma;&eta; ό&tau;&iota;
          &delta;&epsilon;&nu; &theta;&alpha;
          &pi;&rho;&omicron;&sigma;&beta;&lambda;&eta;&theta;&epsilon;ί
          &alpha;&pi;ό &iota;&omicron;ύ&sigmaf;. &Omicron;
          &kappa;ά&theta;&epsilon; &Chi;&rho;ή&sigma;&tau;&eta;&sigmaf;,
          &gamma;&iota;&alpha; &delta;&iota;&kappa;ή &tau;&omicron;&upsilon;
          &pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha;,
          &pi;&rho;έ&pi;&epsilon;&iota; &nu;&alpha; &kappa;ά&nu;&epsilon;&iota;
          &tau;&iota;&sigmaf; &delta;έ&omicron;&upsilon;&sigma;&epsilon;&sigmaf;
          &epsilon;&nu;έ&rho;&gamma;&epsilon;&iota;&epsilon;&sigmaf;
          &gamma;&iota;&alpha; &nu;&alpha;
          &epsilon;&xi;&alpha;&sigma;&phi;&alpha;&lambda;ί&sigma;&epsilon;&iota;
          &tau;&alpha; &kappa;&alpha;&tau;ά&lambda;&lambda;&eta;&lambda;&alpha;
          &mu;έ&tau;&rho;&alpha;
          &alpha;&sigma;&phi;ά&lambda;&epsilon;&iota;&alpha;&sigmaf;
          &kappa;&alpha;&iota; &nu;&alpha;
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;ή&sigma;&epsilon;&iota;
          &tau;&alpha; &kappa;&alpha;&tau;ά&lambda;&lambda;&eta;&lambda;&alpha;
          &mu;έ&sigma;&alpha;
          &alpha;&nu;ί&chi;&nu;&epsilon;&upsilon;&sigma;&eta;&sigmaf;
          &iota;ώ&nu; &pi;&rho;&iota;&nu;
          &alpha;&pi;&omicron;&theta;&eta;&kappa;&epsilon;ύ&sigma;&epsilon;&iota;
          &omicron;&pi;&omicron;&iota;&alpha;&delta;ή&pi;&omicron;&tau;&epsilon;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&alpha;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >3.10</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;&Rho;&eta;&tau;ά &epsilon;&pi;ί&sigma;&eta;&sigmaf;
          &alpha;&pi;&alpha;&gamma;&omicron;&rho;&epsilon;ύ&epsilon;&tau;&alpha;&iota;
          &eta; &mu;&epsilon;
          &omicron;&pi;&omicron;&iota;&omicron;&nu;&delta;ή&pi;&omicron;&tau;&epsilon;
          &tau;&epsilon;&chi;&nu;&iota;&kappa;ό ή &mu;&eta;
          &tau;&rho;ό&pi;&omicron;
          &pi;&alpha;&rho;έ&mu;&beta;&alpha;&sigma;&eta;
          &omicron;&pi;&omicron;&iota;&omicron;&upsilon;&delta;ή&pi;&omicron;&tau;&epsilon;
          &tau;&rho;ί&tau;&omicron;&upsilon; &sigma;&tau;&eta;&nu;
          &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;ί&alpha;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;,
          &chi;&omega;&rho;ί&sigmaf; &rho;&eta;&tau;ή
          έ&gamma;&gamma;&rho;&alpha;&phi;&eta;
          &sigma;&upsilon;&nu;&alpha;ί&nu;&epsilon;&sigma;&eta;
          &tau;&eta;&sigmaf;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;.&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >3.11</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;&Omicron; &Alpha;&pi;&omicron;&delta;έ&kappa;&tau;&eta;&sigmaf;
          &delta;&epsilon;&sigma;&mu;&epsilon;ύ&epsilon;&tau;&alpha;&iota;
          ό&tau;&iota; &theta;&alpha; &alpha;&pi;έ&chi;&epsilon;&iota;
          &alpha;&pi;ό &kappa;ά&theta;&epsilon;
          &upsilon;&beta;&rho;&iota;&sigma;&tau;&iota;&kappa;ή,
          &pi;&rho;&omicron;&sigma;&beta;&lambda;&eta;&tau;&iota;&kappa;ή ή
          &rho;&alpha;&tau;&sigma;&iota;&sigma;&tau;&iota;&kappa;ή
          &alpha;&nu;ά&rho;&tau;&eta;&sigma;&eta; &sigma;&tau;&omicron;&nu;
          &Iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron; &kappa;&alpha;&iota;,
          &sigma;&epsilon; &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta;
          &alpha;&nu;ά&rho;&tau;&eta;&sigma;&eta;&sigmaf;
          &kappa;&rho;&iota;&tau;&iota;&kappa;ή&sigmaf; &gamma;&iota;&alpha;
          &kappa;ά&pi;&omicron;&iota;&omicron;&nu;
          &Epsilon;&pi;&alpha;&gamma;&gamma;&epsilon;&lambda;&mu;&alpha;&tau;ί&alpha;-&Sigma;&upsilon;&nu;&epsilon;&rho;&gamma;ά&tau;&eta;,
          &alpha;&upsilon;&tau;ή &theta;&alpha; &pi;&rho;έ&pi;&epsilon;&iota;
          &nu;&alpha; &epsilon;ί&nu;&alpha;&iota; &gamma;ό&nu;&iota;&mu;&eta;
          &kappa;&alpha;&iota;
          &alpha;&nu;&tau;&iota;&kappa;&epsilon;&iota;&mu;&epsilon;&nu;&iota;&kappa;ή,
          &epsilon;&nu;&tau;ό&sigmaf; &tau;&omega;&nu;
          &omicron;&rho;ί&omega;&nu; &pi;&omicron;&upsilon;
          &epsilon;&pi;&iota;&beta;ά&lambda;&lambda;&omicron;&upsilon;&nu; &eta;
          &kappa;&alpha;&lambda;ή &pi;ί&sigma;&tau;&eta; &kappa;&alpha;&iota;
          &tau;&alpha; &chi;&rho;&eta;&sigma;&tau;ά ή&theta;&eta;
          &kappa;&alpha;&iota; &omicron; &Nu;ό&mu;&omicron;&sigmaf;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >4. &Gamma;&Epsilon;&Nu;&Iota;&Kappa;&Epsilon;&Sigma;
          &Upsilon;&Pi;&Omicron;&Chi;&Rho;&Epsilon;&Omega;&Sigma;&Epsilon;&Iota;&Sigma;
          &Epsilon;&Pi;&Alpha;&Gamma;&Gamma;&Epsilon;&Lambda;&Mu;&Alpha;&Tau;&Iota;&Alpha;
          &Chi;&Rho;&Eta;&Sigma;&Tau;&Eta;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        &nbsp;
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >4.1&nbsp;</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Omicron;&iota;
          &Epsilon;&pi;&alpha;&gamma;&gamma;&epsilon;&lambda;&mu;&alpha;&tau;ί&epsilon;&sigmaf;-&Chi;&rho;ή&sigma;&tau;&epsilon;&sigmaf;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;
          &delta;ύ&nu;&alpha;&nu;&tau;&alpha;&iota; &nu;&alpha;
          &sigma;&upsilon;&mu;&beta;ά&lambda;&lambda;&omicron;&nu;&tau;&alpha;&iota;
          &mu;&epsilon; &tau;&eta;&nu;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;
          &epsilon;ί&tau;&epsilon; &mu;&epsilon; &tau;&eta;&nu;
          &pi;&lambda;ή&rho;&eta;
          &sigma;&upsilon;&mu;&pi;&lambda;ή&rho;&omega;&sigma;&eta;
          &tau;&eta;&sigmaf; &sigma;&chi;&epsilon;&tau;&iota;&kappa;ή&sigmaf;
          &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;ή&sigmaf;
          &phi;ό&rho;&mu;&alpha;&sigmaf; &pi;&omicron;&upsilon;
          &beta;&rho;ί&sigma;&kappa;&epsilon;&tau;&alpha;&iota;
          &sigma;&tau;&omicron;&nu;
          &Iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;,
          &epsilon;ί&tau;&epsilon; &mu;&epsilon; ad hoc
          &sigma;ύ&mu;&beta;&alpha;&sigma;&eta; &pi;&omicron;&upsilon;
          &theta;&alpha;
          &kappa;&alpha;&tau;&alpha;&rho;&tau;ί&sigma;&omicron;&upsilon;&nu;
          &mu;&epsilon; &tau;&eta;&nu;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;
          &alpha;&nu;ά&lambda;&omicron;&gamma;&alpha; &mu;&epsilon;
          &tau;&eta;&nu; &mu;&omicron;&rho;&phi;ή &kappa;&alpha;&iota;
          &tau;&omicron; &pi;&lambda;&alpha;ί&sigma;&iota;&omicron;
          &tau;&eta;&sigmaf;
          &sigma;&upsilon;&nu;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;&sigmaf;
          &tau;&omicron;&upsilon;&sigmaf;, &sigma;&tau;&omicron;
          &omicron;&pi;&omicron;ί&omicron;
          &kappa;&alpha;&theta;&omicron;&rho;ί&zeta;&epsilon;&tau;&alpha;&iota;
          &kappa;&alpha;&iota; &tau;&omicron;
          &kappa;ό&sigma;&tau;&omicron;&sigmaf;
          &sigma;&upsilon;&mu;&mu;&epsilon;&tau;&omicron;&chi;ή&sigmaf;
          &tau;&omicron;&upsilon;&sigmaf; &sigma;&tau;&omicron;&nu;
          &Iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;,
          &epsilon;ί&tau;&epsilon; &sigma;&epsilon; &mu;&omicron;&rho;&phi;ή
          &pi;&epsilon;&rho;&iota;&omicron;&delta;&iota;&kappa;ή&sigmaf;
          &sigma;&upsilon;&nu;&delta;&rho;&omicron;&mu;ή&sigmaf;
          &epsilon;ί&tau;&epsilon; &mu;έ&sigma;&omega;
          ά&lambda;&lambda;&omicron;&upsilon; &tau;ύ&pi;&omicron;&upsilon;
          &kappa;&omicron;&sigma;&tau;&omicron;&lambda;ό&gamma;&eta;&sigma;&eta;&sigmaf;
          &alpha;&nu;ά&lambda;&omicron;&gamma;&alpha; &mu;&epsilon;
          &tau;&eta;&nu;
          &tau;&iota;&mu;&omicron;&lambda;&omicron;&gamma;&iota;&alpha;&kappa;ή
          &pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή &tau;&eta;&sigmaf;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;
          &kappa;&alpha;&tau;ά
          &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >4.2</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;&Omicron;&iota;
          &Epsilon;&pi;&alpha;&gamma;&gamma;&epsilon;&lambda;&mu;&alpha;&tau;ί&epsilon;&sigmaf;-&Chi;&rho;ή&sigma;&tau;&epsilon;&sigmaf;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;
          &omicron;&phi;&epsilon;ί&lambda;&omicron;&upsilon;&nu; &nu;&alpha;
          &tau;&eta;&rho;&omicron;ύ&nu;
          &alpha;&pi;&alpha;&rho;έ&gamma;&kappa;&lambda;&iota;&tau;&alpha;
          &tau;ό&sigma;&omicron; &tau;&omicron;
          &pi;&epsilon;&rho;&iota;&epsilon;&chi;ό&mu;&epsilon;&nu;&omicron;
          &sigma;&tau;&omicron; &pi;&alpha;&rho;ό&nu;
          &Gamma;&epsilon;&nu;&iota;&kappa;ό
          &Pi;&lambda;&alpha;ί&sigma;&iota;&omicron; Ό&rho;&omega;&nu;
          &Chi;&rho;ή&sigma;&eta;&sigmaf;, ό&sigma;&omicron;
          &kappa;&alpha;&iota; &kappa;ά&theta;&epsilon;
          &epsilon;&pi;&iota;&mu;έ&rho;&omicron;&upsilon;&sigmaf;
          ό&rho;&omicron; &pi;&omicron;&upsilon;
          &tau;ί&theta;&epsilon;&tau;&alpha;&iota; &mu;&epsilon;
          &tau;&iota;&sigmaf; ad hoc &kappa;&alpha;&tau;ά
          &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta;
          &sigma;&upsilon;&mu;&beta;ά&sigma;&epsilon;&iota;&sigmaf;
          &pi;&omicron;&upsilon;
          &epsilon;&nu;&delta;έ&chi;&epsilon;&tau;&alpha;&iota; &nu;&alpha;
          &kappa;&alpha;&tau;&alpha;&rho;&tau;ί&sigma;&omicron;&upsilon;&nu;
          &mu;&epsilon; &tau;&eta;&nu;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;, &epsilon;&nu;ώ
          &omicron;&phi;&epsilon;ί&lambda;&omicron;&upsilon;&nu;,
          &kappa;&alpha;&tau;ά &tau;&eta;&nu;
          &sigma;&upsilon;&nu;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;
          &tau;&omicron;&upsilon;&sigmaf; &mu;&epsilon; &tau;&omicron;&nu;
          &Iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron; &nu;&alpha;
          &tau;&eta;&rho;&omicron;ύ&nu; &kappa;ά&theta;&epsilon;
          &theta;&epsilon;&sigma;&pi;&iota;&sigma;&mu;έ&nu;&eta;
          &nu;&omicron;&mu;&iota;&kappa;ή &delta;&iota;ά&tau;&alpha;&xi;&eta;
          &tau;ό&sigma;&omicron; &tau;&omicron;&upsilon;
          &Epsilon;&theta;&nu;&iota;&kappa;&omicron;ύ, ό&sigma;&omicron;
          &kappa;&alpha;&iota; &tau;&omicron;&upsilon;
          &Epsilon;&upsilon;&rho;&omega;&pi;&alpha;ϊ&kappa;&omicron;ύ
          &kappa;&alpha;&iota;
          &Delta;&iota;&epsilon;&theta;&nu;&omicron;ύ&sigmaf;
          &Delta;&iota;&kappa;&alpha;ί&omicron;&upsilon; &kappa;&alpha;&iota;
          &tau;&omicron;&nu; &Kappa;ώ&delta;&iota;&kappa;&alpha;
          &Delta;&epsilon;&omicron;&nu;&tau;&omicron;&lambda;&omicron;&gamma;&iota;&kappa;ή&sigmaf;
          &sigma;&upsilon;&mu;&pi;&epsilon;&rho;&iota;&phi;&omicron;&rho;ά&sigmaf;
          &sigma;&tau;&omicron;
          &Delta;&iota;&alpha;&delta;ί&kappa;&tau;&upsilon;&omicron;
          ό&pi;&omega;&sigmaf; &iota;&sigma;&chi;ύ&epsilon;&iota; (NETIQUETTE),
          &epsilon;&nu;ώ
          &alpha;&pi;&alpha;&gamma;&omicron;&rho;&epsilon;ύ&epsilon;&tau;&alpha;&iota;
          &nu;&alpha; &pi;&rho;&omicron;&beta;&alpha;ί&nu;&omicron;&upsilon;&nu;
          &sigma;&epsilon; &pi;&rho;ά&xi;&epsilon;&iota;&sigmaf; ή &nu;&alpha;
          &delta;&iota;&alpha;&mu;&omicron;&rho;&phi;ώ&nu;&omicron;&upsilon;&nu;
          &pi;&rho;&alpha;&kappa;&tau;&iota;&kappa;έ&sigmaf;
          &pi;&omicron;&upsilon;
          &pi;&alpha;&rho;&alpha;&beta;&iota;ά&zeta;&omicron;&upsilon;&nu;
          &tau;&alpha; &delta;&iota;&kappa;&alpha;&iota;ώ&mu;&alpha;&tau;&alpha;
          &tau;&omicron;&upsilon;
          &Kappa;&alpha;&tau;&alpha;&nu;&alpha;&lambda;&omega;&tau;ή,
          &sigma;&upsilon;&nu;&iota;&sigma;&tau;&omicron;ύ&nu;
          &pi;&alpha;&rho;&alpha;&pi;&lambda;&alpha;&nu;&eta;&tau;&iota;&kappa;ή
          &delta;&iota;&alpha;&phi;ή&mu;&iota;&sigma;&eta; ή
          &sigma;&upsilon;&nu;&tau;&epsilon;ί&nu;&omicron;&upsilon;&nu;
          &sigma;&epsilon; &alpha;&theta;έ&mu;&iota;&tau;&omicron;
          &alpha;&nu;&tau;&alpha;&gamma;&omega;&nu;&iota;&sigma;&mu;ό
          &tau;ό&sigma;&omicron; &omega;&sigmaf; &pi;&rho;&omicron;&sigmaf;
          &tau;&omicron;&upsilon;&sigmaf;
          &lambda;&omicron;&iota;&pi;&omicron;ύ&sigmaf;
          &sigma;&upsilon;&nu;&epsilon;&rho;&gamma;ά&tau;&epsilon;&sigmaf;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;
          ό&sigma;&omicron; &kappa;&alpha;&iota; &pi;&rho;&omicron;&sigmaf;
          &tau;&omicron;&nu; ί&delta;&iota;&omicron; &tau;&omicron;&nu;
          &Iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >4.3&nbsp;</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Omicron;&iota;
          &Epsilon;&pi;&alpha;&gamma;&gamma;&epsilon;&lambda;&mu;&alpha;&tau;ί&epsilon;&sigmaf;-&Chi;&rho;ή&sigma;&tau;&epsilon;&sigmaf;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;
          &delta;&epsilon;&sigma;&mu;&epsilon;ύ&omicron;&nu;&tau;&alpha;&iota;
          &kappa;&alpha;&iota;
          &alpha;&nu;&alpha;&lambda;&alpha;&mu;&beta;ά&nu;&omicron;&upsilon;&nu;
          &pi;&lambda;ή&rho;&omega;&sigmaf; &tau;&eta;&nu;
          &sigma;&chi;&epsilon;&tau;&iota;&kappa;ή
          &epsilon;&upsilon;&theta;ύ&nu;&eta; ό&tau;&iota; &theta;&alpha;
          &pi;&alpha;&rho;έ&chi;&omicron;&upsilon;&nu;
          &pi;&rho;&omicron;&sigmaf; &tau;&omicron;&nu;
          &Iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron; &tau;&alpha;
          &pi;&rho;&alpha;&gamma;&mu;&alpha;&tau;&iota;&kappa;ά
          &tau;&omicron;&upsilon;&sigmaf;
          &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
          &kappa;&alpha;&iota;
          &epsilon;&pi;&alpha;&gamma;&gamma;&epsilon;&lambda;&mu;&alpha;&tau;&iota;&kappa;ά
          &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&alpha;,
          ό&pi;&omega;&sigmaf;
          &epsilon;&nu;&delta;&epsilon;&iota;&kappa;&tau;&iota;&kappa;ά
          &tau;&eta;&nu; &epsilon;&pi;&omega;&nu;&upsilon;&mu;ί&alpha;,
          &tau;&eta;&nu; &delta;&iota;&epsilon;ύ&theta;&upsilon;&nu;&sigma;&eta;
          &tau;&eta;&sigmaf;
          &epsilon;&pi;&alpha;&gamma;&gamma;&epsilon;&lambda;&mu;&alpha;&tau;&iota;&kappa;ή&sigmaf;
          &tau;&omicron;&upsilon;&sigmaf; έ&delta;&rho;&alpha;&sigmaf;,
          &sigma;&epsilon; &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta;
          &nu;&omicron;&mu;&iota;&kappa;&omicron;ύ
          &pi;&rho;&omicron;&sigma;ώ&pi;&omicron;&upsilon; &tau;&alpha;
          &pi;&lambda;ή&rho;&eta;
          &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&alpha;
          &tau;&omicron;&upsilon; &nu;&omicron;&mu;ί&mu;&omicron;&upsilon;
          &epsilon;&kappa;&pi;&rho;&omicron;&sigma;ώ&pi;&omicron;&upsilon;
          &tau;&omicron;&upsilon;&sigmaf; &kappa;&alpha;&iota;
          &tau;&omicron;&upsilon;
          &omicron;&rho;&iota;&sigma;&mu;έ&nu;&omicron;&upsilon;
          &gamma;&iota;&alpha; &tau;&eta;&nu;
          &epsilon;&pi;&iota;&kappa;&omicron;&iota;&nu;&omega;&nu;ί&alpha;
          &mu;&epsilon; &tau;&omicron;&nu;
          &Iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron; &kappa;&alpha;&iota;
          &tau;&omicron;&upsilon;&sigmaf;
          &epsilon;&pi;&iota;&sigma;&kappa;έ&pi;&tau;&epsilon;&sigmaf;,
          &tau;&omicron;&upsilon;&sigmaf;
          &chi;&rho;ή&sigma;&tau;&epsilon;&sigmaf; ή &tau;&alpha;
          &mu;έ&lambda;&eta; &tau;&omicron;&upsilon;
          &pi;&rho;&omicron;&sigma;ώ&pi;&omicron;&upsilon; &tau;&alpha;
          &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&alpha;
          &epsilon;&pi;&iota;&kappa;&omicron;&iota;&nu;&omega;&nu;ί&alpha;&sigmaf;
          &tau;&omicron;&upsilon;&sigmaf; (ή&tau;&omicron;&iota;
          &epsilon;&nu;&delta;&epsilon;&iota;&kappa;&tau;&iota;&kappa;ά
          &kappa;&alpha;&iota; ό&chi;&iota;
          &pi;&epsilon;&rho;&iota;&omicron;&rho;&iota;&sigma;&tau;&iota;&kappa;ά
          &alpha;&rho;&iota;&theta;&mu;&omicron;ί
          &sigma;&tau;&alpha;&theta;&epsilon;&rho;ώ&nu; &kappa;&alpha;&iota;
          &kappa;&iota;&nu;&eta;&tau;ώ&nu;
          &tau;&eta;&lambda;&epsilon;&phi;ώ&nu;&omega;&nu;,
          &alpha;&rho;&iota;&theta;&mu;&omicron;ί fax,
          &delta;&iota;&epsilon;ύ&theta;&upsilon;&nu;&sigma;&eta; e-mail, domain
          names &tau;&omega;&nu;
          &epsilon;&pi;&alpha;&gamma;&gamma;&epsilon;&lambda;&mu;&alpha;&tau;&iota;&kappa;ώ&nu;
          &tau;&omicron;&upsilon;&sigmaf;
          &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&omega;&nu;
          &kappa;.&lambda;&pi;.), &tau;&omicron;
          &alpha;&kappa;&rho;&iota;&beta;έ&sigmaf;
          &pi;&epsilon;&delta;ί&omicron;
          &delta;&rho;&alpha;&sigma;&tau;&eta;&rho;&iota;ό&tau;&eta;&tau;ά&sigmaf;
          &tau;&omicron;&upsilon;&sigmaf; &kappa;&alpha;&iota;
          &tau;&iota;&sigmaf;
          &kappa;&alpha;&tau;&eta;&gamma;&omicron;&rho;ί&epsilon;&sigmaf;
          &tau;&omega;&nu;
          &pi;&alpha;&rho;&epsilon;&chi;ό&mu;&epsilon;&nu;&omega;&nu;
          &alpha;&pi;ό &alpha;&upsilon;&tau;&omicron;ύ&sigmaf;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;.
          &Epsilon;&iota;&delta;&iota;&kappa;ό&tau;&epsilon;&rho;&alpha;,
          &tau;&alpha; &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&alpha;
          &alpha;&upsilon;&tau;ά, &omega;&sigmaf; &kappa;&alpha;&iota;
          &kappa;ά&theta;&epsilon; ά&lambda;&lambda;&eta;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&alpha; ή
          &psi;&eta;&phi;&iota;&alpha;&kappa;ό
          &alpha;&rho;&chi;&epsilon;ί&omicron; &pi;&omicron;&upsilon;
          &theta;&alpha; &delta;&iota;&alpha;&theta;έ&sigma;&epsilon;&iota;
          &sigma;&tau;&omicron;&nu;
          &Iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron; &delta;&epsilon;&nu;
          &theta;&alpha; &pi;&rho;έ&pi;&epsilon;&iota; &sigma;&epsilon;
          &kappa;&alpha;&mu;ί&alpha;
          &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta; &nu;&alpha;
          &epsilon;ί&nu;&alpha;&iota; &psi;&epsilon;&upsilon;&delta;ή,
          &pi;&alpha;&rho;&alpha;&pi;&lambda;&alpha;&nu;&eta;&tau;&iota;&kappa;ά
          ή &alpha;&nu;&alpha;&kappa;&rho;&iota;&beta;ή,
          &omicron;ύ&tau;&epsilon; &nu;&alpha;
          &omicron;&delta;&eta;&gamma;&omicron;ύ&nu;,
          ά&mu;&epsilon;&sigma;&alpha; ή έ&mu;&mu;&epsilon;&sigma;&alpha;,
          &sigma;&epsilon; &pi;&rho;ό&kappa;&lambda;&eta;&sigma;&eta;
          &alpha;&nu;&alpha;&lambda;&eta;&theta;ώ&nu;
          &pi;&alpha;&rho;&alpha;&sigma;&tau;ά&sigma;&epsilon;&omega;&nu;
          &sigma;&epsilon; &tau;&rho;ί&tau;&omicron;&upsilon;&sigmaf; ή
          &nu;&alpha;
          &alpha;&pi;&omicron;&beta;&lambda;έ&pi;&omicron;&upsilon;&nu;
          &sigma;&tau;&eta;&nu; &mu;&epsilon;
          &omicron;&pi;&omicron;&iota;&omicron;&nu;&delta;ή&pi;&omicron;&tau;&epsilon;
          &tau;&rho;ό&pi;&omicron; &delta;&iota;ά&theta;&epsilon;&sigma;&eta;
          &pi;&rho;&omicron;ϊό&nu;&tau;&omega;&nu; ή
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
          &pi;&omicron;&upsilon;
          &alpha;&nu;&tau;ί&kappa;&epsilon;&iota;&tau;&alpha;&iota;
          &sigma;&tau;&eta;&nu; &kappa;&epsilon;ί&mu;&epsilon;&nu;&eta;
          &nu;&omicron;&mu;&omicron;&theta;&epsilon;&sigma;ί&alpha;.
          &Pi;&epsilon;&rho;&alpha;&iota;&tau;έ&rho;&omega;, &tau;&alpha;
          &omega;&sigmaf; ά&nu;&omega;
          &pi;&alpha;&rho;&epsilon;&chi;ό&mu;&epsilon;&nu;&alpha;
          &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&alpha; &delta;&epsilon;&nu;
          &theta;&alpha; &pi;&rho;έ&pi;&epsilon;&iota; &mu;&epsilon;
          &omicron;&pi;&omicron;&iota;&omicron;&nu;&delta;ή&pi;&omicron;&tau;&epsilon;
          &tau;&rho;ό&pi;&omicron; &nu;&alpha;
          &pi;&alpha;&rho;&alpha;&beta;&iota;ά&zeta;&omicron;&upsilon;&nu;
          &tau;&iota;&sigmaf;
          &iota;&sigma;&chi;ύ&omicron;&upsilon;&sigma;&epsilon;&sigmaf;
          &delta;&iota;&alpha;&tau;ά&xi;&epsilon;&iota;&sigmaf;
          &tau;&omicron;&upsilon;
          &Sigma;&upsilon;&nu;&tau;ά&gamma;&mu;&alpha;&tau;&omicron;&sigmaf;
          &kappa;&alpha;&iota; &tau;&omicron;&upsilon;
          &Alpha;&sigma;&tau;&iota;&kappa;&omicron;ύ,
          &Pi;&omicron;&iota;&nu;&iota;&kappa;&omicron;ύ &kappa;&alpha;&iota;
          &Epsilon;&mu;&pi;&omicron;&rho;&iota;&kappa;&omicron;ύ
          &Delta;&iota;&kappa;&alpha;ί&omicron;&upsilon;,
          &sigma;&upsilon;&mu;&pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;&alpha;&nu;&omicron;&mu;έ&nu;&omega;&nu;
          &tau;&omega;&nu; &delta;&iota;&alpha;&tau;ά&xi;&epsilon;&omega;&nu;
          &pi;&omicron;&upsilon; &alpha;&phi;&omicron;&rho;&omicron;ύ&nu;
          &sigma;&tau;&omicron; &Delta;ί&kappa;&alpha;&iota;&omicron;
          &Pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha;&sigmaf;
          &Kappa;&alpha;&tau;&alpha;&nu;&alpha;&lambda;&omega;&tau;ή
          &kappa;&alpha;&iota;
          &Delta;&iota;&alpha;&phi;ή&mu;&iota;&sigma;&eta;&sigmaf;,
          &sigma;&tau;&omicron;&nu; &Alpha;&theta;έ&mu;&iota;&tau;&omicron;
          &Alpha;&nu;&tau;&alpha;&gamma;&omega;&nu;&iota;&sigma;&mu;ό,
          &sigma;&tau;&eta;&nu;
          &Pi;&nu;&epsilon;&upsilon;&mu;&alpha;&tau;&iota;&kappa;ή
          &kappa;&alpha;&iota;
          &Beta;&iota;&omicron;&mu;&eta;&chi;&alpha;&nu;&iota;&kappa;ή
          &Iota;&delta;&iota;&omicron;&kappa;&tau;&eta;&sigma;ί&alpha;
          &kappa;&alpha;&iota; &sigma;&tau;&eta;&nu;
          &Pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha;
          &tau;&eta;&sigmaf;
          &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ό&tau;&eta;&tau;&alpha;&sigmaf;,
          &omicron;ύ&tau;&epsilon; &nu;&alpha;
          &pi;&epsilon;&rho;&iota;έ&chi;&omicron;&upsilon;&nu; ή &nu;&alpha;
          &gamma;&epsilon;&nu;&nu;&omicron;ύ&nu; &kappa;ά&theta;&epsilon;
          &epsilon;ί&delta;&omicron;&upsilon;&sigmaf;
          &delta;&iota;&alpha;&kappa;&rho;ί&sigma;&epsilon;&iota;&sigmaf;
          &pi;&omicron;&upsilon; &beta;&alpha;ί&nu;&omicron;&upsilon;&nu;
          &sigma;&epsilon; &beta;ά&rho;&omicron;&sigmaf; &tau;&eta;&sigmaf;
          &alpha;&xi;ί&alpha;&sigmaf; &tau;&omicron;&upsilon;
          &alpha;&nu;&theta;&rho;ώ&pi;&omicron;&upsilon; &omega;&sigmaf;
          &kappa;&alpha;&iota; &kappa;ά&theta;&epsilon;
          &mu;&omicron;&rho;&phi;ή&sigmaf; &zeta;&omega;ή&sigmaf;,
          &epsilon;&nu;ώ &theta;&alpha; &pi;&rho;έ&pi;&epsilon;&iota;
          &nu;&alpha; &epsilon;ί&nu;&alpha;&iota;
          &sigma;ύ&mu;&phi;&omega;&nu;&alpha; &mu;&epsilon; &tau;&omicron;
          &sigma;ύ&nu;&omicron;&lambda;&omicron; &tau;&eta;&sigmaf;
          &iota;&sigma;&chi;ύ&omicron;&upsilon;&sigma;&alpha;&sigmaf;
          &nu;&omicron;&mu;&omicron;&theta;&epsilon;&sigma;ί&alpha;&sigmaf;
          &sigma;&epsilon; &epsilon;&theta;&nu;&iota;&kappa;ό,
          &epsilon;&upsilon;&rho;&omega;&pi;&alpha;ϊ&kappa;ό
          &kappa;&alpha;&iota; &delta;&iota;&epsilon;&theta;&nu;έ&sigmaf;
          &epsilon;&pi;ί&pi;&epsilon;&delta;&omicron;.&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >4.4</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;&Omicron;&iota;
          &Epsilon;&pi;&alpha;&gamma;&gamma;&epsilon;&lambda;&mu;&alpha;&tau;ί&epsilon;&sigmaf;-&Chi;&rho;ή&sigma;&tau;&epsilon;&sigmaf;
          &sigma;&upsilon;&nu;&alpha;&iota;&nu;&omicron;ύ&nu; &rho;&eta;&tau;ά
          &delta;&iota;ά &tau;&omicron;&upsilon;
          &pi;&alpha;&rho;ό&nu;&tau;&omicron;&sigmaf; &kappa;&alpha;&iota;
          &mu;&epsilon; &tau;&eta;&nu; &pi;&alpha;&rho;&omicron;&chi;ή
          &tau;&omega;&nu; &omega;&sigmaf; ά&nu;&omega;
          &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&omega;&nu;
          &kappa;&alpha;&iota;
          &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ώ&nu;
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu; ό&tau;&iota;
          &pi;&alpha;&rho;έ&chi;&omicron;&upsilon;&nu;
          &pi;&rho;&omicron;&sigmaf; &tau;&eta;&nu;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha; &tau;&eta;&nu;
          &rho;&eta;&tau;ή &sigma;&upsilon;&nu;&alpha;ί&nu;&epsilon;&sigma;ή
          &tau;&omicron;&upsilon;&sigmaf; &alpha;&upsilon;&tau;ά &nu;&alpha;
          &tau;&eta;&rho;&eta;&theta;&omicron;ύ&nu; &sigma;&epsilon;
          &beta;ά&sigma;&eta;
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu; &mu;&epsilon;
          &kappa;&alpha;&tau;&eta;&gamma;&omicron;&rho;&iota;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;
          &alpha;&nu;ά&lambda;&omicron;&gamma;&alpha; &mu;&epsilon;
          &tau;&eta;&nu;
          &epsilon;&pi;&alpha;&gamma;&gamma;&epsilon;&lambda;&mu;&alpha;&tau;&iota;&kappa;ή
          &tau;&omicron;&upsilon;&sigmaf;
          &delta;&rho;&alpha;&sigma;&tau;&eta;&rho;&iota;ό&tau;&eta;&tau;&alpha;
          &kappa;&alpha;&iota; &tau;&omicron; &epsilon;ί&delta;&omicron;&sigmaf;
          &tau;&omega;&nu;
          &pi;&alpha;&rho;&epsilon;&chi;ό&mu;&epsilon;&nu;&omega;&nu;
          &alpha;&pi;ό &alpha;&upsilon;&tau;&omicron;ύ&sigmaf;
          &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
          &pi;&omicron;&upsilon; &eta;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;
          &tau;&eta;&rho;&epsilon;ί &kappa;&alpha;&iota; &nu;&alpha;
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&eta;&theta;&omicron;ύ&nu;
          &tau;ό&sigma;&omicron; &alpha;&pi;ό &tau;&eta;&nu;
          &tau;&epsilon;&lambda;&epsilon;&upsilon;&tau;&alpha;ί&alpha;
          &kappa;&alpha;&iota; &alpha;&pi;ό &kappa;ά&theta;&epsilon;
          &pi;&rho;ό&sigma;&omega;&pi;&omicron; &pi;&omicron;&upsilon;
          έ&lambda;&kappa;&epsilon;&iota;
          &delta;&iota;&kappa;&alpha;&iota;ώ&mu;&alpha;&tau;&alpha; &alpha;&pi;ό
          &alpha;&upsilon;&tau;ή&nu; &gamma;&iota;&alpha;
          &kappa;ά&theta;&epsilon; &sigma;&chi;&epsilon;&tau;&iota;&kappa;ή
          &mu;&epsilon; &tau;&eta;&nu;
          &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;ί&alpha;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;
          &epsilon;&pi;&iota;&kappa;&omicron;&iota;&nu;&omega;&nu;ί&alpha;,
          &omega;&sigmaf; &kappa;&alpha;&iota; &alpha;&pi;ό
          &kappa;ά&theta;&epsilon; &tau;&rho;ί&tau;&omicron;
          &pi;&rho;ό&sigma;&omega;&pi;&omicron;, &chi;&rho;ή&sigma;&tau;&eta;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon; ή
          &Sigma;&upsilon;&nu;&epsilon;&rho;&gamma;ά&tau;&eta;
          &tau;&eta;&sigmaf;, &kappa;&alpha;&tau;ό&pi;&iota;&nu;
          &alpha;&iota;&tau;ή&mu;&alpha;&tau;ό&sigmaf; &tau;&omicron;&upsilon;
          &gamma;&iota;&alpha; &tau;&eta;&nu; &pi;&alpha;&rho;&omicron;&chi;ή
          &kappa;ά&pi;&omicron;&iota;&alpha;&sigmaf;
          &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&alpha;&sigmaf; &alpha;&pi;ό
          &alpha;&upsilon;&tau;ό&nu; ή &tau;&eta;&nu;
          &pi;&alpha;&rho;&omicron;&chi;ή
          &omicron;&iota;&kappa;&omicron;&nu;&omicron;&mu;&iota;&kappa;ή&sigmaf;
          &pi;&rho;&omicron;&sigma;&phi;&omicron;&rho;ά&sigmaf;. &Eta;
          &epsilon;&pi;&iota;&kappa;&omicron;&iota;&nu;&omega;&nu;ί&alpha;
          &theta;&alpha; &mu;&pi;&omicron;&rho;&epsilon;ί &nu;&alpha;
          &pi;&rho;&alpha;&gamma;&mu;&alpha;&tau;&omicron;&pi;&omicron;&iota;&epsilon;ί&tau;&alpha;&iota;
          &mu;&epsilon; &kappa;ά&theta;&epsilon;
          &pi;&rho;ό&sigma;&phi;&omicron;&rho;&omicron; &mu;έ&sigma;&omicron;,
          ό&pi;&omega;&sigmaf; e-mail, sms ή
          &tau;&eta;&lambda;&epsilon;&phi;&omega;&nu;&iota;&kappa;ή
          &kappa;&lambda;ή&sigma;&eta;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >4.5</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;&Omicron;&iota;
          &Epsilon;&pi;&alpha;&gamma;&gamma;&epsilon;&lambda;&mu;&alpha;&tau;ί&epsilon;&sigmaf;-&Chi;&rho;ή&sigma;&tau;&epsilon;&sigmaf;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;
          &delta;&epsilon;&sigma;&mu;&epsilon;ύ&omicron;&nu;&tau;&alpha;&iota;
          &nu;&alpha; &pi;&rho;&omicron;&omega;&theta;&omicron;ύ&nu;
          &mu;έ&sigma;&omega; &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;
          &tau;&iota;&sigmaf;
          &pi;&alpha;&rho;&epsilon;&chi;ό&mu;&epsilon;&nu;&epsilon;&sigmaf;
          &alpha;&pi;ό &alpha;&upsilon;&tau;&omicron;ύ&sigmaf;
          &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;
          &kappa;&alpha;&tau;ά &tau;&rho;ό&pi;&omicron;
          &sigma;ύ&mu;&phi;&omega;&nu;&omicron; &mu;&epsilon; &tau;&eta;&nu;
          &kappa;&alpha;&lambda;ή &pi;ί&sigma;&tau;&eta; &kappa;&alpha;&iota;
          &tau;&alpha; &chi;&rho;&eta;&sigma;&tau;ά ή&theta;&eta;,
          &alpha;&pi;έ&chi;&omicron;&nu;&tau;&alpha;&sigmaf; &alpha;&pi;ό
          &kappa;ά&theta;&epsilon; &pi;&rho;&alpha;&kappa;&tau;&iota;&kappa;ή
          &pi;&omicron;&upsilon;
          &sigma;&upsilon;&nu;&tau;&epsilon;ί&nu;&epsilon;&iota; ή
          &mu;&pi;&omicron;&rho;&epsilon;ί &nu;&alpha;
          &sigma;&upsilon;&mu;&beta;ά&lambda;&lambda;&epsilon;&iota;
          &mu;&epsilon;
          &omicron;&pi;&omicron;&iota;&omicron;&nu;&delta;ή&pi;&omicron;&tau;&epsilon;
          &tau;&rho;ό&pi;&omicron; &sigma;&epsilon;
          &pi;&alpha;&rho;&alpha;&pi;&lambda;ά&nu;&eta;&sigma;&eta;
          &tau;&omicron;&upsilon;
          &kappa;&alpha;&tau;&alpha;&nu;&alpha;&lambda;&omega;&tau;ή
          &kappa;&alpha;&tau;ά &tau;&alpha;
          &omicron;&rho;&iota;&zeta;ό&mu;&epsilon;&nu;&alpha;
          &sigma;&tau;&omicron;&nu; &nu;. 2251/1994 &pi;&epsilon;&rho;ί
          &pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha;&sigmaf;
          &Kappa;&alpha;&tau;&alpha;&nu;&alpha;&lambda;&omega;&tau;ή
          ό&pi;&omega;&sigmaf; &iota;&sigma;&chi;ύ&epsilon;&iota;,
          &tau;&omicron; &Pi;&Delta; 131.2003 &kappa;&alpha;&iota;
          &tau;&eta;&nu; &kappa;&epsilon;ί&mu;&epsilon;&nu;&eta;
          &nu;&omicron;&mu;&omicron;&theta;&epsilon;&sigma;ί&alpha;
          &sigma;&tau;&omicron;
          &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;ό
          &epsilon;&mu;&pi;ό&rho;&iota;&omicron; &kappa;&alpha;&iota;
          &alpha;&pi;έ&chi;&omicron;&nu;&tau;&alpha;&sigmaf; &alpha;&pi;ό
          &omicron;&pi;&omicron;&iota;&alpha;&delta;ή&pi;&omicron;&tau;&epsilon;
          &pi;&rho;&alpha;&kappa;&tau;&iota;&kappa;ή &iota;&kappa;&alpha;&nu;ή
          &nu;&alpha;
          &pi;&rho;&omicron;&kappa;&alpha;&lambda;έ&sigma;&epsilon;&iota;
          &beta;&lambda;ά&beta;&eta; ή
          &delta;&upsilon;&sigma;&lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;ί&alpha;
          &sigma;&tau;&omicron; &delta;ί&kappa;&tau;&upsilon;&omicron;,
          ό&pi;&omega;&sigmaf; &mu;&alpha;&zeta;&iota;&kappa;ή
          &alpha;&pi;&omicron;&sigma;&tau;&omicron;&lambda;ή
          &mu;&eta;&nu;&upsilon;&mu;ά&tau;&omega;&nu; (&laquo;spaming&raquo;) ή
          &mu;&alpha;&zeta;&iota;&kappa;ή
          &sigma;&upsilon;&gamma;&kappa;έ&nu;&tau;&rho;&omega;&sigma;&eta;
          &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ώ&nu;
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &chi;&rho;&eta;&sigma;&tau;ώ&nu; &mu;&epsilon;
          &sigma;&kappa;&omicron;&pi;ό &tau;&eta;&nu;
          &delta;&iota;&alpha;&phi;ή&mu;&iota;&sigma;&eta;
          &epsilon;&kappa;&tau;ό&sigmaf; &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >4.6</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;&Omicron;&iota;
          &Epsilon;&pi;&alpha;&gamma;&gamma;&epsilon;&lambda;&mu;&alpha;&tau;ί&epsilon;&sigmaf;-&Chi;&rho;ή&sigma;&tau;&epsilon;&sigmaf;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;
          &omicron;&phi;&epsilon;ί&lambda;&omicron;&upsilon;&nu; &nu;&alpha;
          &sigma;έ&beta;&omicron;&nu;&tau;&alpha;&iota; &kappa;&alpha;&iota;
          &nu;&alpha;
          &sigma;&upsilon;&mu;&beta;ά&lambda;&lambda;&omicron;&upsilon;&nu;
          &sigma;&tau;&eta;&nu;
          &delta;&iota;&alpha;&phi;ύ&lambda;&alpha;&xi;&eta; &tau;&eta;&sigmaf;
          &omicron;&rho;&theta;ή&sigmaf; &kappa;&alpha;&iota;
          &alpha;&kappa;ώ&lambda;&upsilon;&tau;&eta;&sigmaf;
          &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;ί&alpha;&sigmaf;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;,
          &mu;&epsilon;&rho;&iota;&mu;&nu;ώ&nu;&tau;&alpha;&sigmaf;
          ώ&sigma;&tau;&epsilon; ό&lambda;&epsilon;&sigmaf; &omicron;&iota;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf; ή
          &tau;&alpha; &psi;&eta;&phi;&iota;&alpha;&kappa;ά
          &alpha;&rho;&chi;&epsilon;ί&alpha; &pi;&omicron;&upsilon;
          &theta;&alpha; &alpha;&nu;&alpha;&rho;&tau;ά ή &theta;&alpha;
          &delta;&iota;&omicron;&chi;&epsilon;&tau;&epsilon;ύ&epsilon;&iota;
          &sigma;&tau;&omicron;&nu;
          &Iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron; &theta;&alpha;
          &epsilon;ί&nu;&alpha;&iota;
          &alpha;&pi;&omicron;&lambda;ύ&tau;&omega;&sigmaf;
          &epsilon;&lambda;&epsilon;&gamma;&mu;έ&nu;&alpha; &mu;&epsilon;
          &delta;&iota;&kappa;ή &tau;&omicron;&upsilon;
          &mu;έ&rho;&iota;&mu;&nu;&alpha; ώ&sigma;&tau;&epsilon; &nu;&alpha;
          &mu;&eta;&nu; &pi;&epsilon;&rho;&iota;έ&chi;&omicron;&upsilon;&nu;
          &omicron;&pi;&omicron;&iota;&omicron;&delta;ή&pi;&omicron;&tau;&epsilon;
          &kappa;&alpha;&kappa;ό&beta;&omicron;&upsilon;&lambda;&omicron; ή
          &epsilon;&nu; &gamma;έ&nu;&epsilon;&iota;
          &beta;&lambda;&alpha;&beta;&epsilon;&rho;ό
          &lambda;&omicron;&gamma;&iota;&sigma;&mu;&iota;&kappa;ό (malware)
          &pi;&omicron;&upsilon; &mu;&pi;&omicron;&rho;&epsilon;ί &nu;&alpha;
          &pi;&rho;&omicron;&kappa;&alpha;&lambda;έ&sigma;&epsilon;&iota;
          &omicron;&pi;&omicron;&iota;&alpha;&delta;ή&pi;&omicron;&tau;&epsilon;
          &zeta;&eta;&mu;ί&alpha; ή
          &kappa;&alpha;&theta;&upsilon;&sigma;&tau;έ&rho;&eta;&sigma;&eta;
          &sigma;&tau;&omicron;&upsilon;&sigmaf; servers ή &tau;&alpha;
          &sigma;&upsilon;&sigma;&tau;ή&mu;&alpha;&tau;&alpha;
          &tau;&eta;&sigmaf;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;
          &kappa;&alpha;&iota; &pi;&alpha;&nu;&tau;ό&sigmaf;
          &tau;&rho;ί&tau;&omicron;&upsilon; &mu;&epsilon; &tau;&omicron;&nu;
          &omicron;&pi;&omicron;ί&omicron; &alpha;&upsilon;&tau;ή
          &sigma;&upsilon;&nu;&epsilon;&rho;&gamma;ά&zeta;&epsilon;&tau;&alpha;&iota;,
          &kappa;&alpha;&iota; &sigma;&tau;&omicron;&upsilon;&sigmaf;
          &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;&omicron;ύ&sigmaf;
          &upsilon;&pi;&omicron;&lambda;&omicron;&gamma;&iota;&sigma;&tau;έ&sigmaf;,
          &tau;&iota;&sigmaf;
          &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;έ&sigmaf;
          &sigma;&upsilon;&sigma;&kappa;&epsilon;&upsilon;έ&sigmaf; ή
          &tau;&omicron;&upsilon;&sigmaf; servers &tau;&omega;&nu;
          &lambda;&omicron;&iota;&pi;ώ&nu;
          &epsilon;&pi;&iota;&sigma;&kappa;&epsilon;&pi;&tau;ώ&nu;,
          &chi;&rho;&eta;&sigma;&tau;ώ&nu; ή &mu;&epsilon;&lambda;ώ&nu;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;,
          ό&pi;&omega;&sigmaf;
          &epsilon;&nu;&delta;&epsilon;&iota;&kappa;&tau;&iota;&kappa;ά
          &iota;&omicron;ύ&sigmaf;, trojans, worms &kappa;.&lambda;&pi;.,
          &omicron;ύ&tau;&epsilon; &kappa;ά&pi;&omicron;&iota;&omicron;
          ά&lambda;&lambda;&omicron; &alpha;&rho;&chi;&epsilon;ί&omicron; ή
          &lambda;&omicron;&gamma;&iota;&sigma;&mu;&iota;&kappa;ό
          &pi;&omicron;&upsilon; &theta;&alpha;
          &pi;&alpha;&rho;&alpha;&beta;&iota;ά&zeta;&epsilon;&iota;
          &tau;&eta;&nu;
          &iota;&delta;&iota;&omega;&tau;&iota;&kappa;ό&tau;&eta;&tau;&alpha; ή
          &tau;&alpha; &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha; &tau;&eta;&sigmaf;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;,
          &tau;&omega;&nu;
          &epsilon;&rho;&gamma;&alpha;&zeta;&omicron;&mu;έ&nu;&omega;&nu;
          &sigma;&epsilon; &alpha;&upsilon;&tau;ή&nu;, &tau;&omega;&nu;
          &lambda;&omicron;&iota;&pi;ώ&nu;
          &epsilon;&pi;&alpha;&gamma;&gamma;&epsilon;&lambda;&mu;&alpha;&tau;&iota;ώ&nu;-&sigma;&upsilon;&nu;&epsilon;&rho;&gamma;&alpha;&tau;ώ&nu;
          &tau;&eta;&sigmaf; &kappa;&alpha;&iota; &kappa;ά&theta;&epsilon;
          &epsilon;&pi;&iota;&sigma;&kappa;έ&pi;&tau;&eta;,
          &chi;&rho;ή&sigma;&tau;&eta; ή
          &epsilon;&gamma;&gamma;&epsilon;&gamma;&rho;&alpha;&mu;&mu;έ&nu;&omicron;&upsilon;
          &mu;έ&lambda;&omicron;&upsilon;&sigmaf; &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;.&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >4.7</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;&Omicron;&iota;
          &Epsilon;&pi;&alpha;&gamma;&gamma;&epsilon;&lambda;&mu;&alpha;&tau;ί&epsilon;&sigmaf;-&Chi;&rho;ή&sigma;&tau;&epsilon;&sigmaf;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon; &mu;&epsilon;
          &tau;&eta;&nu; έ&nu;&alpha;&rho;&xi;&eta; &tau;&eta;&sigmaf;
          &sigma;&upsilon;&nu;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;&sigmaf;
          &tau;&omicron;&upsilon;&sigmaf; &mu;&epsilon; &tau;&eta;&nu;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha; &kappa;&alpha;&iota;
          &tau;&omicron;&nu; &Iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;,
          &pi;&alpha;&rho;έ&chi;&omicron;&upsilon;&nu; &rho;&eta;&tau;ά
          &tau;&eta;&nu; &sigma;&upsilon;&nu;&alpha;ί&nu;&epsilon;&sigma;ή
          &tau;&omicron;&upsilon;&sigmaf; &nu;&alpha;
          &delta;έ&chi;&omicron;&nu;&tau;&alpha;&iota; &kappa;ά&theta;&epsilon;
          &epsilon;ί&delta;&omicron;&upsilon;&sigmaf;
          &epsilon;&pi;&iota;&kappa;&omicron;&iota;&nu;&omega;&nu;ί&epsilon;&sigmaf;,
          &pi;&rho;&omicron;&sigma;&kappa;&lambda;ή&sigma;&epsilon;&iota;&sigmaf;,
          &alpha;&iota;&tau;ή&mu;&alpha;&tau;&alpha; &tau;&omega;&nu;
          &epsilon;&pi;&iota;&sigma;&kappa;&epsilon;&pi;&tau;ώ&nu;,
          &chi;&rho;&eta;&sigma;&tau;ώ&nu; ή &mu;&epsilon;&lambda;ώ&nu;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;
          &gamma;&iota;&alpha;
          &omicron;&iota;&kappa;&omicron;&nu;&omicron;&mu;&iota;&kappa;έ&sigmaf;
          &pi;&rho;&omicron;&sigma;&phi;&omicron;&rho;έ&sigmaf;
          &sigma;&tau;&alpha; &pi;&lambda;&alpha;ί&sigma;&iota;&alpha;
          &tau;&eta;&sigmaf;
          &pi;&epsilon;&rho;&iota;&gamma;&rho;&alpha;&phi;ό&mu;&epsilon;&nu;&eta;&sigmaf;
          &sigma;&tau;&omicron; &upsilon;&pi;ό 2 &tau;&omicron;&upsilon;
          &pi;&alpha;&rho;ό&nu;&tau;&omicron;&sigmaf;
          &Lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;ί&alpha;&sigmaf;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;.&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >4.8</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;&Omicron;&iota;
          &Epsilon;&pi;&alpha;&gamma;&gamma;&epsilon;&lambda;&mu;&alpha;&tau;ί&epsilon;&sigmaf;-&Chi;&rho;ή&sigma;&tau;&epsilon;&sigmaf;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;
          &delta;&epsilon;&sigma;&mu;&epsilon;ύ&omicron;&nu;&tau;&alpha;&iota;,
          &mu;&epsilon; &tau;&eta;&nu;
          &epsilon;&pi;&iota;&phi;ύ&lambda;&alpha;&xi;&eta;
          &epsilon;&iota;&delta;&iota;&kappa;ό&tau;&epsilon;&rho;&eta;&sigmaf;
          &sigma;ύ&mu;&beta;&alpha;&sigma;ή&sigmaf;
          &tau;&omicron;&upsilon;&sigmaf; &mu;&epsilon; &tau;&eta;&nu;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;, &nu;&alpha;
          &alpha;&nu;&tau;&alpha;&pi;&omicron;&kappa;&rho;ί&nu;&omicron;&nu;&tau;&alpha;&iota;
          ά&mu;&epsilon;&sigma;&alpha; ή &sigma;&epsilon;
          &kappa;ά&theta;&epsilon;
          &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta;
          &sigma;&tau;&omicron;&nu;
          &alpha;&pi;&omicron;&lambda;ύ&tau;&omega;&sigmaf;
          &alpha;&nu;&alpha;&gamma;&kappa;&alpha;ί&omicron;
          &chi;&rho;ό&nu;&omicron; &sigma;&epsilon; &kappa;ά&theta;&epsilon;
          &epsilon;&pi;&iota;&kappa;&omicron;&iota;&nu;&omega;&nu;ί&alpha;
          &tau;&eta;&sigmaf;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf; ή
          &chi;&rho;ή&sigma;&tau;&eta; &tau;&omega;&nu;
          &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;
          &tau;&eta;&sigmaf; &mu;&epsilon;
          &alpha;&upsilon;&tau;&omicron;ύ&sigmaf;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >4.9</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;&Sigma;&epsilon;
          &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta;
          &alpha;&iota;&tau;ή&mu;&alpha;&tau;&omicron;&sigmaf;
          &omicron;&iota;&kappa;&omicron;&nu;&omicron;&mu;&iota;&kappa;ή&sigmaf;
          &pi;&rho;&omicron;&sigma;&phi;&omicron;&rho;ά&sigmaf; &alpha;&pi;ό
          &epsilon;&pi;&iota;&sigma;&kappa;έ&pi;&tau;&eta;,
          &chi;&rho;ή&sigma;&tau;&eta; ή
          &epsilon;&gamma;&gamma;&epsilon;&gamma;&rho;&alpha;&mu;&mu;έ&nu;&omicron;
          &mu;έ&lambda;&omicron;&sigmaf; &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;,
          &omicron;&iota;
          &Epsilon;&pi;&alpha;&gamma;&gamma;&epsilon;&lambda;&mu;&alpha;&tau;ί&epsilon;&sigmaf;-&Chi;&rho;ή&sigma;&tau;&epsilon;&sigmaf;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon; &nu;&alpha;
          &chi;&omicron;&rho;&eta;&gamma;&omicron;ύ&nu;
          &pi;&lambda;ή&rho;&epsilon;&iota;&sigmaf; &kappa;&alpha;&iota;
          &alpha;&nu;&alpha;&lambda;&upsilon;&tau;&iota;&kappa;έ&sigmaf;
          &omicron;&iota;&kappa;&omicron;&nu;&omicron;&mu;&iota;&kappa;έ&sigmaf;
          &pi;&rho;&omicron;&sigma;&phi;&omicron;&rho;έ&sigmaf;,
          &tau;&iota;&sigmaf; &omicron;&pi;&omicron;ί&epsilon;&sigmaf;
          &nu;&alpha;
          &kappa;&omicron;&iota;&nu;&omicron;&pi;&omicron;&iota;&omicron;ύ&nu;
          &epsilon;ί&tau;&epsilon; &sigma;&tau;&omicron;&nu;
          &Iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;
          &epsilon;ί&tau;&epsilon; &sigma;&tau;&omicron;&nu;
          &alpha;&iota;&tau;ή&sigma;&alpha;&nu;&tau;&alpha;
          &alpha;&upsilon;&tau;ή &chi;&rho;ή&sigma;&tau;&eta; &tau;&omega;&nu;
          &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;,
          &alpha;&nu;ά&lambda;&omicron;&gamma;&alpha; &mu;&epsilon;
          &tau;&omicron;&nu; &kappa;&alpha;&tau;ά
          &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta;
          &omicron;&rho;&iota;&zeta;ό&mu;&epsilon;&nu;&omicron; &alpha;&pi;ό
          &tau;&omicron;&nu; &Iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;
          &tau;&rho;ό&pi;&omicron;. &Eta;
          &pi;&rho;&omicron;&sigma;&phi;&omicron;&rho;ά &alpha;&upsilon;&tau;ή
          &theta;&alpha; &pi;&rho;έ&pi;&epsilon;&iota; &nu;&alpha;
          &pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;ά&nu;&epsilon;&iota;
          &kappa;&alpha;&iota; &tau;&iota;&sigmaf; &kappa;ά&theta;&epsilon;
          &epsilon;ί&delta;&omicron;&upsilon;&sigmaf;
          &delta;&alpha;&pi;ά&nu;&epsilon;&sigmaf; &kappa;&alpha;&iota;
          &phi;&omicron;&rho;&omicron;&lambda;&omicron;&gamma;&iota;&kappa;έ&sigmaf;
          ή ά&lambda;&lambda;&epsilon;&sigmaf;
          &epsilon;&pi;&iota;&beta;&alpha;&rho;ύ&nu;&sigma;&epsilon;&iota;&sigmaf;
          &tau;&omicron;&upsilon;
          &Epsilon;&pi;&alpha;&gamma;&gamma;&epsilon;&lambda;&mu;&alpha;&tau;ί&alpha;
          &kappa;&alpha;&iota; &tau;&omicron;&nu;
          &delta;&epsilon;&sigma;&mu;&epsilon;ύ&epsilon;&iota;
          &alpha;&pi;&omicron;&lambda;ύ&tau;&omega;&sigmaf;
          &alpha;&pi;έ&nu;&alpha;&nu;&tau;&iota; &sigma;&tau;&omicron;&nu;
          &Iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron; &kappa;&alpha;&iota;
          &tau;&omicron;&nu; &chi;&rho;ή&sigma;&tau;&eta; &tau;&omega;&nu;
          &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;,
          ώ&sigma;&tau;&epsilon; &delta;&epsilon;&nu;
          &delta;ύ&nu;&alpha;&tau;&alpha;&iota; &nu;&alpha; &tau;&eta;&nu;
          &alpha;&upsilon;&xi;ή&sigma;&epsilon;&iota;,
          ά&mu;&epsilon;&sigma;&alpha; ή έ&mu;&mu;&epsilon;&sigma;&alpha;,
          &kappa;&alpha;&theta;&rsquo;
          &omicron;&iota;&omicron;&nu;&delta;ή&pi;&omicron;&tau;&epsilon;
          &tau;&rho;ό&pi;&omicron;, &sigma;&tau;&alpha;
          &pi;&lambda;&alpha;ί&sigma;&iota;&alpha; &tau;&eta;&sigmaf;
          &pi;&alpha;&rho;&omicron;&chi;ή&sigmaf; &tau;&omega;&nu;
          &pi;&rho;&omicron;&sigma;&phi;&epsilon;&rho;ό&mu;&epsilon;&nu;&omega;&nu;
          &kappa;&alpha;&tau;ό&pi;&iota;&nu;
          &sigma;&chi;&epsilon;&tau;&iota;&kappa;ή&sigmaf;
          &alpha;ί&tau;&eta;&sigma;&eta;&sigmaf;
          &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;.&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >4.10&nbsp;</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Sigma;&epsilon; &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta;
          &sigma;&upsilon;&nu;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;&sigmaf;
          &tau;&omicron;&upsilon;
          &Epsilon;&pi;&alpha;&gamma;&gamma;&epsilon;&lambda;&mu;&alpha;&tau;ί&alpha;-&Chi;&rho;ή&sigma;&tau;&eta;
          &mu;&epsilon; &chi;&rho;ή&sigma;&tau;&eta; &tau;&omega;&nu;
          &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;
          &sigma;&tau;&alpha; &pi;&lambda;&alpha;ί&sigma;&iota;&alpha;
          &tau;&eta;&sigmaf;
          &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;ί&alpha;&sigmaf;
          &tau;&omicron;&upsilon;, &omicron; &pi;&rho;ώ&tau;&omicron;&sigmaf;
          &omicron;&phi;&epsilon;ί&lambda;&epsilon;&iota; &nu;&alpha;
          &epsilon;&pi;&iota;&delta;&epsilon;&iota;&kappa;&nu;ύ&epsilon;&iota;
          &tau;&omicron;&nu; &delta;έ&omicron;&nu;&tau;&alpha;
          &epsilon;&pi;&alpha;&gamma;&gamma;&epsilon;&lambda;&mu;&alpha;&tau;&iota;&sigma;&mu;ό
          &kappa;&alpha;&iota;
          &epsilon;&pi;&iota;&mu;έ&lambda;&epsilon;&iota;&alpha;
          &kappa;&alpha;&tau;ά &tau;&eta;&nu; &pi;&alpha;&rho;&omicron;&chi;ή
          &tau;&omega;&nu; &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
          &tau;&omicron;&upsilon;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >4.11&nbsp;</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Omicron;
          &Epsilon;&pi;&alpha;&gamma;&gamma;&epsilon;&lambda;&mu;&alpha;&tau;ί&alpha;&sigmaf;-&Chi;&rho;ή&sigma;&tau;&eta;&sigmaf;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;
          έ&chi;&epsilon;&iota;
          &epsilon;&nu;&eta;&mu;&epsilon;&rho;&omega;&theta;&epsilon;ί
          &kappa;&alpha;&iota;
          &alpha;&pi;&omicron;&delta;έ&chi;&epsilon;&tau;&alpha;&iota;
          &delta;&iota;ά &tau;&omicron;&upsilon;
          &pi;&alpha;&rho;ό&nu;&tau;&omicron;&sigmaf; ό&tau;&iota;
          &sigma;&tau;&omicron;&nu;
          &Iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;
          &delta;ύ&nu;&alpha;&tau;&alpha;&iota; &nu;&alpha;
          &pi;&rho;&omicron;&beta;ά&lambda;&lambda;&omicron;&nu;&tau;&alpha;&iota;
          &theta;&epsilon;&tau;&iota;&kappa;έ&sigmaf; ή &kappa;&alpha;&iota;
          &alpha;&rho;&nu;&eta;&tau;&iota;&kappa;έ&sigmaf;
          &kappa;&rho;&iota;&tau;&iota;&kappa;έ&sigmaf; &tau;&eta;&sigmaf;
          &pi;&rho;&omicron;&sigma;&phi;&epsilon;&rho;ό&mu;&epsilon;&nu;&eta;&sigmaf;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&alpha;&sigmaf;
          &pi;&omicron;&upsilon; &pi;&alpha;&rho;&epsilon;ί&chi;&epsilon;
          &sigma;&epsilon; &kappa;ά&pi;&omicron;&iota;&omicron;&nu;
          &chi;&rho;ή&sigma;&tau;&eta; &mu;έ&sigma;&omega;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;, &eta;
          &omicron;&pi;&omicron;ί&alpha; &delta;ύ&nu;&alpha;&tau;&alpha;&iota;
          &epsilon;&nu;&delta;&epsilon;&iota;&kappa;&tau;&iota;&kappa;ά
          &nu;&alpha;
          &pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;ά&nu;&epsilon;&iota;
          &beta;&alpha;&theta;&mu;&omicron;&lambda;&omicron;&gamma;ί&epsilon;&sigmaf;
          ή &kappa;&alpha;&iota; &sigma;&chi;ό&lambda;&iota;&alpha;
          &alpha;&pi;&epsilon;&upsilon;&theta;&epsilon;ί&alpha;&sigmaf;
          &alpha;&pi;ό &tau;&omicron;&nu; &chi;&rho;ή&sigma;&tau;&eta;
          &tau;&omega;&nu; &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        &nbsp;
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >5. &Delta;&Iota;&Alpha;&Phi;&Eta;&Mu;&Iota;&Sigma;&Eta;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >5.1</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;&Eta; &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;
          &mu;&pi;&omicron;&rho;&epsilon;ί &mu;έ&sigma;&omega;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon; &nu;&alpha;
          &pi;&omega;&lambda;&epsilon;ί
          &delta;&iota;&alpha;&phi;&eta;&mu;&iota;&sigma;&tau;&iota;&kappa;ό
          &chi;ώ&rho;&omicron;, &nu;&alpha;
          &pi;&alpha;&rho;έ&chi;&epsilon;&iota; ά&delta;&epsilon;&iota;&alpha;
          &chi;&rho;ή&sigma;&eta;&sigmaf; ή &kappa;&alpha;&iota; &nu;&alpha;
          &pi;&alpha;&rho;&omicron;&upsilon;&sigma;&iota;ά&zeta;&epsilon;&iota;
          &omicron;&pi;&omicron;&iota;&omicron;&delta;ή&pi;&omicron;&tau;&epsilon;
          &delta;&iota;&alpha;&phi;&eta;&mu;&iota;&sigma;&tau;&iota;&kappa;ό
          &mu;ή&nu;&upsilon;&mu;&alpha;,
          &iota;&delta;&iota;ό&tau;&eta;&tau;&alpha;,
          &sigma;ύ&nu;&delta;&epsilon;&sigma;&mu;&omicron;,
          &delta;&iota;&kappa;&alpha;ί&omega;&mu;&alpha;
          &pi;&rho;&omicron;ώ&theta;&eta;&sigma;&eta;&sigmaf; ή
          &kappa;&alpha;&iota; &delta;&iota;&alpha;&nu;&omicron;&mu;ή&sigmaf;
          &sigma;&tau;&alpha; &pi;&lambda;&alpha;ί&sigma;&iota;&alpha;
          &tau;&eta;&sigmaf;
          &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;ί&alpha;&sigmaf;
          &tau;&eta;&sigmaf;.&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >5.2</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;&Eta; &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;
          έ&chi;&epsilon;&iota; &alpha;&sigma;&phi;&alpha;&lambda;ώ&sigmaf;
          &tau;&omicron; &delta;&iota;&kappa;&alpha;ί&omega;&mu;&alpha;
          &nu;&alpha; &delta;&iota;&alpha;&tau;&eta;&rho;&epsilon;ί
          ό&lambda;&omicron; &tau;&omicron;
          &epsilon;&iota;&sigma;ό&delta;&eta;&mu;&alpha; &pi;&omicron;&upsilon;
          &pi;&alpha;&rho;ά&gamma;&epsilon;&tau;&alpha;&iota; &alpha;&pi;ό
          &kappa;ά&theta;&epsilon; &pi;ώ&lambda;&eta;&sigma;&eta; ή
          &pi;&alpha;&rho;&omicron;&chi;ή ά&delta;&epsilon;&iota;&alpha;&sigmaf;
          &chi;&rho;ή&sigma;&eta;&sigmaf; &tau;&omega;&nu; &epsilon;&nu;
          &lambda;ό&gamma;&omega;
          &delta;&iota;&alpha;&phi;&eta;&mu;&iota;&sigma;&tau;&iota;&kappa;ώ&nu;
          &mu;&eta;&nu;&upsilon;&mu;ά&tau;&omega;&nu;,
          &iota;&delta;&iota;&omicron;&tau;ή&tau;&omega;&nu;,
          &sigma;&upsilon;&nu;&delta;έ&sigma;&mu;&omega;&nu; ή
          &delta;&iota;&kappa;&alpha;&iota;&omega;&mu;ά&tau;&omega;&nu;
          &pi;&rho;&omicron;ώ&theta;&eta;&sigma;&eta;&sigmaf; ή
          &delta;&iota;&alpha;&nu;&omicron;&mu;ή&sigmaf; ή
          &pi;&alpha;&rho;&alpha;&chi;ώ&rho;&eta;&sigma;&eta;&sigmaf;
          &delta;&iota;&alpha;&phi;&eta;&mu;&iota;&sigma;&tau;&iota;&kappa;&omicron;ύ
          &chi;ώ&rho;&omicron;&upsilon; &sigma;&tau;&omicron;&nu;
          &Iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;,
          &kappa;&alpha;&theta;&omicron;&rho;ί&zeta;&omicron;&nu;&tau;&alpha;&sigmaf;
          &kappa;&alpha;&tau;ά &tau;&eta;&nu; &kappa;&rho;ί&sigma;&eta;
          &tau;&eta;&sigmaf; &tau;&eta;&nu;
          &tau;&iota;&mu;&omicron;&lambda;&omicron;&gamma;&iota;&alpha;&kappa;ή
          &tau;&eta;&sigmaf; &pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή
          &sigma;&epsilon; &sigma;&upsilon;&mu;&phi;&omega;&nu;ί&alpha;
          &mu;&epsilon; &tau;&omicron;&upsilon;&sigmaf;
          &delta;&iota;&alpha;&phi;&eta;&mu;&iota;&zeta;ό&mu;&epsilon;&nu;&omicron;&upsilon;&sigmaf;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >5.3</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;&Omicron;&iota;
          &sigma;&upsilon;&nu;&alpha;&lambda;&lambda;&alpha;&gamma;έ&sigmaf;
          &tau;&omicron;&upsilon; &Alpha;&pi;&omicron;&delta;έ&kappa;&tau;&eta;
          &mu;&epsilon; &tau;&omicron;&upsilon;&sigmaf;
          &delta;&iota;&alpha;&phi;&eta;&mu;&iota;&zeta;ό&mu;&epsilon;&nu;&omicron;&upsilon;&sigmaf;
          &tau;&iota;&sigmaf;
          &delta;&iota;&alpha;&phi;&eta;&mu;ί&sigma;&epsilon;&iota;&sigmaf;
          &tau;&omega;&nu; &omicron;&pi;&omicron;ί&omega;&nu;
          &epsilon;&nu;&tau;&omicron;&pi;ί&zeta;&epsilon;&iota;
          &mu;έ&sigma;&omega; &tau;&eta;&sigmaf;
          &Iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;,
          &sigma;&upsilon;&mu;&pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;&alpha;&nu;&omicron;&mu;έ&nu;&eta;&sigmaf;
          &tau;&eta;&sigmaf;
          &sigma;&upsilon;&mu;&mu;&epsilon;&tau;&omicron;&chi;ή&sigmaf;
          &sigma;&epsilon;
          &pi;&rho;&omicron;&omega;&theta;&eta;&tau;&iota;&kappa;έ&sigmaf;
          &epsilon;&nu;έ&rho;&gamma;&epsilon;&iota;&epsilon;&sigmaf; ή
          &tau;&eta;&sigmaf; &pi;&lambda;&eta;&rho;&omega;&mu;ή&sigmaf;
          &kappa;&alpha;&iota;
          &pi;&alpha;&rho;ά&delta;&omicron;&sigma;&eta;&sigmaf;
          &alpha;&gamma;&alpha;&theta;ώ&nu; ή
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;,
          &kappa;&alpha;&iota; ό&lambda;&omicron;&iota; &omicron;&iota;
          &lambda;&omicron;&iota;&pi;&omicron;ί ό&rho;&omicron;&iota;,
          &pi;&rho;&omicron;ϋ&pi;&omicron;&theta;έ&sigma;&epsilon;&iota;&sigmaf;,
          &epsilon;&gamma;&gamma;&upsilon;ή&sigma;&epsilon;&iota;&sigmaf; ή
          &delta;&eta;&lambda;ώ&sigma;&epsilon;&iota;&sigmaf;
          &pi;&omicron;&upsilon;
          &sigma;&chi;&epsilon;&tau;ί&zeta;&omicron;&nu;&tau;&alpha;&iota;
          &mu;&epsilon; &tau;&iota;&sigmaf; &epsilon;&nu;
          &lambda;ό&gamma;&omega;
          &sigma;&upsilon;&nu;&alpha;&lambda;&lambda;&alpha;&gamma;έ&sigmaf;,
          &sigma;&upsilon;&nu;ά&pi;&tau;&omicron;&nu;&tau;&alpha;&iota;
          &alpha;&pi;&omicron;&kappa;&lambda;&epsilon;&iota;&sigma;&tau;&iota;&kappa;ά
          &alpha;&nu;ά&mu;&epsilon;&sigma;&alpha; &sigma;&tau;&omicron;&nu;
          &Alpha;&pi;&omicron;&delta;έ&kappa;&tau;&eta; &kappa;&alpha;&iota;
          &tau;&omicron;&upsilon;&sigmaf;
          &delta;&iota;&alpha;&phi;&eta;&mu;&iota;&zeta;ό&mu;&epsilon;&nu;&omicron;&upsilon;&sigmaf;
          &kappa;&alpha;&iota; &eta;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha; &rho;&eta;&tau;ά
          &sigma;&upsilon;&mu;&phi;&omega;&nu;&epsilon;ί&tau;&alpha;&iota;
          ό&tau;&iota; &delta;&epsilon;&nu; &theta;&alpha;
          &phi;έ&rho;&epsilon;&iota; &epsilon;&upsilon;&theta;ύ&nu;&eta;
          &gamma;&iota;&alpha; &tau;&upsilon;&chi;ό&nu;
          &alpha;&pi;ώ&lambda;&epsilon;&iota;&alpha; ή &zeta;&eta;&mu;ί&alpha;
          &omicron;&pi;&omicron;&iota;&omicron;&upsilon;&delta;ή&pi;&omicron;&tau;&epsilon;
          &epsilon;ί&delta;&omicron;&upsilon;&sigmaf;
          &epsilon;&nu;&delta;έ&chi;&epsilon;&tau;&alpha;&iota; &nu;&alpha;
          &upsilon;&pi;&omicron;&sigma;&tau;&epsilon;ί &omicron;
          &Alpha;&pi;&omicron;&delta;έ&kappa;&tau;&eta;&sigmaf; &omega;&sigmaf;
          &alpha;&pi;&omicron;&tau;έ&lambda;&epsilon;&sigma;&mu;&alpha;
          &tau;&omega;&nu; &epsilon;&nu; &lambda;ό&gamma;&omega;
          &sigma;&upsilon;&nu;&alpha;&lambda;&lambda;&alpha;&gamma;ώ&nu; ή
          &omega;&sigmaf;
          &alpha;&pi;&omicron;&tau;έ&lambda;&epsilon;&sigma;&mu;&alpha;
          &tau;&eta;&sigmaf;
          &pi;&alpha;&rho;&omicron;&upsilon;&sigma;ί&alpha;&sigmaf;
          &delta;&iota;&alpha;&phi;&eta;&mu;&iota;&zeta;ό&mu;&epsilon;&nu;&omega;&nu;
          &sigma;&tau;&iota;&sigmaf;
          &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;
          &tau;&eta;&sigmaf;
          &Iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        &nbsp;
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >6.
          &nbsp;&Pi;&Epsilon;&Rho;&Iota;&Omicron;&Rho;&Iota;&Sigma;&Mu;&Omicron;&Sigma;
          &Epsilon;&Upsilon;&Theta;&Upsilon;&Nu;&Eta;&Sigma;
          &Epsilon;&Tau;&Alpha;&Iota;&Rho;&Epsilon;&Iota;&Alpha;&Sigma;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        &nbsp;
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >6.1&nbsp;</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Eta; &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;
          &kappa;&alpha;&tau;&alpha;&beta;ά&lambda;&lambda;&epsilon;&iota;
          &kappa;ά&theta;&epsilon; &delta;&upsilon;&nu;&alpha;&tau;ή
          &pi;&rho;&omicron;&sigma;&pi;ά&theta;&epsilon;&iota;&alpha;,
          &sigma;&tau;&omicron; &mu;έ&tau;&rho;&omicron; &tau;&omicron;&upsilon;
          &delta;&upsilon;&nu;&alpha;&tau;&omicron;ύ, ώ&sigma;&tau;&epsilon;
          &tau;&omicron; &sigma;ύ&nu;&omicron;&lambda;&omicron; &tau;&omega;&nu;
          &pi;&alpha;&rho;&epsilon;&chi;ό&mu;&epsilon;&nu;&omega;&nu;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;&iota;ώ&nu;
          &kappa;&alpha;&iota;
          &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&omega;&nu;
          &mu;έ&sigma;&alpha; &alpha;&pi;ό &tau;&omicron;&nu;
          &Iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron; &nu;&alpha;
          &epsilon;ί&nu;&alpha;&iota; &pi;&lambda;ή&rho;&eta;,
          &alpha;&kappa;&rho;&iota;&beta;ή, &omicron;&rho;&theta;ά
          &kappa;&alpha;&iota; &sigma;ύ&mu;&phi;&omega;&nu;&alpha; &mu;&epsilon;
          &tau;&eta;&nu; &kappa;&alpha;&lambda;ή &pi;ί&sigma;&tau;&eta;
          &kappa;&alpha;&iota; &tau;&alpha;
          &sigma;&upsilon;&nu;&alpha;&lambda;&lambda;&alpha;&kappa;&tau;&iota;&kappa;ά
          ή&theta;&eta;. &Omega;&sigma;&tau;ό&sigma;&omicron;, &rho;&eta;&tau;ά
          &sigma;&upsilon;&mu;&phi;&omega;&nu;&epsilon;ί&tau;&alpha;&iota;
          &delta;&iota;ά &tau;&omicron;&upsilon;
          &pi;&alpha;&rho;ό&nu;&tau;&omicron;&sigmaf; ό&tau;&iota;
          &omicron;&upsilon;&delta;&epsilon;&mu;ί&alpha;
          &epsilon;&upsilon;&theta;ύ&nu;&eta; έ&chi;&epsilon;&iota; &eta;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha; &sigma;&epsilon;
          &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta; &kappa;&alpha;&tau;ά
          &tau;&eta;&nu; &omicron;&pi;&omicron;ί&alpha;
          &pi;&rho;&omicron;&beta;&lambda;&eta;&theta;&omicron;ύ&nu;
          &sigma;&tau;&omicron;&nu;
          &Iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf; ή
          &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&alpha;
          &alpha;&nu;&alpha;&kappa;&rho;&iota;&beta;ή,
          &alpha;&nu;&alpha;&lambda;&eta;&theta;ή ή
          &pi;&alpha;&rho;&alpha;&pi;&lambda;&alpha;&nu;&eta;&tau;&iota;&kappa;ά,
          &kappa;&alpha;&theta;ώ&sigmaf; &alpha;&upsilon;&tau;ά
          &delta;&eta;&mu;&omicron;&sigma;&iota;&epsilon;ύ&omicron;&nu;&tau;&alpha;&iota;
          &sigma;&tau;&alpha; &pi;&lambda;&alpha;ί&sigma;&iota;&alpha;
          &tau;&eta;&sigmaf;
          &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;ί&alpha;&sigmaf;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon; &alpha;&pi;ό
          &tau;&omicron;&nu;
          &epsilon;&kappa;ά&sigma;&tau;&omicron;&tau;&epsilon;
          &chi;&rho;ή&sigma;&tau;&eta; ή
          &epsilon;&pi;&alpha;&gamma;&gamma;&epsilon;&lambda;&mu;&alpha;&tau;ί&alpha;,
          &omicron; &omicron;&pi;&omicron;ί&omicron;&sigmaf;
          &phi;έ&rho;&epsilon;&iota; &kappa;&alpha;&iota; &tau;&eta;&nu;
          &alpha;&pi;&omicron;&kappa;&lambda;&epsilon;&iota;&sigma;&tau;&iota;&kappa;ή
          &epsilon;&upsilon;&theta;ύ&nu;&eta; &gamma;&iota;&alpha;
          &tau;&eta;&nu; &omicron;&rho;&theta;ό&tau;&eta;&tau;ά
          &tau;&omicron;&upsilon;&sigmaf;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >6.2 &nbsp;</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Eta; &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;,
          &kappa;&alpha;ί&tau;&omicron;&iota;
          &kappa;&alpha;&tau;&alpha;&beta;ά&lambda;&epsilon;&iota;
          &kappa;ά&theta;&epsilon;
          &pi;&rho;&omicron;&sigma;&pi;ά&theta;&epsilon;&iota;&alpha;
          &gamma;&iota;&alpha; &tau;&eta;&nu;
          &alpha;&pi;&rho;ό&sigma;&kappa;&omicron;&pi;&tau;&eta;
          &kappa;&alpha;&iota; ά&nu;&epsilon;&upsilon;
          &kappa;&alpha;&kappa;ό&beta;&omicron;&upsilon;&lambda;&omega;&nu;
          &lambda;&omicron;&gamma;&iota;&sigma;&mu;&iota;&kappa;ώ&nu;
          &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;ί&alpha;
          &tau;&eta;&sigmaf;
          &Iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;,
          &delta;&epsilon;&nu;
          &epsilon;&upsilon;&theta;ύ&nu;&epsilon;&tau;&alpha;&iota;, &alpha;&nu;
          &alpha;&pi;ό &tau;&eta;&nu;
          &epsilon;&pi;ί&sigma;&kappa;&epsilon;&psi;&eta;
          &sigma;&tau;&omicron;&nu;
          &Iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;, &tau;&omicron;
          &tau;&epsilon;&rho;&mu;&alpha;&tau;&iota;&kappa;ό
          &tau;&omicron;&upsilon;
          &epsilon;&pi;&iota;&sigma;&kappa;έ&pi;&tau;&eta; ή
          &tau;&omicron;&upsilon; &chi;&rho;ή&sigma;&tau;&eta;
          &upsilon;&pi;&omicron;&sigma;&tau;&epsilon;ί
          &kappa;ά&pi;&omicron;&iota;&alpha; &beta;&lambda;ά&beta;&eta;
          &alpha;&pi;ό
          &kappa;&alpha;&kappa;ό&beta;&omicron;&upsilon;&lambda;&omicron;
          &lambda;&omicron;&gamma;&iota;&sigma;&mu;&iota;&kappa;ό
          &kappa;&alpha;&iota; &omicron; &chi;&rho;ή&sigma;&tau;&eta;&sigmaf;
          &epsilon;ί&nu;&alpha;&iota;
          &upsilon;&pi;&omicron;&chi;&rho;&epsilon;&omega;&mu;έ&nu;&omicron;&sigmaf;
          &nu;&alpha; &epsilon;&gamma;&kappa;&alpha;&theta;&iota;&sigma;&tau;ά
          &sigma;&tau;&omicron;
          &tau;&epsilon;&rho;&mu;&alpha;&tau;&iota;&kappa;ό
          &tau;&omicron;&upsilon;
          &kappa;&alpha;&tau;ά&lambda;&lambda;&eta;&lambda;&alpha;
          &lambda;&omicron;&gamma;&iota;&sigma;&mu;&iota;&kappa;ά
          &alpha;&nu;ί&chi;&nu;&epsilon;&upsilon;&sigma;&eta;&sigmaf;
          &kappa;&alpha;&kappa;ό&beta;&omicron;&upsilon;&lambda;&omega;&nu;
          &upsilon;&lambda;&iota;&kappa;ώ&nu;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >6.3</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;&Eta; &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;
          &epsilon;&phi;&alpha;&rho;&mu;ό&zeta;&epsilon;&iota;
          &pi;&rho;&omicron;&eta;&gamma;&mu;έ&nu;&epsilon;&sigmaf;
          &tau;&epsilon;&chi;&nu;&omicron;&lambda;&omicron;&gamma;&iota;&kappa;έ&sigmaf;
          &mu;&epsilon;&theta;ό&delta;&omicron;&upsilon;&sigmaf;
          &gamma;&iota;&alpha; &tau;&eta;&nu;
          &pi;&rho;&omicron;ά&sigma;&pi;&iota;&sigma;&eta; &tau;&omega;&nu;
          &sigma;&upsilon;&sigma;&tau;&eta;&mu;ά&tau;&omega;&nu;
          &tau;&eta;&sigmaf; &kappa;&alpha;&iota; &tau;&eta;&nu;
          &alpha;&pi;&rho;ό&sigma;&kappa;&omicron;&pi;&tau;&eta;
          &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;ί&alpha;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;.
          &Omega;&sigma;&tau;ό&sigma;&omicron;, &kappa;ά&theta;&epsilon;
          &alpha;&nu;&tau;&iota;&sigma;&upsilon;&mu;&beta;&alpha;&lambda;&lambda;ό&mu;&epsilon;&nu;ό&sigmaf;
          &tau;&eta;&sigmaf; &kappa;&alpha;&iota;
          &chi;&rho;ή&sigma;&tau;&eta;&sigmaf; &tau;&omega;&nu;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu; &tau;&eta;&sigmaf;
          &kappa;&alpha;&iota; &kappa;ά&theta;&epsilon;
          &Epsilon;&pi;&alpha;&gamma;&gamma;&epsilon;&lambda;&mu;&alpha;&tau;ί&alpha;&sigmaf;
          &alpha;&nu;&alpha;&gamma;&nu;&omega;&rho;ί&zeta;&epsilon;&iota;
          ό&tau;&iota; &eta; &alpha;&upsilon;&tau;ή &delta;&epsilon;&nu;
          &phi;έ&rho;&epsilon;&iota; &kappa;&alpha;&mu;ί&alpha;
          &epsilon;&upsilon;&theta;ύ&nu;&eta; &alpha;&nu;
          &chi;&omega;&rho;ί&sigmaf; &delta;&iota;&kappa;ή &tau;&eta;&sigmaf;
          &upsilon;&pi;&alpha;&iota;&tau;&iota;ό&tau;&eta;&tau;&alpha;
          &kappa;&alpha;&iota; έ&nu;&epsilon;&kappa;&alpha;
          &kappa;&alpha;&kappa;ό&beta;&omicron;&upsilon;&lambda;&eta;&sigmaf;
          &pi;&alpha;&rho;έ&mu;&beta;&alpha;&sigma;&eta;&sigmaf;
          &tau;&rho;ί&tau;&omega;&nu;
          &delta;&iota;&alpha;&rho;&rho;&epsilon;ύ&sigma;&omicron;&upsilon;&nu;
          &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha; ή
          &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&alpha; ή
          &delta;&iota;&alpha;&kappa;&omicron;&pi;&epsilon;ί &eta;
          &alpha;&pi;&rho;ό&sigma;&kappa;&omicron;&pi;&tau;&eta;
          &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;ί&alpha;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;.
          &Sigma;&epsilon; &kappa;ά&theta;&epsilon;
          &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta;, &eta;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;
          &delta;&epsilon;&sigma;&mu;&epsilon;ύ&epsilon;&tau;&alpha;&iota;
          ό&tau;&iota; &theta;&alpha;
          &mu;&epsilon;&rho;&iota;&mu;&nu;ή&sigma;&epsilon;&iota;
          &gamma;&iota;&alpha; &tau;&eta;&nu; ά&mu;&epsilon;&sigma;&eta;
          &alpha;&pi;&omicron;&kappa;&alpha;&tau;ά&sigma;&tau;&alpha;&sigma;ή
          &tau;&eta;&sigmaf;
          &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;ί&alpha;&sigmaf;
          &tau;&omicron;&upsilon;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >6.4</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;&Kappa;&alpha;ί&tau;&omicron;&iota; &eta;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;
          &sigma;&upsilon;&nu;&epsilon;&rho;&gamma;ά&zeta;&epsilon;&tau;&alpha;&iota;
          &mu;&epsilon;
          &delta;&iota;&alpha;&kappa;&epsilon;&kappa;&rho;&iota;&mu;έ&nu;&omicron;&upsilon;&sigmaf;
          &sigma;&tau;&omicron; &epsilon;ί&delta;&omicron;&sigmaf;
          &tau;&omicron;&upsilon;&sigmaf;
          &Epsilon;&pi;&alpha;&gamma;&gamma;&epsilon;&lambda;&mu;&alpha;&tau;ί&epsilon;&sigmaf;,
          &omega;&sigma;&tau;ό&sigma;&omicron; &delta;&epsilon;&nu;
          &epsilon;&mu;&pi;&lambda;έ&kappa;&epsilon;&tau;&alpha;&iota;
          &kappa;&alpha;&iota; &delta;&epsilon;&nu;
          &epsilon;&upsilon;&theta;ύ&nu;&epsilon;&tau;&alpha;&iota;
          &gamma;&iota;&alpha; &tau;&eta;&nu;
          &pi;&omicron;&iota;ό&tau;&eta;&tau;&alpha;, &tau;&eta;&nu;
          &epsilon;&gamma;&kappa;&upsilon;&rho;ό&tau;&eta;&tau;&alpha;,
          &tau;&eta;&nu;
          &tau;&iota;&mu;&omicron;&lambda;ό&gamma;&eta;&sigma;&eta;
          &kappa;&alpha;&iota; ό&lambda;&omicron; &epsilon;&nu;
          &gamma;έ&nu;&epsilon;&iota; &tau;&omicron; &phi;ά&sigma;&mu;&alpha;
          &tau;&omega;&nu;
          &pi;&alpha;&rho;&epsilon;&chi;ό&mu;&epsilon;&nu;&omega;&nu;
          &alpha;&pi;ό &alpha;&upsilon;&tau;&omicron;ύ&sigmaf;
          &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;,
          &gamma;&iota;&alpha; &tau;&alpha; &omicron;&pi;&omicron;ί&alpha;
          &omicron;&iota; ί&delta;&iota;&omicron;&iota; &omicron;&iota;
          &Epsilon;&pi;&alpha;&gamma;&gamma;&epsilon;&lambda;&mu;&alpha;&tau;ί&epsilon;&sigmaf;,
          &epsilon;ί&tau;&epsilon;
          &pi;&rho;ό&kappa;&epsilon;&iota;&tau;&alpha;&iota;
          &gamma;&iota;&alpha;
          &Epsilon;&pi;&alpha;&gamma;&gamma;&epsilon;&lambda;&mu;&alpha;&tau;ί&epsilon;&sigmaf;-&Sigma;&upsilon;&nu;&epsilon;&rho;&gamma;ά&tau;&epsilon;&sigmaf;
          &epsilon;ί&tau;&epsilon; &gamma;&iota;&alpha; online
          &Sigma;&upsilon;&mu;&beta;&omicron;ύ&lambda;&omicron;&upsilon;&sigmaf;
          &phi;έ&rho;&omicron;&upsilon;&nu; ό&lambda;&eta; &tau;&eta;&nu;
          &epsilon;&upsilon;&theta;ύ&nu;&eta;.&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >6.5</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp; &nbsp;&Eta; &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;
          &delta;&iota;&alpha;&tau;&eta;&rho;&epsilon;ί &tau;&omicron;
          &delta;&iota;&kappa;&alpha;ί&omega;&mu;&alpha; &nu;&alpha;
          &alpha;&nu;&alpha;&sigma;&tau;έ&lambda;&lambda;&epsilon;&iota; ή
          &kappa;&alpha;&iota; &nu;&alpha;
          &alpha;&pi;&alpha;&gamma;&omicron;&rho;&epsilon;ύ&epsilon;&iota;
          &tau;&eta;&nu; &chi;&rho;ή&sigma;&eta; &tau;&omega;&nu;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu; &tau;&eta;&sigmaf;
          &sigma;&epsilon; &kappa;ά&theta;&epsilon; &chi;&rho;ή&sigma;&tau;&eta;
          ή
          &Epsilon;&pi;&alpha;&gamma;&gamma;&epsilon;&lambda;&mu;&alpha;&tau;ί&alpha;
          &pi;&omicron;&upsilon; &delta;&epsilon;&nu;
          &sigma;&upsilon;&mu;&mu;&omicron;&rho;&phi;ώ&nu;&epsilon;&tau;&alpha;&iota;
          &mu;&epsilon; &tau;&iota;&sigmaf;
          &delta;&iota;&alpha;&tau;ά&xi;&epsilon;&iota;&sigmaf;
          &tau;&omicron;&upsilon; &pi;&alpha;&rho;ό&nu;&tau;&omicron;&sigmaf;,
          &omicron; &omicron;&pi;&omicron;ί&omicron;&sigmaf;
          &kappa;&alpha;&iota; &delta;&epsilon;&nu;
          &delta;ύ&nu;&alpha;&tau;&alpha;&iota; &nu;&alpha;
          &epsilon;&gamma;&gamma;&rho;&alpha;&phi;&epsilon;ί
          &sigma;&tau;&iota;&sigmaf;
          &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;
          &epsilon;&kappa; &nu;έ&omicron;&upsilon;, &chi;&omega;&rho;ί&sigmaf;
          &tau;&eta;&nu;
          &pi;&rho;&omicron;&eta;&gamma;&omicron;ύ&mu;&epsilon;&nu;&eta;
          έ&gamma;&gamma;&rho;&alpha;&phi;&eta;
          &sigma;&upsilon;&nu;&alpha;ί&nu;&epsilon;&sigma;&eta;
          &tau;&eta;&sigmaf;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;.&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >6.6</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;&Eta; &epsilon;&tau;&alpha;&iota;&rho;ί&alpha;
          &epsilon;&pi;&iota;&phi;&upsilon;&lambda;ά&sigma;&sigma;&epsilon;&tau;&alpha;&iota;
          &nu;&alpha;
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;ή&sigma;&epsilon;&iota;
          &kappa;ά&theta;&epsilon; &nu;ό&mu;&iota;&mu;&omicron;
          &delta;&iota;&kappa;&alpha;ί&omega;&mu;ά &tau;&eta;&sigmaf;
          &kappa;&alpha;&iota; &nu;&alpha;
          &alpha;&xi;&iota;ώ&sigma;&epsilon;&iota;
          &alpha;&pi;&omicron;&zeta;&eta;&mu;&iota;ώ&sigma;&epsilon;&iota;&sigmaf;
          &alpha;&pi;ό &tau;&omicron;&upsilon;&sigmaf;
          &sigma;&upsilon;&mu;&beta;&alpha;&lambda;&lambda;ό&mu;&epsilon;&nu;&omicron;&upsilon;&sigmaf;
          &sigma;&upsilon;&nu;&epsilon;&rho;&gamma;ά&tau;&epsilon;&sigmaf;
          &tau;&eta;&sigmaf;, &sigma;&epsilon;
          &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta; &epsilon;&kappa;
          &mu;έ&rho;&omicron;&upsilon;&sigmaf; &tau;&omicron;&upsilon;&sigmaf;
          &pi;&alpha;&rho;&alpha;&beta;ί&alpha;&sigma;&eta;&sigmaf;
          &tau;&omega;&nu; Ό&rho;&omega;&nu; &Chi;&rho;ή&sigma;&eta;&sigmaf;
          &eta; &tau;&omega;&nu;
          &delta;&iota;&kappa;&alpha;&iota;&omega;&mu;ά&tau;&omega;&nu;
          &sigma;&upsilon;&nu;&epsilon;&rho;&gamma;&alpha;&zeta;ό&mu;&epsilon;&nu;&omega;&nu;
          &epsilon;&tau;&alpha;&iota;&rho;&iota;ώ&nu; ή/&kappa;&alpha;&iota;
          &tau;&rho;ί&tau;&omega;&nu;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        &nbsp;
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >7. &Lambda;&Omicron;&Iota;&Pi;&Omicron;&Iota;
          &Omicron;&Rho;&Omicron;&Iota; &Kappa;&Alpha;&Iota;
          &Tau;&Epsilon;&Lambda;&Iota;&Kappa;&Epsilon;&Sigma;
          &Delta;&Iota;&Alpha;&Tau;&Alpha;&Xi;&Epsilon;&Iota;&Sigma;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        &nbsp;
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >7.1&nbsp;</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Eta; &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;
          &delta;ύ&nu;&alpha;&tau;&alpha;&iota; &nu;&alpha;
          &kappa;&alpha;&tau;&alpha;&gamma;&gamma;&epsilon;ί&lambda;&epsilon;&iota;
          &tau;&eta;&nu; &pi;&alpha;&rho;&omicron;ύ&sigma;&alpha;
          &sigma;ύ&mu;&beta;&alpha;&sigma;&eta;, &epsilon;ί&tau;&epsilon;
          &alpha;&phi;&omicron;&rho;ά &sigma;&epsilon;
          &epsilon;&gamma;&gamma;&epsilon;&gamma;&rho;&alpha;&mu;&mu;έ&nu;&omicron;
          &mu;έ&lambda;&omicron;&sigmaf; &epsilon;ί&tau;&epsilon;
          &sigma;&epsilon;
          &Epsilon;&pi;&alpha;&gamma;&gamma;&epsilon;&lambda;&mu;&alpha;&tau;ί&alpha;-&Sigma;&upsilon;&nu;&epsilon;&rho;&gamma;ά&tau;&eta;
          &omicron;&pi;&omicron;&tau;&epsilon;&delta;ή&pi;&omicron;&tau;&epsilon;,
          &alpha;&kappa;ό&mu;&alpha; &kappa;&alpha;&iota;
          &chi;&omega;&rho;ί&sigmaf;
          &sigma;&pi;&omicron;&upsilon;&delta;&alpha;ί&omicron;
          &lambda;ό&gamma;&omicron;, &mu;&epsilon;
          έ&gamma;&gamma;&rho;&alpha;&phi;&eta;
          &kappa;&alpha;&tau;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;
          &kappa;&omicron;&iota;&nu;&omicron;&pi;&omicron;&iota;&omicron;ύ&mu;&epsilon;&nu;&eta;
          &sigma;&epsilon; &alpha;&upsilon;&tau;ό&nu; &mu;&epsilon;
          &kappa;ά&theta;&epsilon; &pi;&rho;ό&sigma;&phi;&omicron;&rho;&omicron;
          &mu;έ&sigma;&omicron;. &Iota;&delta;ί&omega;&sigmaf; &delta;&epsilon;
          &delta;ύ&nu;&alpha;&tau;&alpha;&iota; &nu;&alpha;
          &kappa;&alpha;&tau;&alpha;&gamma;&gamma;&epsilon;ί&lambda;&epsilon;&iota;
          &tau;&eta;&nu; &pi;&alpha;&rho;&omicron;ύ&sigma;&alpha;
          &lambda;ό&gamma;&omega; &mu;&eta;
          &kappa;&alpha;&tau;&alpha;&beta;&omicron;&lambda;ή&sigmaf;
          &tau;&eta;&sigmaf;
          &epsilon;&kappa;ά&sigma;&tau;&omicron;&tau;&epsilon;
          &kappa;&alpha;&theta;&omicron;&rho;&iota;&zeta;ό&mu;&epsilon;&nu;&eta;&sigmaf;
          &pi;&epsilon;&rho;&iota;&omicron;&delta;&iota;&kappa;ή&sigmaf;
          &sigma;&upsilon;&nu;&delta;&rho;&omicron;&mu;ή&sigmaf;
          &tau;&omega;&nu;
          &alpha;&nu;&tau;&iota;&sigma;&upsilon;&mu;&beta;&alpha;&lambda;&lambda;&omicron;&mu;έ&nu;&omega;&nu;
          &tau;&eta;&sigmaf; ή
          &gamma;&epsilon;&nu;&iota;&kappa;ό&tau;&epsilon;&rho;&alpha;
          &tau;&omicron;&upsilon;
          &kappa;&alpha;&theta;&omicron;&rho;&iota;&sigma;&mu;έ&nu;&omicron;&upsilon;
          &alpha;&nu;&tau;&iota;&tau;ί&mu;&omicron;&upsilon;
          &pi;&omicron;&upsilon;
          &alpha;&nu;&tau;&iota;&sigma;&tau;&omicron;&iota;&chi;&epsilon;ί
          &sigma;&tau;&eta;&nu; &pi;&alpha;&rho;&omicron;&chi;ή &tau;&omega;&nu;
          &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu; &tau;&eta;&sigmaf;
          ή &alpha;&nu; &upsilon;&pi;ά&rho;&xi;&epsilon;&iota;
          &epsilon;ύ&lambda;&omicron;&gamma;&eta;
          &upsilon;&pi;ό&nu;&omicron;&iota;&alpha; &gamma;&iota;&alpha;
          &alpha;&theta;έ&mu;&iota;&tau;&eta; ή
          &kappa;&alpha;&kappa;ό&beta;&omicron;&upsilon;&lambda;&eta; ή
          &zeta;&eta;&mu;&iota;&omicron;&gamma;ό&nu;&alpha;
          &chi;&rho;ή&sigma;&eta; &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon; &alpha;&pi;ό
          &alpha;&upsilon;&tau;&omicron;ύ&sigmaf;, &mu;&epsilon; &tau;&eta;&nu;
          &epsilon;&pi;&iota;&phi;ύ&lambda;&alpha;&xi;&eta;
          &pi;&alpha;&nu;&tau;ό&sigmaf; ά&lambda;&lambda;&omicron;&upsilon;
          &nu;&omicron;&mu;ί&mu;&omicron;&upsilon;
          &delta;&iota;&kappa;&alpha;&iota;ώ&mu;&alpha;&tau;ό&sigmaf;
          &tau;&eta;&sigmaf;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >7.2</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;&Tau;&omicron;
          &epsilon;&gamma;&gamma;&epsilon;&gamma;&rho;&alpha;&mu;&mu;έ&nu;&omicron;
          &mu;έ&lambda;&omicron;&sigmaf; ή &omicron;
          &Epsilon;&pi;&alpha;&gamma;&gamma;&epsilon;&lambda;&mu;&alpha;&tau;ί&alpha;&sigmaf;-&Chi;&rho;ή&sigma;&tau;&eta;&sigmaf;
          &delta;ύ&nu;&alpha;&nu;&tau;&alpha;&iota;
          &epsilon;&pi;ί&sigma;&eta;&sigmaf; &nu;&alpha;
          &kappa;&alpha;&tau;&alpha;&gamma;&gamma;&epsilon;ί&lambda;&omicron;&upsilon;&nu;
          &tau;&eta;&nu; &pi;&alpha;&rho;&omicron;ύ&sigma;&alpha;
          &omicron;&pi;&omicron;&tau;&epsilon;&delta;ή&pi;&omicron;&tau;&epsilon;
          &kappa;&alpha;&iota; &nu;&alpha;
          &delta;&iota;&alpha;&gamma;&rho;&alpha;&phi;&omicron;ύ&nu;
          &alpha;&pi;ό &tau;&omicron;&nu;
          &Iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;
          &alpha;&kappa;ό&mu;&alpha; &kappa;&alpha;&iota;
          &chi;&omega;&rho;ί&sigmaf;
          &sigma;&pi;&omicron;&upsilon;&delta;&alpha;ί&omicron;
          &lambda;ό&gamma;&omicron;. &Sigma;&tau;&eta;&nu;
          &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta;
          &alpha;&upsilon;&tau;ή, &tau;&upsilon;&chi;ό&nu;
          &kappa;&alpha;&tau;&alpha;&beta;&lambda;&eta;&theta;&epsilon;ί&sigma;&alpha;
          &sigma;&upsilon;&nu;&delta;&rho;&omicron;&mu;ή ή
          &alpha;&nu;&tau;ί&tau;&iota;&mu;&omicron; &gamma;&iota;&alpha;
          &tau;&eta;&nu; &pi;&alpha;&rho;&omicron;&chi;ή &tau;&omega;&nu;
          &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;,
          &theta;&alpha; &kappa;&alpha;&tau;&alpha;&pi;ί&pi;&tau;&epsilon;&iota;
          &upsilon;&pi;έ&rho; &tau;&eta;&sigmaf;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;
          &omega;&sigmaf; &alpha;&pi;ό &sigma;ή&mu;&epsilon;&rho;&omicron;&nu;
          &kappa;&alpha;&iota; &epsilon;&iota;&sigmaf; &tau;&omicron;
          &pi;&alpha;&rho;ό&nu;
          &omicron;&mu;&omicron;&lambda;&omicron;&gamma;&omicron;ύ&mu;&epsilon;&nu;&eta;
          &omega;&sigmaf; &delta;ί&kappa;&alpha;&iota;&eta;,
          &epsilon;ύ&lambda;&omicron;&gamma;&eta; &kappa;&alpha;&iota;
          &nu;ό&mu;&iota;&mu;&eta; &pi;&omicron;&iota;&nu;&iota;&kappa;ή
          &rho;ή&tau;&rho;&alpha; &pi;&omicron;&upsilon;
          &kappa;&alpha;&tau;&alpha;&pi;ί&pi;&tau;&epsilon;&iota;
          &upsilon;&pi;έ&rho; &tau;&eta;&sigmaf;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >7.3</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;&Tau;&omicron; &pi;&alpha;&rho;ό&nu;
          &alpha;&pi;&omicron;&tau;&epsilon;&lambda;&epsilon;ί έ&nu;&alpha;
          &sigma;ύ&nu;&omicron;&lambda;&omicron; &tau;ό&sigma;&omicron;
          &mu;&epsilon; &tau;&omicron;
          &Pi;&rho;&omicron;&sigma;ά&rho;&tau;&eta;&mu;&alpha;
          &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ώ&nu;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;,
          &Pi;&nu;&epsilon;&upsilon;&mu;&alpha;&tau;&iota;&kappa;ή&sigmaf;
          &kappa;&alpha;&iota;
          &Beta;&iota;&omicron;&mu;&eta;&chi;&alpha;&nu;&iota;&kappa;ή&sigmaf;
          &Iota;&delta;&iota;&omicron;&kappa;&tau;&eta;&sigma;ί&alpha;&sigmaf;
          &kappa;&alpha;&iota; &tau;&eta;&sigmaf;
          &Pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή&sigmaf;
          &Alpha;&pi;&omicron;&rho;&rho;ή&tau;&omicron;&upsilon;, &epsilon;&nu;ώ
          &upsilon;&pi;&epsilon;&rho;&iota;&sigma;&chi;ύ&epsilon;&iota;
          &kappa;&alpha;&iota;
          &sigma;&upsilon;&mu;&pi;&lambda;&eta;&rho;ώ&nu;&epsilon;&iota;
          &kappa;ά&theta;&epsilon;
          &epsilon;&iota;&delta;&iota;&kappa;ό&tau;&epsilon;&rho;&eta;
          &sigma;&upsilon;&mu;&phi;&omega;&nu;ί&alpha; &mu;&epsilon;
          &tau;&omicron;&upsilon;&sigmaf;
          &chi;&rho;ή&sigma;&tau;&epsilon;&sigmaf; &tau;&omega;&nu;
          &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon; ή
          &tau;&omicron;&upsilon;&sigmaf;
          &Epsilon;&pi;&alpha;&gamma;&gamma;&epsilon;&lambda;&mu;&alpha;&tau;ί&epsilon;&sigmaf;-&Chi;&rho;ή&sigma;&tau;&epsilon;&sigmaf;
          &alpha;&upsilon;&tau;&omicron;ύ.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >7.4</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;&Eta; &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;
          &delta;ύ&nu;&alpha;&tau;&alpha;&iota; &nu;&alpha;
          &pi;&rho;&omicron;&beta;&alpha;ί&nu;&epsilon;&iota;
          &kappa;&alpha;&tau;ά &tau;&eta;&nu;
          &alpha;&pi;ό&lambda;&upsilon;&tau;&eta; &kappa;&rho;ί&sigma;&eta;
          &tau;&eta;&sigmaf; &sigma;&epsilon;
          &tau;&rho;&omicron;&pi;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;
          &tau;ό&sigma;&omicron; &tau;&omicron;&upsilon;
          &pi;&alpha;&rho;ό&nu;&tau;&omicron;&sigmaf; ό&sigma;&omicron;
          &kappa;&alpha;&iota; &tau;&omega;&nu;
          &epsilon;&iota;&delta;&iota;&kappa;ό&tau;&epsilon;&rho;&omega;&nu;
          &sigma;&upsilon;&mu;&phi;&omega;&nu;&iota;ώ&nu; &pi;&omicron;&upsilon;
          έ&chi;&epsilon;&iota;
          &kappa;&alpha;&tau;&alpha;&rho;&tau;ί&sigma;&epsilon;&iota;
          &tau;ό&sigma;&omicron; &mu;&epsilon; &tau;&omicron;&upsilon;&sigmaf;
          &chi;&rho;ή&sigma;&tau;&epsilon;&sigmaf; &tau;&omega;&nu;
          &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;
          ό&sigma;&omicron; &kappa;&alpha;&iota; &mu;&epsilon;
          &tau;&omicron;&upsilon;&sigmaf;
          &Epsilon;&pi;&alpha;&gamma;&gamma;&epsilon;&lambda;&mu;&alpha;&tau;ί&epsilon;&sigmaf;-&Chi;&rho;ή&sigma;&tau;&epsilon;&sigmaf;
          &alpha;&upsilon;&tau;&omicron;ύ, &eta; &omicron;&pi;&omicron;ί&alpha;
          &sigma;&epsilon; &kappa;ά&theta;&epsilon;
          &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta; &theta;&alpha;
          &gamma;ί&nu;&epsilon;&tau;&alpha;&iota;
          &epsilon;&gamma;&gamma;&rho;ά&phi;&omega;&sigmaf; &kappa;&alpha;&iota;
          &theta;&alpha; &iota;&sigma;&chi;ύ&epsilon;&iota;
          &alpha;&mu;έ&sigma;&omega;&sigmaf; &mu;&epsilon; &tau;&eta;&nu;
          &alpha;&nu;ά&rho;&tau;&eta;&sigma;ή &tau;&eta;&sigmaf;
          &sigma;&tau;&omicron;&nu;
          &Iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;, &eta;
          &omicron;&pi;&omicron;ί&alpha; &kappa;&alpha;&tau;ά &rho;&eta;&tau;ή
          &sigma;&upsilon;&mu;&phi;&omega;&nu;ί&alpha; &tau;&omega;&nu;
          &mu;&epsilon;&rho;ώ&nu; &theta;&alpha;
          &iota;&sigma;&chi;ύ&epsilon;&iota; &omega;&sigmaf;
          &kappa;&omicron;&iota;&nu;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;
          &tau;&eta;&sigmaf;
          &tau;&rho;&omicron;&pi;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;&sigmaf;.
          &Tau;ό&sigma;&omicron; &omicron;
          &Alpha;&pi;&omicron;&delta;έ&kappa;&tau;&eta;&sigmaf; &tau;&omega;&nu;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;
          ό&sigma;&omicron; &kappa;&alpha;&iota; &omicron;
          &Epsilon;&pi;&alpha;&gamma;&gamma;&epsilon;&lambda;&mu;&alpha;&tau;ί&alpha;&sigmaf;-&Chi;&rho;ή&sigma;&tau;&eta;&sigmaf;
          &omicron;&phi;&epsilon;ί&lambda;&omicron;&upsilon;&nu; &mu;&epsilon;
          &delta;&iota;&kappa;ή &tau;&omicron;&upsilon;&sigmaf;
          &epsilon;&upsilon;&theta;ύ&nu;&eta; &nu;&alpha;
          &epsilon;&lambda;έ&gamma;&chi;&omicron;&upsilon;&nu;
          &pi;&rho;&iota;&nu; &alpha;&pi;ό &kappa;ά&theta;&epsilon;
          &chi;&rho;ή&sigma;&eta; &tau;&omega;&nu;
          &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;
          &tau;&upsilon;&chi;ό&nu;
          &tau;&rho;&omicron;&pi;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;
          &tau;&omicron;&upsilon; &pi;&alpha;&rho;ό&nu;&tau;&omicron;&sigmaf;
          &kappa;&alpha;&iota; &sigma;&epsilon;
          &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta; &pi;&omicron;&upsilon;
          &delta;&iota;&alpha;&phi;&omega;&nu;&omicron;ύ&nu; &mu;&epsilon;
          &alpha;&upsilon;&tau;ή&nu;, &nu;&alpha;
          &alpha;&pi;έ&chi;&omicron;&upsilon;&nu; &alpha;&pi;ό &tau;&eta;&nu;
          &epsilon;&pi;ί&sigma;&kappa;&epsilon;&psi;ή
          &tau;&omicron;&upsilon;&sigmaf; &sigma;&tau;&omicron;&nu;
          &Iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron; &kappa;&alpha;&iota;
          &tau;&eta;&nu; &chi;&rho;ή&sigma;&eta; &tau;&omega;&nu;
          &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
          &tau;&omicron;&upsilon;, ά&lambda;&lambda;&omega;&sigmaf;
          &theta;&alpha;
          &tau;&epsilon;&kappa;&mu;&alpha;ί&rho;&epsilon;&tau;&alpha;&iota;
          ά&mu;&epsilon;&sigma;&alpha; &eta;
          &alpha;&nu;&epsilon;&pi;&iota;&phi;ύ&lambda;&alpha;&kappa;&tau;&eta;
          &alpha;&pi;&omicron;&delta;&omicron;&chi;ή &alpha;&pi;ό
          &alpha;&upsilon;&tau;&omicron;ύ&sigmaf; &tau;&eta;&sigmaf;
          &tau;&rho;&omicron;&pi;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;&sigmaf;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >7.5</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp; &Eta;
          &pi;&rho;&omicron;&kappa;&epsilon;ί&mu;&epsilon;&nu;&eta;
          &sigma;ύ&mu;&beta;&alpha;&sigma;&eta; &chi;&rho;ή&sigma;&eta;&sigmaf;
          &kappa;&alpha;&iota; ά&pi;&alpha;&nu;&tau;&epsilon;&sigmaf;
          &omicron;&iota; ό&rho;&omicron;&iota; &pi;&omicron;&upsilon;
          &pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;ά&nu;&omicron;&nu;&tau;&alpha;&iota;
          &nbsp;&delta;&iota;έ&pi;&epsilon;&tau;&alpha;&iota; &alpha;&pi;ό
          &tau;&omicron; &Epsilon;&upsilon;&rho;&omega;&pi;&alpha;ϊ&kappa;ό
          &kappa;&alpha;&iota; &tau;&omicron;
          &Epsilon;&lambda;&lambda;&eta;&nu;&iota;&kappa;ό
          &Delta;ί&kappa;&alpha;&iota;&omicron; &kappa;&alpha;&theta;ώ&sigmaf;
          &kappa;&alpha;&iota; &tau;&iota;&sigmaf;
          &delta;&iota;&epsilon;&theta;&nu;&epsilon;ί&sigmaf;
          &sigma;&upsilon;&mu;&beta;ά&sigma;&epsilon;&iota;&sigmaf;
          &pi;&omicron;&upsilon; έ&chi;&omicron;&upsilon;&nu;
          &epsilon;&nu;&sigma;&omega;&mu;&alpha;&tau;ώ&sigma;&epsilon;&iota;
          &omega;&sigmaf; &kappa;&alpha;&iota; &tau;&iota;&sigmaf;
          &lambda;&omicron;&iota;&pi;έ&sigmaf;
          &sigma;&chi;&epsilon;&tau;&iota;&kappa;έ&sigmaf;
          &delta;&iota;&epsilon;&theta;&nu;&epsilon;ί&sigmaf;
          &delta;&iota;&alpha;&tau;ά&xi;&epsilon;&iota;&sigmaf;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >7.6</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp; &Alpha;&nu; &kappa;ά&pi;&omicron;&iota;&alpha; &alpha;&pi;ό
          &tau;&iota;&sigmaf; &alpha;&nu;&omega;&tau;έ&rho;&omega;
          &delta;&iota;&alpha;&tau;ά&xi;&epsilon;&iota;&sigmaf;
          &kappa;&rho;&iota;&theta;&epsilon;ί ά&kappa;&upsilon;&rho;&eta; ή
          &alpha;&kappa;&upsilon;&rho;ώ&sigma;&iota;&mu;&eta;
          &delta;&epsilon;&nu; &theta;ί&gamma;&epsilon;&iota; &sigma;&epsilon;
          &kappa;&alpha;&mu;ί&alpha;
          &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta; &tau;&eta;&nu;
          &iota;&sigma;&chi;ύ &tau;&omega;&nu; &lambda;&omicron;&iota;&pi;ώ&nu;
          &delta;&iota;&alpha;&tau;ά&xi;&epsilon;&omega;&nu;
          &pi;&omicron;&upsilon; &theta;&alpha;
          &epsilon;&xi;&alpha;&kappa;&omicron;&lambda;&omicron;&upsilon;&theta;&omicron;ύ&nu;
          &sigma;&epsilon; &kappa;ά&theta;&epsilon;
          &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta; &nu;&alpha;
          &pi;&alpha;&rho;&alpha;&mu;έ&nu;&omicron;&upsilon;&nu;
          &sigma;&epsilon; &iota;&sigma;&chi;ύ.&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >7.7</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;&Eta; &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;
          &epsilon;&pi;&iota;&phi;&upsilon;&lambda;ά&sigma;&sigma;&epsilon;&tau;&alpha;&iota;
          &pi;&alpha;&nu;&tau;ό&sigmaf; &nu;ό&mu;&iota;&mu;&omicron;&upsilon;
          &delta;&iota;&kappa;&alpha;&iota;ώ&mu;&alpha;&tau;ό&sigmaf;
          &tau;&eta;&sigmaf; &gamma;&iota;&alpha; &kappa;ά&theta;&epsilon;
          &delta;&iota;&kappa;&alpha;ί&omega;&mu;ά &tau;&omicron;&upsilon;
          &pi;&omicron;&upsilon; &theta;ί&gamma;&epsilon;&tau;&alpha;&iota;
          &alpha;&nu;&alpha;&phi;&omicron;&rho;&iota;&kappa;ά &mu;&epsilon;
          &tau;&eta;&nu; &pi;&alpha;&rho;&omicron;ύ&sigma;&alpha;,
          &tau;&omicron;&upsilon;&sigmaf; ό&rho;&omicron;&upsilon;&sigmaf;
          &kappa;&alpha;&iota; &tau;&iota;&sigmaf;
          &pi;&rho;&omicron;ϋ&pi;&omicron;&theta;έ&sigma;&epsilon;&iota;&sigmaf;
          &epsilon;&pi;ί&sigma;&kappa;&epsilon;&psi;&eta;&sigmaf;
          &kappa;&alpha;&iota; &chi;&rho;ή&sigma;&eta;&sigmaf;
          &alpha;&upsilon;&tau;&omicron;ύ &kappa;&alpha;&iota; &tau;&alpha;
          &delta;&iota;&kappa;&alpha;&iota;ώ&mu;&alpha;&tau;&alpha;
          &pi;&nu;&epsilon;&upsilon;&mu;&alpha;&tau;&iota;&kappa;ή&sigmaf;
          &kappa;&alpha;&iota;
          &delta;&iota;&alpha;&nu;&omicron;&eta;&tau;&iota;&kappa;ή&sigmaf;
          &tau;&omicron;&upsilon;
          &iota;&delta;&iota;&omicron;&kappa;&tau;&eta;&sigma;ί&alpha;&sigmaf;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >7.8&nbsp;</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Gamma;&iota;&alpha; &tau;&eta;&nu;
          &epsilon;&pi;ί&lambda;&upsilon;&sigma;&eta;
          &omicron;&pi;&omicron;&iota;&alpha;&sigma;&delta;ή&pi;&omicron;&tau;&epsilon;
          &delta;&iota;&alpha;&phi;&omicron;&rho;ά&sigmaf;
          &pi;&rho;&omicron;&kappa;ύ&pi;&tau;&omicron;&upsilon;&sigma;&alpha;&sigmaf;
          &alpha;&pi;ό &tau;&eta;&nu;
          &pi;&alpha;&rho;&alpha;&beta;ί&alpha;&sigma;&eta; &tau;&omega;&nu;
          &alpha;&nu;&omega;&tau;έ&rho;&omega;
          &epsilon;&phi;&alpha;&rho;&mu;ό&zeta;&epsilon;&tau;&alpha;&iota;
          &tau;&omicron; &epsilon;&lambda;&lambda;&eta;&nu;&iota;&kappa;ό
          &kappa;&alpha;&iota;
          &epsilon;&upsilon;&rho;&omega;&pi;&alpha;ϊ&kappa;ό
          &delta;ί&kappa;&alpha;&iota;&omicron; &kappa;&alpha;&iota;
          &rho;&eta;&tau;ά
          &alpha;&nu;&alpha;&gamma;&nu;&omega;&rho;ί&zeta;&omicron;&nu;&tau;&alpha;&iota;
          &omega;&sigmaf; &alpha;&rho;&mu;ό&delta;&iota;&alpha;
          &Delta;&iota;&kappa;&alpha;&sigma;&tau;ή&rho;&iota;&alpha;
          &epsilon;&pi;ί&lambda;&upsilon;&sigma;&eta;&sigmaf; &tau;&eta;&sigmaf;
          &delta;&iota;&alpha;&phi;&omicron;&rho;ά&sigmaf; &tau;&alpha;
          &alpha;&rho;&mu;ό&delta;&iota;&alpha;
          &Delta;&iota;&kappa;&alpha;&sigma;&tau;ή&rho;&iota;&alpha;
          &tau;&eta;&sigmaf; &Alpha;&theta;ή&nu;&alpha;&sigmaf;.&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        &nbsp;
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >8. &Pi;&alpha;&rho;ά&rho;&tau;&eta;&mu;&alpha;
          &Pi;&nu;&epsilon;&upsilon;&mu;&alpha;&tau;&iota;&kappa;ή&sigmaf;
          &Iota;&delta;&iota;&omicron;&kappa;&tau;&eta;&sigma;ί&alpha;&sigmaf;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >8.1&nbsp;</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Tau;&alpha; &pi;&nu;&epsilon;&upsilon;&mu;&alpha;&tau;&iota;&kappa;ά
          &delta;&iota;&kappa;&alpha;&iota;ώ&mu;&alpha;&tau;&alpha;
          &tau;&omicron;&upsilon;
          &pi;&epsilon;&rho;&iota;&epsilon;&chi;&omicron;&mu;έ&nu;&omicron;&upsilon;
          (&lambda;.&chi;.
          &pi;&rho;&omicron;&gamma;&rho;ά&mu;&mu;&alpha;&tau;&alpha;,
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;,
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;) &kappa;&alpha;&iota;
          &tau;&omega;&nu; &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
          &tau;&eta;&sigmaf;
          &pi;&lambda;&alpha;&tau;&phi;ό&rho;&mu;&alpha;&sigmaf; stoferno
          &pi;&omicron;&upsilon; έ&chi;&omicron;&upsilon;&nu;
          &epsilon;&iota;&sigma;&alpha;&chi;&theta;&epsilon;ί ή &theta;&alpha;
          &epsilon;&iota;&sigma;&alpha;&chi;&theta;&omicron;ύ&nu;
          &sigma;&tau;&omicron;&upsilon;&sigmaf;
          &sigma;&upsilon;&nu;&delta;&epsilon;&delta;&epsilon;&mu;έ&nu;&omega;&nu;
          &mu;&epsilon; &alpha;&upsilon;&tau;ή&nu;
          &delta;&iota;&alpha;&delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;&omicron;ύ&sigmaf;
          &tau;ό&pi;&omicron;&upsilon;&sigmaf; (www.stoferno.gr) &alpha;&pi;ό
          &tau;&eta;&nu;
          &laquo;&epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&raquo;
          &pi;&rho;&omicron;&sigma;&tau;&alpha;&tau;&epsilon;ύ&omicron;&nu;&tau;&alpha;&iota;
          &alpha;&pi;ό &tau;&omicron;&upsilon;&sigmaf;
          &epsilon;&lambda;&lambda;&eta;&nu;&iota;&kappa;&omicron;ύ&sigmaf;,
          &kappa;&omicron;&iota;&nu;&omicron;&tau;&iota;&kappa;&omicron;ύ&sigmaf;
          &kappa;&alpha;&iota;
          &delta;&iota;&epsilon;&theta;&nu;&epsilon;ί&sigmaf;
          &nu;ό&mu;&omicron;&upsilon;&sigmaf; &pi;&epsilon;&rho;ί
          &pi;&nu;&epsilon;&upsilon;&mu;&alpha;&tau;&iota;&kappa;ή&sigmaf;
          &iota;&delta;&iota;&omicron;&kappa;&tau;&eta;&sigma;ί&alpha;&sigmaf;
          &kappa;&alpha;&iota; &alpha;&nu;ή&kappa;&omicron;&upsilon;&nu;
          &alpha;&pi;&omicron;&kappa;&lambda;&epsilon;&iota;&sigma;&tau;&iota;&kappa;ά
          &sigma;&tau;&eta;&nu;
          &laquo;&epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&raquo; ή
          &sigma;&tau;&omicron;&nu;
          &epsilon;&kappa;ά&sigma;&tau;&omicron;&tau;&epsilon;
          &delta;&iota;&kappa;&alpha;&iota;&omicron;ύ&chi;&omicron;
          &tau;&omicron;&upsilon;&sigmaf;,
          &delta;&iota;&alpha;&tau;ί&theta;&epsilon;&nu;&tau;&alpha;&iota;
          &delta;&epsilon; &sigma;&tau;&omicron;&upsilon;&sigmaf;
          &epsilon;&pi;&iota;&sigma;&kappa;έ&pi;&tau;&epsilon;&sigmaf;
          &tau;&omicron;&upsilon; &alpha;&upsilon;&sigma;&tau;&eta;&rho;ά
          &gamma;&iota;&alpha;
          &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ή (&mu;&eta;
          &epsilon;&mu;&pi;&omicron;&rho;&iota;&kappa;ή ή
          &kappa;&epsilon;&rho;&delta;&omicron;&sigma;&kappa;&omicron;&pi;&iota;&kappa;ή)
          &chi;&rho;ή&sigma;&eta;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        &nbsp;
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >8.2&nbsp;</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Tau;&alpha; &omicron;&nu;ό&mu;&alpha;&tau;&alpha;,
          &epsilon;&iota;&kappa;ό&nu;&epsilon;&sigmaf;,
          &lambda;&omicron;&gamma;ό&tau;&upsilon;&pi;&alpha;
          &kappa;&alpha;&iota;
          &delta;&iota;&alpha;&kappa;&rho;&iota;&tau;&iota;&kappa;ά
          &gamma;&nu;&omega;&rho;ί&sigma;&mu;&alpha;&tau;&alpha;
          &pi;&omicron;&upsilon;
          &alpha;&nu;&tau;&iota;&pi;&rho;&omicron;&sigma;&omega;&pi;&epsilon;ύ&omicron;&upsilon;&nu;
          &tau;&eta;&nu;
          &laquo;&epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&raquo; ή
          &tau;&rho;ί&tau;&alpha; &mu;έ&rho;&eta; &kappa;&alpha;&iota;
          &tau;&alpha; &pi;&rho;&omicron;ϊό&nu;&tau;&alpha; ή
          &tau;&iota;&sigmaf;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;
          &tau;&omicron;&upsilon;&sigmaf;, &epsilon;ί&nu;&alpha;&iota;
          &alpha;&pi;&omicron;&kappa;&lambda;&epsilon;&iota;&sigma;&tau;&iota;&kappa;ά
          &sigma;ή&mu;&alpha;&tau;&alpha; &tau;&eta;&sigmaf;
          &laquo;&epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;&raquo;
          ή &tau;&rho;ί&tau;&omega;&nu; &mu;&epsilon;&rho;ώ&nu;,
          &pi;&rho;&omicron;&sigma;&tau;&alpha;&tau;&epsilon;&upsilon;ό&mu;&epsilon;&nu;&alpha;
          &alpha;&pi;ό &tau;&omicron;&upsilon;&sigmaf;
          &sigma;&chi;&epsilon;&tau;&iota;&kappa;&omicron;ύ&sigmaf;
          &nu;ό&mu;&omicron;&upsilon;&sigmaf; &pi;&epsilon;&rho;ί
          &epsilon;&mu;&pi;&omicron;&rho;&iota;&kappa;ώ&nu;
          &sigma;&eta;&mu;ά&tau;&omega;&nu;. &Eta;
          &epsilon;&mu;&phi;ά&nu;&iota;&sigma;ή &tau;&omicron;&upsilon;&sigmaf;
          &sigma;&tau;&omicron;&nu;
          &delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;ό &tau;ό&pi;&omicron;
          &delta;&epsilon;&nu; &theta;&alpha; &pi;&rho;έ&pi;&epsilon;&iota;
          &kappa;&alpha;&tau;ά &kappa;&alpha;&nu;έ&nu;&alpha;
          &tau;&rho;ό&pi;&omicron; &nu;&alpha;
          &epsilon;&kappa;&lambda;&eta;&phi;&theta;&epsilon;ί &omega;&sigmaf;
          &mu;&epsilon;&tau;&alpha;&beta;ί&beta;&alpha;&sigma;ή ή
          &epsilon;&kappa;&chi;ώ&rho;&eta;&sigma;&eta;
          ά&delta;&epsilon;&iota;&alpha;&sigmaf; ή
          &delta;&iota;&kappa;&alpha;&iota;ώ&mu;&alpha;&tau;&omicron;&sigmaf;
          &chi;&rho;ή&sigma;&eta;&sigmaf; &tau;&omicron;&upsilon;&sigmaf;.
          &Epsilon;&kappa;&tau;ό&sigmaf; &tau;&omega;&nu; &rho;&eta;&tau;ά
          &alpha;&nu;&alpha;&phi;&epsilon;&rho;ό&mu;&epsilon;&nu;&omega;&nu;
          &epsilon;&xi;&alpha;&iota;&rho;έ&sigma;&epsilon;&omega;&nu;
          (&pi;&nu;&epsilon;&upsilon;&mu;&alpha;&tau;&iota;&kappa;ά
          &delta;&iota;&kappa;&alpha;&iota;ώ&mu;&alpha;&tau;&alpha;
          &tau;&rho;ί&tau;&omega;&nu;,
          &sigma;&upsilon;&nu;&epsilon;&rho;&gamma;&alpha;&tau;ώ&nu;
          &kappa;&alpha;&iota; &phi;&omicron;&rho;έ&omega;&nu;),
          ό&lambda;&omicron; &tau;&omicron;
          &pi;&epsilon;&rho;&iota;&epsilon;&chi;ό&mu;&epsilon;&nu;&omicron;
          &tau;&eta;&sigmaf;
          &pi;&lambda;&alpha;&tau;&phi;ό&rho;&mu;&alpha;&sigmaf;
          &kappa;&alpha;&iota; &tau;&omega;&nu;
          &sigma;&upsilon;&nu;&delta;&epsilon;&delta;&epsilon;&mu;έ&nu;&omega;&nu;
          &mu;&epsilon; &alpha;&upsilon;&tau;ή&nu;
          &delta;&iota;&alpha;&delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;ώ&nu;
          &tau;ό&pi;&omega;&nu; (www.stoferno.gr)
          &sigma;&upsilon;&mu;&pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;&alpha;&nu;&omicron;&mu;έ&nu;&omega;&nu;
          &epsilon;&iota;&kappa;ό&nu;&omega;&nu;,
          &gamma;&rho;&alpha;&phi;&iota;&kappa;ώ&nu;,
          &phi;&omega;&tau;&omicron;&gamma;&rho;&alpha;&phi;&iota;ώ&nu;,
          &sigma;&chi;&epsilon;&delta;ί&omega;&nu;,
          &kappa;&epsilon;&iota;&mu;έ&nu;&omega;&nu;, &tau;&omega;&nu;
          &pi;&alpha;&rho;&epsilon;&chi;&omicron;&mu;έ&nu;&omega;&nu;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
          &kappa;&alpha;&iota; &gamma;&epsilon;&nu;&iota;&kappa;ά
          ό&lambda;&omega;&nu; &tau;&omega;&nu;
          &alpha;&rho;&chi;&epsilon;ί&omega;&nu; &alpha;&upsilon;&tau;&omicron;ύ
          &tau;&omicron;&upsilon;
          &delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;&omicron;ύ
          &tau;ό&pi;&omicron;&upsilon;,
          &alpha;&pi;&omicron;&tau;&epsilon;&lambda;&omicron;ύ&nu;
          &pi;&nu;&epsilon;&upsilon;&mu;&alpha;&tau;&iota;&kappa;ή
          &iota;&delta;&iota;&omicron;&kappa;&tau;&eta;&sigma;ί&alpha;,
          &delta;&iota;&alpha;&kappa;&rho;&iota;&tau;&iota;&kappa;ά
          &gamma;&nu;&omega;&rho;ί&sigma;&mu;&alpha;&tau;&alpha;
          &kappa;&alpha;&iota;
          &kappa;&alpha;&tau;&alpha;&tau;&epsilon;&theta;&epsilon;&iota;&mu;έ&nu;&alpha;
          &sigma;ή&mu;&alpha;&tau;&alpha; &kappa;&alpha;&iota;
          &sigma;ή&mu;&alpha;&tau;&alpha;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu; &tau;&eta;&sigmaf;
          &laquo;&epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;&raquo;
          &kappa;&alpha;&iota;
          &pi;&rho;&omicron;&sigma;&tau;&alpha;&tau;&epsilon;ύ&omicron;&nu;&tau;&alpha;&iota;
          &alpha;&pi;ό &tau;&iota;&sigmaf;
          &sigma;&chi;&epsilon;&tau;&iota;&kappa;έ&sigmaf;
          &delta;&iota;&alpha;&tau;ά&xi;&epsilon;&iota;&sigmaf;
          &tau;&omicron;&upsilon;
          &epsilon;&lambda;&lambda;&eta;&nu;&iota;&kappa;&omicron;ύ
          &delta;&iota;&kappa;&alpha;ί&omicron;&upsilon;,
          &tau;&omicron;&upsilon;
          &epsilon;&upsilon;&rho;&omega;&pi;&alpha;ϊ&kappa;&omicron;ύ
          &delta;&iota;&kappa;&alpha;ί&omicron;&upsilon; &kappa;&alpha;&iota;
          &tau;&omega;&nu; &delta;&iota;&epsilon;&theta;&nu;ώ&nu;
          &sigma;&upsilon;&mu;&beta;ά&sigma;&epsilon;&omega;&nu;
          &kappa;&alpha;&iota; &sigma;&upsilon;&nu;&theta;&eta;&kappa;ώ&nu;.
          &Sigma;&upsilon;&nu;&epsilon;&pi;ώ&sigmaf;,
          &kappa;&alpha;&nu;έ&nu;&alpha; &epsilon;&xi;
          &alpha;&upsilon;&tau;ώ&nu; &delta;&epsilon;
          &delta;ύ&nu;&alpha;&tau;&alpha;&iota; &nu;&alpha;
          &alpha;&pi;&omicron;&tau;&epsilon;&lambda;έ&sigma;&epsilon;&iota;
          &epsilon;&nu; ό&lambda;&omega; ή &epsilon;&nu;
          &mu;έ&rho;&epsilon;&iota;
          &alpha;&nu;&tau;&iota;&kappa;&epsilon;ί&mu;&epsilon;&nu;&omicron;
          &pi;ώ&lambda;&eta;&sigma;&eta;&sigmaf;,
          &alpha;&nu;&tau;&iota;&gamma;&rho;&alpha;&phi;ή&sigmaf;,
          &tau;&rho;&omicron;&pi;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;&sigmaf;,
          &alpha;&nu;&alpha;&pi;&alpha;&rho;&alpha;&gamma;&omega;&gamma;ή&sigmaf;,
          &alpha;&nu;&alpha;&delta;&eta;&mu;&omicron;&sigma;ί&epsilon;&upsilon;&sigma;&eta;&sigmaf;
          ή &nu;&alpha;
          &quot;&phi;&omicron;&rho;&tau;&omega;&theta;&epsilon;ί&quot;,
          &nu;&alpha; &mu;&epsilon;&tau;&alpha;&delta;&omicron;&theta;&epsilon;ί
          ή &nu;&alpha;
          &delta;&iota;&alpha;&nu;&epsilon;&mu;&eta;&theta;&epsilon;ί
          &mu;&epsilon;
          &omicron;&pi;&omicron;&iota;&omicron;&nu;&delta;ή&pi;&omicron;&tau;&epsilon;
          &tau;&rho;ό&pi;&omicron;. &Omicron;
          &chi;&rho;ή&sigma;&tau;&eta;&sigmaf;
          &kappa;&alpha;&tau;&alpha;&nu;&omicron;&epsilon;ί &kappa;&alpha;&iota;
          &alpha;&pi;&omicron;&delta;έ&chi;&epsilon;&tau;&alpha;&iota;
          ό&tau;&iota; &delta;&epsilon;&nu; &tau;&omicron;&upsilon;
          &pi;&alpha;&rho;έ&chi;&epsilon;&tau;&alpha;&iota; &tau;&omicron;
          &delta;&iota;&kappa;&alpha;ί&omega;&mu;&alpha; &nu;&alpha;
          &alpha;&nu;&alpha;&pi;&alpha;&rho;ά&gamma;&epsilon;&iota;,
          &alpha;&nu;&tau;&iota;&gamma;&rho;ά&phi;&epsilon;&iota;,
          &pi;&omega;&lambda;&epsilon;ί,
          &mu;&epsilon;&tau;&alpha;&pi;&omega;&lambda;&epsilon;ί
          ή/&kappa;&alpha;&iota;
          &epsilon;&kappa;&mu;&epsilon;&tau;&alpha;&lambda;&lambda;&epsilon;ύ&epsilon;&tau;&alpha;&iota;
          &epsilon;&mu;&pi;&omicron;&rho;&iota;&kappa;ά &mu;&epsilon;
          &omicron;&pi;&omicron;&iota;&omicron;&nu;&delta;ή&pi;&omicron;&tau;&epsilon;
          &tau;&rho;ό&pi;&omicron; &sigma;ύ&nu;&omicron;&lambda;&omicron; ή
          &mu;έ&rho;&omicron;&sigmaf; &tau;&omicron;&upsilon;
          &pi;&epsilon;&rho;&iota;&epsilon;&chi;&omicron;&mu;έ&nu;&omicron;&upsilon;
          &tau;&omicron;&upsilon; &pi;&alpha;&rho;ό&nu;&tau;&omicron;&sigmaf;
          &delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;&omicron;ύ
          &tau;ό&pi;&omicron;&upsilon; &kappa;&alpha;&iota;
          &sigma;&upsilon;&nu;&epsilon;&pi;ώ&sigmaf;
          &alpha;&pi;&alpha;&gamma;&omicron;&rho;&epsilon;ύ&epsilon;&tau;&alpha;&iota;
          &omicron;&pi;&omicron;&iota;&alpha;&delta;ή&pi;&omicron;&tau;&epsilon;
          &alpha;&nu;&tau;&iota;&gamma;&rho;&alpha;&phi;ή,
          &delta;&iota;&alpha;&nu;&omicron;&mu;ή,
          &mu;&epsilon;&tau;&alpha;&phi;&omicron;&rho;ά,
          &mu;&epsilon;&tau;&alpha;&pi;&omicron;ί&eta;&sigma;&eta;,
          &mu;&epsilon;&tau;&alpha;&pi;ώ&lambda;&eta;&sigma;&eta;,
          &delta;&eta;&mu;&iota;&omicron;&upsilon;&rho;&gamma;ί&alpha;
          &pi;&alpha;&rho;ά&gamma;&omega;&gamma;&eta;&sigmaf;
          &epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;&sigmaf; ή
          &pi;&alpha;&rho;&alpha;&pi;&lambda;ά&nu;&eta;&sigma;&eta;
          &tau;&omicron;&upsilon; &kappa;&omicron;&iota;&nu;&omicron;ύ
          &sigma;&chi;&epsilon;&tau;&iota;&kappa;ά &mu;&epsilon;
          &tau;&omicron;&nu;
          &pi;&rho;&alpha;&gamma;&mu;&alpha;&tau;&iota;&kappa;ό
          &pi;&alpha;&rho;&omicron;&chi;έ&alpha; &tau;&omicron;&upsilon;&sigmaf;
          &tau;&omicron;&upsilon;
          &pi;&epsilon;&rho;&iota;&epsilon;&chi;&omicron;&mu;έ&nu;&omicron;&upsilon;
          &tau;&omicron;&upsilon;
          &delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;&omicron;ύ
          &tau;ό&pi;&omicron;&upsilon;. &Tau;&upsilon;&chi;ό&nu;
          &alpha;&nu;&alpha;&pi;&alpha;&rho;&alpha;&gamma;&omega;&gamma;ή
          &epsilon;&pi;&alpha;&nu;έ&kappa;&delta;&omicron;&sigma;&eta;,
          &phi;ό&rho;&tau;&omega;&sigma;&eta;,
          &alpha;&nu;&alpha;&kappa;&omicron;ί&nu;&omega;&sigma;&eta;,
          &delta;&iota;ά&delta;&omicron;&sigma;&eta; ή
          &mu;&epsilon;&tau;ά&delta;&omicron;&sigma;&eta; ή
          &omicron;&pi;&omicron;&iota;&alpha;&delta;ή&pi;&omicron;&tau;&epsilon;
          ά&lambda;&lambda;&eta; &chi;&rho;ή&sigma;&eta; &tau;&omicron;&upsilon;
          &pi;&epsilon;&rho;&iota;&epsilon;&chi;&omicron;&mu;έ&nu;&omicron;&upsilon;
          &mu;&epsilon;
          &omicron;&pi;&omicron;&iota;&omicron;&nu;&delta;ή&pi;&omicron;&tau;&epsilon;
          &tau;&rho;ό&pi;&omicron; ή &mu;έ&sigma;&omicron; &gamma;&iota;&alpha;
          &epsilon;&mu;&pi;&omicron;&rho;&iota;&kappa;&omicron;ύ&sigmaf; ή
          ά&lambda;&lambda;&omicron;&upsilon;&sigmaf;
          &sigma;&kappa;&omicron;&pi;&omicron;ύ&sigmaf;
          &epsilon;&pi;&iota;&tau;&rho;έ&pi;&epsilon;&tau;&alpha;&iota;
          &mu;ό&nu;&omicron; &kappa;&alpha;&tau;ό&pi;&iota;&nu;
          &pi;&rho;&omicron;&eta;&gamma;&omicron;ύ&mu;&epsilon;&nu;&eta;&sigmaf;
          &gamma;&rho;&alpha;&pi;&tau;ή&sigmaf;
          ά&delta;&epsilon;&iota;&alpha;&sigmaf; &tau;&eta;&sigmaf;
          &laquo;&epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;&raquo;
          ή &omicron;&iota;&omicron;&upsilon;&delta;ή&pi;&omicron;&tau;&epsilon;
          ά&lambda;&lambda;&omicron;&upsilon;
          &delta;&iota;&kappa;&alpha;&iota;&omicron;ύ&chi;&omicron;&upsilon;
          &tau;&omega;&nu;
          &pi;&nu;&epsilon;&upsilon;&mu;&alpha;&tau;&iota;&kappa;ώ&nu;
          &delta;&iota;&kappa;&alpha;&iota;&omega;&mu;ά&tau;&omega;&nu;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        &nbsp;
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >8.3&nbsp;</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Eta; &laquo;&epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&raquo;
          &kappa;&alpha;&tau;&alpha;&beta;ά&lambda;&lambda;&epsilon;&iota;
          &kappa;ά&theta;&epsilon;
          &pi;&rho;&omicron;&sigma;&pi;ά&theta;&epsilon;&iota;&alpha;
          ώ&sigma;&tau;&epsilon; &tau;&omicron;
          &pi;&epsilon;&rho;&iota;&epsilon;&chi;ό&mu;&epsilon;&nu;&omicron;
          &kappa;&alpha;&iota; &omicron;&iota;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
          &pi;&omicron;&upsilon;
          &epsilon;&mu;&phi;&alpha;&nu;ί&zeta;&omicron;&nu;&tau;&alpha;&iota;
          &kappa;ά&theta;&epsilon; &phi;&omicron;&rho;ά &sigma;&tau;&omicron;
          &delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;ό &tau;ό&pi;&omicron;
          &nu;&alpha; &epsilon;ί&nu;&alpha;&iota; &kappa;&alpha;&tau;ά
          &tau;&omicron; &delta;&upsilon;&nu;&alpha;&tau;ό&nu;
          &alpha;&kappa;&rho;&iota;&beta;ή &kappa;&alpha;&iota;
          &alpha;&lambda;&eta;&theta;ή, &delta;&epsilon;&nu;
          &phi;έ&rho;&epsilon;&iota; ό&mu;&omega;&sigmaf;
          &kappa;&alpha;&mu;ί&alpha; &epsilon;&upsilon;&theta;ύ&nu;&eta;
          &gamma;&iota;&alpha; &tau;&eta;&nu;
          &alpha;&xi;&iota;&omicron;&pi;&iota;&sigma;&tau;ί&alpha;,
          &pi;&lambda;&eta;&rho;ό&tau;&eta;&tau;&alpha; ή
          &epsilon;&pi;&iota;&kappa;&alpha;&iota;&rho;ό&tau;&eta;&tau;&alpha;
          &tau;&omicron;&upsilon;&sigmaf;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        &nbsp;
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >8.4&nbsp;</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Eta; &laquo;&epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&raquo;
          &pi;&alpha;&rho;έ&chi;&epsilon;&iota; &tau;&omicron;
          &pi;&epsilon;&rho;&iota;&epsilon;&chi;ό&mu;&epsilon;&nu;&omicron;
          (&lambda;.&chi;.
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;,
          &omicron;&nu;ό&mu;&alpha;&tau;&alpha;,
          &phi;&omega;&tau;&omicron;&gamma;&rho;&alpha;&phi;ί&epsilon;&sigmaf;,
          &alpha;&pi;&epsilon;&iota;&kappa;&omicron;&nu;ί&sigma;&epsilon;&iota;&sigmaf;,)
          &tau;&alpha; &pi;&rho;&omicron;ϊό&nu;&tau;&alpha; &kappa;&alpha;&iota;
          &tau;&iota;&sigmaf;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;
          &pi;&omicron;&upsilon;
          &delta;&iota;&alpha;&tau;ί&theta;&epsilon;&nu;&tau;&alpha;&iota;
          &mu;έ&sigma;&omega; &tau;&eta;&sigmaf;
          &pi;&lambda;&alpha;&tau;&phi;ό&rho;&mu;&alpha;&sigmaf;
          &quot;&Omicron;&Pi;&Omega;&Sigma;
          &Alpha;&Kappa;&Rho;&Iota;&Beta;&Omega;&Sigma;
          &Epsilon;&Chi;&Omicron;&Upsilon;&Nu;&quot; &kappa;&alpha;&iota;
          &chi;&omega;&rho;ί&sigmaf; &kappa;&alpha;&nu;&epsilon;&nu;ό&sigmaf;
          &epsilon;ί&delta;&omicron;&upsilon;&sigmaf;
          &epsilon;&kappa;&pi;&rho;&omicron;&sigma;ώ&pi;&eta;&sigma;&eta;,
          έ&gamma;&kappa;&rho;&iota;&sigma;&eta; ή
          έ&gamma;&gamma;&rho;&alpha;&phi;&eta;
          &epsilon;&gamma;&gamma;ύ&eta;&sigma;&eta;,
          &delta;&iota;&alpha;&tau;&upsilon;&pi;&omega;&mu;έ&nu;&eta; ή
          &upsilon;&pi;&omicron;&tau;&iota;&theta;έ&mu;&epsilon;&nu;&eta;,
          &epsilon;&xi;&upsilon;&pi;&alpha;&kappa;&omicron;&upsilon;ό&mu;&epsilon;&nu;&omega;&nu;
          &tau;&omega;&nu;
          &epsilon;&gamma;&gamma;&upsilon;ή&sigma;&epsilon;&omega;&nu;
          &iota;&kappa;&alpha;&nu;&omicron;&pi;&omicron;&iota;&eta;&tau;&iota;&kappa;ή&sigmaf;
          &pi;&omicron;&iota;ό&tau;&eta;&tau;&alpha;&sigmaf;,
          &kappa;&alpha;&tau;&alpha;&lambda;&lambda;&eta;&lambda;ό&lambda;&eta;&tau;&alpha;&sigmaf;,
          &alpha;&pi;&alpha;&rho;&alpha;&beta;ί&alpha;&sigma;&tau;&omicron;&upsilon;
          &sigma;&upsilon;&mu;&beta;&alpha;&tau;ό&tau;&eta;&tau;&alpha;&sigmaf;,
          &alpha;&sigma;&phi;ά&lambda;&epsilon;&iota;&alpha;&sigmaf;
          &kappa;&alpha;&iota;
          &alpha;&kappa;&rho;ί&beta;&epsilon;&iota;&alpha;&sigmaf;.
          &Sigma;&epsilon; &kappa;&alpha;&mu;ί&alpha;
          &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta; &eta;
          &laquo;&epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&raquo;
          &delta;&epsilon;&nu; &phi;έ&rho;&epsilon;&iota;
          &epsilon;&upsilon;&theta;ύ&nu;&eta; &gamma;&iota;&alpha;
          &tau;&upsilon;&chi;ό&nu;
          &alpha;&pi;&alpha;&iota;&tau;ή&sigma;&epsilon;&iota;&sigmaf;
          &nu;&omicron;&mu;&iota;&kappa;ή&sigmaf; ή
          &alpha;&sigma;&tau;&iota;&kappa;ή&sigmaf; ή/&kappa;&alpha;&iota;
          &pi;&omicron;&iota;&nu;&iota;&kappa;ή&sigmaf;
          &phi;ύ&sigma;&epsilon;&omega;&sigmaf; &omicron;ύ&tau;&epsilon;
          &gamma;&iota;&alpha; &tau;&upsilon;&chi;ό&nu; &zeta;&eta;&mu;ί&alpha;
          (&theta;&epsilon;&tau;&iota;&kappa;ή,
          &epsilon;&iota;&delta;&iota;&kappa;ή ή
          &alpha;&pi;&omicron;&theta;&epsilon;&tau;&iota;&kappa;ή, &eta;
          &omicron;&pi;&omicron;ί&alpha;
          &epsilon;&nu;&delta;&epsilon;&iota;&kappa;&tau;&iota;&kappa;ά
          &kappa;&alpha;&iota; ό&chi;&iota;
          &pi;&epsilon;&rho;&iota;&omicron;&rho;&iota;&sigma;&tau;&iota;&kappa;ά,
          &delta;&iota;&alpha;&zeta;&epsilon;&upsilon;&kappa;&tau;&iota;&kappa;ά
          ή/&kappa;&alpha;&iota;
          &sigma;&omega;&rho;&epsilon;&upsilon;&tau;&iota;&kappa;ά
          &sigma;&upsilon;&nu;ί&sigma;&tau;&alpha;&tau;&alpha;&iota;
          &sigma;&epsilon; &alpha;&pi;ώ&lambda;&epsilon;&iota;&alpha;
          &kappa;&epsilon;&rho;&delta;ώ&nu;,
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;,
          &delta;&iota;&alpha;&phi;&upsilon;&gamma;ό&nu;&tau;&alpha;
          &kappa;έ&rho;&delta;&eta;,
          &chi;&rho;&eta;&mu;&alpha;&tau;&iota;&kappa;ή
          &iota;&kappa;&alpha;&nu;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;
          &kappa;&lambda;&pi;) &alpha;&pi;ό
          &epsilon;&pi;&iota;&sigma;&kappa;έ&pi;&tau;&epsilon;&sigmaf;
          &tau;&omicron;&upsilon;
          &delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;&omicron;ύ
          &tau;ό&pi;&omicron;&upsilon; ή
          &tau;&rho;ί&tau;&omicron;&upsilon;&sigmaf; &alpha;&pi;ό
          &alpha;&iota;&tau;ί&alpha; &pi;&omicron;&upsilon;
          έ&chi;&epsilon;&iota; &sigma;&chi;έ&sigma;&eta; &mu;&epsilon;
          &tau;&eta;
          &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;ί&alpha; ή
          &mu;&eta; ή/&kappa;&alpha;&iota; &tau;&eta; &chi;&rho;ή&sigma;&eta;
          &tau;&omicron;&upsilon;
          &delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;&omicron;ύ
          &tau;ό&pi;&omicron;&upsilon; ή/&kappa;&alpha;&iota; &mu;&epsilon;
          &tau;&eta;&nu; &alpha;&delta;&upsilon;&nu;&alpha;&mu;ί&alpha;
          &pi;&alpha;&rho;&omicron;&chi;ή&sigmaf;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
          ή/&kappa;&alpha;&iota;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;&iota;ώ&nu;
          &pi;&omicron;&upsilon;
          &delta;&iota;&alpha;&tau;ί&theta;&epsilon;&nu;&tau;&alpha;&iota;
          &alpha;&pi;ό &alpha;&upsilon;&tau;ό&nu; ή/&kappa;&alpha;&iota;
          &mu;&epsilon; &tau;&upsilon;&chi;ό&nu; &mu;&eta;
          &epsilon;&pi;&iota;&tau;&rho;&epsilon;&pi;ό&mu;&epsilon;&nu;&epsilon;&sigmaf;
          &pi;&alpha;&rho;&epsilon;&mu;&beta;ά&sigma;&epsilon;&iota;&sigmaf;
          &tau;&rho;ί&tau;&omega;&nu; &sigma;&epsilon;
          &pi;&rho;&omicron;ϊό&nu;&tau;&alpha; ή/&kappa;&alpha;&iota;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;
          ή/&kappa;&alpha;&iota;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
          &pi;&omicron;&upsilon;
          &delta;&iota;&alpha;&tau;ί&theta;&epsilon;&nu;&tau;&alpha;&iota;
          &mu;έ&sigma;&omega; &alpha;&upsilon;&tau;&omicron;ύ. &Tau;&omicron;
          &pi;&epsilon;&rho;&iota;&epsilon;&chi;ό&mu;&epsilon;&nu;&omicron;
          &kappa;&alpha;&iota; &omicron;&iota;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
          &pi;&omicron;&upsilon;
          &pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;ά&nu;&omicron;&nu;&tau;&alpha;&iota;
          &sigma;&tau;&omicron;&nu; &pi;&alpha;&rho;ό&nu;&tau;&alpha;
          &delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;ό &tau;ό&pi;&omicron;
          &alpha;&pi;&omicron;&tau;&epsilon;&lambda;&omicron;ύ&nu; &mu;ί&alpha;
          &pi;&rho;&omicron;&sigma;&phi;&omicron;&rho;ά
          &pi;&rho;&omicron;&sigmaf; &tau;&omicron;&nu;
          &chi;&rho;ή&sigma;&tau;&eta; &kappa;&alpha;&iota;
          &gamma;&epsilon;&nu;&iota;&kappa;ά &pi;&rho;&omicron;&sigmaf;
          &tau;&eta;&nu; &kappa;&omicron;&iota;&nu;ό&tau;&eta;&tau;&alpha;
          &tau;&omega;&nu; &chi;&rho;&eta;&sigma;&tau;ώ&nu;
          &tau;&omicron;&upsilon;
          &delta;&iota;&alpha;&delta;&iota;&kappa;&tau;ύ&omicron;&upsilon;
          &kappa;&alpha;&iota; &delta;&epsilon;
          &delta;ύ&nu;&alpha;&nu;&tau;&alpha;&iota; &sigma;&epsilon;
          &kappa;&alpha;&mu;&iota;ά
          &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta; &nu;&alpha;
          &epsilon;&kappa;&lambda;&eta;&phi;&theta;&omicron;ύ&nu;
          &omega;&sigmaf; έ&gamma;&kappa;&upsilon;&rho;&epsilon;&sigmaf;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
          ή/&kappa;&alpha;&iota;
          &sigma;&upsilon;&mu;&beta;&omicron;&upsilon;&lambda;έ&sigmaf;
          ή/&kappa;&alpha;&iota;
          &pi;&rho;&omicron;&tau;&rho;&omicron;&pi;έ&sigmaf;,
          &omicron;ύ&tau;&epsilon;
          &upsilon;&pi;&omicron;&kappa;&rho;ύ&pi;&tau;&omicron;&upsilon;&nu;
          &sigma;&epsilon; &kappa;&alpha;&mu;ί&alpha;
          &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta;
          &omicron;&pi;&omicron;&iota;&alpha;&delta;ή&pi;&omicron;&tau;&epsilon;
          &pi;&rho;&omicron;&tau;&rho;&omicron;&pi;ή ή
          &alpha;&pi;&omicron;&tau;&rho;&omicron;&pi;ή &gamma;&iota;&alpha;
          &tau;&eta;&nu;
          &epsilon;&pi;&iota;&chi;&epsilon;ί&rho;&eta;&sigma;&eta; ή &mu;&eta;
          &sigma;&upsilon;&gamma;&kappa;&epsilon;&kappa;&rho;&iota;&mu;έ&nu;&omega;&nu;
          &pi;&rho;ά&xi;&epsilon;&omega;&nu;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        &nbsp;
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >9. &Pi;&alpha;&rho;ά&rho;&tau;&eta;&mu;&alpha;
          &Pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha;&sigmaf;
          &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ώ&nu;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Tau;&omicron; &pi;&alpha;&rho;ό&nu;
          &pi;&epsilon;&rho;&iota;&gamma;&rho;ά&phi;&epsilon;&iota;
          &tau;&eta;&nu; &pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή
          &tau;&eta;&sigmaf;
          &laquo;&epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;&raquo;
          &sigma;&chi;&epsilon;&tau;&iota;&kappa;ά &mu;&epsilon; &tau;&alpha;
          &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &pi;&omicron;&upsilon;
          &sigma;&upsilon;&lambda;&lambda;έ&gamma;&epsilon;&iota; &alpha;&pi;ό
          &tau;&omicron;&upsilon;&sigmaf;
          &chi;&rho;ή&sigma;&tau;&epsilon;&sigmaf; &tau;&omega;&nu;
          &sigma;&epsilon;&lambda;ί&delta;&omega;&nu; &tau;&eta;&sigmaf;.
          &Epsilon;&iota;&delta;&iota;&kappa;ό&tau;&epsilon;&rho;&alpha;,
          &omicron;&rho;ά&tau;&epsilon;
          &kappa;&alpha;&tau;&omega;&tau;έ&rho;&omega; &tau;&omicron;
          &sigma;&chi;&epsilon;&tau;&iota;&kappa;ό
          &kappa;&epsilon;ί&mu;&epsilon;&nu;&omicron;
          &sigma;&upsilon;&mu;&mu;ό&rho;&phi;&omega;&sigma;&eta;&sigmaf;
          &mu;&epsilon; &tau;&omicron;&upsilon;&sigmaf;
          &kappa;&alpha;&nu;ό&nu;&epsilon;&sigmaf; &tau;&omicron;&upsilon;
          GDPR.&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >9.1 &Tau;ή&rho;&eta;&sigma;&eta;
          &alpha;&rho;&chi;&epsilon;ί&omicron;&upsilon;
          &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ώ&nu;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Kappa;&alpha;&tau;ά &tau;&eta;&nu;
          &epsilon;&pi;ί&sigma;&kappa;&epsilon;&psi;&eta;
          &tau;&omicron;&upsilon; &alpha;&pi;&omicron;&delta;έ&kappa;&tau;&eta;
          &sigma;&tau;&iota;&sigmaf;
          &sigma;&epsilon;&lambda;ί&delta;&epsilon;&sigmaf; &tau;&eta;&sigmaf;
          &epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;
          &kappa;&alpha;&iota; &kappa;&alpha;&tau;ά &tau;&eta;&nu;
          &chi;&rho;ή&sigma;&eta; &tau;&omega;&nu;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu; &tau;&eta;&sigmaf;
          &epsilon;ί&nu;&alpha;&iota; &pi;&iota;&theta;&alpha;&nu;ό &nu;&alpha;
          &zeta;&eta;&tau;&eta;&theta;&epsilon;ί &eta;
          &delta;ή&lambda;&omega;&sigma;&eta;
          &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&omega;&nu;
          &pi;&omicron;&upsilon; &tau;&omicron;&nu;
          &alpha;&phi;&omicron;&rho;&omicron;ύ&nu; (ό&nu;&omicron;&mu;&alpha;,
          &epsilon;&pi;ά&gamma;&gamma;&epsilon;&lambda;&mu;&alpha;,
          &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;ή
          &delta;&iota;&epsilon;ύ&theta;&upsilon;&nu;&sigma;&eta;,
          &eta;&mu;&epsilon;&rho;&omicron;&mu;&eta;&nu;ί&alpha;
          &gamma;έ&nu;&nu;&eta;&sigma;&eta;&sigmaf; &kappa;&lambda;&pi;.) ή
          &eta; &pi;&alpha;&rho;&omicron;&chi;ή
          &epsilon;&gamma;&gamma;&rho;ά&phi;&omega;&nu;
          &tau;&alpha;&upsilon;&tau;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;&sigmaf;
          (&alpha;&sigma;&tau;&upsilon;&nu;&omicron;&mu;&iota;&kappa;ό
          &delta;&epsilon;&lambda;&tau;ί&omicron;
          &tau;&alpha;&upsilon;&tau;ό&tau;&eta;&tau;&alpha;&sigmaf;,
          &delta;&iota;&alpha;&beta;&alpha;&tau;ή&rho;&iota;&omicron;),
          &pi;&rho;&omicron;&kappa;&epsilon;&iota;&mu;έ&nu;&omicron;&upsilon;
          &nu;&alpha;
          &epsilon;&nu;&epsilon;&rho;&gamma;&omicron;&pi;&omicron;&iota;&eta;&theta;&omicron;ύ&nu;
          &alpha;&nu;&tau;ί&sigma;&tau;&omicron;&iota;&chi;&epsilon;&sigmaf;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;
          (&pi;&lambda;&eta;&rho;&omega;&mu;έ&sigmaf;, badges, newsletters,
          &delta;&iota;&alpha;&gamma;&omega;&nu;&iota;&sigma;&mu;&omicron;ί
          chats, Web2SMS &kappa;&lambda;&pi;.)
          &pi;&rho;&omicron;&kappa;&epsilon;&iota;&mu;έ&nu;&omicron;&upsilon;
          &nu;&alpha;
          &delta;&iota;&alpha;&sigma;&phi;&alpha;&lambda;&iota;&sigma;&theta;&epsilon;ί
          &eta; &delta;&upsilon;&nu;&alpha;&tau;ό&tau;&eta;&tau;&alpha;
          &epsilon;&pi;&iota;&kappa;&omicron;&iota;&nu;&omega;&nu;ί&alpha;&sigmaf;,
          &sigma;&tau;&eta;&nu; &omicron;&pi;&omicron;ί&alpha;
          έ&chi;&epsilon;&iota; ή&delta;&eta; &rho;&eta;&tau;ά
          &sigma;&upsilon;&gamma;&kappa;&alpha;&tau;&alpha;&tau;&epsilon;&theta;&epsilon;ί.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Delta;&iota;&epsilon;&upsilon;&kappa;&rho;&iota;&nu;ί&zeta;&epsilon;&tau;&alpha;&iota;
          ό&tau;&iota; &eta;
          &laquo;&epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&raquo;
          &delta;&epsilon;&nu; &delta;&iota;&alpha;&theta;έ&tau;&epsilon;&iota;
          &delta;&eta;&mu;ό&sigma;&iota;&alpha;
          &pi;&rho;&omicron;&sigma;&beta;ά&sigma;&iota;&mu;&omicron;
          &kappa;&alpha;&tau;ά&lambda;&omicron;&gamma;&omicron;
          &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;ώ&nu;
          &delta;&iota;&epsilon;&upsilon;&theta;ύ&nu;&sigma;&epsilon;&omega;&nu;
          &tau;&omega;&nu;
          &sigma;&upsilon;&nu;&delta;&rho;&omicron;&mu;&eta;&tau;ώ&nu;
          &tau;&eta;&sigmaf;. &Omega;&sigmaf; &epsilon;&kappa;
          &tau;&omicron;ύ&tau;&omicron;&upsilon;, &tau;&alpha;
          &tau;&upsilon;&chi;ό&nu;
          &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha; (&pi;.&chi;.
          &omicron;&nu;ό&mu;&alpha;&tau;&alpha;
          &pi;&rho;ό&sigma;&beta;&alpha;&sigma;&eta;&sigmaf;,
          &kappa;&lambda;&pi;.) &pi;&omicron;&upsilon;
          &epsilon;&mu;&phi;&alpha;&nu;ί&zeta;&omicron;&nu;&tau;&alpha;&iota;
          &omicron;&pi;&omicron;&upsilon;&delta;ή&pi;&omicron;&tau;&epsilon;
          &sigma;&tau;&iota;&sigmaf;
          &sigma;&epsilon;&lambda;ί&delta;&epsilon;&sigmaf; &kappa;&alpha;&iota;
          &tau;&iota;&sigmaf;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;
          &tau;&omicron;&upsilon;
          &delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;&omicron;ύ
          &tau;ό&pi;&omicron;&upsilon; &tau;&eta;&sigmaf;
          &laquo;&epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;&raquo;
          &pi;&rho;&omicron;&omicron;&rho;ί&zeta;&omicron;&nu;&tau;&alpha;&iota;
          &alpha;&pi;&omicron;&kappa;&lambda;&epsilon;&iota;&sigma;&tau;&iota;&kappa;ά
          &kappa;&alpha;&iota; &mu;ό&nu;&omicron; &gamma;&iota;&alpha;
          &tau;&eta; &delta;&iota;&alpha;&sigma;&phi;ά&lambda;&iota;&sigma;&eta;
          &tau;&eta;&sigmaf;
          &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;ί&alpha;&sigmaf;
          &tau;&eta;&sigmaf;
          &alpha;&nu;&tau;ί&sigma;&tau;&omicron;&iota;&chi;&eta;&sigmaf;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&alpha;&sigmaf;
          &kappa;&alpha;&iota; &delta;&epsilon;&nu;
          &epsilon;&pi;&iota;&tau;&rho;έ&pi;&epsilon;&tau;&alpha;&iota;
          &nu;&alpha;
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&eta;&theta;&omicron;ύ&nu;
          &alpha;&pi;ό
          &omicron;&pi;&omicron;&iota;&omicron;&nu;&delta;ή&pi;&omicron;&tau;&epsilon;
          &tau;&rho;ί&tau;&omicron;. H
          &laquo;&epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&raquo;
          &epsilon;&nu;&epsilon;&rho;&gamma;&epsilon;ί
          &sigma;ύ&mu;&phi;&omega;&nu;&alpha; &mu;&epsilon; &tau;&eta;&nu;
          &iota;&sigma;&chi;ύ&omicron;&upsilon;&sigma;&alpha;
          &nu;&omicron;&mu;&omicron;&theta;&epsilon;&sigma;ί&alpha;
          &kappa;&alpha;&iota;
          &sigma;&tau;&omicron;&chi;&epsilon;ύ&epsilon;&iota;
          &sigma;&tau;&eta;&nu; &kappa;&alpha;&lambda;ύ&tau;&epsilon;&rho;&eta;
          &epsilon;&phi;&alpha;&rho;&mu;&omicron;&gamma;ή &tau;&eta;&sigmaf;
          &omicron;&rho;&theta;ή&sigmaf;
          &pi;&rho;&alpha;&kappa;&tau;&iota;&kappa;ή&sigmaf; &sigma;&epsilon;
          ό&tau;&iota; &alpha;&phi;&omicron;&rho;ά &tau;&omicron; Internet.
          &Tau;&alpha; &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
          &sigma;&alpha;&sigmaf;
          &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&alpha;
          &tau;&eta;&rho;&omicron;ύ&nu;&tau;&alpha;&iota; &mu;&epsilon;
          &alpha;&sigma;&phi;ά&lambda;&epsilon;&iota;&alpha;
          &gamma;&iota;&alpha; ό&sigma;&omicron;
          &chi;&rho;&omicron;&nu;&iota;&kappa;ό
          &delta;&iota;ά&sigma;&tau;&eta;&mu;&alpha;
          &epsilon;ί&sigma;&tau;&epsilon;
          &epsilon;&gamma;&gamma;&epsilon;&gamma;&rho;&alpha;&mu;&mu;έ&nu;&omicron;&sigmaf;
          &sigma;&epsilon; &kappa;ά&pi;&omicron;&iota;&alpha;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&alpha; &tau;&eta;&sigmaf;
          &laquo;&epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;&raquo;
          &kappa;&alpha;&iota;
          &delta;&iota;&alpha;&gamma;&rho;ά&phi;&omicron;&nu;&tau;&alpha;&iota;
          &mu;&epsilon;&tau;ά &tau;&eta;&nu; &mu;&epsilon;
          &omicron;&pi;&omicron;&iota;&omicron;&delta;ή&pi;&omicron;&tau;&epsilon;
          &tau;&rho;ό&pi;&omicron; &lambda;ή&xi;&eta; &tau;&eta;&sigmaf;
          &sigma;&upsilon;&nu;&alpha;&lambda;&lambda;&alpha;&kappa;&tau;&iota;&kappa;ή&sigmaf;
          &sigma;&alpha;&sigmaf; &sigma;&chi;έ&sigma;&eta;&sigmaf; &mu;&epsilon;
          &tau;&eta;&nu;
          &laquo;&epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&raquo;.
          Ό&tau;&alpha;&nu; &pi;&rho;ό&kappa;&epsilon;&iota;&tau;&alpha;&iota;
          &gamma;&iota;&alpha; &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&alpha;
          &alpha;&tau;ό&mu;&omega;&nu; &pi;&omicron;&upsilon;
          &delta;&epsilon;&nu; έ&chi;&omicron;&upsilon;&nu;
          &alpha;&kappa;ό&mu;&alpha;
          &epsilon;&gamma;&gamma;&rho;&alpha;&phi;&epsilon;ί &sigma;&epsilon;
          &kappa;ά&pi;&omicron;&iota;&alpha;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&alpha;,
          &alpha;&lambda;&lambda;ά έ&chi;&omicron;&upsilon;&nu;
          &sigma;&upsilon;&mu;&mu;&epsilon;&tau;ά&sigma;&chi;&epsilon;&iota;
          &kappa;&alpha;&tau;ά &kappa;&alpha;&iota;&rho;&omicron;ύ&sigmaf;
          &sigma;&epsilon; ά&lambda;&lambda;&epsilon;&sigmaf; on-line
          &delta;&rho;&alpha;&sigma;&tau;&eta;&rho;&iota;ό&tau;&eta;&tau;&epsilon;&sigmaf;
          (&pi;.&chi;. &sigma;&epsilon;
          &delta;&iota;&alpha;&gamma;&omega;&nu;&iota;&sigma;&mu;&omicron;ύ&sigmaf;),
          &tau;&alpha; &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&alpha;
          &tau;&omicron;&upsilon;&sigmaf;
          &delta;&iota;&alpha;&tau;&eta;&rho;&omicron;ύ&nu;&tau;&alpha;&iota;
          &gamma;&iota;&alpha; ό&sigma;&omicron; &chi;&rho;ό&nu;&omicron;
          &alpha;&pi;&alpha;&iota;&tau;&epsilon;ί&tau;&alpha;&iota;
          &gamma;&iota;&alpha; &tau;&eta;
          &delta;&iota;&alpha;&sigma;&phi;ά&lambda;&iota;&sigma;&eta;
          &tau;&eta;&sigmaf; &omicron;&rho;&theta;ή&sigmaf;
          &epsilon;&kappa;&tau;έ&lambda;&epsilon;&sigma;&eta;&sigmaf;
          &tau;&eta;&sigmaf;
          &sigma;&upsilon;&gamma;&kappa;&epsilon;&kappa;&rho;&iota;&mu;έ&nu;&eta;&sigmaf;
          &delta;&rho;&alpha;&sigma;&tau;&eta;&rho;&iota;ό&tau;&eta;&tau;&alpha;&sigmaf;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >9.2 &Tau;&alpha; &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &pi;&omicron;&upsilon;
          &delta;&eta;&lambda;ώ&nu;&epsilon;&tau;&epsilon; &sigma;&tau;&eta;&nu;
          &laquo;&epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&raquo;
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&nu;&tau;&alpha;&iota;
          &alpha;&pi;&omicron;&kappa;&lambda;&epsilon;&iota;&sigma;&tau;&iota;&kappa;ά
          &alpha;&pi;ό &alpha;&upsilon;&tau;ή, &mu;&epsilon;
          &sigma;&kappa;&omicron;&pi;ό &tau;&eta;&nu;
          &upsilon;&pi;&omicron;&sigma;&tau;ή&rho;&iota;&xi;&eta;,
          &pi;&rho;&omicron;ώ&theta;&eta;&sigma;&eta; &kappa;&alpha;&iota;
          &epsilon;&kappa;&tau;έ&lambda;&epsilon;&sigma;&eta; &tau;&eta;&sigmaf;
          &sigma;&upsilon;&nu;&alpha;&lambda;&lambda;&alpha;&kappa;&tau;&iota;&kappa;ή&sigmaf;
          &sigma;&chi;έ&sigma;&eta;&sigmaf; ή &gamma;&iota;&alpha;
          &sigma;&tau;&alpha;&tau;&iota;&sigma;&tau;&iota;&kappa;&omicron;ύ&sigmaf;
          &lambda;ό&gamma;&omicron;&upsilon;&sigmaf;.&nbsp;</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Alpha;&pi;&omicron;&delta;έ&kappa;&tau;&epsilon;&sigmaf;
          &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&omega;&nu;
          &tau;&omicron;&upsilon; &Alpha;&rho;&chi;&epsilon;ί&omicron;&upsilon;,
          &delta;ύ&nu;&alpha;&nu;&tau;&alpha;&iota; &nu;&alpha;
          &epsilon;ί&nu;&alpha;&iota;
          &epsilon;&tau;&alpha;&iota;&rho;ί&epsilon;&sigmaf;
          &pi;&omicron;&upsilon;
          &epsilon;&nu;&delta;&epsilon;&chi;&omicron;&mu;έ&nu;&omega;&sigmaf;
          &mu;&epsilon;&sigma;&omicron;&lambda;&alpha;&beta;&omicron;ύ&nu;
          &gamma;&iota;&alpha; &tau;&eta;&nu;
          &pi;&rho;&omicron;ώ&theta;&eta;&sigma;&eta;,
          &upsilon;&pi;&omicron;&sigma;&tau;ή&rho;&iota;&xi;&eta;
          &kappa;&alpha;&iota;
          &epsilon;&xi;&upsilon;&pi;&eta;&rho;έ&tau;&eta;&sigma;&eta;
          &tau;&eta;&sigmaf;
          &sigma;&upsilon;&nu;&alpha;&lambda;&lambda;&alpha;&kappa;&tau;&iota;&kappa;ή&sigmaf;
          &sigma;&chi;έ&sigma;&eta;&sigmaf; &kappa;&alpha;&iota;
          &mu;ό&nu;&omicron; &gamma;&iota;&alpha; &tau;&omicron;&nu;
          &sigma;&kappa;&omicron;&pi;ό &tau;&eta;&sigmaf;
          &pi;&alpha;&rho;&omicron;&chi;ή&sigmaf; &tau;&omega;&nu;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
          &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;ώ&nu;
          &epsilon;&pi;&iota;&kappa;&omicron;&iota;&nu;&omega;&nu;&iota;ώ&nu; ή
          &tau;&eta;&nu; &tau;&upsilon;&chi;ό&nu;
          &pi;&alpha;&rho;&omicron;&chi;ή
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
          &pi;&rho;&omicron;&sigma;&tau;&iota;&theta;έ&mu;&epsilon;&nu;&eta;&sigmaf;
          &alpha;&xi;ί&alpha;&sigmaf; &pi;&omicron;&upsilon;
          έ&chi;&epsilon;&iota; &zeta;&eta;&tau;ή&sigma;&epsilon;&iota;
          &omicron; &chi;&rho;ή&sigma;&tau;&eta;&sigmaf;.
          &Sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&alpha;
          &tau;&omicron;&upsilon; &alpha;&rho;&chi;&epsilon;ί&omicron;&upsilon;
          &kappa;&omicron;&iota;&nu;&omicron;&pi;&omicron;&iota;&omicron;ύ&nu;&tau;&alpha;&iota;
          &epsilon;&pi;ί&sigma;&eta;&sigmaf; &sigma;&tau;&iota;&sigmaf;
          &alpha;&rho;&mu;ό&delta;&iota;&epsilon;&sigmaf;
          &delta;&iota;&kappa;&alpha;&sigma;&tau;&iota;&kappa;έ&sigmaf;,
          &alpha;&sigma;&tau;&upsilon;&nu;&omicron;&mu;&iota;&kappa;έ&sigmaf;
          &kappa;&alpha;&iota; ά&lambda;&lambda;&epsilon;&sigmaf;
          &delta;&iota;&omicron;&iota;&kappa;&eta;&tau;&iota;&kappa;έ&sigmaf;
          &alpha;&rho;&chi;έ&sigmaf; &kappa;&alpha;&tau;ό&pi;&iota;&nu;
          &nu;&omicron;&mu;ί&mu;&omicron;&upsilon;
          &alpha;&iota;&tau;ή&mu;&alpha;&tau;ό&sigmaf;
          &tau;&omicron;&upsilon;&sigmaf; &kappa;&alpha;&iota;
          &sigma;ύ&mu;&phi;&omega;&nu;&alpha; &mu;&epsilon; &tau;&iota;&sigmaf;
          &kappa;ά&theta;&epsilon; &phi;&omicron;&rho;ά
          &iota;&sigma;&chi;ύ&omicron;&upsilon;&sigma;&epsilon;&sigmaf;
          &nu;&omicron;&mu;&omicron;&theta;&epsilon;&tau;&iota;&kappa;έ&sigmaf;
          &delta;&iota;&alpha;&tau;ά&xi;&epsilon;&iota;&sigmaf;. &Omicron;
          &Chi;&rho;ή&sigma;&tau;&eta;&sigmaf; έ&chi;&epsilon;&iota;,
          &mu;έ&sigma;&alpha; &sigma;&tau;&alpha;
          &pi;&lambda;&alpha;ί&sigma;&iota;&alpha; &tau;&eta;&sigmaf;
          &nu;&omicron;&mu;&omicron;&theta;&epsilon;&sigma;ί&alpha;&sigmaf;
          &pi;&epsilon;&rho;ί
          &alpha;&pi;&omicron;&rho;&rho;ή&tau;&omicron;&upsilon;
          &tau;&omega;&nu;
          &tau;&eta;&lambda;&epsilon;&pi;&iota;&kappa;&omicron;&iota;&nu;&omega;&nu;&iota;ώ&nu;,
          &tau;&alpha; &delta;&iota;&kappa;&alpha;&iota;ώ&mu;&alpha;&tau;&alpha;
          &epsilon;&nu;&eta;&mu;έ&rho;&omega;&sigma;&eta;&sigmaf;
          &kappa;&alpha;&iota;
          &alpha;&nu;&tau;ί&rho;&rho;&eta;&sigma;&eta;&sigmaf;
          &pi;&omicron;&upsilon;
          &pi;&rho;&omicron;&beta;&lambda;έ&pi;&omicron;&upsilon;&nu;
          &omicron;&iota; &delta;&iota;&alpha;&tau;ά&xi;&epsilon;&iota;&sigmaf;
          &tau;&omega;&nu; &sigma;&chi;&epsilon;&tau;&iota;&kappa;ώ&nu;
          &Nu;ό&mu;&omega;&nu; &pi;&omicron;&upsilon;
          &alpha;&nu;&alpha;&phi;έ&rho;&omicron;&nu;&tau;&alpha;&iota;
          &sigma;&tau;&alpha; &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >9.3 &Chi;&rho;ή&sigma;&eta; cookies</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Eta; &laquo;&epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&raquo;
          (&eta; ί&delta;&iota;&alpha; ή/&kappa;&alpha;&iota; &sigma;&epsilon;
          &sigma;&upsilon;&nu;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;
          &mu;&epsilon; &tau;&rho;ί&tau;&epsilon;&sigmaf;
          &epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&epsilon;&sigmaf;
          &epsilon;&xi;&epsilon;&iota;&delta;&iota;&kappa;&epsilon;&upsilon;&mu;έ&nu;&epsilon;&sigmaf;
          &pi;&rho;&omicron;&sigmaf; &tau;&omicron;ύ&tau;&omicron;)
          &sigma;&upsilon;&lambda;&lambda;έ&gamma;&epsilon;&iota;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
          &gamma;&iota;&alpha; &tau;&eta;&nu; &kappa;ί&nu;&eta;&sigma;&eta;
          &tau;&omicron;&upsilon; &pi;&alpha;&rho;ό&nu;&tau;&omicron;&sigmaf;
          &delta;&iota;&alpha;&delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;&omicron;ύ
          &tau;ό&pi;&omicron;&upsilon; &mu;&epsilon;
          &sigma;&kappa;&omicron;&pi;ό &tau;&eta;&nu;
          &kappa;&alpha;&tau;&alpha;&mu;έ&tau;&rho;&eta;&sigma;&eta;
          &tau;&omega;&nu;
          &epsilon;&pi;&iota;&sigma;&kappa;&epsilon;&pi;&tau;ώ&nu;,
          &tau;&eta;&nu; &epsilon;&xi;&alpha;&gamma;&omega;&gamma;ή
          &sigma;&tau;&alpha;&tau;&iota;&sigma;&tau;&iota;&kappa;ώ&nu;
          &kappa;&alpha;&iota; &tau;&eta;&nu;
          &beta;&epsilon;&lambda;&tau;ί&omega;&sigma;&eta; &tau;&eta;&sigmaf;
          &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;&iota;&kappa;ό&tau;&eta;&tau;&alpha;&sigmaf;
          &tau;&omicron;&upsilon;,
          &epsilon;&nu;&delta;&epsilon;&iota;&kappa;&tau;&iota;&kappa;ά
          &mu;έ&sigma;&omega; &tau;&eta;&sigmaf; &chi;&rho;ή&sigma;&eta;&sigmaf;
          cookies, web beacons &kappa;&lambda;&pi;. &Kappa;&alpha;&tau;ά
          &tau;&eta; &delta;&iota;ά&rho;&kappa;&epsilon;&iota;&alpha;
          &omicron;&pi;&omicron;&iota;&alpha;&sigma;&delta;ή&pi;&omicron;&tau;&epsilon;
          &epsilon;&pi;ί&sigma;&kappa;&epsilon;&psi;ή&sigmaf;
          &sigma;&tau;&omicron;&nu;
          &delta;&iota;&alpha;&delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;ό
          &tau;ό&pi;&omicron; &omicron;&iota;
          &sigma;&epsilon;&lambda;ί&delta;&epsilon;&sigmaf; &tau;&iota;&sigmaf;
          &omicron;&pi;&omicron;ί&epsilon;&sigmaf; &omicron;
          &chi;&rho;ή&sigma;&tau;&eta;&sigmaf;
          &epsilon;&pi;&iota;&sigma;&kappa;έ&pi;&tau;&epsilon;&tau;&alpha;&iota;,
          &mu;&alpha;&zeta;ί &mu;&epsilon; &tau;&alpha; cookies
          &pi;&omicron;&upsilon; &alpha;&upsilon;&tau;έ&sigmaf;
          &tau;&upsilon;&chi;ό&nu;
          &pi;&epsilon;&rho;&iota;έ&chi;&omicron;&upsilon;&nu;
          &epsilon;&nu;&delta;έ&chi;&epsilon;&tau;&alpha;&iota; &nu;&alpha;
          &laquo;&phi;&omicron;&rho;&tau;ώ&nu;&omicron;&nu;&tau;&alpha;&iota;&raquo;
          &sigma;&tau;&omicron;&nu;
          &upsilon;&pi;&omicron;&lambda;&omicron;&gamma;&iota;&sigma;&tau;ή
          &tau;&omicron;&upsilon; &chi;&rho;ή&sigma;&tau;&eta;. &Tau;&alpha;
          cookies &epsilon;ί&nu;&alpha;&iota;
          &kappa;&epsilon;ί&mu;&epsilon;&nu;&alpha;-&alpha;&rho;&chi;&epsilon;ί&alpha;
          &mu;έ&sigma;&omega; &tau;&omega;&nu;
          &omicron;&pi;&omicron;ί&omega;&nu; &omicron; server
          &alpha;&nu;&alpha;&gamma;&nu;&omega;&rho;ί&zeta;&epsilon;&iota;
          &tau;&omicron;&nu;
          &upsilon;&pi;&omicron;&lambda;&omicron;&gamma;&iota;&sigma;&tau;ή
          &tau;&omicron;&upsilon;
          &epsilon;&pi;&iota;&sigma;&kappa;έ&pi;&tau;&eta;
          &tau;&omicron;&upsilon;
          &delta;&iota;&alpha;&delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;&omicron;ύ
          &tau;ό&pi;&omicron;&upsilon;. &Tau;&alpha; cookies
          &alpha;&upsilon;&tau;ά
          &alpha;&pi;&omicron;&theta;&eta;&kappa;&epsilon;ύ&omicron;&nu;&tau;&alpha;&iota;
          &sigma;&tau;&omicron;&nu;
          &upsilon;&pi;&omicron;&lambda;&omicron;&gamma;&iota;&sigma;&tau;ή
          &tau;&omicron;&upsilon; &chi;&rho;ή&sigma;&tau;&eta;
          &mu;ό&nu;&omicron;
          &pi;&rho;&omicron;&kappa;&epsilon;&iota;&mu;έ&nu;&omicron;&upsilon;
          &nu;&alpha;
          &epsilon;&pi;&iota;&tau;&epsilon;&upsilon;&chi;&theta;&epsilon;ί &eta;
          &delta;&iota;&epsilon;&nu;έ&rho;&gamma;&epsilon;&iota;&alpha; ή &eta;
          &delta;&iota;&epsilon;&upsilon;&kappa;ό&lambda;&upsilon;&nu;&sigma;&eta;
          &tau;&eta;&sigmaf;
          &delta;&iota;&alpha;&beta;ί&beta;&alpha;&sigma;&eta;&sigmaf;
          &tau;&eta;&sigmaf;
          &epsilon;&pi;&iota;&kappa;&omicron;&iota;&nu;&omega;&nu;ί&alpha;&sigmaf;
          &mu;έ&sigma;&omega; &tau;&omicron;&upsilon;
          &delta;&iota;&kappa;&tau;ύ&omicron;&upsilon;
          &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;ώ&nu;
          &epsilon;&pi;&iota;&kappa;&omicron;&iota;&nu;&omega;&nu;&iota;ώ&nu; ή
          ό&tau;&alpha;&nu; &alpha;&upsilon;&tau;ό &epsilon;ί&nu;&alpha;&iota;
          &alpha;&nu;&alpha;&gamma;&kappa;&alpha;ί&omicron; &gamma;&iota;&alpha;
          &tau;&eta;&nu; &pi;&alpha;&rho;&omicron;&chi;ή
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&alpha;&sigmaf; &tau;&eta;&nu;
          &omicron;&pi;&omicron;ί&alpha; &omicron;
          &chi;&rho;ή&sigma;&tau;&eta;&sigmaf; έ&chi;&epsilon;&iota;
          &zeta;&eta;&tau;ή&sigma;&epsilon;&iota;. &Omicron;
          &chi;&rho;ή&sigma;&tau;&eta;&sigmaf; &tau;&eta;&sigmaf;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&alpha;&sigmaf;
          &mu;&pi;&omicron;&rho;&epsilon;ί &nu;&alpha;
          &rho;&upsilon;&theta;&mu;ί&sigma;&epsilon;&iota; &tau;&omicron;&nu;
          &pi;&epsilon;&rho;&iota;&eta;&gamma;&eta;&tau;ή (browser)
          &tau;&omicron;&upsilon; &kappa;&alpha;&tau;ά
          &tau;έ&tau;&omicron;&iota;&omicron; &tau;&rho;ό&pi;&omicron;
          ώ&sigma;&tau;&epsilon; &epsilon;ί&tau;&epsilon; &nu;&alpha;
          &tau;&omicron;&nu;
          &pi;&rho;&omicron;&epsilon;&iota;&delta;&omicron;&pi;&omicron;&iota;&epsilon;ί
          &gamma;&iota;&alpha; &tau;&eta; &chi;&rho;ή&sigma;&eta;
          &tau;&omega;&nu; cookies &sigma;&epsilon;
          &sigma;&upsilon;&gamma;&kappa;&epsilon;&kappa;&rho;&iota;&mu;έ&nu;&epsilon;&sigmaf;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;
          &tau;&omicron;&upsilon;
          &delta;&iota;&alpha;&delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;&omicron;ύ
          &tau;ό&pi;&omicron;&upsilon;, &epsilon;ί&tau;&epsilon; &nu;&alpha;
          &mu;&eta;&nu; &epsilon;&pi;&iota;&tau;&rho;έ&pi;&epsilon;&iota;
          &tau;&eta;&nu; &alpha;&pi;&omicron;&delta;&omicron;&chi;ή
          &tau;&eta;&sigmaf; &chi;&rho;ή&sigma;&eta;&sigmaf; cookies
          &sigma;&epsilon; &kappa;&alpha;&mu;&iota;ά
          &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta;.)
          &Omicron;&rho;ά&tau;&epsilon;
          &kappa;&alpha;&tau;&omega;&tau;έ&rho;&omega; &tau;&omicron;
          &sigma;&chi;&epsilon;&tau;&iota;&kappa;ό
          &kappa;&epsilon;ί&mu;&epsilon;&nu;&omicron; &uml;Ό&rho;&omicron;&iota;
          &chi;&rho;ή&sigma;&eta;&sigmaf; cookies&uml;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >9.4 &Pi;&alpha;&rho;&omicron;&chi;ή
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;&iota;ώ&nu;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Kappa;&alpha;&tau;ά &kappa;&alpha;&iota;&rho;&omicron;ύ&sigmaf;
          &theta;&alpha; &sigma;&alpha;&sigmaf;
          &pi;&alpha;&rho;έ&chi;&omicron;&upsilon;&mu;&epsilon;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
          &gamma;&iota;&alpha; ά&lambda;&lambda;&epsilon;&sigmaf;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;
          &kappa;&alpha;&iota; &pi;&rho;&omicron;ϊό&nu;&tau;&alpha;
          &tau;&eta;&sigmaf;
          &laquo;&epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;&raquo;
          &sigma;&tau;&iota;&sigmaf; &omicron;&pi;&omicron;ί&epsilon;&sigmaf;
          &mu;&pi;&omicron;&rho;&epsilon;ί&tau;&epsilon; &nu;&alpha;
          &epsilon;&gamma;&gamma;&rho;&alpha;&phi;&epsilon;ί&tau;&epsilon;
          &kappa;&alpha;&iota; &nu;&alpha;
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;ή&sigma;&epsilon;&tau;&epsilon;.
          &Sigma;&alpha;&sigmaf;
          &pi;&rho;&omicron;&sigma;&phi;έ&rho;&omicron;&upsilon;&mu;&epsilon;
          &epsilon;&pi;ί&sigma;&eta;&sigmaf; &tau;&eta;
          &delta;&upsilon;&nu;&alpha;&tau;ό&tau;&eta;&tau;&alpha; &nu;&alpha;
          &sigma;&upsilon;&mu;&mu;&epsilon;&tau;ά&sigma;&chi;&epsilon;&tau;&epsilon;
          &sigma;&epsilon; έ&rho;&epsilon;&upsilon;&nu;&epsilon;&sigmaf;
          &pi;&omicron;&upsilon;
          &delta;&iota;&epsilon;&xi;ά&gamma;&omicron;&nu;&tau;&alpha;&iota;
          &alpha;&pi;ό &tau;&eta;&nu;
          &laquo;&epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&raquo; ή
          &tau;&iota;&sigmaf;
          &sigma;&upsilon;&nu;&epsilon;&rho;&gamma;&alpha;&zeta;ό&mu;&epsilon;&nu;&epsilon;&sigmaf;
          &epsilon;&tau;&alpha;&iota;&rho;ί&epsilon;&sigmaf;, &omicron;&iota;
          &omicron;&pi;&omicron;ί&epsilon;&sigmaf;
          &epsilon;&xi;&epsilon;&tau;ά&zeta;&omicron;&upsilon;&nu;
          &tau;&eta;&nu;
          &alpha;&pi;&omicron;&tau;&epsilon;&lambda;&epsilon;&sigma;&mu;&alpha;&tau;&iota;&kappa;ό&tau;&eta;&tau;&alpha;
          &kappa;&alpha;&iota; &tau;&eta;&nu; &alpha;&pi;ή&chi;&eta;&sigma;&eta;
          &tau;&omega;&nu;
          &pi;&alpha;&rho;&epsilon;&chi;ό&mu;&epsilon;&nu;&omega;&nu;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >9.5 &Sigma;&chi;ό&lambda;&iota;&alpha; &kappa;&alpha;&iota;
          &pi;&epsilon;&rho;&iota;&epsilon;&chi;ό&mu;&epsilon;&nu;&omicron;
          &chi;&rho;&eta;&sigma;&tau;ώ&nu;&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.4pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Tau;&omicron;
          &pi;&epsilon;&rho;&iota;&epsilon;&chi;ό&mu;&epsilon;&nu;&omicron;
          &pi;&omicron;&upsilon; &mu;&pi;&omicron;&rho;&omicron;ύ&nu;
          &nu;&alpha;
          &delta;&eta;&mu;&omicron;&sigma;&iota;&epsilon;ύ&sigma;&omicron;&upsilon;&nu;
          &sigma;&tau;&omicron;&nbsp;</span
        ><a href="http://keeppet.gr/" style="text-decoration: none"
          ><span
            style="
              font-size: 11pt;
              font-family: Arial;
              color: #0563c1;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: underline;
              -webkit-text-decoration-skip: none;
              text-decoration-skip-ink: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >stoferno.gr</span
          ></a
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;&omicron;&iota; &chi;&rho;ή&sigma;&tau;&epsilon;&sigmaf;,
          ό&pi;&omega;&sigmaf; &kappa;&epsilon;ί&mu;&epsilon;&nu;&alpha;,
          &epsilon;&iota;&kappa;ό&nu;&epsilon;&sigmaf; &kappa;&alpha;&iota;
          video - ή links &pi;&rho;&omicron;&sigmaf; ά&lambda;&lambda;&alpha;
          Websites &epsilon;ί&nu;&alpha;&iota; &epsilon;&xi;
          &alpha;&rho;&chi;ή&sigmaf; - &kappa;&alpha;&iota;
          &pi;&alpha;&rho;&alpha;&mu;έ&nu;&epsilon;&iota; &kappa;&alpha;&iota;
          &kappa;&alpha;&tau;ό&pi;&iota;&nu; &tau;&eta;&sigmaf;
          &delta;&eta;&mu;&omicron;&sigma;ί&epsilon;&upsilon;&sigma;ή&sigmaf;
          &tau;&omicron;&upsilon; - &sigma;&tau;&eta;&nu;
          &alpha;&pi;&omicron;&kappa;&lambda;&epsilon;&iota;&sigma;&tau;&iota;&kappa;ή
          &epsilon;&upsilon;&theta;ύ&nu;&eta; &tau;&omicron;&upsilon;
          &chi;&rho;ή&sigma;&tau;&eta; &pi;&omicron;&upsilon; &tau;&omicron;
          &kappa;&alpha;&tau;&alpha;&chi;ώ&rho;&eta;&sigma;&epsilon;.
          &Omicron;&iota; &chi;&rho;ή&sigma;&tau;&epsilon;&sigmaf;
          &kappa;&alpha;&tau;&alpha;&nu;&omicron;&omicron;ύ&nu;
          &kappa;&alpha;&iota;
          &alpha;&pi;&omicron;&delta;έ&chi;&omicron;&nu;&tau;&alpha;&iota;
          ό&tau;&iota; &tau;&omicron; stoferno
          &delta;&iota;&alpha;&tau;&eta;&rho;&epsilon;ί &tau;&omicron;
          &delta;&iota;&kappa;&alpha;ί&omega;&mu;&alpha; &nu;&alpha;
          &alpha;&pi;&omicron;&mu;&alpha;&kappa;&rho;ύ&nu;&epsilon;&iota;
          &epsilon;&kappa; &tau;&omega;&nu;
          &upsilon;&sigma;&tau;έ&rho;&omega;&nu;
          &omicron;&pi;&omicron;&iota;&omicron;&delta;ή&pi;&omicron;&tau;&epsilon;
          &pi;&epsilon;&rho;&iota;&epsilon;&chi;ό&mu;&epsilon;&nu;&omicron;
          έ&chi;&epsilon;&iota;
          &delta;&eta;&mu;&omicron;&sigma;&iota;&epsilon;&upsilon;&tau;&epsilon;ί
          &alpha;&pi;ό &tau;&omicron;&nu; &chi;&rho;ή&sigma;&tau;&eta;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Omicron;&iota; &chi;&rho;ή&sigma;&tau;&epsilon;&sigmaf;
          &kappa;&alpha;&tau;&alpha;&nu;&omicron;&omicron;ύ&nu;
          &kappa;&alpha;&iota;
          &alpha;&pi;&omicron;&delta;έ&chi;&omicron;&nu;&tau;&alpha;&iota;
          ό&tau;&iota;
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;ώ&nu;&tau;&alpha;&sigmaf;
          &tau;&iota;&sigmaf; &sigma;&epsilon;&lambda;ί&delta;&epsilon;&sigmaf;
          &kappa;&alpha;&iota; &tau;&iota;&sigmaf;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;
          &tau;&omicron;&upsilon; stoferno &mu;&pi;&omicron;&rho;&epsilon;ί
          &nu;&alpha; &epsilon;&kappa;&tau;&epsilon;&theta;&omicron;ύ&nu;
          &sigma;&epsilon;
          &pi;&epsilon;&rho;&iota;&epsilon;&chi;ό&mu;&epsilon;&nu;&omicron;
          &pi;&rho;&omicron;&sigma;&beta;&lambda;&eta;&tau;&iota;&kappa;ό,
          &alpha;&nu;ή&theta;&iota;&kappa;&omicron; ή
          &pi;&alpha;&rho;ά&nu;&omicron;&mu;&omicron;.
          &Epsilon;&pi;ί&sigma;&eta;&sigmaf;
          &kappa;&alpha;&tau;&alpha;&nu;&omicron;&omicron;ύ&nu;
          &kappa;&alpha;&iota;
          &alpha;&pi;&omicron;&delta;έ&chi;&omicron;&nu;&tau;&alpha;&iota;
          ό&tau;&iota; &tau;&omicron; stoferno &delta;&epsilon;&nu;
          &mu;&pi;&omicron;&rho;&epsilon;ί &nu;&alpha;
          &theta;&epsilon;&omega;&rho;&eta;&theta;&epsilon;ί ό&tau;&iota;
          &epsilon;&nu;&sigma;&tau;&epsilon;&rho;&nu;ί&zeta;&epsilon;&tau;&alpha;&iota;
          ή &phi;έ&rho;&epsilon;&iota; &epsilon;&upsilon;&theta;ύ&nu;&eta;
          &gamma;&iota;&alpha; &tau;&omicron;
          &pi;&epsilon;&rho;&iota;&epsilon;&chi;ό&mu;&epsilon;&nu;&omicron;
          &pi;&omicron;&upsilon; έ&chi;&epsilon;&iota;
          &delta;&eta;&mu;&omicron;&sigma;&iota;&epsilon;&upsilon;&theta;&epsilon;ί
          &alpha;&pi;ό &chi;&rho;ή&sigma;&tau;&epsilon;&sigmaf;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Omicron;&iota; &chi;&rho;ή&sigma;&tau;&epsilon;&sigmaf;
          &kappa;&alpha;&tau;&alpha;&nu;&omicron;&omicron;ύ&nu;
          &kappa;&alpha;&iota;
          &sigma;&upsilon;&mu;&phi;&omega;&nu;&omicron;ύ&nu; ό&tau;&iota;
          έ&chi;&omicron;&upsilon;&nu; &tau;&iota;&sigmaf;
          &pi;&alpha;&rho;&alpha;&kappa;ά&tau;&omega;
          &upsilon;&pi;&omicron;&chi;&rho;&epsilon;ώ&sigma;&epsilon;&iota;&sigmaf;
          &sigma;&chi;&epsilon;&tau;&iota;&kappa;ά &mu;&epsilon; &tau;&omicron;
          &pi;&epsilon;&rho;&iota;&epsilon;&chi;ό&mu;&epsilon;&nu;&omicron;
          &pi;&omicron;&upsilon;
          &delta;&eta;&mu;&omicron;&sigma;&iota;&epsilon;ύ&omicron;&upsilon;&nu;
          &sigma;&tau;&omicron; site:</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Nu;&alpha; &mu;&eta;&nu; &epsilon;ί&nu;&alpha;&iota;
          &pi;&alpha;&rho;ά&nu;&omicron;&mu;&omicron;,
          &pi;&rho;&omicron;&sigma;&beta;&lambda;&eta;&tau;&iota;&kappa;ό,
          &upsilon;&beta;&rho;&iota;&sigma;&tau;&iota;&kappa;ό,
          &delta;&upsilon;&sigma;&phi;&eta;&mu;&iota;&sigma;&tau;&iota;&kappa;ό
          ή &rho;&alpha;&tau;&sigma;&iota;&sigma;&tau;&iota;&kappa;ό.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Nu;&alpha; &mu;&eta;&nu;
          &pi;&alpha;&rho;&alpha;&beta;&iota;ά&zeta;&epsilon;&iota;
          &tau;&eta;&nu;
          &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ό&tau;&eta;&tau;&alpha;
          &kappa;&alpha;&iota; &tau;&alpha;
          &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &tau;&rho;ί&tau;&omega;&nu;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Nu;&alpha; &mu;&eta;&nu;
          &pi;&alpha;&rho;&alpha;&beta;&iota;ά&zeta;&epsilon;&iota;
          &pi;&nu;&epsilon;&upsilon;&mu;&alpha;&tau;&iota;&kappa;ά
          &delta;&iota;&kappa;&alpha;&iota;ώ&mu;&alpha;&tau;&alpha;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Nu;&alpha; &mu;&eta;&nu; &sigma;&upsilon;&nu;&iota;&sigma;&tau;ά
          &pi;&omicron;&iota;&nu;&iota;&kappa;ό
          &alpha;&delta;ί&kappa;&eta;&mu;&alpha; &mu;&epsilon;
          &omicron;&pi;&omicron;&iota;&omicron;&delta;ή&pi;&omicron;&tau;&epsilon;
          &tau;&rho;ό&pi;&omicron;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Nu;&alpha; &epsilon;ί&nu;&alpha;&iota;
          &sigma;ύ&mu;&phi;&omega;&nu;&omicron; &mu;&epsilon; &tau;&eta;&nu;
          &upsilon;&pi;ά&rho;&chi;&omicron;&upsilon;&sigma;&alpha;
          &epsilon;&theta;&nu;&iota;&kappa;ή,
          &epsilon;&upsilon;&rho;&omega;&pi;&alpha;ϊ&kappa;ή
          &kappa;&alpha;&iota; &delta;&iota;&epsilon;&theta;&nu;ή
          &nu;&omicron;&mu;&omicron;&theta;&epsilon;&sigma;ί&alpha;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Sigma;&epsilon; &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta;
          &pi;&omicron;&upsilon; &kappa;ά&pi;&omicron;&iota;&omicron;&sigmaf;
          &chi;&rho;ή&sigma;&tau;&eta;&sigmaf;
          &alpha;&nu;&tau;&iota;&lambda;&eta;&phi;&theta;&epsilon;ί
          &pi;&epsilon;&rho;&iota;&epsilon;&chi;ό&mu;&epsilon;&nu;&omicron;
          &alpha;&nu;&tau;ί&theta;&epsilon;&tau;&omicron; &mu;&epsilon;
          &tau;&alpha; &pi;&alpha;&rho;&alpha;&pi;ά&nu;&omega;, &tau;&omicron;
          stoferno &tau;&omicron;&nu;
          &epsilon;&nu;&theta;&alpha;&rho;&rho;ύ&nu;&epsilon;&iota; &nu;&alpha;
          &epsilon;&pi;&iota;&kappa;&omicron;&iota;&nu;&omega;&nu;ή&sigma;&epsilon;&iota;
          &mu;&epsilon; &tau;&eta;&nu;
          &epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;
          &pi;&rho;&omicron;&kappa;&epsilon;&iota;&mu;έ&nu;&omicron;&upsilon;
          &nu;&alpha; &tau;&omicron;
          &alpha;&nu;&alpha;&phi;έ&rho;&epsilon;&iota;. &Tau;&omicron;
          &pi;&epsilon;&rho;&iota;&epsilon;&chi;ό&mu;&epsilon;&nu;&omicron;
          &tau;&omicron; &omicron;&pi;&omicron;ί&omicron; &theta;&alpha;
          &upsilon;&pi;&omicron;&delta;&epsilon;&iota;&chi;&theta;&epsilon;ί
          &mu;&epsilon; &tau;&omicron;&nu; &tau;&rho;ό&pi;&omicron;
          &alpha;&upsilon;&tau;ό &theta;&alpha;
          &epsilon;&lambda;&epsilon;&gamma;&chi;&theta;&epsilon;ί
          &kappa;&alpha;&iota;, &alpha;&nu; &kappa;&rho;&iota;&theta;&epsilon;ί
          &alpha;&nu;&alpha;&gamma;&kappa;&alpha;ί&omicron;, &theta;&alpha;
          &alpha;&pi;&omicron;&mu;&alpha;&kappa;&rho;&upsilon;&nu;&theta;&epsilon;ί.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Eta; &alpha;&xi;&iota;&omicron;&lambda;ό&gamma;&eta;&sigma;&eta;
          &tau;&omicron;&upsilon;
          &pi;&epsilon;&rho;&iota;&epsilon;&chi;&omicron;&mu;έ&nu;&omicron;&upsilon;
          &pi;&omicron;&upsilon; έ&chi;&epsilon;&iota;
          &delta;&eta;&mu;&omicron;&sigma;&iota;&epsilon;&upsilon;&theta;&epsilon;ί
          &alpha;&pi;ό &tau;&omicron;&upsilon;&sigmaf;
          &chi;&rho;ή&sigma;&tau;&epsilon;&sigmaf;
          &kappa;&alpha;&theta;ώ&sigmaf; &kappa;&alpha;&iota; &eta;
          &chi;&rho;ή&sigma;&eta; &alpha;&upsilon;&tau;&omicron;ύ
          &sigma;&alpha;&nu;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&alpha;
          &epsilon;ί&nu;&alpha;&iota;
          &alpha;&pi;&omicron;&kappa;&lambda;&epsilon;&iota;&sigma;&tau;&iota;&kappa;ή
          &upsilon;&pi;&epsilon;&upsilon;&theta;&upsilon;&nu;ό&tau;&eta;&tau;&alpha;
          &tau;&omicron;&upsilon; &chi;&rho;ή&sigma;&tau;&eta;
          &kappa;&alpha;&iota; &tau;&omicron; stoferno &delta;&epsilon;&nu;
          &mu;&pi;&omicron;&rho;&epsilon;ί &nu;&alpha;
          &theta;&epsilon;&omega;&rho;&eta;&theta;&epsilon;ί &omega;&sigmaf;
          &upsilon;&pi;&epsilon;ύ&theta;&upsilon;&nu;&omicron;
          &gamma;&iota;&alpha; &tau;&eta;&nu;
          &omicron;&rho;&theta;ό&tau;&eta;&tau;&alpha; &kappa;&alpha;&iota;
          &tau;&eta; &chi;&rho;&eta;&sigma;&iota;&mu;ό&tau;&eta;&tau;ά
          &tau;&omicron;&upsilon;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
          padding: 0pt 0pt 33.75pt 0pt;
        "
      >
        &nbsp;
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
          padding: 0pt 0pt 6pt 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >10. Ό&rho;&omicron;&iota; &kappa;&alpha;&iota;
          &Pi;&rho;&omicron;ϋ&pi;&omicron;&theta;έ&sigma;&epsilon;&iota;&sigmaf;
          &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu; &tau;&eta;&sigmaf;
          STOFERNO</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
          padding: 0pt 0pt 6pt 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #242424;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Omicron;&iota; ό&rho;&omicron;&iota; &kappa;&alpha;&iota;
          &pi;&rho;&omicron;ϋ&pi;&omicron;&theta;έ&sigma;&epsilon;&iota;&sigmaf;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu; &tau;&eta;&sigmaf;
          STOFERNO (&omicron;&iota; &laquo;Ό&rho;&omicron;&iota;
          &kappa;&alpha;&iota;
          &Pi;&rho;&omicron;ϋ&pi;&omicron;&theta;έ&sigma;&epsilon;&iota;&sigmaf;
          &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;&raquo;)
          &pi;&omicron;&upsilon; &iota;&sigma;&chi;ύ&omicron;&upsilon;&nu;
          &gamma;&iota;&alpha; &tau;&iota;&sigmaf;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;
          &mu;&epsilon;&tau;&alpha;&phi;&omicron;&rho;ώ&nu; &kappa;&alpha;&iota;
          &sigma;&upsilon;&nu;&alpha;&phi;&epsilon;ί&sigmaf;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;
          &pi;&omicron;&upsilon;
          &pi;&alpha;&rho;έ&chi;&omicron;&nu;&tau;&alpha;&iota; &alpha;&pi;ό
          &tau;&eta;&nu; &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;,
          &delta;&iota;έ&pi;&omicron;&upsilon;&nu; &tau;&eta;
          &chi;&rho;ή&sigma;&eta; &tau;&omega;&nu; &epsilon;&nu;
          &lambda;ό&gamma;&omega;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
          &mu;&epsilon;&tau;&alpha;&phi;&omicron;&rho;ώ&nu; &kappa;&alpha;&iota;
          &sigma;&upsilon;&nu;&alpha;&phi;ώ&nu;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu; &tau;&eta;&sigmaf;
          &pi;&omicron;&upsilon;
          &alpha;&pi;&omicron;&kappa;&tau;ώ&nu;&tau;&alpha;&iota;
          &mu;έ&sigma;&omega; &alpha;&upsilon;&tau;&omicron;ύ
          &tau;&omicron;&upsilon;
          &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;&upsilon;,
          &epsilon;&pi;&iota;&pi;&lambda;έ&omicron;&nu;
          &omicron;&pi;&omicron;&iota;&omega;&nu;&delta;ή&pi;&omicron;&tau;&epsilon;
          ά&lambda;&lambda;&omega;&nu; ό&rho;&omega;&nu; &kappa;&alpha;&iota;
          &pi;&rho;&omicron;ϋ&pi;&omicron;&theta;έ&sigma;&epsilon;&omega;&nu;
          &pi;&omicron;&upsilon; &mu;&pi;&omicron;&rho;&epsilon;ί &nu;&alpha;
          &iota;&sigma;&chi;ύ&omicron;&upsilon;&nu; &gamma;&iota;&alpha;
          &mu;&iota;&alpha; &tau;έ&tau;&omicron;&iota;&alpha;
          &sigma;&upsilon;&nu;&alpha;&lambda;&lambda;&alpha;&gamma;ή,
          ό&pi;&omega;&sigmaf;
          &pi;&rho;&omicron;&beta;&lambda;έ&pi;&epsilon;&tau;&alpha;&iota;
          &sigma;&tau;&omicron;&upsilon;&sigmaf;
          &pi;&alpha;&rho;ό&nu;&tau;&epsilon;&sigmaf;
          Ό&rho;&omicron;&upsilon;&sigmaf; &Chi;&rho;ή&sigma;&eta;&sigmaf;
          &tau;&omicron;&upsilon;
          &Iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;&upsilon;
          &kappa;&alpha;&iota; &tau;&iota;&sigmaf;
          &Sigma;&upsilon;&mu;&beta;ά&sigma;&epsilon;&iota;&sigmaf;
          &Pi;&alpha;&rho;&omicron;&chi;ή&sigmaf;
          &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;. &Omicron;&iota;
          Ό&rho;&omicron;&iota; &kappa;&alpha;&iota;
          &Pi;&rho;&omicron;ϋ&pi;&omicron;&theta;έ&sigma;&epsilon;&iota;&sigmaf;
          &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu; &tau;&eta;&sigmaf;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;
          &epsilon;&nu;&sigma;&omega;&mu;&alpha;&tau;ώ&nu;&omicron;&nu;&tau;&alpha;&iota;
          &delta;&iota;&alpha; &tau;&eta;&sigmaf;
          &pi;&alpha;&rho;&omicron;ύ&sigma;&alpha;&sigmaf;
          &sigma;&tau;&omicron;&upsilon;&sigmaf;
          Ό&rho;&omicron;&upsilon;&sigmaf; &Chi;&rho;ή&sigma;&eta;&sigmaf;,
          έ&tau;&sigma;&iota; ώ&sigma;&tau;&epsilon; ό&lambda;&epsilon;&sigmaf;
          &omicron;&iota; &pi;&alpha;&rho;&alpha;&pi;&omicron;&mu;&pi;έ&sigmaf;
          &pi;&omicron;&upsilon;
          &alpha;&nu;&alpha;&phi;έ&rho;&omicron;&nu;&tau;&alpha;&iota;
          &sigma;&tau;&omicron;&upsilon;&sigmaf;
          Ό&rho;&omicron;&upsilon;&sigmaf; &Chi;&rho;ή&sigma;&eta;&sigmaf;
          &nu;&alpha; &theta;&epsilon;&omega;&rho;&epsilon;ί&tau;&alpha;&iota;
          ό&tau;&iota;
          &pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;ά&nu;&omicron;&upsilon;&nu;,
          &sigma;&tau;&omicron; &beta;&alpha;&theta;&mu;ό &pi;&omicron;&upsilon;
          &iota;&sigma;&chi;ύ&epsilon;&iota;, &tau;&omicron;&upsilon;&sigmaf;
          Ό&rho;&omicron;&upsilon;&sigmaf; &kappa;&alpha;&iota;
          &Pi;&rho;&omicron;ϋ&pi;&omicron;&theta;έ&sigma;&epsilon;&iota;&sigmaf;
          &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu; &tau;&eta;&sigmaf;
          STOFERNO.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
          padding: 0pt 0pt 6pt 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #242424;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Eta; STOFERNO &pi;&alpha;&rho;έ&chi;&epsilon;&iota; &mu;ί&alpha;
          &pi;&epsilon;&rho;&iota;&omicron;&rho;&iota;&sigma;&mu;έ&nu;&eta;,
          &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ή, &mu;&eta;
          &mu;&epsilon;&tau;&alpha;&beta;&iota;&beta;ά&sigma;&iota;&mu;&eta;,
          &mu;&eta;
          &epsilon;&pi;&iota;&delta;&epsilon;&chi;ό&mu;&epsilon;&nu;&eta;
          &upsilon;&pi;&omicron;-&alpha;&delta;&epsilon;&iota;&omicron;&delta;ό&tau;&eta;&sigma;&eta;
          &kappa;&alpha;&iota; &alpha;&nu;&alpha;&kappa;&lambda;&eta;&tau;ή
          ά&delta;&epsilon;&iota;&alpha; &sigma;&tau;&omicron;&nu;
          &chi;&rho;ή&sigma;&tau;&eta; (&alpha;) &gamma;&iota;&alpha;
          &tau;&eta;&nu; &pi;&rho;ό&sigma;&beta;&alpha;&sigma;&eta;
          &kappa;&alpha;&iota; &tau;&eta; &chi;&rho;ή&sigma;&eta;
          &mu;ό&nu;&omicron; &tau;&eta;&sigmaf;
          &Iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;,
          &tau;&omicron;&upsilon;
          &pi;&epsilon;&rho;&iota;&epsilon;&chi;&omicron;&mu;έ&nu;&omicron;&upsilon;
          &kappa;&alpha;&iota; &tau;&omega;&nu;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;&iota;ώ&nu;,
          &mu;ό&nu;&omicron; &mu;&epsilon; &tau;&omicron;&nu;
          &tau;&rho;ό&pi;&omicron; &pi;&omicron;&upsilon;
          &pi;&alpha;&rho;&omicron;&upsilon;&sigma;&iota;ά&zeta;&epsilon;&tau;&alpha;&iota;
          &alpha;&pi;ό &tau;&eta;&nu; STOFERNO, &kappa;&alpha;&iota; (&beta;)
          &gamma;&iota;&alpha; &tau;&eta;&nu;
          &pi;&rho;ό&sigma;&beta;&alpha;&sigma;&eta; &kappa;&alpha;&iota;
          &tau;&eta; &chi;&rho;ή&sigma;&eta; &tau;&omega;&nu;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
          &upsilon;&pi;&omicron;&lambda;&omicron;&gamma;&iota;&sigma;&tau;ώ&nu;
          &kappa;&alpha;&iota; &delta;&iota;&kappa;&tau;ύ&omega;&nu;
          &tau;&eta;&sigmaf; STOFERNO &pi;&omicron;&upsilon;
          &pi;&alpha;&rho;έ&chi;&omicron;&nu;&tau;&alpha;&iota;
          &sigma;&tau;&eta;&nu;
          &Iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;
          &mu;ό&nu;&omicron; &mu;&epsilon; &tau;&omicron;&nu;
          &tau;&rho;ό&pi;&omicron; &pi;&omicron;&upsilon;
          &epsilon;&pi;&iota;&tau;&rho;έ&pi;&epsilon;&tau;&alpha;&iota;
          &rho;&eta;&tau;ά &alpha;&pi;ό &alpha;&upsilon;&tau;ή&nu;.
          &Mu;&epsilon; &tau;&eta;&nu;
          &epsilon;&xi;&alpha;ί&rho;&epsilon;&sigma;&eta; &tau;&eta;&sigmaf;
          &pi;&epsilon;&rho;&iota;&omicron;&rho;&iota;&sigma;&mu;έ&nu;&eta;&sigmaf;
          &alpha;&upsilon;&tau;ή&sigmaf; ά&delta;&epsilon;&iota;&alpha;&sigmaf;,
          &eta; STOFERNO &delta;&epsilon;&nu;
          &mu;&epsilon;&tau;&alpha;&beta;&iota;&beta;ά&zeta;&epsilon;&iota;
          &mu;&epsilon; &tau;&eta;&nu; ά&delta;&epsilon;&iota;&alpha;
          &pi;&omicron;&upsilon; &sigma;&alpha;&sigmaf;
          &pi;&alpha;&rho;έ&chi;&epsilon;&iota; &gamma;&iota;&alpha;
          &pi;&rho;ό&sigma;&beta;&alpha;&sigma;&eta; &sigma;&tau;&eta;&nu;
          &Iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;
          &kappa;&alpha;&nu;έ&nu;&alpha;
          &delta;&iota;&kappa;&alpha;ί&omega;&mu;&alpha; &epsilon;&pi;ί
          &tau;&omega;&nu;
          &sigma;&upsilon;&sigma;&tau;&eta;&mu;ά&tau;&omega;&nu;
          &tau;&eta;&sigmaf;, &tau;&omega;&nu;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;&iota;ώ&nu; ή
          &tau;&omega;&nu; &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &pi;&omicron;&upsilon; &epsilon;ί&nu;&alpha;&iota;
          &delta;&iota;&alpha;&theta;έ&sigma;&iota;&mu;&alpha;
          &mu;έ&sigma;&omega; &tau;&omega;&nu;
          &Sigma;&upsilon;&sigma;&tau;&eta;&mu;ά&tau;&omega;&nu; STOFERNO,
          &tau;&omicron;&upsilon;
          &Pi;&epsilon;&rho;&iota;&epsilon;&chi;&omicron;&mu;έ&nu;&omicron;&upsilon;,
          &tau;&omega;&nu; &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;,
          &tau;&eta;&sigmaf;
          &Iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;
          ή
          &omicron;&pi;&omicron;&iota;&omicron;&upsilon;&delta;ή&pi;&omicron;&tau;&epsilon;
          ά&lambda;&lambda;&omicron;&upsilon;
          &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&omicron;&upsilon;
          &iota;&delta;&iota;&omicron;&kappa;&tau;&eta;&sigma;ί&alpha;&sigmaf;
          &tau;&eta;&sigmaf; STOFERNO. &Mu;&epsilon; &tau;&eta;&nu;
          &epsilon;&xi;&alpha;ί&rho;&epsilon;&sigma;&eta; ό&sigma;&omega;&nu;
          &alpha;&pi;&alpha;&iota;&tau;&omicron;ύ&nu;&tau;&alpha;&iota;
          &alpha;&pi;ό &tau;&omicron; &Nu;ό&mu;&omicron; &kappa;&alpha;&iota;
          &tau;&omega;&nu; &rho;&eta;&tau;ώ&nu;
          &pi;&rho;&omicron;&beta;&lambda;έ&psi;&epsilon;&omega;&nu;
          &pi;&omicron;&upsilon;
          &pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;ά&nu;&omicron;&nu;&tau;&alpha;&iota;
          &sigma;&tau;&omicron; &pi;&alpha;&rho;ό&nu;,
          &kappa;&alpha;&nu;έ&nu;&alpha;
          &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&omicron;
          &pi;&epsilon;&rho;&iota;&epsilon;&chi;&omicron;&mu;έ&nu;&omicron;&upsilon;
          ή/&kappa;&alpha;&iota; &kappa;&alpha;&mu;ί&alpha;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&alpha;
          &delta;&epsilon;&nu;
          &epsilon;&pi;&iota;&tau;&rho;έ&pi;&epsilon;&tau;&alpha;&iota;
          &nu;&alpha; &upsilon;&pi;&omicron;&beta;&lambda;&eta;&theta;&epsilon;ί
          &sigma;&epsilon;
          &alpha;&pi;&omicron;&sigma;&upsilon;&mu;&pi;ί&lambda;&eta;&sigma;&eta;,
          &tau;&rho;&omicron;&pi;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;,
          &alpha;&nu;&alpha;&pi;&alpha;&rho;&alpha;&gamma;&omega;&gamma;ή,
          &alpha;&nu;&alpha;&delta;&eta;&mu;&omicron;&sigma;ί&epsilon;&upsilon;&sigma;&eta;,
          &mu;&epsilon;&tau;ά&phi;&rho;&alpha;&sigma;&eta; &sigma;&epsilon;
          &omicron;&pi;&omicron;&iota;&alpha;&delta;ή&pi;&omicron;&tau;&epsilon;
          &gamma;&lambda;ώ&sigma;&sigma;&alpha; ή
          &gamma;&lambda;ώ&sigma;&sigma;&alpha;
          &upsilon;&pi;&omicron;&lambda;&omicron;&gamma;&iota;&sigma;&tau;ώ&nu;,
          &alpha;&nu;&alpha;&mu;&epsilon;&tau;ά&delta;&omicron;&sigma;&eta;
          &sigma;&epsilon;
          &omicron;&pi;&omicron;&iota;&alpha;&delta;ή&pi;&omicron;&tau;&epsilon;
          &mu;&omicron;&rho;&phi;ή ή &mu;&epsilon;
          &omicron;&pi;&omicron;&iota;&omicron;&delta;ή&pi;&omicron;&tau;&epsilon;
          &mu;έ&sigma;&omicron;&nu;,
          &epsilon;&pi;&alpha;&nu;&alpha;&pi;ώ&lambda;&eta;&sigma;&eta; ή
          &epsilon;&pi;&alpha;&nu;&alpha;&delta;&iota;&alpha;&nu;&omicron;&mu;ή
          &chi;&omega;&rho;ί&sigmaf; &tau;&eta;&nu;
          &pi;&rho;&omicron;&eta;&gamma;&omicron;ύ&mu;&epsilon;&nu;&eta;
          &gamma;&rho;&alpha;&pi;&tau;ή
          &sigma;&upsilon;&nu;&alpha;ί&nu;&epsilon;&sigma;&eta;
          &tau;&eta;&sigmaf; STOFERNO. &Delta;&epsilon;&nu;
          &epsilon;&pi;&iota;&tau;&rho;έ&pi;&epsilon;&tau;&alpha;&iota;
          &nu;&alpha; &pi;&rho;&omicron;&beta;&epsilon;ί&tau;&epsilon;
          &sigma;&epsilon;
          &upsilon;&lambda;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;,
          &pi;ώ&lambda;&eta;&sigma;&eta;,
          &pi;&rho;&omicron;&sigma;&phi;&omicron;&rho;ά &gamma;&iota;&alpha;
          &pi;ώ&lambda;&eta;&sigma;&eta;,
          &tau;&rho;&omicron;&pi;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;,
          &alpha;&nu;&alpha;&pi;&alpha;&rho;&alpha;&gamma;&omega;&gamma;ή,
          &epsilon;&pi;ί&delta;&epsilon;&iota;&xi;&eta;,
          &delta;&eta;&mu;ό&sigma;&iota;&alpha;
          &epsilon;&kappa;&tau;έ&lambda;&epsilon;&sigma;&eta;,
          &epsilon;&iota;&sigma;&alpha;&gamma;&omega;&gamma;ή,
          &delta;&iota;&alpha;&nu;&omicron;&mu;ή,
          &alpha;&nu;&alpha;&mu;&epsilon;&tau;ά&delta;&omicron;&sigma;&eta; ή
          ά&lambda;&lambda;&eta; &chi;&rho;ή&sigma;&eta; &tau;&omicron;&upsilon;
          &Pi;&epsilon;&rho;&iota;&epsilon;&chi;&omicron;&mu;έ&nu;&omicron;&upsilon;
          &mu;&epsilon;
          &omicron;&pi;&omicron;&iota;&omicron;&nu;&delta;ή&pi;&omicron;&tau;&epsilon;
          &tau;&rho;ό&pi;&omicron;, &epsilon;&kappa;&tau;ό&sigmaf;
          &kappa;&alpha;&iota; &alpha;&nu;
          &delta;&iota;&alpha;&theta;έ&tau;&epsilon;&tau;&epsilon; &tau;&eta;
          &rho;&eta;&tau;ή &sigma;&chi;&epsilon;&tau;&iota;&kappa;ή
          ά&delta;&epsilon;&iota;&alpha; &tau;&eta;&sigmaf;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;.&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
          padding: 0pt 0pt 6pt 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >11. &Pi;&epsilon;&rho;&iota;&omicron;&rho;&iota;&sigma;&mu;&omicron;ί
          &sigma;&tau;&eta; &chi;&rho;ή&sigma;&eta; &tau;&eta;&sigmaf;
          &Iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
          padding: 0pt 0pt 6pt 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #242424;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Pi;&lambda;έ&omicron;&nu; &tau;&omega;&nu;
          ά&lambda;&lambda;&omega;&nu;
          &pi;&epsilon;&rho;&iota;&omicron;&rho;&iota;&sigma;&mu;ώ&nu;
          &pi;&omicron;&upsilon;
          &omicron;&rho;ί&zeta;&omicron;&nu;&tau;&alpha;&iota;
          &sigma;&tau;&omicron;&upsilon;&sigmaf;
          &pi;&alpha;&rho;ό&nu;&tau;&epsilon;&sigmaf;
          Ό&rho;&omicron;&upsilon;&sigmaf; &chi;&rho;ή&sigma;&eta;&sigmaf;,
          &sigma;&upsilon;&mu;&phi;&omega;&nu;&epsilon;ί&tau;&epsilon;
          &mu;&epsilon; &tau;&alpha;
          &alpha;&kappa;ό&lambda;&omicron;&upsilon;&theta;&alpha;:</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
          padding: 0pt 0pt 6pt 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #242424;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >(&alpha;) &Delta;&epsilon;&nu; &theta;&alpha;
          &pi;&rho;&omicron;&beta;&alpha;ί&nu;&epsilon;&tau;&epsilon;
          &sigma;&epsilon; &alpha;&pi;ό&kappa;&rho;&upsilon;&psi;&eta;
          &tau;&eta;&sigmaf;
          &pi;&rho;&omicron;έ&lambda;&epsilon;&upsilon;&sigma;&eta;&sigmaf;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;&iota;ώ&nu;
          &pi;&omicron;&upsilon;
          &mu;&epsilon;&tau;&alpha;&delta;ί&delta;&omicron;&nu;&tau;&alpha;&iota;
          &mu;έ&sigma;&omega; &tau;&eta;&sigmaf;
          &Iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;.</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #242424;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          ><br /></span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #242424;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >(&beta;) &Delta;&epsilon;&nu; &theta;&alpha;
          &pi;&alpha;&rho;έ&chi;&epsilon;&tau;&epsilon;
          &psi;&epsilon;&upsilon;&delta;&epsilon;ί&sigmaf; ή
          &pi;&alpha;&rho;&alpha;&pi;&lambda;&alpha;&nu;&eta;&tau;&iota;&kappa;έ&sigmaf;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
          &mu;έ&sigma;&omega; &tau;&eta;&sigmaf;
          &Iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;.</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #242424;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          ><br /></span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #242424;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >(&gamma;) &Delta;&epsilon;&nu; &theta;&alpha;
          &sigma;&upsilon;&nu;&delta;έ&epsilon;&sigma;&tau;&epsilon;
          &kappa;&alpha;&iota; &delta;&epsilon;&nu; &theta;&alpha;
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&epsilon;ί&tau;&epsilon;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;,
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;,
          &epsilon;&phi;&alpha;&rho;&mu;&omicron;&gamma;έ&sigmaf; ή
          &lambda;&omicron;&gamma;&iota;&sigma;&mu;&iota;&kappa;ό
          &pi;&omicron;&upsilon;
          &delta;&iota;&alpha;&tau;ί&theta;&epsilon;&tau;&alpha;&iota;
          &mu;έ&sigma;&omega; &tau;&eta;&sigmaf;
          &Iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;
          &mu;&epsilon; &tau;&rho;ό&pi;&omicron; &pi;&omicron;&upsilon;
          &delta;&epsilon;&nu;
          &epsilon;&pi;&iota;&tau;&rho;έ&pi;&epsilon;&tau;&alpha;&iota;
          &rho;&eta;&tau;ά &alpha;&pi;ό &tau;&eta;&nu; STOFERNO.</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #242424;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          ><br /></span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #242424;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >(&delta;) &Delta;&epsilon;&nu; &theta;&alpha;
          &epsilon;&iota;&sigma;ά&gamma;&epsilon;&tau;&epsilon;/&phi;&omicron;&rho;&tau;ώ&nu;&epsilon;&tau;&epsilon;
          &sigma;&tau;&eta;&nu;
          &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;
          &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&alpha;
          &pi;&omicron;&upsilon;
          &pi;&epsilon;&rho;&iota;έ&chi;&omicron;&upsilon;&nu;
          &iota;&omicron;ύ&sigmaf;, Trojan horses, worms, time bombs ή
          ά&lambda;&lambda;&epsilon;&sigmaf;
          &rho;&omicron;&upsilon;&tau;ί&nu;&epsilon;&sigmaf;
          &pi;&rho;&omicron;&gamma;&rho;&alpha;&mu;&mu;&alpha;&tau;&iota;&sigma;&mu;&omicron;ύ
          &upsilon;&pi;&omicron;&lambda;&omicron;&gamma;&iota;&sigma;&tau;ώ&nu;
          &pi;&omicron;&upsilon; έ&chi;&omicron;&upsilon;&nu;
          &sigma;&kappa;&omicron;&pi;ό &tau;&eta;&nu;
          &pi;&rho;ό&kappa;&lambda;&eta;&sigma;&eta; &zeta;&eta;&mu;&iota;ώ&nu;,
          &tau;&eta;&nu; &pi;&alpha;&rho;&epsilon;&mu;&beta;&omicron;&lambda;ή,
          &tau;&eta;&nu; &upsilon;&pi;&omicron;&kappa;&lambda;&omicron;&pi;ή ή
          &tau;&eta;&nu; &kappa;&alpha;&tau;ά&lambda;&eta;&psi;&eta;
          &omicron;&pi;&omicron;&iota;&omicron;&upsilon;&delta;ή&pi;&omicron;&tau;&epsilon;
          &sigma;&upsilon;&sigma;&tau;ή&mu;&alpha;&tau;&omicron;&sigmaf;,
          &tau;&eta;&sigmaf;
          &Iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;
          ή &tau;&omega;&nu;
          &Pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;&iota;ώ&nu; ή
          &pi;&omicron;&upsilon;
          &pi;&alpha;&rho;&alpha;&beta;&iota;ά&zeta;&omicron;&upsilon;&nu;
          &tau;&alpha; &delta;&iota;&kappa;&alpha;&iota;ώ&mu;&alpha;&tau;&alpha;
          &pi;&nu;&epsilon;&upsilon;&mu;&alpha;&tau;&iota;&kappa;ή&sigmaf;
          &iota;&delta;&iota;&omicron;&kappa;&tau;&eta;&sigma;ί&alpha;&sigmaf;
          ά&lambda;&lambda;&omega;&nu;.</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #242424;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          ><br /></span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #242424;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >(&epsilon;) &Omicron;&rho;&iota;&sigma;&mu;έ&nu;&epsilon;&sigmaf;
          &pi;&epsilon;&rho;&iota;&omicron;&chi;έ&sigmaf; &tau;&eta;&sigmaf;
          &Iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;
          &epsilon;ί&nu;&alpha;&iota;
          &delta;&iota;&alpha;&theta;έ&sigma;&iota;&mu;&epsilon;&sigmaf;
          &mu;ό&nu;&omicron; &sigma;&epsilon;
          &pi;&epsilon;&lambda;ά&tau;&epsilon;&sigmaf; &tau;&eta;&sigmaf;
          STOFERNO.</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #242424;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          ><br /></span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #242424;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >(&sigma;&tau;) &Delta;&epsilon;&nu;
          &epsilon;&pi;&iota;&tau;&rho;έ&pi;&epsilon;&tau;&alpha;&iota; &eta;
          &sigma;ύ&nu;&delta;&epsilon;&sigma;&eta; &kappa;&alpha;&iota; &eta;
          &chi;&rho;ή&sigma;&eta; &tau;&eta;&sigmaf;
          &Iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;
          ή &tau;&omega;&nu;
          &Sigma;&upsilon;&sigma;&tau;&eta;&mu;ά&tau;&omega;&nu; ή
          &tau;&omega;&nu; &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
          &tau;&eta;&sigmaf; STOFERNO &mu;&epsilon; &tau;&rho;ό&pi;&omicron;
          &pi;&omicron;&upsilon;, &kappa;&alpha;&tau;ά &tau;&eta;&nu;
          &kappa;&rho;ί&sigma;&eta; &tau;&eta;&sigmaf; STOFERNO,
          &epsilon;&pi;&iota;&delta;&rho;ά
          &alpha;&rho;&nu;&eta;&tau;&iota;&kappa;ά &sigma;&tau;&eta;&nu;
          &alpha;&pi;ό&delta;&omicron;&sigma;&eta; ή &tau;&eta;
          &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;ί&alpha;
          &tau;&omega;&nu;
          &Sigma;&upsilon;&sigma;&tau;&eta;&mu;ά&tau;&omega;&nu; STOFERNO,
          &tau;&omega;&nu; &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu; ή
          &tau;&eta;&sigmaf;
          &Iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;
          ή
          &pi;&alpha;&rho;&epsilon;&mu;&beta;ά&lambda;&lambda;&epsilon;&tau;&alpha;&iota;
          &sigma;&tau;&eta;
          &delta;&upsilon;&nu;&alpha;&tau;ό&tau;&eta;&tau;&alpha;
          &epsilon;&xi;&omicron;&upsilon;&sigma;&iota;&omicron;&delta;&omicron;&tau;&eta;&mu;έ&nu;&omega;&nu;
          &chi;&rho;&eta;&sigma;&tau;ώ&nu; &gamma;&iota;&alpha;
          &pi;&rho;ό&sigma;&beta;&alpha;&sigma;&eta; &sigma;&tau;&alpha;
          &Sigma;&upsilon;&sigma;&tau;ή&mu;&alpha;&tau;&alpha; STOFERNO,
          &tau;&iota;&sigmaf;
          &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf; ή
          &tau;&eta;&nu;
          &Iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;.</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #242424;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          ><br /></span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #242424;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >(&zeta;) &Delta;&epsilon;&nu;
          &epsilon;&pi;&iota;&tau;&rho;έ&pi;&epsilon;&tau;&alpha;&iota; &eta;
          &pi;&lambda;&alpha;&iota;&sigma;ί&omega;&sigma;&eta; ή &eta;
          &chi;&rho;ή&sigma;&eta; &tau;&epsilon;&chi;&nu;&iota;&kappa;ώ&nu;
          &pi;&lambda;&alpha;&iota;&sigma;ί&omega;&sigma;&eta;&sigmaf;
          &pi;&omicron;&upsilon; &nu;&alpha;
          &pi;&epsilon;&rho;&iota;&kappa;&lambda;&epsilon;ί&omicron;&upsilon;&nu;
          &omicron;&pi;&omicron;&iota;&omicron;&delta;ή&pi;&omicron;&tau;&epsilon;
          &tau;&mu;ή&mu;&alpha; ή
          &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&omicron;
          &tau;&omicron;&upsilon;
          &Pi;&epsilon;&rho;&iota;&epsilon;&chi;&omicron;&mu;έ&nu;&omicron;&upsilon;
          ή &tau;&omega;&nu;
          &Pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;&iota;ώ&nu;,
          &chi;&omega;&rho;ί&sigmaf; &tau;&eta; &rho;&eta;&tau;ή
          &gamma;&rho;&alpha;&pi;&tau;ή
          &sigma;&upsilon;&nu;&alpha;ί&nu;&epsilon;&sigma;&eta;
          &tau;&eta;&sigmaf; STOFERNO.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
          padding: 0pt 0pt 6pt 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >12. &Pi;&rho;ό&sigma;&beta;&alpha;&sigma;&eta; &mu;έ&sigma;&omega;
          &tau;&eta;&sigmaf;
          &Iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;
          &sigma;&epsilon; &Sigma;&upsilon;&sigma;&tau;ή&mu;&alpha;&tau;&alpha;
          &kappa;&alpha;&iota;
          &Pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
          &pi;&omicron;&upsilon;
          &sigma;&chi;&epsilon;&tau;ί&zeta;&omicron;&nu;&tau;&alpha;&iota;
          &mu;&epsilon; &tau;&eta;&nu;
          &alpha;&pi;&omicron;&sigma;&tau;&omicron;&lambda;ή</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
          padding: 0pt 0pt 6pt 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #242424;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Eta; &chi;&rho;ή&sigma;&eta; &kappa;&alpha;&iota; &eta;
          &pi;&rho;ό&sigma;&beta;&alpha;&sigma;ή &sigma;&alpha;&sigmaf;
          &sigma;&tau;&alpha;
          &Sigma;&upsilon;&sigma;&tau;ή&mu;&alpha;&tau;&alpha;
          &kappa;&alpha;&iota; &tau;&iota;&sigmaf;
          &Pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
          &pi;&omicron;&upsilon;
          &sigma;&chi;&epsilon;&tau;ί&zeta;&omicron;&nu;&tau;&alpha;&iota;
          &mu;&epsilon; &tau;&eta;&nu;
          &alpha;&pi;&omicron;&sigma;&tau;&omicron;&lambda;ή
          &delta;&iota;έ&pi;&omicron;&nu;&tau;&alpha;&iota; &alpha;&pi;ό
          &tau;&omicron;&upsilon;&sigmaf;
          &alpha;&kappa;ό&lambda;&omicron;&upsilon;&theta;&omicron;&upsilon;&sigmaf;
          ό&rho;&omicron;&upsilon;&sigmaf;:</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
          padding: 0pt 0pt 6pt 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #242424;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >(&alpha;) &nbsp;&Tau;&alpha;
          &quot;&Sigma;&upsilon;&sigma;&tau;ή&mu;&alpha;&tau;&alpha;
          &pi;&omicron;&upsilon;
          &sigma;&chi;&epsilon;&tau;ί&zeta;&omicron;&nu;&tau;&alpha;&iota;
          &mu;&epsilon; &tau;&eta;&nu;
          &alpha;&pi;&omicron;&sigma;&tau;&omicron;&lambda;ή&quot;
          &alpha;&pi;&omicron;&tau;&epsilon;&lambda;&omicron;ύ&nu;&tau;&alpha;&iota;
          &alpha;&pi;ό &tau;&alpha;
          &sigma;&upsilon;&sigma;&tau;ή&mu;&alpha;&tau;&alpha;
          &tau;&eta;&sigmaf; STOFERNO &pi;&omicron;&upsilon;
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&nu;&tau;&alpha;&iota;
          &gamma;&iota;&alpha; &tau;&eta;&nu; &pi;&alpha;&rho;&omicron;&chi;ή
          &tau;&eta;&sigmaf;
          &delta;&upsilon;&nu;&alpha;&tau;ό&tau;&eta;&tau;&alpha;&sigmaf;
          &alpha;&nu;&alpha;&zeta;ή&tau;&eta;&sigma;&eta;&sigmaf;,
          &epsilon;ύ&rho;&epsilon;&sigma;&eta;&sigmaf;
          &chi;&rho;ό&nu;&omicron;&upsilon;
          &delta;&iota;&alpha;&kappa;ί&nu;&eta;&sigma;&eta;&sigmaf;,
          &epsilon;&nu;&tau;&omicron;&pi;&iota;&sigma;&mu;&omicron;ύ
          &sigma;&eta;&mu;&epsilon;ί&omega;&nu; STOFERNO,
          &epsilon;&pi;&alpha;&lambda;ή&theta;&epsilon;&upsilon;&sigma;&eta;&sigmaf;
          &delta;&iota;&epsilon;&upsilon;&theta;ύ&nu;&sigma;&epsilon;&omega;&nu;,
          &kappa;&alpha;&iota; ά&lambda;&lambda;&omega;&nu;
          &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;&iota;ώ&nu;
          &kappa;&alpha;&iota;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;&iota;ώ&nu;
          &pi;&omicron;&upsilon;
          &sigma;&chi;&epsilon;&tau;ί&zeta;&omicron;&nu;&tau;&alpha;&iota;
          &mu;&epsilon; &tau;&eta;&nu;
          &alpha;&pi;&omicron;&sigma;&tau;&omicron;&lambda;ή
          &delta;&epsilon;&mu;ά&tau;&omega;&nu; &mu;έ&sigma;&omega;
          &tau;&eta;&sigmaf; STOFERNO. &nbsp;&Tau;&alpha;
          &Sigma;&upsilon;&sigma;&tau;ή&mu;&alpha;&tau;&alpha;
          &pi;&omicron;&upsilon;
          &sigma;&chi;&epsilon;&tau;ί&zeta;&omicron;&nu;&tau;&alpha;&iota;
          &mu;&epsilon; &tau;&eta;&nu;
          &alpha;&pi;&omicron;&sigma;&tau;&omicron;&lambda;ή
          &kappa;&alpha;&iota; &omicron;&iota;
          &Pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
          &pi;&omicron;&upsilon;
          &epsilon;&xi;ά&gamma;&omicron;&nu;&tau;&alpha;&iota; &alpha;&pi;ό
          &alpha;&upsilon;&tau;ά &tau;&alpha;
          &sigma;&upsilon;&sigma;&tau;ή&mu;&alpha;&tau;&alpha;
          (&quot;&Pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
          &alpha;&pi;&omicron;&sigma;&tau;&omicron;&lambda;ή&sigmaf;&quot;)
          &pi;&rho;&omicron;&omicron;&rho;ί&zeta;&omicron;&nu;&tau;&alpha;&iota;
          &gamma;&iota;&alpha; &chi;&rho;ή&sigma;&eta; &alpha;&pi;ό
          &epsilon;&sigma;ά&sigmaf; &mu;ό&nu;&omicron; &sigma;&epsilon;
          &sigma;&upsilon;&nu;&delta;&upsilon;&alpha;&sigma;&mu;ό &mu;&epsilon;
          &delta;έ&mu;&alpha;&tau;&alpha; &pi;&omicron;&upsilon;
          έ&chi;&omicron;&upsilon;&nu;
          &alpha;&pi;&omicron;&sigma;&tau;&alpha;&lambda;&epsilon;ί &alpha;&pi;ό
          &epsilon;&sigma;ά&sigmaf; ή &gamma;&iota;&alpha;
          &lambda;&omicron;&gamma;&alpha;&rho;&iota;&alpha;&sigma;&mu;ό
          &sigma;&alpha;&sigmaf; &kappa;&alpha;&iota; &gamma;&iota;&alpha;
          &kappa;&alpha;&nu;έ&nu;&alpha;&nu; ά&lambda;&lambda;&omicron;
          &sigma;&kappa;&omicron;&pi;ό.&nbsp;</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #242424;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          ><br /></span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #242424;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >(&beta;) &nbsp;&Tau;&alpha;
          &Sigma;&upsilon;&sigma;&tau;ή&mu;&alpha;&tau;&alpha;
          &kappa;&alpha;&iota; &omicron;&iota;
          &Pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
          &pi;&omicron;&upsilon;
          &sigma;&chi;&epsilon;&tau;ί&zeta;&omicron;&nu;&tau;&alpha;&iota;
          &mu;&epsilon; &tau;&eta;&nu;
          &alpha;&pi;&omicron;&sigma;&tau;&omicron;&lambda;ή
          &alpha;&pi;&omicron;&tau;&epsilon;&lambda;&omicron;ύ&nu;
          &iota;&delta;&iota;&omicron;&kappa;&tau;&eta;&sigma;ί&alpha;
          &tau;&eta;&sigmaf; STOFERNO. &nbsp;&Eta; STOFERNO
          &sigma;&alpha;&sigmaf;
          &epsilon;&pi;&iota;&tau;&rho;έ&pi;&epsilon;&iota; &nu;&alpha;
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&epsilon;ί&tau;&epsilon;
          &tau;&eta; &delta;&upsilon;&nu;&alpha;&tau;ό&tau;&eta;&tau;&alpha;
          &alpha;&nu;&alpha;&zeta;ή&tau;&eta;&sigma;&eta;&sigmaf;
          &sigma;&tau;&alpha;
          &Sigma;&upsilon;&sigma;&tau;ή&mu;&alpha;&tau;&alpha;
          &pi;&omicron;&upsilon;
          &sigma;&chi;&epsilon;&tau;ί&zeta;&omicron;&nu;&tau;&alpha;&iota;
          &mu;&epsilon; &tau;&eta;&nu;
          &alpha;&pi;&omicron;&sigma;&tau;&omicron;&lambda;ή &mu;ό&nu;&omicron;
          &gamma;&iota;&alpha; &tau;&eta;&nu;
          &alpha;&nu;&alpha;&zeta;ή&tau;&eta;&sigma;&eta;
          &delta;&epsilon;&mu;ά&tau;&omega;&nu; &pi;&omicron;&upsilon;
          έ&chi;&omicron;&upsilon;&nu;
          &pi;&alpha;&rho;&alpha;&delta;&omicron;&theta;&epsilon;ί &alpha;&pi;ό
          &sigma;&alpha;&sigmaf; ή &gamma;&iota;&alpha; &sigma;&alpha;&sigmaf;
          &sigma;&tau;&eta;&nu; STOFERNO &gamma;&iota;&alpha;
          &pi;&alpha;&rho;ά&delta;&omicron;&sigma;&eta;, &kappa;&alpha;&iota;
          &gamma;&iota;&alpha; &kappa;&alpha;&nu;έ&nu;&alpha;&nu;
          ά&lambda;&lambda;&omicron; &sigma;&kappa;&omicron;&pi;ό.
          &nbsp;&Chi;&omega;&rho;ί&sigmaf;
          &pi;&epsilon;&rho;&iota;&omicron;&rho;&iota;&sigma;&mu;ό,
          &delta;&epsilon;&nu; έ&chi;&epsilon;&tau;&epsilon;
          &epsilon;&xi;&omicron;&upsilon;&sigma;&iota;&omicron;&delta;ό&tau;&eta;&sigma;&eta;
          &gamma;&iota;&alpha; &tau;&eta;
          &delta;&iota;ά&theta;&epsilon;&sigma;&eta; &alpha;&upsilon;&tau;ώ&nu;
          &tau;&omega;&nu;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;&iota;ώ&nu;
          &alpha;&pi;&omicron;&sigma;&tau;&omicron;&lambda;ή&sigmaf;
          &mu;έ&sigma;&omega;
          &omicron;&pi;&omicron;&iota;&alpha;&sigma;&delta;ή&pi;&omicron;&tau;&epsilon;
          &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;,
          &omicron;ύ&tau;&epsilon; &gamma;&iota;&alpha; &tau;&eta;&nu;
          &alpha;&nu;&alpha;&pi;&alpha;&rho;&alpha;&gamma;&omega;&gamma;ή,
          &tau;&eta; &delta;&iota;&alpha;&nu;&omicron;&mu;ή, &tau;&eta;&nu;
          &alpha;&nu;&tau;&iota;&gamma;&rho;&alpha;&phi;ή, &tau;&eta;&nu;
          &alpha;&pi;&omicron;&theta;ή&kappa;&epsilon;&upsilon;&sigma;&eta;,
          &tau;&eta; &chi;&rho;ή&sigma;&eta; ή &tau;&eta;&nu;
          &pi;ώ&lambda;&eta;&sigma;&eta; &tau;&omega;&nu;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;&iota;ώ&nu;
          &alpha;&pi;&omicron;&sigma;&tau;&omicron;&lambda;ή&sigmaf;
          &gamma;&iota;&alpha; &epsilon;&mu;&pi;&omicron;&rho;&iota;&kappa;ό
          ό&phi;&epsilon;&lambda;&omicron;&sigmaf; &chi;&omega;&rho;ί&sigmaf;
          &tau;&eta;&nu;
          &pi;&rho;&omicron;&eta;&gamma;&omicron;ύ&mu;&epsilon;&nu;&eta;
          &gamma;&rho;&alpha;&pi;&tau;ή
          &sigma;&upsilon;&nu;&alpha;ί&nu;&epsilon;&sigma;&eta;
          &tau;&eta;&sigmaf; STOFERNO.
          &nbsp;&Pi;&rho;ό&kappa;&epsilon;&iota;&tau;&alpha;&iota;
          &gamma;&iota;&alpha; &mu;&iota;&alpha;
          &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ή
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&alpha;, &kappa;&alpha;&iota;
          &sigma;&upsilon;&nu;&epsilon;&pi;ώ&sigmaf; &tau;&omicron;
          &delta;&iota;&kappa;&alpha;ί&omega;&mu;ά &sigma;&alpha;&sigmaf;
          &gamma;&iota;&alpha; &chi;&rho;ή&sigma;&eta; &tau;&omega;&nu;
          &sigma;&upsilon;&sigma;&tau;&eta;&mu;ά&tau;&omega;&nu;
          &pi;&omicron;&upsilon;
          &sigma;&chi;&epsilon;&tau;ί&zeta;&omicron;&nu;&tau;&alpha;&iota;
          &mu;&epsilon; &tau;&eta;&nu;
          &alpha;&pi;&omicron;&sigma;&tau;&omicron;&lambda;ή
          &kappa;&alpha;&iota; &tau;&omega;&nu;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;&iota;ώ&nu;
          &alpha;&pi;&omicron;&sigma;&tau;&omicron;&lambda;ή&sigmaf;
          &delta;&epsilon;&nu; &mu;&pi;&omicron;&rho;&epsilon;ί &nu;&alpha;
          &epsilon;&kappa;&chi;&omega;&rho;&eta;&theta;&epsilon;ί.
          &nbsp;&Omicron;&pi;&omicron;&iota;&alpha;&delta;ή&pi;&omicron;&tau;&epsilon;
          &pi;&rho;ό&sigma;&beta;&alpha;&sigma;&eta; ή &chi;&rho;ή&sigma;&eta;
          &pi;&omicron;&upsilon; &delta;&epsilon;&nu;
          &epsilon;ί&nu;&alpha;&iota; &sigma;ύ&mu;&phi;&omega;&nu;&eta;
          &mu;&epsilon; &tau;&omicron;&upsilon;&sigmaf;
          ό&rho;&omicron;&upsilon;&sigmaf;
          &alpha;&upsilon;&tau;&omicron;ύ&sigmaf; &epsilon;ί&nu;&alpha;&iota;
          &mu;&eta;
          &epsilon;&xi;&omicron;&upsilon;&sigma;&iota;&omicron;&delta;&omicron;&tau;&eta;&mu;έ&nu;&eta;
          &kappa;&alpha;&iota;
          &alpha;&pi;&alpha;&gamma;&omicron;&rho;&epsilon;ύ&epsilon;&tau;&alpha;&iota;
          &alpha;&upsilon;&sigma;&tau;&eta;&rho;ά.</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #242424;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          ><br /></span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #242424;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >(&gamma;)
          &nbsp;&Alpha;&pi;&alpha;&gamma;&omicron;&rho;&epsilon;ύ&epsilon;&tau;&alpha;&iota;
          &rho;&eta;&tau;ά &nu;&alpha;
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&nu;&tau;&alpha;&iota;
          &sigma;&epsilon; &sigma;&chi;έ&sigma;&eta; &mu;&epsilon; &tau;&alpha;
          &Sigma;&upsilon;&sigma;&tau;ή&mu;&alpha;&tau;&alpha;
          &pi;&omicron;&upsilon;
          &sigma;&chi;&epsilon;&tau;ί&zeta;&omicron;&nu;&tau;&alpha;&iota;
          &mu;&epsilon; &tau;&eta;&nu;
          &alpha;&pi;&omicron;&sigma;&tau;&omicron;&lambda;ή
          &alpha;&upsilon;&tau;ό&mu;&alpha;&tau;&epsilon;&sigmaf;
          &sigma;&upsilon;&sigma;&kappa;&epsilon;&upsilon;έ&sigmaf;
          &alpha;&nu;&alpha;&zeta;ή&tau;&eta;&sigma;&eta;&sigmaf;, robots ή
          &epsilon;&rho;&gamma;&alpha;&lambda;&epsilon;ί&alpha;
          &epsilon;&pi;&alpha;&nu;&alpha;&lambda;&eta;&pi;&tau;&iota;&kappa;ή&sigmaf;
          &sigma;&upsilon;&lambda;&lambda;&omicron;&gamma;ή&sigmaf;
          &kappa;&alpha;&iota;
          &epsilon;&xi;&alpha;&gamma;&omega;&gamma;ή&sigmaf;
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;,
          &rho;&omicron;&upsilon;&tau;ί&nu;&epsilon;&sigmaf;,
          &sigma;&upsilon;&mu;&beta;&omicron;&lambda;&omicron;&sigma;&epsilon;&iota;&rho;έ&sigmaf;,
          ή ά&lambda;&lambda;&omicron;&iota;
          &mu;&eta;&chi;&alpha;&nu;&iota;&sigma;&mu;&omicron;ί &mu;&epsilon;
          &pi;&alpha;&rho;ό&mu;&omicron;&iota;&alpha;
          &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;&iota;&kappa;ό&tau;&eta;&tau;&alpha;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
          padding: 0pt 0pt 6pt 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >13. &Upsilon;&pi;&omicron;&beta;&omicron;&lambda;έ&sigmaf;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
          padding: 0pt 0pt 6pt 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #242424;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Eta; STOFERNO &delta;&epsilon;&nu;
          &delta;έ&chi;&epsilon;&tau;&alpha;&iota;
          &iota;&delta;έ&epsilon;&sigmaf;,
          έ&nu;&nu;&omicron;&iota;&epsilon;&sigmaf; ή
          &tau;&epsilon;&chi;&nu;&iota;&kappa;έ&sigmaf; &gamma;&iota;&alpha;
          &nu;έ&epsilon;&sigmaf;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf; ή
          &pi;&rho;&omicron;ϊό&nu;&tau;&alpha; &mu;έ&sigma;&omega;
          &tau;&eta;&sigmaf;
          &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;
          (&quot;&Sigma;&chi;ό&lambda;&iota;&alpha;&quot;). &nbsp;&Epsilon;ά&nu;
          &lambda;&eta;&phi;&theta;&omicron;ύ&nu;
          &tau;έ&tau;&omicron;&iota;&alpha; &sigma;&chi;ό&lambda;&iota;&alpha;,
          &alpha;&nu;&alpha;&gamma;&nu;&omega;&rho;ί&zeta;&epsilon;&tau;&epsilon;
          ό&tau;&iota; (&alpha;) &delta;&epsilon;&nu; &theta;&alpha;
          &theta;&epsilon;&omega;&rho;&eta;&theta;&omicron;ύ&nu;
          &epsilon;&mu;&pi;&iota;&sigma;&tau;&epsilon;&upsilon;&tau;&iota;&kappa;ά
          &eta; &iota;&delta;&iota;ό&kappa;&tau;&eta;&tau;&alpha;, (&beta;)
          &eta; STOFERNO &kappa;&alpha;&iota; &omicron;&iota;
          &sigma;&upsilon;&nu;&delta;&epsilon;&delta;&epsilon;&mu;έ&nu;&epsilon;&sigmaf;
          &mu;&epsilon; &alpha;&upsilon;&tau;ή&nu;
          &epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&epsilon;&sigmaf;
          &delta;&epsilon;&nu;
          &alpha;&nu;&alpha;&lambda;&alpha;&mu;&beta;ά&nu;&omicron;&upsilon;&nu;
          &kappa;&alpha;&mu;ί&alpha;
          &upsilon;&pi;&omicron;&chi;&rho;έ&omega;&sigma;&eta;
          &delta;&iota;&alpha;&tau;ή&rho;&eta;&sigma;&eta;&sigmaf;
          &epsilon;&mu;&pi;&iota;&sigma;&tau;&epsilon;&upsilon;&tau;&iota;&kappa;ό&tau;&eta;&tau;&alpha;&sigmaf;
          &gamma;&iota;&apos; &alpha;&upsilon;&tau;έ&sigmaf; &tau;&iota;&sigmaf;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;,
          &kappa;&alpha;&iota; (&gamma;) &eta; STOFERNO &theta;&alpha;
          έ&chi;&epsilon;&iota;
          &alpha;&pi;&epsilon;&rho;&iota;ό&rho;&iota;&sigma;&tau;&omicron;,
          &alpha;&nu;έ&kappa;&kappa;&lambda;&eta;&tau;&omicron;
          &kappa;&alpha;&iota;
          &alpha;&pi;&alpha;&lambda;&lambda;&alpha;&gamma;&mu;έ&nu;&omicron;
          &alpha;&pi;ό &tau;&eta;&nu;
          &kappa;&alpha;&tau;&alpha;&beta;&omicron;&lambda;ή
          &delta;&iota;&kappa;&alpha;&iota;&omega;&mu;ά&tau;&omega;&nu;
          (royalties) &delta;&iota;&kappa;&alpha;ί&omega;&mu;&alpha;
          &chi;&rho;ή&sigma;&eta;&sigmaf;,
          &gamma;&nu;&omega;&sigma;&tau;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;&sigmaf;,
          &alpha;&nu;&alpha;&pi;&alpha;&rho;&alpha;&gamma;&omega;&gamma;ή&sigmaf;,
          &delta;&eta;&mu;&omicron;&sigma;ί&epsilon;&upsilon;&sigma;&eta;&sigmaf;,
          &pi;&alpha;&rho;&omicron;&upsilon;&sigma;ί&alpha;&sigma;&eta;&sigmaf;,
          &delta;&iota;&alpha;&nu;&omicron;&mu;ή&sigmaf; &kappa;&alpha;&iota;
          &epsilon;&kappa;&mu;&epsilon;&tau;ά&lambda;&lambda;&epsilon;&upsilon;&sigma;&eta;&sigmaf;
          &tau;&omega;&nu; &epsilon;&nu; &lambda;ό&gamma;&omega;
          &sigma;&chi;&omicron;&lambda;ί&omega;&nu; &mu;&epsilon;
          &tau;&omicron;&nu; &tau;&rho;ό&pi;&omicron; &pi;&omicron;&upsilon;
          &theta;&alpha;
          &epsilon;&pi;&iota;&lambda;έ&xi;&epsilon;&iota;.&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
          padding: 0pt 0pt 6pt 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >14.
          &Tau;&epsilon;&rho;&mu;&alpha;&tau;&iota;&sigma;&mu;ό&sigmaf;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
          padding: 0pt 0pt 6pt 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #242424;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Sigma;&upsilon;&mu;&phi;&omega;&nu;&epsilon;ί&tau;&epsilon;
          ό&tau;&iota; &eta; STOFERNO, &kappa;&alpha;&tau;ά &tau;&eta;&nu;
          &alpha;&pi;ό&lambda;&upsilon;&tau;&eta; &kappa;&rho;ί&sigma;&eta;
          &tau;&eta;&sigmaf;,
          &delta;&iota;&kappa;&alpha;&iota;&omicron;ύ&tau;&alpha;&iota;
          &nu;&alpha; &tau;&epsilon;&rho;&mu;&alpha;&tau;ί&sigma;&epsilon;&iota;
          ή &nu;&alpha;
          &alpha;&nu;&alpha;&sigma;&tau;&epsilon;ί&lambda;&epsilon;&iota;
          &tau;&eta; &chi;&rho;ή&sigma;&eta; &tau;&eta;&sigmaf;
          &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;,
          &tau;&omega;&nu;
          &sigma;&upsilon;&sigma;&tau;&eta;&mu;ά&tau;&omega;&nu;
          &tau;&eta;&sigmaf; STOFERNO, &tau;&omega;&nu;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;,
          &tau;&omicron;&upsilon;
          &pi;&epsilon;&rho;&iota;&epsilon;&chi;&omicron;&mu;έ&nu;&omicron;&upsilon;
          ή/&kappa;&alpha;&iota; &tau;&omega;&nu;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;&iota;ώ&nu;
          &alpha;&pi;ό &epsilon;&sigma;ά&sigmaf;
          &omicron;&pi;&omicron;&iota;&alpha;&delta;ή&pi;&omicron;&tau;&epsilon;
          &sigma;&tau;&iota;&gamma;&mu;ή &kappa;&alpha;&iota;
          &gamma;&iota;&alpha;
          &omicron;&pi;&omicron;&iota;&omicron;&nu;&delta;ή&pi;&omicron;&tau;&epsilon;
          &lambda;ό&gamma;&omicron;, ή &kappa;&alpha;&iota;
          &chi;&omega;&rho;ί&sigmaf;
          &alpha;&iota;&tau;&iota;&omicron;&lambda;&omicron;&gamma;ί&alpha;,
          &alpha;&kappa;ό&mu;&eta; &kappa;&alpha;&iota; &alpha;&nu; &eta;
          &pi;&rho;ό&sigma;&beta;&alpha;&sigma;&eta; &kappa;&alpha;&iota; &eta;
          &chi;&rho;ή&sigma;&eta;
          &sigma;&upsilon;&nu;&epsilon;&chi;ί&sigma;&epsilon;&iota; &nu;&alpha;
          &epsilon;&pi;&iota;&tau;&rho;έ&pi;&epsilon;&tau;&alpha;&iota;
          &sigma;&epsilon; ά&lambda;&lambda;&omicron;&upsilon;&sigmaf;.
          &nbsp;&Mu;&epsilon; &tau;&eta;&nu; &epsilon;&nu;
          &lambda;ό&gamma;&omega;
          &alpha;&nu;&alpha;&sigma;&tau;&omicron;&lambda;ή ή &tau;&omicron;&nu;
          &tau;&epsilon;&rho;&mu;&alpha;&tau;&iota;&sigma;&mu;ό
          &tau;&eta;&sigmaf; &chi;&rho;ή&sigma;&eta;&sigmaf;,
          &upsilon;&pi;&omicron;&chi;&rho;&epsilon;&omicron;ύ&sigma;&tau;&epsilon;
          (&alpha;) &nu;&alpha;
          &delta;&iota;&alpha;&kappa;ό&psi;&epsilon;&tau;&epsilon;
          &alpha;&mu;έ&sigma;&omega;&sigmaf; &tau;&eta; &chi;&rho;ή&sigma;&eta;
          &tau;&eta;&sigmaf;
          &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;
          &kappa;&alpha;&iota; &tau;&omega;&nu;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;,
          &kappa;&alpha;&iota; (&beta;) &nu;&alpha;
          &kappa;&alpha;&tau;&alpha;&sigma;&tau;&rho;έ&psi;&epsilon;&tau;&epsilon;
          &omicron;&pi;&omicron;&iota;&omicron;&delta;ή&pi;&omicron;&tau;&epsilon;
          &alpha;&nu;&tau;ί&gamma;&rho;&alpha;&phi;&omicron;
          &tau;&mu;ή&mu;&alpha;&tau;&omicron;&sigmaf; &tau;&omicron;&upsilon;
          &pi;&epsilon;&rho;&iota;&epsilon;&chi;&omicron;&mu;έ&nu;&omicron;&upsilon;
          έ&chi;&epsilon;&tau;&epsilon;
          &delta;&eta;&mu;&iota;&omicron;&upsilon;&rho;&gamma;ή&sigma;&epsilon;&iota;.
          &nbsp; &Eta; &pi;&rho;ό&sigma;&beta;&alpha;&sigma;&eta;
          &sigma;&tau;&eta;&nu;
          &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;,
          &tau;&alpha; &Sigma;&upsilon;&sigma;&tau;ή&mu;&alpha;&tau;&alpha;
          STOFERNO, &tau;&iota;&sigmaf;
          &Pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf; ή
          &tau;&iota;&sigmaf;
          &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;
          &mu;&epsilon;&tau;ά &alpha;&pi;ό &tau;&omicron;&nu;
          &tau;&epsilon;&rho;&mu;&alpha;&tau;&iota;&sigma;&mu;ό, &tau;&eta;&nu;
          &alpha;&nu;&alpha;&sigma;&tau;&omicron;&lambda;ή ή &tau;&eta;
          &delta;&iota;&alpha;&kappa;&omicron;&pi;ή &kappa;&alpha;&tau;ά
          &tau;&alpha; &alpha;&nu;&omega;&tau;έ&rho;&omega;,
          &sigma;&upsilon;&nu;&iota;&sigma;&tau;ά &pi;&rho;ά&xi;&eta;
          &pi;&alpha;&rho;ά&nu;&omicron;&mu;&eta;&sigmaf;
          &epsilon;&iota;&sigma;ό&delta;&omicron;&upsilon;.
          &nbsp;&Epsilon;&pi;&iota;&pi;&lambda;έ&omicron;&nu;,
          &sigma;&upsilon;&mu;&phi;&omega;&nu;&epsilon;ί&tau;&epsilon;
          ό&tau;&iota; &eta; STOFERNO &delta;&epsilon;&nu;
          &epsilon;&upsilon;&theta;ύ&nu;&epsilon;&tau;&alpha;&iota;
          έ&nu;&alpha;&nu;&tau;&iota; &upsilon;&mu;ώ&nu; ή
          έ&nu;&alpha;&nu;&tau;&iota;
          &omicron;&pi;&omicron;&iota;&omicron;&upsilon;&delta;ή&pi;&omicron;&tau;&epsilon;
          &tau;&rho;ί&tau;&omicron;&upsilon; &gamma;&iota;&alpha;
          &omicron;&pi;&omicron;&iota;&omicron;&nu;&delta;ή&pi;&omicron;&tau;&epsilon;
          &tau;&epsilon;&rho;&mu;&alpha;&tau;&iota;&sigma;&mu;ό ή
          &alpha;&nu;&alpha;&sigma;&tau;&omicron;&lambda;ή &tau;&eta;&sigmaf;
          &pi;&rho;ό&sigma;&beta;&alpha;&sigma;ή&sigmaf; &sigma;&alpha;&sigmaf;
          &sigma;&tau;&eta;&nu;
          &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;,
          &tau;&alpha; &Sigma;&upsilon;&sigma;&tau;ή&mu;&alpha;&tau;&alpha;,
          &tau;&iota;&sigmaf;
          &Pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
          ή/&kappa;&alpha;&iota; &tau;&iota;&sigmaf;
          &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
          padding: 0pt 0pt 6pt 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >15.
          &Alpha;&pi;&alpha;&gamma;ό&rho;&epsilon;&upsilon;&sigma;&eta;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
          padding: 0pt 0pt 6pt 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #242424;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Delta;&epsilon;&nu;
          &epsilon;&pi;&iota;&tau;&rho;έ&pi;&epsilon;&tau;&alpha;&iota;
          &nu;&alpha;
          &epsilon;&kappa;&chi;&omega;&rho;ή&sigma;&epsilon;&tau;&epsilon;
          &tau;&omicron;&upsilon;&sigmaf;
          &pi;&alpha;&rho;ό&nu;&tau;&epsilon;&sigmaf;
          Ό&rho;&omicron;&upsilon;&sigmaf; &chi;&rho;ή&sigma;&eta;&sigmaf; ή
          &omicron;&pi;&omicron;&iota;&omicron;&delta;ή&pi;&omicron;&tau;&epsilon;
          &alpha;&pi;ό &tau;&alpha;
          &sigma;&upsilon;&mu;&phi;έ&rho;&omicron;&nu;&tau;&alpha;, &tau;&alpha;
          &delta;&iota;&kappa;&alpha;&iota;ώ&mu;&alpha;&tau;&alpha; ή
          &tau;&iota;&sigmaf;
          &upsilon;&pi;&omicron;&chi;&rho;&epsilon;ώ&sigma;&epsilon;&iota;&sigmaf;
          &sigma;&alpha;&sigmaf; &sigma;&tau;&alpha;
          &pi;&lambda;&alpha;ί&sigma;&iota;&alpha; &tau;&omega;&nu;
          &pi;&alpha;&rho;ό&nu;&tau;&omega;&nu; Ό&rho;&omega;&nu;
          &chi;&rho;ή&sigma;&eta;&sigmaf;. &nbsp;&Epsilon;ά&nu;
          &omicron;&pi;&omicron;&iota;&omicron;&sigma;&delta;ή&pi;&omicron;&tau;&epsilon;
          &alpha;&pi;ό &tau;&omicron;&upsilon;&sigmaf;
          &pi;&alpha;&rho;ό&nu;&tau;&epsilon;&sigmaf;
          ό&rho;&omicron;&upsilon;&sigmaf; &kappa;&rho;&iota;&theta;&epsilon;ί
          &alpha;&pi;ό &alpha;&rho;&mu;ό&delta;&iota;&omicron;
          &Delta;&iota;&kappa;&alpha;&sigma;&tau;ή&rho;&iota;&omicron; &mu;&eta;
          έ&gamma;&kappa;&upsilon;&rho;&omicron;&sigmaf;, &eta;
          &alpha;&kappa;&upsilon;&rho;ό&tau;&eta;&tau;&alpha;
          &tau;&omicron;&upsilon; &epsilon;&nu; &lambda;ό&gamma;&omega;
          ό&rho;&omicron;&upsilon; &delta;&epsilon;&nu;
          &epsilon;&pi;&eta;&rho;&epsilon;ά&zeta;&epsilon;&iota; &tau;&eta;&nu;
          &epsilon;&gamma;&kappa;&upsilon;&rho;ό&tau;&eta;&tau;&alpha;
          &tau;&omega;&nu;
          &upsilon;&pi;&omicron;&lambda;&omicron;ί&pi;&omega;&nu;
          Ό&rho;&omega;&nu; &chi;&rho;ή&sigma;&eta;&sigmaf;,
          &pi;&omicron;&upsilon;
          &pi;&alpha;&rho;&alpha;&mu;έ&nu;&omicron;&upsilon;&nu;
          &sigma;&epsilon; &pi;&lambda;ή&rho;&eta; &iota;&sigma;&chi;ύ.
          &nbsp;&Eta; &pi;&alpha;&rho;&alpha;ί&tau;&eta;&sigma;&eta;
          &alpha;&pi;ό
          &omicron;&pi;&omicron;&iota;&omicron;&nu;&delta;ή&pi;&omicron;&tau;&epsilon;
          &alpha;&pi;ό &tau;&omicron;&upsilon;&sigmaf;
          &pi;&alpha;&rho;ό&nu;&tau;&epsilon;&sigmaf;
          Ό&rho;&omicron;&upsilon;&sigmaf; &chi;&rho;ή&sigma;&eta;&sigmaf;
          &delta;&epsilon;&nu;
          &theta;&epsilon;&omega;&rho;&epsilon;ί&tau;&alpha;&iota; ό&tau;&iota;
          &sigma;&upsilon;&nu;&iota;&sigma;&tau;ά
          &pi;&epsilon;&rho;&alpha;&iota;&tau;έ&rho;&omega; ή
          &sigma;&upsilon;&nu;&epsilon;&chi;&iota;&zeta;ό&mu;&epsilon;&nu;&eta;
          &pi;&alpha;&rho;&alpha;ί&tau;&eta;&sigma;&eta; &alpha;&pi;ό
          &tau;&omicron;&nu; &epsilon;&nu; &lambda;ό&gamma;&omega;
          ό&rho;&omicron; ή &tau;&eta;&nu;
          &pi;&rho;&omicron;ϋ&pi;ό&theta;&epsilon;&sigma;&eta; ή &alpha;&pi;ό
          &omicron;&pi;&omicron;&iota;&omicron;&nu;&delta;ή&pi;&omicron;&tau;&epsilon;
          ά&lambda;&lambda;&omicron; ό&rho;&omicron; ή
          &omicron;&pi;&omicron;&iota;&alpha;&delta;ή&pi;&omicron;&tau;&epsilon;
          ά&lambda;&lambda;&eta;
          &pi;&rho;&omicron;ϋ&pi;ό&theta;&epsilon;&sigma;&eta;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
          padding: 0pt 0pt 6pt 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >16. &Epsilon;&xi;&upsilon;&pi;&eta;&rho;έ&tau;&eta;&sigma;&eta;
          &pi;&epsilon;&lambda;&alpha;&tau;ώ&nu;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
          padding: 0pt 0pt 6pt 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #242424;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Epsilon;&rho;&omega;&tau;ή&sigma;&epsilon;&iota;&sigmaf;,
          &delta;&iota;&epsilon;&upsilon;&kappa;&rho;&iota;&nu;ί&sigma;&epsilon;&iota;&sigmaf;,
          &sigma;&chi;ό&lambda;&iota;&alpha; &kappa;&alpha;&iota;
          &pi;ά&sigma;&eta;&sigmaf; &phi;ύ&sigma;&epsilon;&omega;&sigmaf;
          ά&lambda;&lambda;&eta;
          &epsilon;&pi;&iota;&kappa;&omicron;&iota;&nu;&omega;&nu;ί&alpha;
          &pi;&omicron;&upsilon; &alpha;&phi;&omicron;&rho;&omicron;ύ&nu;
          &tau;&eta;&nu;
          &Iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;
          &mu;&pi;&omicron;&rho;&omicron;ύ&nu; &nu;&alpha;
          &upsilon;&pi;&omicron;&beta;ά&lambda;&lambda;&omicron;&nu;&tau;&alpha;&iota;
          &sigma;&tau;&omicron; &Tau;&mu;ή&mu;&alpha;
          &Epsilon;&xi;&upsilon;&pi;&eta;&rho;έ&tau;&eta;&sigma;&eta;&sigmaf;
          &pi;&epsilon;&lambda;&alpha;&tau;ώ&nu; &tau;&eta;&sigmaf;
          STOFERNO.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
          padding: 0pt 0pt 6pt 0pt;
        "
      >
        &nbsp;
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: center;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Omicron;&Rho;&Omicron;&Iota; &Chi;&Rho;&Eta;&Sigma;&Eta;&Sigma;
          COOKIES</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >Stoferno.gr&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >URL: app.stoferno.gr/cookies-policy-el</span
        >
      </p>
      <p><br /></p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Delta;ή&lambda;&omega;&sigma;&eta; &ndash;&nbsp;</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Alpha;&pi;&omicron;&kappa;&tau;ώ&nu;&tau;&alpha;&sigmaf;
          &kappa;&alpha;&iota;
          &delta;&iota;&alpha;&tau;&eta;&rho;ώ&nu;&tau;&alpha;&sigmaf;
          &pi;&rho;ό&sigma;&beta;&alpha;&sigma;&eta; &sigma;&tau;&omicron;&nu;
          &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron; &alpha;&upsilon;&tau;ό
          &alpha;&pi;&omicron;&delta;έ&chi;&epsilon;&sigma;&tau;&epsilon;
          &rho;&eta;&tau;ά &kappa;&alpha;&iota; &tau;&eta;&nu;
          &alpha;&kappa;ό&lambda;&omicron;&upsilon;&theta;&eta;
          &Pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή
          &chi;&rho;ή&sigma;&eta;&sigmaf; cookies.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Tau;&epsilon;&lambda;&epsilon;&upsilon;&tau;&alpha;ί&alpha;
          &Alpha;&nu;&alpha;&theta;&epsilon;ώ&rho;&eta;&sigma;&eta;
          &tau;&omicron;&upsilon; &pi;&alpha;&rho;ό&nu;&tau;&omicron;&sigmaf; :
          01/06/2021</span
        >
      </p>
      <p><br /></p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: center;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 13.999999999999998pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Pi;&Omicron;&Lambda;&Iota;&Tau;&Iota;&Kappa;&Eta;
          &gamma;&iota;&alpha; &tau;&alpha;
          &Eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;ά
          &Mu;&pi;&iota;&sigma;&kappa;ό&tau;&alpha; (Cookies)</span
        ><span
          style="
            font-size: 13.999999999999998pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          ><br /><br
        /></span>
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Gamma;&Epsilon;&Nu;&Iota;&Kappa;&Alpha;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Eta; &laquo;&Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&raquo;
          &tau;&eta;&rho;&epsilon;ί &alpha;&upsilon;&sigma;&tau;&eta;&rho;ή
          &pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή &gamma;&iota;&alpha;
          &tau;&eta;&nu; &pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha;
          &tau;&eta;&sigmaf;
          &iota;&delta;&iota;&omega;&tau;&iota;&kappa;ό&tau;&eta;&tau;&alpha;&sigmaf;
          &tau;&omega;&nu;
          &epsilon;&pi;&iota;&sigma;&kappa;&epsilon;&pi;&tau;ώ&nu;
          &tau;&eta;&sigmaf;
          &laquo;&Iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;&raquo;.
          &Mu;&epsilon; &tau;&omicron; &pi;&alpha;&rho;ό&nu;
          &sigma;&eta;&mu;&epsilon;ί&omega;&mu;&alpha; &sigma;&alpha;&sigmaf;
          &pi;&alpha;&rho;έ&chi;&omicron;&upsilon;&mu;&epsilon;
          &pi;&epsilon;&rho;&iota;&sigma;&sigma;ό&tau;&epsilon;&rho;&epsilon;&sigmaf;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
          &gamma;&iota;&alpha; &tau;&omicron; &pi;ώ&sigmaf;
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&mu;&epsilon;
          &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;ά
          &mu;&pi;&iota;&sigma;&kappa;ό&tau;&alpha; - COOKIES,
          &kappa;ά&theta;&epsilon; &phi;&omicron;&rho;ά &pi;&omicron;&upsilon;
          &epsilon;&pi;&iota;&sigma;&kappa;έ&pi;&tau;&epsilon;&sigma;&theta;&epsilon;
          &tau;&eta;&nu;
          &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;
          &mu;&alpha;&sigmaf;.
          &Theta;&epsilon;&omega;&rho;&omicron;ύ&mu;&epsilon;
          &sigma;&eta;&mu;&alpha;&nu;&tau;&iota;&kappa;ό &omicron;
          &chi;&rho;ή&sigma;&tau;&eta;&sigmaf; &nu;&alpha; έ&chi;&epsilon;&iota;
          &epsilon;&nu;&eta;&mu;έ&rho;&omega;&sigma;&eta;
          &sigma;&chi;&epsilon;&tau;&iota;&kappa;ά &mu;&epsilon; &tau;&alpha;
          &pi;&omicron;&iota;&alpha; &epsilon;ί&nu;&alpha;&iota; &tau;&alpha;
          cookies &pi;&omicron;&upsilon;
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&mu;&epsilon;
          &sigma;&tau;&eta;&nu;
          &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;
          &mu;&alpha;&sigmaf; &alpha;&lambda;&lambda;ά &kappa;&alpha;&iota;
          &gamma;&iota;&alpha; &pi;&omicron;&iota;&omicron;&upsilon;&sigmaf;
          &lambda;ό&gamma;&omicron;&upsilon;&sigmaf; &tau;&alpha;
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&mu;&epsilon;.
          &Sigma;&tau;ό&chi;&omicron;&sigmaf; &mu;&alpha;&sigmaf;
          &epsilon;ί&nu;&alpha;&iota; &eta; &sigma;&omega;&sigma;&tau;ή
          &epsilon;&nu;&eta;&mu;έ&rho;&omega;&sigma;&eta;
          &alpha;&lambda;&lambda;ά &kappa;&alpha;&iota; &eta;
          &kappa;&alpha;&lambda;ύ&tau;&epsilon;&rho;&eta; &kappa;&alpha;&iota;
          &alpha;&sigma;&phi;&alpha;&lambda;έ&sigma;&tau;&epsilon;&rho;&eta;
          &pi;&lambda;&omicron;ή&gamma;&eta;&sigma;&eta;
          &sigma;&tau;&omicron;&nu;
          &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron; &tau;&eta;&sigmaf;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;
          &mu;&alpha;&sigmaf;.&nbsp;</span
        >
      </p>
      <p><br /></p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Omicron;&Rho;&Iota;&Sigma;&Mu;&Omicron;&Sigma;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Tau;&iota; &epsilon;ί&nu;&alpha;&iota; &tau;&alpha;
          &Eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;ά
          &Mu;&pi;&iota;&sigma;&kappa;ό&tau;&alpha; (Cookie);</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          ><br /></span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >T&alpha;
          &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;ά
          &mu;&pi;&iota;&sigma;&kappa;ό&tau;&alpha; cookies
          &epsilon;ί&nu;&alpha;&iota; &sigma;ύ&nu;&tau;&omicron;&mu;&alpha;
          &kappa;&epsilon;ί&mu;&epsilon;&nu;&alpha;
          &kappa;ώ&delta;&iota;&kappa;&alpha;
          &lambda;&omicron;&gamma;&iota;&sigma;&mu;&iota;&kappa;&omicron;ύ,
          &pi;&omicron;&upsilon;
          &alpha;&pi;&omicron;&sigma;&tau;έ&lambda;&lambda;&omicron;&nu;&tau;&alpha;&iota;
          &pi;&rho;&omicron;&sigmaf;
          &alpha;&pi;&omicron;&theta;ή&kappa;&epsilon;&upsilon;&sigma;&eta;
          &alpha;&pi;ό &tau;&omicron;&nu;
          &delta;&iota;&alpha;&delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;ό
          &epsilon;&xi;&upsilon;&pi;&eta;&rho;&epsilon;&tau;&eta;&tau;ή
          (&ldquo;server&rdquo;) &tau;&eta;&sigmaf;
          &Epsilon;&pi;&iota;&chi;&epsilon;ί&rho;&eta;&sigma;ή&sigmaf;
          &mu;&alpha;&sigmaf; &sigma;&tau;&omicron;&nu;
          &tau;&epsilon;&rho;&mu;&alpha;&tau;&iota;&kappa;ό
          &sigma;&alpha;&sigmaf;
          &epsilon;&xi;&omicron;&pi;&lambda;&iota;&sigma;&mu;ό &mu;&epsilon;
          &beta;&alpha;&sigma;&iota;&kappa;ή &tau;&omicron;&upsilon;&sigmaf;
          &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;ί&alpha;
          &tau;&eta;&nu;
          &epsilon;&pi;&iota;&kappa;&omicron;&iota;&nu;&omega;&nu;ί&alpha;
          &sigma;&epsilon; &epsilon;&mu;ά&sigmaf;
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &tau;&omicron;&upsilon;
          &phi;&upsilon;&lambda;&lambda;&omicron;&mu;&epsilon;&tau;&rho;&eta;&tau;ή
          &sigma;&alpha;&sigmaf; (&ldquo;browser&rdquo;).
          &Alpha;&nu;ά&lambda;&omicron;&gamma;&alpha; &mu;&epsilon;
          &tau;&eta;&nu; &delta;&iota;ά&rho;&kappa;&epsilon;&iota;ά
          &tau;&omicron;&upsilon;&sigmaf; &tau;&alpha; cookies
          &epsilon;ί&nu;&alpha;&iota; &epsilon;ί&tau;&epsilon;
          &laquo;&pi;&rho;&omicron;&sigma;&omega;&rho;&iota;&nu;ά&raquo;
          (session cookies) ή &laquo;έ&mu;&mu;&omicron;&nu;&alpha;&raquo;
          (persistent cookies).&Tau;&alpha;
          &laquo;&pi;&rho;&omicron;&sigma;&omega;&rho;&iota;&nu;ά cookies&raquo;
          &epsilon;ί&nu;&alpha;&iota; cookies &pi;&omicron;&upsilon;
          &alpha;&pi;&alpha;&lambda;&epsilon;ί&phi;&omicron;&nu;&tau;&alpha;&iota;
          &alpha;&upsilon;&tau;&omicron;&mu;ά&tau;&omega;&sigmaf;
          ό&tau;&alpha;&nu; &kappa;&lambda;&epsilon;ί&nu;&epsilon;&tau;&epsilon;
          &tau;&omicron;&nu;
          &phi;&upsilon;&lambda;&lambda;&omicron;&mu;&epsilon;&tau;&rho;&eta;&tau;ή
          &sigma;&alpha;&sigmaf;, &epsilon;&nu;ώ &tau;&alpha;
          &laquo;έ&mu;&mu;&omicron;&nu;&alpha; cookies&raquo;
          &pi;&alpha;&rho;&alpha;&mu;έ&nu;&omicron;&upsilon;&nu;
          &alpha;&pi;&omicron;&theta;&eta;&kappa;&epsilon;&upsilon;&mu;έ&nu;&alpha;
          &sigma;&tau;&omicron;&nu;
          &tau;&epsilon;&rho;&mu;&alpha;&tau;&iota;&kappa;ό
          &sigma;&alpha;&sigmaf;
          &epsilon;&xi;&omicron;&pi;&lambda;&iota;&sigma;&mu;ό
          &mu;έ&chi;&rho;&iota; &tau;&eta;
          &sigma;&upsilon;&mu;&pi;&lambda;ή&rho;&omega;&sigma;&eta;
          &tau;&omicron;&upsilon;
          &pi;&rho;&omicron;&kappa;&alpha;&theta;&omicron;&rho;&iota;&sigma;&mu;έ&nu;&omicron;&upsilon;
          &chi;&rho;ό&nu;&omicron;&upsilon; &iota;&sigma;&chi;ύ&omicron;&sigmaf;
          &tau;&omicron;&upsilon;&sigmaf;. &Eta;
          &alpha;&pi;&omicron;&delta;&omicron;&chi;ή &kappa;&alpha;&iota; &eta;
          &chi;&rho;ή&sigma;&eta; &tau;&omega;&nu; cookies &sigma;&epsilon;
          &kappa;&alpha;&mu;ί&alpha;
          &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta; &delta;&epsilon;&nu;
          &pi;&rho;&omicron;&kappa;&alpha;&lambda;&omicron;ύ&nu;
          &beta;&lambda;ά&beta;&epsilon;&sigmaf; &sigma;&tau;&omicron;&nu;
          &tau;&epsilon;&rho;&mu;&alpha;&tau;&iota;&kappa;ό
          &epsilon;&xi;&omicron;&pi;&lambda;&iota;&sigma;&mu;ό
          &pi;&omicron;&upsilon;
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&epsilon;ί&tau;&epsilon;,
          ό&pi;&omega;&sigmaf; &gamma;&iota;&alpha;
          &pi;&alpha;&rho;ά&delta;&epsilon;&iota;&gamma;&mu;&alpha; PC,
          Smartphone, tablet, &omicron;ύ&tau;&epsilon; &sigma;&tau;&alpha;
          &alpha;&rho;&chi;&epsilon;ί&alpha; &pi;&omicron;&upsilon;
          &phi;&upsilon;&lambda;ά&sigma;&sigma;&epsilon;&tau;&epsilon;
          &sigma;&epsilon; &alpha;&upsilon;&tau;ό&nu;. &nbsp;</span
        >
      </p>
      <p><br /></p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Lambda;&Omicron;&Gamma;&Omicron;&Iota;
          &Chi;&Rho;&Eta;&Sigma;&Eta;&Sigma;</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;cookies</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&epsilon;ί
          &eta;
          &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;
          &mu;&alpha;&sigmaf; Cookies;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Pi;&rho;ά&gamma;&mu;&alpha;&tau;&iota;
          &kappa;ά&nu;&omicron;&upsilon;&mu;&epsilon; &chi;&rho;ή&sigma;&eta;
          &omicron;&rho;&iota;&sigma;&mu;έ&nu;&omega;&nu;
          &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;ώ&nu;
          &mu;&pi;&iota;&sigma;&kappa;ό&tau;&omega;&nu; &mu;&epsilon;
          &kappa;ύ&rho;&iota;&omicron; &sigma;&kappa;&omicron;&pi;ό &nu;&alpha;
          &kappa;&alpha;&tau;&alpha;&sigma;&tau;ή&sigma;&omicron;&upsilon;&mu;&epsilon;
          &tau;&eta;&nu;
          &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;
          &mu;&alpha;&sigmaf;&nbsp;&uml;</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #ff0000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >stoferno.gr</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&uml; &pi;&iota;&omicron;
          &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;&iota;&kappa;ή
          &kappa;&alpha;&iota;
          &pi;&rho;&omicron;&sigma;&phi;&iota;&lambda;έ&sigma;&tau;&epsilon;&rho;&eta;
          &pi;&rho;&omicron;&sigmaf; &tau;&omicron;&upsilon;&sigmaf;
          &chi;&rho;ή&sigma;&tau;&epsilon;&sigmaf; &mu;&alpha;&sigmaf;,
          &beta;&epsilon;&lambda;&tau;&iota;ώ&nu;&omicron;&nu;&tau;&alpha;&sigmaf;
          &tau;&eta; &gamma;&epsilon;&nu;&iota;&kappa;ή
          &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;ί&alpha;
          &tau;&eta;&sigmaf;, &tau;&eta; &sigma;&omega;&sigma;&tau;ή
          &kappa;&alpha;&iota; &tau;&alpha;&chi;&epsilon;ί&alpha;
          &pi;&epsilon;&rho;&iota;ή&gamma;&eta;&sigma;&eta;, &tau;&eta;&nu;
          &epsilon;ύ&kappa;&omicron;&lambda;&eta;
          &sigma;ύ&nu;&delta;&epsilon;&sigma;&eta; &kappa;&alpha;&iota;
          &tau;&eta;&nu; &omicron;&mu;&alpha;&lambda;ή
          &mu;&epsilon;&tau;&alpha;&kappa;ί&nu;&eta;&sigma;&eta;
          &sigma;&tau;&iota;&sigmaf;
          &epsilon;&pi;&iota;&mu;έ&rho;&omicron;&upsilon;&sigmaf;
          &sigma;&epsilon;&lambda;ί&delta;&epsilon;&sigmaf;
          &mu;&alpha;&sigmaf;.&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Epsilon;&pi;&iota;&pi;&rho;&omicron;&sigma;&theta;έ&tau;&omega;&sigmaf;,
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&nu;&tau;&alpha;&iota;
          cookies &gamma;&iota;&alpha; &tau;&eta;
          &delta;&iota;&epsilon;&upsilon;&kappa;ό&lambda;&upsilon;&nu;&sigma;&eta;
          &pi;&rho;ό&sigma;&beta;&alpha;&sigma;&eta;&sigmaf;
          &tau;&omicron;&upsilon;
          &epsilon;&pi;&iota;&sigma;&kappa;έ&pi;&tau;&eta; /
          &chi;&rho;ή&sigma;&tau;&eta; &sigma;&tau;&iota;&sigmaf;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;
          &tau;&eta;&sigmaf;
          &laquo;&Iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;&raquo;
          &alpha;&lambda;&lambda;ά &kappa;&alpha;&iota; &gamma;&iota;&alpha;
          &sigma;&tau;&alpha;&tau;&iota;&sigma;&tau;&iota;&kappa;&omicron;ύ&sigmaf;
          &lambda;ό&gamma;&omicron;&upsilon;&sigmaf;
          &pi;&rho;&omicron;&kappa;&epsilon;&iota;&mu;έ&nu;&omicron;&upsilon;
          &nu;&alpha;
          &kappa;&alpha;&theta;&omicron;&rho;ί&zeta;&omicron;&nu;&tau;&alpha;&iota;
          &omicron;&iota; &pi;&epsilon;&rho;&iota;&omicron;&chi;έ&sigmaf;
          &sigma;&tau;&iota;&sigmaf; &omicron;&pi;&omicron;ί&epsilon;&sigmaf;
          &omicron;&iota;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;
          &tau;&eta;&sigmaf;
          &laquo;&Iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;&raquo;
          &epsilon;ί&nu;&alpha;&iota;
          &chi;&rho;ή&sigma;&iota;&mu;&epsilon;&sigmaf; ή
          &delta;&eta;&mu;&omicron;&phi;&iota;&lambda;&epsilon;ί&sigmaf;.
          Ό&lambda;&epsilon;&sigmaf; &omicron;&iota;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
          &pi;&omicron;&upsilon;
          &sigma;&upsilon;&lambda;&lambda;έ&gamma;&omicron;&nu;&tau;&alpha;&iota;
          &alpha;&pi;ό &alpha;&upsilon;&tau;ά &tau;&alpha; cookies
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&nu;&tau;&alpha;&iota;
          &gamma;&iota;&alpha; &nu;&alpha;
          &beta;&epsilon;&lambda;&tau;&iota;ώ&sigma;&omicron;&upsilon;&nu;
          &tau;&eta;&nu;
          &alpha;&gamma;&omicron;&rho;&alpha;&sigma;&tau;&iota;&kappa;ή
          &epsilon;&mu;&pi;&epsilon;&iota;&rho;ί&alpha;, &tau;&eta;
          &delta;&omicron;&mu;ή, &tau;&omicron;
          &pi;&epsilon;&rho;&iota;&epsilon;&chi;ό&mu;&epsilon;&nu;&omicron;
          &kappa;&alpha;&iota; &tau;&eta;&nu;
          &alpha;&mu;&epsilon;&sigma;ό&tau;&eta;&tau;&alpha; &tau;&eta;&sigmaf;
          &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;
          &mu;&alpha;&sigmaf;. &Epsilon;&pi;ί&sigma;&eta;&sigmaf;, &mu;&epsilon;
          &tau;&eta; &chi;&rho;ή&sigma;&eta; &tau;&omega;&nu; cookies
          &epsilon;&pi;&iota;&tau;&upsilon;&gamma;&chi;ά&nu;&omicron;&upsilon;&mu;&epsilon;
          &tau;&eta; &sigma;&upsilon;&nu;&epsilon;&chi;ή
          &beta;&epsilon;&lambda;&tau;&iota;&sigma;&tau;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;
          &tau;&eta;&sigmaf;
          &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;ί&alpha;&sigmaf;
          &tau;&omicron;&upsilon; e-shop &tau;&eta;&sigmaf;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;
          &mu;&alpha;&sigmaf; &kappa;&alpha;&theta;ώ&sigmaf;
          &kappa;&alpha;&iota; &tau;&eta;&nu; ά&mu;&epsilon;&sigma;&eta;
          &alpha;&nu;&tau;&iota;&mu;&epsilon;&tau;ώ&pi;&iota;&sigma;&eta;
          &tau;&upsilon;&chi;ό&nu;
          &pi;&rho;&omicron;&beta;&lambda;&eta;&mu;ά&tau;&omega;&nu;
          &pi;&omicron;&upsilon; &pi;&iota;&theta;&alpha;&nu;ό&nu;
          &pi;&rho;&omicron;&kappa;ύ&pi;&tau;&omicron;&upsilon;&nu;.&nbsp;</span
        >
      </p>
      <p><br /></p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Epsilon;&Iota;&Delta;&Eta;&nbsp;</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >Cookies &pi;&omicron;&upsilon;
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&mu;&epsilon;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;&Eta;
          &laquo;&Iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&raquo;
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&epsilon;ί
          &tau;&eta;&nu;
          &tau;&epsilon;&chi;&nu;&omicron;&lambda;&omicron;&gamma;ί&alpha;
          &tau;&omega;&nu; cookies &kappa;&alpha;&iota;
          &epsilon;&iota;&delta;&iota;&kappa;ά:</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Tau;&alpha; &laquo;&pi;&rho;&omicron;&sigma;&omega;&rho;&iota;&nu;ά
          cookies&raquo;(session cookies) &gamma;&iota;&alpha;
          &tau;&omicron;&upsilon;&sigmaf;
          &pi;&alpha;&rho;&alpha;&kappa;ά&tau;&omega;
          &sigma;&kappa;&omicron;&pi;&omicron;ύ&sigmaf;:</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >- &Gamma;&iota;&alpha; &tau;&eta;&nu;
          &alpha;&upsilon;&tau;ό&mu;&alpha;&tau;&eta;
          &alpha;&nu;&alpha;&gamma;&nu;ώ&rho;&iota;&sigma;ή
          &sigma;&alpha;&sigmaf; &omega;&sigmaf;
          &epsilon;&gamma;&gamma;&epsilon;&gamma;&rho;&alpha;&mu;&mu;έ&nu;&omicron;&upsilon;
          &chi;&rho;ή&sigma;&tau;&eta; &mu;&epsilon;&tau;ά &tau;&eta;&nu;
          &sigma;ύ&nu;&delta;&epsilon;&sigma;ή &sigma;&alpha;&sigmaf; (log in) ή
          &gamma;&iota;&alpha; &tau;&eta;&nu;
          &pi;&epsilon;&rho;&iota;ή&gamma;&eta;&sigma;&eta; &sigma;&epsilon;
          &alpha;&sigma;&phi;&alpha;&lambda;&epsilon;ί&sigmaf;
          &upsilon;&pi;&omicron;&sigma;&epsilon;&lambda;ί&delta;&epsilon;&sigmaf;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >- &Gamma;&iota;&alpha; &tau;&eta;&nu;
          &alpha;&upsilon;&tau;ό&mu;&alpha;&tau;&eta;
          &alpha;&nu;&alpha;&gamma;&nu;ώ&rho;&iota;&sigma;ή
          &sigma;&alpha;&sigmaf; &kappa;&alpha;&tau;ά &tau;&eta;&nu;
          &epsilon;&kappa;&tau;έ&lambda;&epsilon;&sigma;&eta;
          &alpha;&gamma;&omicron;&rho;ώ&nu; &kappa;&alpha;&iota; &tau;&alpha;
          &omicron;&pi;&omicron;ί&alpha; &epsilon;ί&nu;&alpha;&iota;
          &alpha;&pi;&alpha;&rho;&alpha;ί&tau;&eta;&tau;&alpha;
          &gamma;&iota;&alpha; &tau;&eta;&nu;
          &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;ί&alpha;
          &tau;&eta;&sigmaf;
          &sigma;&upsilon;&nu;ή&theta;&omicron;&upsilon;&sigmaf;
          &epsilon;&phi;&alpha;&rho;&mu;&omicron;&gamma;ή&sigmaf;
          &tau;&omicron;&upsilon;
          &laquo;&eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;&omicron;ύ
          &kappa;&alpha;&lambda;&alpha;&theta;&iota;&omicron;ύ&raquo;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >- &Gamma;&iota;&alpha; &tau;&eta;&nu;
          &kappa;&alpha;&tau;&alpha;&nu;&omicron;&mu;ή &tau;&eta;&sigmaf;
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;&sigmaf;
          &alpha;&iota;&tau;ή&sigma;&epsilon;&omega;&nu;
          &delta;&iota;&alpha;&kappa;&omicron;&mu;&iota;&sigma;&tau;ή
          &mu;&epsilon;&tau;&alpha;&xi;ύ &mu;&iota;&alpha;&sigmaf;
          &omicron;&mu;ά&delta;&alpha;&sigmaf;
          &delta;&iota;&alpha;&kappa;&omicron;&mu;&iota;&sigma;&tau;ώ&nu;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >- &Gamma;&iota;&alpha; &tau;&eta;&nu;
          &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;ή
          &alpha;&nu;&alpha;&gamma;&nu;ώ&rho;&iota;&sigma;&eta;
          &chi;&rho;&eta;&sigma;&tau;ώ&nu; ή&delta;&eta;
          &sigma;&upsilon;&nu;&delta;&epsilon;&delta;&epsilon;&mu;έ&nu;&omega;&nu;
          &sigma;&epsilon; &mu;έ&sigma;&alpha;
          &kappa;&omicron;&iota;&nu;&omega;&nu;&iota;&kappa;ή&sigmaf;
          &delta;&iota;&kappa;&tau;ύ&omega;&sigma;&eta;&sigmaf;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Pi;&epsilon;&rho;&alpha;&iota;&tau;έ&rho;&omega;,
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&mu;&epsilon;
          &laquo;έ&mu;&mu;&omicron;&nu;&alpha; cookies&raquo;
          &gamma;&iota;&alpha; &tau;&omicron;&upsilon;&sigmaf;
          &epsilon;&xi;ή&sigmaf;
          &sigma;&kappa;&omicron;&pi;&omicron;ύ&sigmaf;:</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >- &Gamma;&iota;&alpha; &tau;&eta;&nu;
          &beta;&epsilon;&lambda;&tau;ί&omega;&sigma;&eta; &tau;&omega;&nu;
          &epsilon;&pi;&iota;&delta;ό&sigma;&epsilon;&omega;&nu;
          &tau;&eta;&sigmaf;
          &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;
          &mu;&alpha;&sigmaf;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >- &Gamma;&iota;&alpha; &tau;&eta;&nu;
          &epsilon;&xi;&alpha;&tau;&omicron;&mu;ί&kappa;&epsilon;&upsilon;&sigma;&eta;
          &tau;&eta;&sigmaf; &delta;&iota;&epsilon;&pi;&alpha;&phi;ή&sigmaf;
          &sigma;&alpha;&sigmaf; &kappa;&alpha;&iota; &tau;&eta;&nu;
          &pi;&rho;&omicron;&sigma;&omega;&pi;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;
          &tau;&eta;&sigmaf;
          &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;ί&alpha;&sigmaf;
          &tau;&eta;&sigmaf;
          &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;
          &mu;&alpha;&sigmaf;.</span
        >
      </p>
      <p><br /></p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Kappa;&Alpha;&Tau;&Eta;&Gamma;&Omicron;&Rho;&Iota;&Epsilon;&Sigma;&nbsp;</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >Cookies &pi;&omicron;&upsilon;
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&mu;&epsilon;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >1) &Alpha;&nu;&alpha;&gamma;&kappa;&alpha;ί&alpha; Cookies</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Epsilon;&pi;&iota;&tau;&rho;έ&pi;&omicron;&upsilon;&nu;
          &tau;&eta;&nu; &epsilon;&kappa;&tau;έ&lambda;&epsilon;&sigma;&eta;
          &beta;&alpha;&sigma;&iota;&kappa;ώ&nu;
          &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;&iota;ώ&nu;
          &tau;&omicron;&upsilon; site, ό&pi;&omega;&sigmaf; &tau;&eta;&nu;
          &pi;&rho;&omicron;&sigma;&theta;ή&kappa;&eta;
          &pi;&rho;&omicron;ϊό&nu;&tau;&omega;&nu; &sigma;&tau;&omicron;
          &kappa;&alpha;&lambda;ά&theta;&iota;, &tau;&eta;&nu;
          &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;ή
          &pi;&lambda;&eta;&rho;&omega;&mu;ή &kappa;&alpha;&iota; &tau;&eta;&nu;
          &alpha;&pi;&omicron;&theta;ή&kappa;&epsilon;&upsilon;&sigma;&eta;
          &pi;&rho;&omicron;ϊό&nu;&tau;&omega;&nu; &sigma;&tau;&eta; wish-list.
          &Chi;&omega;&rho;ί&sigmaf; &alpha;&upsilon;&tau;ά &tau;&alpha;
          &alpha;&pi;&omicron;&lambda;ύ&tau;&omega;&sigmaf;
          &alpha;&pi;&alpha;&rho;&alpha;ί&tau;&eta;&tau;&alpha; Cookies,
          &pi;&lambda;ή&tau;&tau;&epsilon;&tau;&alpha;&iota;
          ά&mu;&epsilon;&sigma;&alpha; &eta; &omicron;&mu;&alpha;&lambda;ή
          &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;ί&alpha;
          &tau;&omicron;&upsilon; e-shop &alpha;&lambda;&lambda;ά
          &upsilon;&pi;&omicron;&beta;&alpha;&theta;&mu;ί&zeta;&epsilon;&tau;&alpha;&iota;
          &kappa;&alpha;&iota; &eta;
          &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ή
          &epsilon;&mu;&pi;&epsilon;&iota;&rho;ί&alpha;
          &pi;&lambda;&omicron;ή&gamma;&eta;&sigma;&eta;&sigmaf;
          &mu;&iota;&alpha;&sigmaf; &kappa;&alpha;&iota;
          &beta;&alpha;&sigma;&iota;&kappa;έ&sigmaf; &gamma;&iota;&alpha;
          &tau;&omicron;
          &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;ό
          &epsilon;&mu;&pi;ό&rho;&iota;&omicron; &nbsp;(e-commerce)
          &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;ί&epsilon;&sigmaf;
          &upsilon;&pi;&omicron;&lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;&omicron;ύ&nu;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >2) Cookies
          &epsilon;&pi;&iota;&delta;ό&sigma;&epsilon;&omega;&nu;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Tau;&alpha; cookies
          &epsilon;&pi;&iota;&delta;ό&sigma;&epsilon;&omega;&nu;
          &sigma;&upsilon;&lambda;&lambda;έ&gamma;&omicron;&upsilon;&nu;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
          &sigma;&chi;&epsilon;&tau;&iota;&kappa;ά &mu;&epsilon; &tau;&omicron;
          &pi;&omega;&sigmaf; &omicron;&iota;
          &epsilon;&pi;&iota;&sigma;&kappa;έ&pi;&tau;&epsilon;&sigmaf;
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&nu;
          &tau;&eta;&nu;
          &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;
          &mu;&alpha;&sigmaf;. &Mu;&alpha;&sigmaf;
          &epsilon;&pi;&iota;&tau;&rho;έ&pi;&omicron;&upsilon;&nu; &nu;&alpha;
          &delta;&omicron;ύ&mu;&epsilon; &pi;&omicron;&iota;&epsilon;&sigmaf;
          &sigma;&epsilon;&lambda;ί&delta;&epsilon;&sigmaf;
          &epsilon;&pi;&iota;&sigma;&kappa;έ&pi;&tau;&omicron;&nu;&tau;&alpha;&iota;
          &pi;&iota;&omicron; &sigma;&upsilon;&chi;&nu;ά ή &mu;&alpha;&sigmaf;
          &delta;&epsilon;ί&chi;&nu;&omicron;&upsilon;&nu; &epsilon;ά&nu;
          &alpha;&nu;&tau;&iota;&mu;&epsilon;&tau;&omega;&pi;ί&zeta;&omicron;&upsilon;&nu;
          &kappa;ά&pi;&omicron;&iota;&omicron;
          &pi;&rho;ό&beta;&lambda;&eta;&mu;&alpha; &kappa;&alpha;&tau;ά
          &tau;&eta;&nu; &pi;&lambda;&omicron;ή&gamma;&eta;&sigma;&eta;.
          &Alpha;&upsilon;&tau;ά &tau;&alpha; cookies &delta;&epsilon;&nu;
          &sigma;&upsilon;&lambda;&lambda;έ&gamma;&omicron;&upsilon;&nu;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
          &pi;&omicron;&upsilon;
          &pi;&rho;&omicron;&sigma;&delta;&iota;&omicron;&rho;ί&zeta;&omicron;&upsilon;&nu;
          &tau;&omicron;&nu; &epsilon;&pi;&iota;&sigma;&kappa;έ&pi;&tau;&eta;.
          Ό&lambda;&epsilon;&sigmaf; &omicron;&iota;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
          &pi;&omicron;&upsilon;
          &sigma;&upsilon;&lambda;&lambda;έ&gamma;&omicron;&upsilon;&nu;
          &alpha;&upsilon;&tau;ά &tau;&alpha; cookies
          &epsilon;ί&nu;&alpha;&iota;
          &sigma;&upsilon;&gamma;&kappa;&epsilon;&nu;&tau;&rho;&omega;&tau;&iota;&kappa;έ&sigmaf;
          &kappa;&alpha;&iota;
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&nu;&tau;&alpha;&iota;
          &mu;ό&nu;&omicron; &gamma;&iota;&alpha; &nu;&alpha; &tau;&eta;
          &beta;&epsilon;&lambda;&tau;ί&omega;&sigma;&eta;
          &tau;&omicron;&upsilon; &tau;&rho;ό&pi;&omicron;&upsilon;
          &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;ί&alpha;&sigmaf;
          &tau;&omicron;&upsilon; stoferno.gr.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >3) Cookies
          &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;&iota;&kappa;ό&tau;&eta;&tau;&alpha;&sigmaf;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Alpha;&upsilon;&tau;ά &tau;&alpha; cookies
          &laquo;&alpha;&pi;&omicron;&mu;&nu;&eta;&mu;&omicron;&nu;&epsilon;ύ&omicron;&upsilon;&nu;&raquo;
          &tau;&iota;&sigmaf;
          &pi;&rho;&omicron;&tau;&iota;&mu;ή&sigma;&epsilon;&iota;&sigmaf;
          &tau;&omicron;&upsilon; &chi;&rho;ή&sigma;&tau;&eta;
          &kappa;&alpha;&tau;ά &tau;&eta;&nu;
          &pi;&epsilon;&rho;&iota;ή&gamma;&eta;&sigma;&eta;
          &sigma;&omicron;&upsilon; &sigma;&tau;&omicron;&nu;
          &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron; / site
          &mu;&alpha;&sigmaf;, ώ&sigma;&tau;&epsilon; &nu;&alpha;
          &mu;&pi;&omicron;&rho;&omicron;ύ&mu;&epsilon;
          &kappa;&alpha;&tau;ό&pi;&iota;&nu; &epsilon;&mu;&epsilon;ί&sigmaf;
          &alpha;&nu;&alpha;&lambda;&omicron;&gamma;&iota;&kappa;ά &nu;&alpha;
          &pi;&rho;&omicron;&tau;&epsilon;ί&nu;&omicron;&upsilon;&mu;&epsilon;
          &tau;&alpha; &kappa;&alpha;&tau;ά&lambda;&lambda;&eta;&lambda;&alpha;
          &pi;&rho;&omicron;ϊό&nu;&tau;&alpha; &mu;&epsilon; &beta;ά&sigma;&eta;
          &tau;&iota;&sigmaf; &alpha;&nu;ά&gamma;&kappa;&epsilon;&sigmaf;
          &tau;&omicron;&upsilon; &chi;&rho;ή&sigma;&tau;&eta;. &Mu;&epsilon;
          &alpha;&upsilon;&tau;ά &tau;&alpha; cookies
          &delta;&eta;&mu;&iota;&omicron;&upsilon;&rho;&gamma;&epsilon;ί&tau;&alpha;&iota;
          &mu;&iota;&alpha;
          &epsilon;&xi;&alpha;&tau;&omicron;&mu;&iota;&kappa;&epsilon;&upsilon;&mu;έ&nu;&eta;
          &epsilon;&kappa;&delta;&omicron;&chi;ή &tau;&omicron;&upsilon;
          stoferno.gr &mu;&epsilon;
          &alpha;&pi;&omicron;&tau;έ&lambda;&epsilon;&sigma;&mu;&alpha;
          &nu;&alpha; &beta;&rho;ί&sigma;&kappa;&epsilon;&iota; &omicron;
          &chi;&rho;ή&sigma;&tau;&eta;&sigmaf; &pi;&omicron;&lambda;ύ
          &pi;&iota;&omicron; &epsilon;ύ&kappa;&omicron;&lambda;&alpha;
          &alpha;&upsilon;&tau;ό &pi;&omicron;&upsilon;
          &alpha;&nu;&alpha;&zeta;&eta;&tau;ά.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >4) Cookies
          &delta;&iota;&alpha;&phi;ή&mu;&iota;&sigma;&eta;&sigmaf;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Alpha;&upsilon;&tau;ά &tau;&alpha; cookies
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&nu;&tau;&alpha;&iota;
          &gamma;&iota;&alpha; &nu;&alpha;
          &pi;&alpha;&rho;έ&chi;&omicron;&upsilon;&nu;
          &delta;&iota;&alpha;&phi;&eta;&mu;ί&sigma;&epsilon;&iota;&sigmaf;
          &pi;&iota;&omicron; &sigma;&chi;&epsilon;&tau;&iota;&kappa;έ&sigmaf;
          &mu;&epsilon; &tau;&omicron;&nu;
          &epsilon;&kappa;ά&sigma;&tau;&omicron;&tau;&epsilon;
          &chi;&rho;ή&sigma;&tau;&eta; &kappa;&alpha;&iota; &tau;&alpha;
          &epsilon;&nu;&delta;&iota;&alpha;&phi;έ&rho;&omicron;&nu;&tau;ά
          &tau;&omicron;&upsilon;.
          &Chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&nu;&tau;&alpha;&iota;
          &epsilon;&pi;ί&sigma;&eta;&sigmaf; &gamma;&iota;&alpha; &tau;&eta;&nu;
          &alpha;&pi;&omicron;&sigma;&tau;&omicron;&lambda;ή
          &sigma;&tau;&omicron;&chi;&epsilon;&upsilon;&mu;έ&nu;&eta;&sigmaf;
          &delta;&iota;&alpha;&phi;ή&mu;&iota;&sigma;&eta;&sigmaf; ή
          &pi;&rho;&omicron;&sigma;&phi;&omicron;&rho;ώ&nu; &mu;&epsilon;
          &sigma;&tau;ό&chi;&omicron; &tau;&omicron;&nu;
          &pi;&epsilon;&rho;&iota;&omicron;&rho;&iota;&sigma;&mu;ό
          &tau;&omega;&nu; &mu;&alpha;&zeta;&iota;&kappa;ώ&nu;,
          &alpha;&nu;&epsilon;&pi;&iota;&theta;ύ&mu;&eta;&tau;&omega;&nu;
          &kappa;&alpha;&iota; &alpha;&nu;&omicron;ύ&sigma;&iota;&omega;&nu;
          &delta;&iota;&alpha;&phi;&eta;&mu;&iota;&sigma;&tau;&iota;&kappa;ώ&nu;
          &mu;&eta;&nu;&upsilon;&mu;ά&tau;&omega;&nu; &gamma;&iota;&alpha;
          &tau;&omicron;&nu; &chi;&rho;ή&sigma;&tau;&eta;.
          &Pi;&alpha;&rho;ά&lambda;&lambda;&eta;&lambda;&alpha;,
          &beta;&omicron;&eta;&theta;&omicron;ύ&nu; &sigma;&tau;&eta;
          &mu;έ&tau;&rho;&eta;&sigma;&eta; &tau;&eta;&sigmaf;
          &alpha;&pi;&omicron;&tau;&epsilon;&lambda;&epsilon;&sigma;&mu;&alpha;&tau;&iota;&kappa;ό&tau;&eta;&tau;&alpha;&sigmaf;
          &tau;&omega;&nu;
          &delta;&iota;&alpha;&phi;&eta;&mu;&iota;&sigma;&tau;&iota;&kappa;ώ&nu;
          &kappa;&alpha;&mu;&pi;&alpha;&nu;&iota;ώ&nu;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >5) Cookies Analytics</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Epsilon;ί&nu;&alpha;&iota;
          &upsilon;&pi;&omicron;&sigma;ύ&nu;&omicron;&lambda;&omicron;
          &tau;&omega;&nu; Cookies
          &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;&iota;&kappa;ό&tau;&eta;&tau;&alpha;&sigmaf;
          &kappa;&alpha;&iota; &pi;&alpha;&rho;έ&chi;&omicron;&upsilon;&nu;
          &tau;&eta; &delta;&upsilon;&nu;&alpha;&tau;ό&tau;&eta;&tau;&alpha;
          &nu;&alpha;
          &alpha;&xi;&iota;&omicron;&lambda;&omicron;&gamma;&omicron;ύ&mu;&epsilon;
          &tau;&eta;&nu;
          &alpha;&pi;&omicron;&tau;&epsilon;&lambda;&epsilon;&sigma;&mu;&alpha;&tau;&iota;&kappa;ό&tau;&eta;&tau;&alpha;
          &tau;&omega;&nu; &delta;&iota;ά&phi;&omicron;&rho;&omega;&nu;
          &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;&iota;ώ&nu;
          &tau;&omicron;&upsilon;
          &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;&upsilon; / site
          &mu;&alpha;&sigmaf;, έ&tau;&sigma;&iota; ώ&sigma;&tau;&epsilon;
          &nu;&alpha;
          &beta;&epsilon;&lambda;&tau;&iota;ώ&nu;&omicron;&upsilon;&mu;&epsilon;
          &sigma;&upsilon;&nu;&epsilon;&chi;ώ&sigmaf; &tau;&eta;&nu;
          &epsilon;&mu;&pi;&epsilon;&iota;&rho;ί&alpha; &pi;&omicron;&upsilon;
          &nbsp;&pi;&rho;&omicron;&sigma;&phi;έ&rho;&omicron;&upsilon;&mu;&epsilon;
          &sigma;&tau;&omicron;&nu; &chi;&rho;ή&sigma;&tau;&eta;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Sigma;&upsilon;&gamma;&kappa;&epsilon;&kappa;&rho;&iota;&mu;έ&nu;&alpha;:</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Tau;&rho;ί&tau;&omicron;&iota;
          &pi;&rho;&omicron;&mu;&eta;&theta;&epsilon;&upsilon;&tau;έ&sigmaf;,
          &sigma;&upsilon;&mu;&pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;&alpha;&nu;&omicron;&mu;έ&nu;&eta;&sigmaf;
          &tau;&eta;&sigmaf; Google, &delta;ύ&nu;&alpha;&tau;&alpha;&iota;
          &nu;&alpha;
          &epsilon;&mu;&phi;&alpha;&nu;ί&sigma;&omicron;&upsilon;&nu;
          &delta;&iota;&alpha;&phi;&eta;&mu;ί&sigma;&epsilon;&iota;&sigmaf;
          &tau;&eta;&sigmaf; &Epsilon;&tau;&alpha;&iota;&rho;ί&alpha;&sigmaf;
          &sigma;&epsilon;
          &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;&upsilon;&sigmaf;
          &sigma;&tau;&omicron;
          &delta;&iota;&alpha;&delta;ί&kappa;&tau;&upsilon;&omicron;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Tau;&rho;ί&tau;&omicron;&iota;
          &pi;&rho;&omicron;&mu;&eta;&theta;&epsilon;&upsilon;&tau;έ&sigmaf;,
          &sigma;&upsilon;&mu;&pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;&alpha;&nu;&omicron;&mu;έ&nu;&eta;&sigmaf;
          &tau;&eta;&sigmaf; Google, &delta;ύ&nu;&alpha;&tau;&alpha;&iota;
          &nu;&alpha; &kappa;ά&nu;&omicron;&upsilon;&nu; &chi;&rho;ή&sigma;&eta;
          cookies &gamma;&iota;&alpha; &tau;&eta;&nu;
          &epsilon;&nu;&eta;&mu;έ&rho;&omega;&sigma;ή,
          &beta;&epsilon;&lambda;&tau;&iota;&sigma;&tau;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;
          &kappa;&alpha;&iota; &pi;&rho;&omicron;&beta;&omicron;&lambda;ή
          &delta;&iota;&alpha;&phi;&eta;&mu;ί&sigma;&epsilon;&omega;&nu;
          &pi;&omicron;&upsilon;
          &beta;&alpha;&sigma;ί&zeta;&omicron;&nu;&tau;&alpha;&iota;
          &sigma;&epsilon;
          &pi;&rho;&omicron;&eta;&gamma;&omicron;ύ&mu;&epsilon;&nu;&eta;
          &epsilon;&pi;ί&sigma;&kappa;&epsilon;&psi;&eta;
          &tau;&omicron;&upsilon; &chi;&rho;ή&sigma;&tau;&eta;
          &sigma;&tau;&omicron;
          &delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;ό &tau;ό&pi;&omicron;
          stoferno.gr.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >To stoferno.gr &delta;ύ&nu;&alpha;&tau;&alpha;&iota;
          &epsilon;&pi;ί&sigma;&eta;&sigmaf; &nu;&alpha;
          &alpha;&xi;&iota;&omicron;&pi;&omicron;&iota;ή&sigma;&epsilon;&iota;
          &tau;&alpha; cookies &alpha;&pi;ό
          &pi;&rho;&omicron;&eta;&gamma;&omicron;ύ&mu;&epsilon;&nu;&eta;
          &epsilon;&pi;ί&sigma;&kappa;&epsilon;&psi;&eta; &sigma;&tau;&eta;&nu;
          &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;
          &tau;&eta;&sigmaf; &gamma;&iota;&alpha;
          &epsilon;&pi;&alpha;&nu;&alpha;&lambda;&eta;&pi;&tau;&iota;&kappa;ό
          &mu;ά&rho;&kappa;&epsilon;&tau;&iota;&nu;&gamma;&kappa;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Omicron; &chi;&rho;ή&sigma;&tau;&eta;&sigmaf;
          &mu;&pi;&omicron;&rho;&epsilon;ί &nu;&alpha;
          &epsilon;&pi;&iota;&lambda;έ&xi;&epsilon;&iota; &tau;&eta;&nu;
          &epsilon;&xi;&alpha;ί&rho;&epsilon;&sigma;ή &tau;&omicron;&upsilon;
          &alpha;&pi;ό &tau;έ&tau;&omicron;&iota;&alpha; &chi;&rho;ή&sigma;&eta;
          &tau;&omega;&nu; cookies &alpha;&pi;ό &tau;&eta;&nu;
          Google,&nbsp;</span
        ><a
          href="https://www.google.com/ads/preferences/"
          style="text-decoration: none"
          ><span
            style="
              font-size: 11pt;
              font-family: Arial;
              color: #0563c1;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: underline;
              -webkit-text-decoration-skip: none;
              text-decoration-skip-ink: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >&epsilon;&delta;ώ</span
          ></a
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Omicron; &chi;&rho;ή&sigma;&tau;&eta;&sigmaf;
          &mu;&pi;&omicron;&rho;&epsilon;ί &nu;&alpha;
          &rho;&upsilon;&theta;&mu;ί&sigma;&epsilon;&iota; &tau;&omicron;&nu;
          Browser ώ&sigma;&tau;&epsilon; &nu;&alpha;
          &epsilon;&nu;&eta;&mu;&epsilon;&rho;ώ&nu;&epsilon;&tau;&alpha;&iota;
          &kappa;ά&theta;&epsilon; &phi;&omicron;&rho;ά &pi;&rho;&iota;&nu;
          &gamma;ί&nu;&epsilon;&iota; &eta; &lambda;ή&psi;&eta;
          &epsilon;&nu;ό&sigmaf; cookie &kappa;&alpha;&iota;
          &epsilon;&pi;&omicron;&mu;έ&nu;&omega;&sigmaf; &nu;&alpha;
          &alpha;&pi;&omicron;&phi;&alpha;&sigma;ί&zeta;&epsilon;&iota;
          &tau;&eta; &lambda;ή&psi;&eta; &tau;&omicron;&upsilon; ή
          &tau;&eta;&nu; &alpha;&pi;ό&rho;&rho;&iota;&psi;ή
          &tau;&omicron;&upsilon;. &Sigma;&apos; &alpha;&upsilon;&tau;ή
          &tau;&eta;&nu; &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta;,
          &sigma;&alpha;&sigmaf;
          &epsilon;&nu;&eta;&mu;&epsilon;&rho;ώ&nu;&omicron;&upsilon;&mu;&epsilon;
          ό&tau;&iota; &epsilon;&nu;&delta;έ&chi;&epsilon;&tau;&alpha;&iota;
          &nu;&alpha; &mu;&eta;&nu; &epsilon;ί&sigma;&tau;&epsilon;
          &sigma;&epsilon; &theta;έ&sigma;&eta; &nu;&alpha;
          &alpha;&xi;&iota;&omicron;&pi;&omicron;&iota;ή&sigma;&epsilon;&tau;&epsilon;
          ό&lambda;&epsilon;&sigmaf; &tau;&iota;&sigmaf;
          &delta;&upsilon;&nu;&alpha;&tau;ό&tau;&eta;&tau;&epsilon;&sigmaf;
          &tau;&omicron;&upsilon;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >To stoferno.gr &delta;ύ&nu;&alpha;&tau;&alpha;&iota; &nu;&alpha;
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&epsilon;ί
          &tau;&iota;&sigmaf;
          &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;ί&epsilon;&sigmaf;
          &tau;&omicron;&upsilon; Google Analytics &gamma;&iota;&alpha;
          &tau;&iota;&sigmaf;
          &delta;&iota;&alpha;&phi;&eta;&mu;ί&sigma;&epsilon;&iota;&sigmaf;
          &pi;&rho;&omicron;&beta;&omicron;&lambda;ή&sigmaf; (&pi;.&chi;.,
          &epsilon;&pi;&alpha;&nu;&alpha;&lambda;&eta;&pi;&tau;&iota;&kappa;ό
          &mu;ά&rho;&kappa;&epsilon;&tau;&iota;&nu;&gamma;&kappa;,
          &alpha;&nu;&alpha;&phi;&omicron;&rho;έ&sigmaf;
          &epsilon;&mu;&phi;ά&nu;&iota;&sigma;&eta;&sigmaf;
          &Delta;&iota;&kappa;&tau;ύ&omicron;&upsilon;
          &epsilon;&mu;&phi;ά&nu;&iota;&sigma;&eta;&sigmaf; Google,
          &epsilon;&nu;&sigma;&omega;&mu;ά&tau;&omega;&sigma;&eta;
          &tau;&omicron;&upsilon;
          &Delta;&iota;&alpha;&chi;&epsilon;&iota;&rho;&iota;&sigma;&tau;ή
          &kappa;&alpha;&mu;&pi;ά&nu;&iota;&alpha;&sigmaf; Doubleclick
          &kappa;&alpha;&iota; &alpha;&nu;&alpha;&phi;&omicron;&rho;έ&sigmaf;
          &delta;&eta;&mu;&omicron;&gamma;&rho;&alpha;&phi;&iota;&kappa;ώ&nu;
          &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&omega;&nu;
          &kappa;&alpha;&iota;
          &epsilon;&nu;&delta;&iota;&alpha;&phi;&epsilon;&rho;ό&nu;&tau;&omega;&nu;).</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;ώ&nu;&tau;&alpha;&sigmaf;
          &tau;&iota;&sigmaf;&nbsp;</span
        ><a
          href="https://www.google.com/settings/ads"
          style="text-decoration: none"
          ><span
            style="
              font-size: 11pt;
              font-family: Arial;
              color: #0563c1;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: underline;
              -webkit-text-decoration-skip: none;
              text-decoration-skip-ink: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >&Rho;&upsilon;&theta;&mu;ί&sigma;&epsilon;&iota;&sigmaf;
            &delta;&iota;&alpha;&phi;&eta;&mu;ί&sigma;&epsilon;&omega;&nu;</span
          ></a
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >, &omicron;&iota; &chi;&rho;ή&sigma;&tau;&epsilon;&sigmaf;
          &mu;&pi;&omicron;&rho;&omicron;ύ&nu; &nu;&alpha;
          &epsilon;&xi;&alpha;&iota;&rho;&epsilon;&theta;&omicron;ύ&nu;
          &alpha;&pi;ό &tau;&omicron; Google Analytics &gamma;&iota;&alpha;
          &delta;&iota;&alpha;&phi;&eta;&mu;ί&sigma;&epsilon;&iota;&sigmaf;
          &pi;&rho;&omicron;&beta;&omicron;&lambda;ή&sigmaf;
          &kappa;&alpha;&iota; &nu;&alpha;
          &pi;&rho;&omicron;&sigma;&alpha;&rho;&mu;ό&sigma;&omicron;&upsilon;&nu;
          &tau;&iota;&sigmaf;
          &delta;&iota;&alpha;&phi;&eta;&mu;ί&sigma;&epsilon;&iota;&sigmaf;
          &tau;&omicron;&upsilon; &Delta;&iota;&kappa;&tau;ύ&omicron;&upsilon;
          &epsilon;&mu;&phi;ά&nu;&iota;&sigma;&eta;&sigmaf; Google.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Epsilon;&delta;ώ
          &beta;&rho;ί&sigma;&kappa;&omicron;&nu;&tau;&alpha;&iota;
          &omicron;&iota;&nbsp;</span
        ><a
          href="https://tools.google.com/dlpage/gaoptout/"
          style="text-decoration: none"
          ><span
            style="
              font-size: 11pt;
              font-family: Arial;
              color: #0563c1;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: underline;
              -webkit-text-decoration-skip: none;
              text-decoration-skip-ink: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >&delta;&iota;&alpha;&theta;έ&sigma;&iota;&mu;&epsilon;&sigmaf;
            &epsilon;&pi;&iota;&lambda;&omicron;&gamma;έ&sigmaf;
            &epsilon;&xi;&alpha;ί&rho;&epsilon;&sigma;&eta;&sigmaf;</span
          ></a
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;&tau;&omicron;&upsilon; Google Analytics &gamma;&iota;&alpha;
          &tau;&omicron;&nu; &iota;&sigma;&tau;ό.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >To stoferno.gr
          &sigma;&upsilon;&mu;&mu;&omicron;&rho;&phi;ώ&nu;&epsilon;&tau;&alpha;&iota;
          &mu;&epsilon; &tau;&eta;&nu;&nbsp;</span
        ><a
          href="https://support.google.com/adwordspolicy/bin/answer.py?answer=143465"
          style="text-decoration: none"
          ><span
            style="
              font-size: 11pt;
              font-family: Arial;
              color: #0563c1;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: underline;
              -webkit-text-decoration-skip: none;
              text-decoration-skip-ink: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >&Pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή
            &delta;&iota;&alpha;&phi;&eta;&mu;ί&sigma;&epsilon;&omega;&nu;
            &beta;ά&sigma;&epsilon;&iota;
            &epsilon;&nu;&delta;&iota;&alpha;&phi;έ&rho;&omicron;&nu;&tau;&omicron;&sigmaf;
            &tau;&omicron;&upsilon; Google AdWords</span
          ></a
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;&kappa;&alpha;&iota;
          &tau;&omicron;&upsilon;&sigmaf;&nbsp;</span
        ><a
          href="https://support.google.com/adwordspolicy/answer/143465#sensitive"
          style="text-decoration: none"
          ><span
            style="
              font-size: 11pt;
              font-family: Arial;
              color: #0563c1;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: underline;
              -webkit-text-decoration-skip: none;
              text-decoration-skip-ink: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >&pi;&epsilon;&rho;&iota;&omicron;&rho;&iota;&sigma;&mu;&omicron;ύ&sigmaf;
            &gamma;&iota;&alpha;
            &epsilon;&upsilon;&alpha;ί&sigma;&theta;&eta;&tau;&epsilon;&sigmaf;
            &kappa;&alpha;&tau;&eta;&gamma;&omicron;&rho;ί&epsilon;&sigmaf;</span
          ></a
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;&kappa;&alpha;&iota;:</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&epsilon;ί
          &epsilon;&pi;&alpha;&nu;&alpha;&lambda;&eta;&pi;&tau;&iota;&kappa;ό
          &mu;ά&rho;&kappa;&epsilon;&tau;&iota;&nu;&gamma;&kappa; &mu;&epsilon;
          &tau;&omicron; Google Analytics &gamma;&iota;&alpha;
          &delta;&iota;&alpha;&phi;ή&mu;&iota;&sigma;&eta; &sigma;&tau;&omicron;
          &delta;&iota;&alpha;&delta;ί&kappa;&tau;&upsilon;&omicron;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >To stoferno.gr &kappa;&alpha;&iota; &tau;&rho;ί&tau;&omicron;&iota;
          &pi;&rho;&omicron;&mu;&eta;&theta;&epsilon;&upsilon;&tau;έ&sigmaf;,
          &sigma;&upsilon;&mu;&pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;&alpha;&nu;&omicron;&mu;έ&nu;&eta;&sigmaf;
          &tau;&eta;&sigmaf; Google,
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&nu;
          &mu;&alpha;&zeta;ί cookie &alpha;&rho;&chi;&iota;&kappa;&omicron;ύ
          &kappa;&alpha;&tau;&alpha;&sigma;&kappa;&epsilon;&upsilon;&alpha;&sigma;&tau;ή
          (ό&pi;&omega;&sigmaf; &tau;&omicron; cookie &tau;&omicron;&upsilon;
          Google Analytics) &kappa;&alpha;&iota; cookie
          &tau;&rho;ί&tau;&omicron;&upsilon;
          &mu;έ&rho;&omicron;&upsilon;&sigmaf; (ό&pi;&omega;&sigmaf;
          &tau;&omicron; cookie DoubleClick), &gamma;&iota;&alpha;
          &tau;&eta;&nu; &epsilon;&nu;&eta;&mu;έ&rho;&omega;&sigma;&eta;,
          &tau;&eta;
          &beta;&epsilon;&lambda;&tau;&iota;&sigma;&tau;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;
          &kappa;&alpha;&iota; &tau;&eta;&nu;
          &pi;&rho;&omicron;&beta;&omicron;&lambda;ή
          &delta;&iota;&alpha;&phi;&eta;&mu;ί&sigma;&epsilon;&omega;&nu;,
          &sigma;ύ&mu;&phi;&omega;&nu;&alpha; &mu;&epsilon; &tau;&iota;&sigmaf;
          &pi;&rho;&omicron;&eta;&gamma;&omicron;ύ&mu;&epsilon;&nu;&epsilon;&sigmaf;
          &epsilon;&pi;&iota;&sigma;&kappa;έ&psi;&epsilon;&iota;&sigmaf;
          &kappa;ά&pi;&omicron;&iota;&omega;&nu;
          &chi;&rho;&eta;&sigma;&tau;ώ&nu; &sigma;&tau;&omicron;&nu;
          &iota;&sigma;&tau;ό&tau;&omicron;&pi;ό &tau;&eta;&sigmaf;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >To stoferno.gr &kappa;&alpha;&iota; &omicron;&iota;
          &tau;&rho;ί&tau;&omicron;&iota;
          &pi;&rho;&omicron;&mu;&eta;&theta;&epsilon;&upsilon;&tau;έ&sigmaf;,
          &sigma;&upsilon;&mu;&pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;&alpha;&nu;&omicron;&mu;έ&nu;&eta;&sigmaf;
          &tau;&eta;&sigmaf; Google,
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&nu;
          &mu;&alpha;&zeta;ί cookie &alpha;&rho;&chi;&iota;&kappa;&omicron;ύ
          &kappa;&alpha;&tau;&alpha;&sigma;&kappa;&epsilon;&upsilon;&alpha;&sigma;&tau;ή
          (ό&pi;&omega;&sigmaf; &tau;&omicron; cookie &tau;&omicron;&upsilon;
          Google Analytics) &kappa;&alpha;&iota; cookie
          &tau;&rho;ί&tau;&omicron;&upsilon;
          &mu;έ&rho;&omicron;&upsilon;&sigmaf; (ό&pi;&omega;&sigmaf;
          &tau;&omicron; cookie DoubleClick), &gamma;&iota;&alpha;
          &tau;&eta;&nu; &epsilon;&kappa;&tau;έ&lambda;&epsilon;&sigma;&eta;
          &alpha;&nu;&alpha;&phi;&omicron;&rho;ώ&nu;
          &sigma;&chi;&epsilon;&tau;&iota;&kappa;ά &mu;&epsilon;
          &tau;&omicron;&nu; &tau;&rho;ό&pi;&omicron; &mu;&epsilon;
          &tau;&omicron;&nu; &omicron;&pi;&omicron;ί&omicron; &omicron;&iota;
          &epsilon;&mu;&phi;&alpha;&nu;ί&sigma;&epsilon;&iota;&sigmaf;
          &tau;&omega;&nu;
          &delta;&iota;&alpha;&phi;&eta;&mu;ί&sigma;&epsilon;ώ&nu;
          &tau;&eta;&sigmaf; stoferno.gr, ά&lambda;&lambda;&epsilon;&sigmaf;
          &chi;&rho;ή&sigma;&epsilon;&iota;&sigmaf;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
          &delta;&iota;&alpha;&phi;ή&mu;&iota;&sigma;&eta;&sigmaf;
          &kappa;&alpha;&iota; &omicron;&iota;
          &alpha;&lambda;&lambda;&eta;&lambda;&epsilon;&pi;&iota;&delta;&rho;ά&sigma;&epsilon;&iota;&sigmaf;
          &mu;&epsilon; &alpha;&upsilon;&tau;έ&sigmaf; &tau;&iota;&sigmaf;
          &epsilon;&mu;&phi;&alpha;&nu;ί&sigma;&epsilon;&iota;&sigmaf;
          &delta;&iota;&alpha;&phi;&eta;&mu;ί&sigma;&epsilon;&omega;&nu;
          &kappa;&alpha;&iota;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;
          &delta;&iota;&alpha;&phi;ή&mu;&iota;&sigma;&eta;&sigmaf;
          &sigma;&chi;&epsilon;&tau;ί&zeta;&omicron;&nu;&tau;&alpha;&iota;
          &mu;&epsilon;
          &epsilon;&pi;&iota;&sigma;&kappa;έ&psi;&epsilon;&iota;&sigmaf;
          &sigma;&tau;&omicron;&nu; &iota;&sigma;&tau;ό&tau;&omicron;&pi;ό
          &tau;&eta;&sigmaf; stoferno.gr.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >To stoferno.gr &delta;ύ&nu;&alpha;&tau;&alpha;&iota; &nu;&alpha;
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&epsilon;ί
          &tau;&alpha; &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &alpha;&pi;ό &tau;&eta;
          &delta;&iota;&alpha;&phi;ή&mu;&iota;&sigma;&eta;
          &beta;ά&sigma;&epsilon;&iota;
          &epsilon;&nu;&delta;&iota;&alpha;&phi;έ&rho;&omicron;&nu;&tau;&omicron;&sigmaf;
          &tau;&eta;&sigmaf; Google ή &tau;&alpha;
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &kappa;&omicron;&iota;&nu;&omicron;ύ
          &tau;&rho;ί&tau;&omicron;&upsilon;
          &mu;έ&rho;&omicron;&upsilon;&sigmaf; (ό&pi;&omega;&sigmaf; &eta;
          &eta;&lambda;&iota;&kappa;ί&alpha;, &tau;&omicron;
          &phi;ύ&lambda;&omicron; &kappa;&alpha;&iota; &tau;&alpha;
          &epsilon;&nu;&delta;&iota;&alpha;&phi;έ&rho;&omicron;&nu;&tau;&alpha;)
          &mu;&epsilon; &tau;&omicron; Google Analytics.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Eta; &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;
          &delta;&iota;&alpha;&tau;&eta;&rho;&epsilon;ί &tau;&omicron;
          &delta;&iota;&kappa;&alpha;ί&omega;&mu;&alpha; &nu;&alpha;
          &alpha;&lambda;&lambda;ά&xi;&epsilon;&iota; &alpha;&upsilon;&tau;ή
          &tau;&eta;&nu; &pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή
          &gamma;&iota;&alpha; cookies
          &omicron;&pi;&omicron;&tau;&epsilon;&delta;ή&pi;&omicron;&tau;&epsilon;.
          &Pi;&alpha;&rho;&alpha;&kappa;&alpha;&lambda;ώ
          &epsilon;&nu;&tau;ό&pi;&iota;&sigma;&epsilon; &tau;&omicron;
          &sigma;&eta;&mu;&epsilon;ί&omicron;
          &laquo;&Tau;&Epsilon;&Lambda;&Epsilon;&Upsilon;&Tau;&Alpha;&Iota;&Alpha;
          &Alpha;&Nu;&Alpha;&Theta;&Epsilon;&Omega;&Rho;&Eta;&Sigma;&Eta;&raquo;
          &sigma;&tau;&eta;&nu; &kappa;&omicron;&rho;&upsilon;&phi;ή
          &tau;&eta;&sigmaf; &pi;&alpha;&rho;&omicron;ύ&sigma;&alpha;&sigmaf;
          &sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;, &gamma;&iota;&alpha;
          &nu;&alpha; &delta;&epsilon;&iota;&sigmaf; &pi;ό&tau;&epsilon;
          &alpha;&nu;&alpha;&theta;&epsilon;&omega;&rho;ή&theta;&eta;&kappa;&epsilon;
          &gamma;&iota;&alpha;
          &tau;&epsilon;&lambda;&epsilon;&upsilon;&tau;&alpha;ί&alpha;
          &phi;&omicron;&rho;ά &eta; &pi;&alpha;&rho;&omicron;ύ&sigma;&alpha;
          &Pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή Cookies.
          &Omicron;&pi;&omicron;&iota;&epsilon;&sigma;&delta;ή&pi;&omicron;&tau;&epsilon;
          &alpha;&lambda;&lambda;&alpha;&gamma;έ&sigmaf; &sigma;&tau;&eta;&nu;
          &pi;&alpha;&rho;&omicron;ύ&sigma;&alpha;
          &Pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή Cookies &theta;&alpha;
          &iota;&sigma;&chi;ύ&sigma;&omicron;&upsilon;&nu; &alpha;&pi;ό
          &tau;&eta; &sigma;&tau;&iota;&gamma;&mu;ή &pi;&omicron;&upsilon; &eta;
          &alpha;&nu;&alpha;&theta;&epsilon;&omega;&rho;&eta;&mu;έ&nu;&eta;
          &Pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή Cookies
          &kappa;&alpha;&tau;&alpha;&sigma;&tau;&epsilon;ί
          &delta;&iota;&alpha;&theta;έ&sigma;&iota;&mu;&eta;
          &sigma;&tau;&omicron;&nu;
          &Iota;&sigma;&tau;&omicron;&chi;ώ&rho;&omicron;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Delta;&iota;&alpha;&phi;&eta;&mu;&iota;&sigma;&tau;έ&sigmaf;
          &tau;&rho;ί&tau;&omega;&nu; &mu;&epsilon;&rho;ώ&nu;
          &kappa;&alpha;&iota; ά&lambda;&lambda;&epsilon;&sigmaf;
          &epsilon;&pi;&iota;&chi;&epsilon;&iota;&rho;ή&sigma;&epsilon;&iota;&sigmaf;
          &pi;&omicron;&upsilon;
          &sigma;&upsilon;&nu;&epsilon;&rho;&gamma;&alpha;&zeta;ό&mu;&alpha;&sigma;&tau;&epsilon;
          &mu;&pi;&omicron;&rho;&epsilon;ί &nu;&alpha;
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&nu;
          &tau;&alpha; &delta;&iota;&kappa;ά &tau;&omicron;&upsilon;&sigmaf;
          Cookies &gamma;&iota;&alpha; &nu;&alpha;
          &sigma;&upsilon;&lambda;&lambda;έ&xi;&omicron;&upsilon;&nu;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
          &pi;&omicron;&upsilon; &alpha;&phi;&omicron;&rho;&omicron;ύ&nu;
          &sigma;&tau;&iota;&sigmaf;
          &delta;&rho;&alpha;&sigma;&tau;&eta;&rho;&iota;ό&tau;&eta;&tau;έ&sigmaf;
          &sigma;&omicron;&upsilon; &sigma;&tau;&omicron;&nu;
          &Iota;&sigma;&tau;&omicron;&chi;ώ&rho;&omicron; &mu;&alpha;&sigmaf;.
          &Delta;&epsilon;&nu;
          &epsilon;&lambda;έ&gamma;&chi;&omicron;&upsilon;&mu;&epsilon;
          &alpha;&upsilon;&tau;ά &tau;&alpha; Cookies.</span
        >
      </p>
      <p><br /></p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Alpha;&Pi;&Epsilon;&Nu;&Epsilon;&Rho;&Gamma;&Omicron;&Pi;&Omicron;&Iota;&Eta;&Sigma;&Eta;</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;Cookies
          &epsilon;&iota;&delta;&iota;&kappa;ό&tau;&epsilon;&rho;&alpha;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Mu;&pi;&omicron;&rho;&epsilon;ί&tau;&epsilon; &nu;&alpha;
          &epsilon;&nu;&epsilon;&rho;&gamma;&omicron;&pi;&omicron;&iota;ή&sigma;&epsilon;&tau;&epsilon;,
          &nu;&alpha;
          &alpha;&pi;&epsilon;&nu;&epsilon;&rho;&gamma;&omicron;&pi;&omicron;&iota;ή&sigma;&epsilon;&tau;&epsilon;
          ή &kappa;&alpha;&iota; &nu;&alpha;
          &sigma;&beta;ή&sigma;&epsilon;&tau;&epsilon;
          &epsilon;&nu;&tau;&epsilon;&lambda;ώ&sigmaf; &tau;&alpha; cookies
          &mu;έ&sigma;&alpha; &alpha;&pi;ό &tau;&iota;&sigmaf;
          &epsilon;&pi;&iota;&lambda;&omicron;&gamma;έ&sigmaf;
          &rho;ύ&theta;&mu;&iota;&sigma;&eta;&sigmaf; &sigma;&tau;&omicron;
          &pi;&rho;ό&gamma;&rho;&alpha;&mu;&mu;&alpha;
          &pi;&epsilon;&rho;&iota;ή&gamma;&eta;&sigma;&eta;&sigmaf;
          &pi;&omicron;&upsilon;
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&epsilon;ί&tau;&epsilon;.
          &Alpha;&nu; &epsilon;&pi;&iota;&lambda;έ&xi;&epsilon;&tau;&epsilon;
          &nu;&alpha;
          &alpha;&pi;&epsilon;&nu;&epsilon;&rho;&gamma;&omicron;&pi;&omicron;&iota;ή&sigma;&epsilon;&tau;&epsilon;
          &tau;&alpha; cookies, &omicron;&rho;&iota;&sigma;&mu;έ&nu;&alpha;
          &mu;έ&rho;&eta; &tau;&eta;&sigmaf;
          &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;
          &mu;&pi;&omicron;&rho;&epsilon;ί &nu;&alpha; &mu;&eta;
          &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;&omicron;ύ&nu;
          &epsilon;&pi;&alpha;&rho;&kappa;ώ&sigmaf; &kappa;&alpha;&iota;
          &gamma;&iota;&apos; &alpha;&upsilon;&tau;ό &omicron;&iota;
          &chi;&rho;ή&sigma;&tau;&epsilon;&sigmaf;
          &alpha;&pi;&omicron;&delta;έ&chi;&omicron;&nu;&tau;&alpha;&iota;
          &epsilon;&xi; &alpha;&rho;&chi;ή&sigmaf; &alpha;&upsilon;&tau;ή
          &tau;&eta;&nu; &epsilon;&pi;έ&mu;&beta;&alpha;&sigma;&eta;.
          &nbsp;&Mu;&pi;&omicron;&rho;&epsilon;ί&tau;&epsilon; &nu;&alpha;
          &epsilon;&pi;&iota;&sigma;&kappa;&epsilon;&phi;&theta;&epsilon;ί&tau;&epsilon;
          &tau;&omicron;
          &delta;&iota;&alpha;&delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;ό
          &tau;ό&pi;&omicron; &chi;&omega;&rho;ί&sigmaf; &nu;&alpha;
          έ&chi;&epsilon;&tau;&epsilon;
          &epsilon;&pi;&iota;&tau;&rho;έ&psi;&epsilon;&iota; &tau;&eta;&nu;
          &epsilon;&pi;έ&mu;&beta;&alpha;&sigma;&eta; &tau;&omega;&nu; cookies,
          &alpha;&lambda;&lambda;ά &delta;&epsilon;&nu; &theta;&alpha;
          &mu;&pi;&omicron;&rho;έ&sigma;&epsilon;&tau;&epsilon; &nu;&alpha;
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;ή&sigma;&epsilon;&tau;&epsilon;
          ό&lambda;&epsilon;&sigmaf; &tau;&iota;&sigmaf;
          &delta;&upsilon;&nu;&alpha;&tau;ό&tau;&eta;&tau;&epsilon;&sigmaf;
          &tau;&eta;&sigmaf;
          &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;
          ή &nu;&alpha;
          &alpha;&pi;&omicron;&lambda;&alpha;ύ&sigma;&epsilon;&tau;&epsilon;
          &sigma;&upsilon;&gamma;&kappa;&epsilon;&kappa;&rho;&iota;&mu;έ&nu;&alpha;
          &chi;&alpha;&rho;&alpha;&kappa;&tau;&eta;&rho;&iota;&sigma;&tau;&iota;&kappa;ά
          &tau;&eta;&sigmaf;. &Gamma;&iota;&alpha; &nu;&alpha;
          &alpha;&lambda;&lambda;ά&xi;&epsilon;&tau;&epsilon;
          &tau;&iota;&sigmaf;
          &rho;&upsilon;&theta;&mu;ί&sigma;&epsilon;&iota;&sigmaf;
          &tau;&omega;&nu; cookies &sigma;&alpha;&sigmaf;,
          &alpha;&nu;&alpha;&tau;&rho;έ&xi;&tau;&epsilon; &sigma;&tau;&omicron;
          &mu;&epsilon;&nu;&omicron;ύ
          &quot;&Beta;&omicron;ή&theta;&epsilon;&iota;&alpha;&quot;
          &tau;&omicron;&upsilon;
          &pi;&rho;&omicron;&gamma;&rho;ά&mu;&mu;&alpha;&tau;&omicron;&sigmaf;
          &pi;&epsilon;&rho;&iota;ή&gamma;&eta;&sigma;&eta;&sigmaf;
          (browser)</span
        >
      </p>
      <p><br /></p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Epsilon;&Iota;&Delta;&Iota;&Kappa;&Omicron;&Tau;&Epsilon;&Rho;&Alpha;&nbsp;</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&pi;&epsilon;&rho;ί
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &gamma;&iota;&alpha;
          &epsilon;&kappa;&tau;&epsilon;&lambda;&epsilon;&sigma;&theta;&epsilon;ί&sigma;&epsilon;&sigmaf;
          &sigma;&upsilon;&nu;&alpha;&lambda;&lambda;&alpha;&gamma;έ&sigmaf;.&nbsp;</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          ><br /></span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Pi;&omega;&sigmaf; &Delta;ί&nu;&epsilon;&tau;&alpha;&iota; &eta;
          &Sigma;&upsilon;&gamma;&kappa;&alpha;&tau;ά&theta;&epsilon;&sigma;&eta;
          &gamma;&iota;&alpha; &tau;&eta;&nu;
          &Alpha;&pi;&omicron;&delta;&omicron;&chi;ή &tau;&eta;&sigmaf;
          &Pi;&alpha;&rho;&alpha;&pi;ά&nu;&omega;
          &Chi;&rho;ή&sigma;&eta;&sigmaf; Cookies &alpha;&pi;ό
          &tau;&omicron;&nu; &chi;&rho;ή&sigma;&tau;&eta;; &Mu;&epsilon;
          &tau;&eta;&nu; &epsilon;ί&sigma;&omicron;&delta;&omicron;
          &tau;&omicron;&upsilon; &chi;&rho;ή&sigma;&tau;&eta;
          &sigma;&tau;&eta;&nu; &kappa;&epsilon;&nu;&tau;&rho;&iota;&kappa;ή
          &sigma;&epsilon;&lambda;ί&delta;&alpha; &tau;&eta;&sigmaf;
          &laquo;&Iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;&raquo;
          &lambda;&alpha;&mu;&beta;ά&nu;&epsilon;&iota; &gamma;&nu;ώ&sigma;&eta;
          &sigma;&chi;&epsilon;&tau;&iota;&kappa;&omicron;ύ
          &sigma;&eta;&mu;&epsilon;&iota;ώ&mu;&alpha;&tau;&omicron;&sigmaf;
          &gamma;&iota;&alpha; &tau;&eta; &chi;&rho;ή&sigma;&eta; &alpha;&pi;ό
          &mu;έ&rho;&omicron;&upsilon;&sigmaf; &mu;&alpha;&sigmaf; cookies,
          &tau;&omicron; &omicron;&pi;&omicron;ί&omicron;
          &beta;&rho;ί&sigma;&kappa;&epsilon;&tau;&alpha;&iota; &sigma;&epsilon;
          &pi;&epsilon;&rho;ί&omicron;&pi;&tau;&eta; &theta;έ&sigma;&eta;
          &kappa;&alpha;&iota;
          &pi;&alpha;&rho;&alpha;&pi;έ&mu;&pi;&epsilon;&iota;
          &sigma;&tau;&eta;&nu; &pi;&alpha;&rho;&omicron;ύ&sigma;&alpha;
          &pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή
          &chi;&rho;ή&sigma;&eta;&sigmaf; &mu;&epsilon;
          &sigma;&chi;&epsilon;&tau;&iota;&kappa;ή &sigma;&alpha;&phi;ή
          &kappa;&alpha;&iota; &epsilon;&kappa;&tau;&epsilon;&nu;ή
          &epsilon;&nu;&eta;&mu;έ&rho;&omega;&sigma;&eta;. &Sigma;&epsilon;
          &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta;,
          &pi;&omicron;&upsilon; &omicron; &chi;&rho;ή&sigma;&tau;&eta;&sigmaf;
          &alpha;&pi;&omicron;&phi;&alpha;&sigma;ί&sigma;&epsilon;&iota;
          &nu;&alpha; &pi;&epsilon;&rho;&iota;&eta;&gamma;&eta;&theta;&epsilon;ί
          &sigma;&epsilon;
          &upsilon;&pi;&omicron;&sigma;&epsilon;&lambda;ί&delta;&epsilon;&sigmaf;
          &tau;&eta;&sigmaf;
          &laquo;&Iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;&raquo;
          &kappa;&alpha;&iota; &epsilon;&phi;ό&sigma;&omicron;&nu;
          έ&chi;&epsilon;&iota; &rho;&upsilon;&theta;&mu;ί&sigma;&epsilon;&iota;
          &kappa;&alpha;&tau;&alpha;&lambda;&lambda;ή&lambda;&omega;&sigmaf;
          &tau;&omicron;&nu;
          &delta;&iota;&alpha;&delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;ό
          &nbsp;&phi;&upsilon;&lambda;&lambda;&omicron;&mu;&epsilon;&tau;&rho;&eta;&tau;ή,
          &pi;&alpha;&rho;έ&chi;&epsilon;&iota;
          &epsilon;&lambda;&epsilon;&upsilon;&theta;έ&rho;&omega;&sigmaf;
          &tau;&eta;&nu; &rho;&eta;&tau;ή &kappa;&alpha;&iota;
          &epsilon;&iota;&delta;&iota;&kappa;ή
          &sigma;&upsilon;&gamma;&kappa;&alpha;&tau;ά&theta;&epsilon;&sigma;&eta;
          &gamma;&iota;&alpha; &tau;&eta;&nu; &chi;&rho;ή&sigma;&eta;
          &alpha;&upsilon;&tau;ή. &Sigma;&epsilon;
          &alpha;&nu;&tau;ί&theta;&epsilon;&tau;&eta;
          &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta; &omicron;
          &chi;&rho;ή&sigma;&tau;&eta;&sigmaf;
          &upsilon;&pi;&omicron;&chi;&rho;&epsilon;&omicron;ύ&tau;&alpha;&iota;
          &nu;&alpha; &alpha;&pi;έ&chi;&epsilon;&iota; &alpha;&pi;ό
          &tau;&eta;&nu; &pi;&epsilon;&rho;&iota;ή&gamma;&eta;&sigma;&eta;
          &epsilon;&nu;&tau;ό&sigmaf; &tau;&eta;&sigmaf;
          &laquo;&Iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;&raquo;
          ή &nu;&alpha;
          &alpha;&pi;&epsilon;&nu;&epsilon;&rho;&gamma;&omicron;&pi;&omicron;&iota;ή&sigma;&epsilon;&iota;
          &tau;&eta; &chi;&rho;ή&sigma;&eta; cookies &sigma;&tau;&iota;&sigmaf;
          &rho;&upsilon;&theta;&mu;ί&sigma;&epsilon;&iota;&sigmaf;
          &tau;&omicron;&upsilon;
          &phi;&upsilon;&lambda;&lambda;&omicron;&mu;&epsilon;&tau;&rho;&eta;&tau;ή.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Pi;&omega;&sigmaf; &mu;&pi;&omicron;&rho;&epsilon;ί &omicron;
          &chi;&rho;ή&sigma;&tau;&eta;&sigmaf; &nu;&alpha;
          &alpha;&pi;&epsilon;&nu;&epsilon;&rho;&gamma;&omicron;&pi;&omicron;&iota;ή&sigma;&epsilon;&iota;
          &tau;&eta; &chi;&rho;ή&sigma;&eta; Cookies;</span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          ><br /></span
        ><span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Sigma;&epsilon; &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta;
          &pi;&omicron;&upsilon; &omicron; &chi;&rho;ή&sigma;&tau;&eta;&sigmaf;
          &epsilon;&pi;&iota;&theta;&upsilon;&mu;&epsilon;ί &nu;&alpha;
          &epsilon;&nu;&epsilon;&rho;&gamma;&omicron;&pi;&omicron;&iota;ή&sigma;&epsilon;&iota;
          ή &nu;&alpha;
          &alpha;&pi;&epsilon;&nu;&epsilon;&rho;&gamma;&omicron;&pi;&omicron;&iota;ή&sigma;&epsilon;&iota;
          &tau;&eta; &chi;&rho;ή&sigma;&eta;
          &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;ώ&nu;
          &mu;&pi;&iota;&sigma;&kappa;ό&tau;&omega;&nu; &alpha;&pi;ό
          &tau;&iota;&sigmaf;
          &rho;&upsilon;&theta;&mu;ί&sigma;&epsilon;&iota;&sigmaf;
          &tau;&omicron;&upsilon;
          &phi;&upsilon;&lambda;&lambda;&omicron;&mu;&epsilon;&tau;&rho;&eta;&tau;ή,
          &alpha;&nu;&alpha;&lambda;ό&gamma;&omega;&sigmaf;
          &tau;&omicron;&upsilon;
          &phi;&upsilon;&lambda;&lambda;&omicron;&mu;&epsilon;&tau;&rho;&eta;&tau;ή
          &pi;&rho;&omicron;&tau;&epsilon;ί&nu;&omicron;&upsilon;&mu;&epsilon;
          &nu;&alpha;
          &epsilon;&pi;&iota;&sigma;&kappa;&epsilon;&phi;&theta;&epsilon;ί&tau;&epsilon;
          &tau;&iota;&sigmaf;
          &alpha;&kappa;ό&lambda;&omicron;&upsilon;&theta;&epsilon;&sigmaf;
          &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&epsilon;&sigmaf;,
          &gamma;&iota;&alpha; &nu;&alpha;
          &lambda;ά&beta;&epsilon;&tau;&epsilon; &gamma;&nu;ώ&sigma;&eta;
          &gamma;&iota;&alpha; &tau;&iota;&sigmaf;
          &alpha;&pi;&alpha;&rho;&alpha;ί&tau;&eta;&tau;&epsilon;&sigmaf;
          &sigma;&chi;&epsilon;&tau;&iota;&kappa;έ&sigmaf;
          &epsilon;&nu;έ&rho;&gamma;&epsilon;&iota;&epsilon;&sigmaf;</span
        >
      </p>
      <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px">
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #000000;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&bull; Internet Explorer&nbsp;</span
            ><a
              href="http://support.microsoft.com/kb/278835"
              style="text-decoration: none"
              ><span
                style="
                  font-size: 11pt;
                  font-family: Arial;
                  color: #0563c1;
                  background-color: transparent;
                  font-weight: 400;
                  font-style: normal;
                  font-variant: normal;
                  text-decoration: underline;
                  -webkit-text-decoration-skip: none;
                  text-decoration-skip-ink: none;
                  vertical-align: baseline;
                  white-space: pre;
                  white-space: pre-wrap;
                "
                >http://support.microsoft.com/kb/278835</span
              ></a
            >
          </p>
        </li>
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #000000;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&bull; Firefox&nbsp;</span
            ><a
              href="http://support.microsoft.com/kb/278835"
              style="text-decoration: none"
              ><span
                style="
                  font-size: 11pt;
                  font-family: Arial;
                  color: #0563c1;
                  background-color: transparent;
                  font-weight: 400;
                  font-style: normal;
                  font-variant: normal;
                  text-decoration: underline;
                  -webkit-text-decoration-skip: none;
                  text-decoration-skip-ink: none;
                  vertical-align: baseline;
                  white-space: pre;
                  white-space: pre-wrap;
                "
                >http://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored</span
              ></a
            >
          </p>
        </li>
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #000000;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&bull; Chrome&nbsp;</span
            ><a
              href="http://support.google.com/accounts/answer/61416?hl=en"
              style="text-decoration: none"
              ><span
                style="
                  font-size: 11pt;
                  font-family: Arial;
                  color: #0563c1;
                  background-color: transparent;
                  font-weight: 400;
                  font-style: normal;
                  font-variant: normal;
                  text-decoration: underline;
                  -webkit-text-decoration-skip: none;
                  text-decoration-skip-ink: none;
                  vertical-align: baseline;
                  white-space: pre;
                  white-space: pre-wrap;
                "
                >http://support.google.com/accounts/answer/61416?hl=en</span
              ></a
            >
          </p>
        </li>
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #000000;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&bull; Opera&nbsp;</span
            ><a
              href="http://www.opera.com/browser/tutorials/security/privacy/"
              style="text-decoration: none"
              ><span
                style="
                  font-size: 11pt;
                  font-family: Arial;
                  color: #0563c1;
                  background-color: transparent;
                  font-weight: 400;
                  font-style: normal;
                  font-variant: normal;
                  text-decoration: underline;
                  -webkit-text-decoration-skip: none;
                  text-decoration-skip-ink: none;
                  vertical-align: baseline;
                  white-space: pre;
                  white-space: pre-wrap;
                "
                >http://www.opera.com/browser/tutorials/security/privacy/</span
              ></a
            >
          </p>
        </li>
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #000000;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&bull; Safari&nbsp;</span
            ><a
              href="http://support.apple.com/kb/PH5042"
              style="text-decoration: none"
              ><span
                style="
                  font-size: 11pt;
                  font-family: Arial;
                  color: #0563c1;
                  background-color: transparent;
                  font-weight: 400;
                  font-style: normal;
                  font-variant: normal;
                  text-decoration: underline;
                  -webkit-text-decoration-skip: none;
                  text-decoration-skip-ink: none;
                  vertical-align: baseline;
                  white-space: pre;
                  white-space: pre-wrap;
                "
                >http://support.apple.com/kb/PH5042</span
              ></a
            >
          </p>
        </li>
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #000000;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&bull; Safari for iPad and iPhone&nbsp;</span
            ><a
              href="http://support.apple.com/kb/HT1677"
              style="text-decoration: none"
              ><span
                style="
                  font-size: 11pt;
                  font-family: Arial;
                  color: #0563c1;
                  background-color: transparent;
                  font-weight: 400;
                  font-style: normal;
                  font-variant: normal;
                  text-decoration: underline;
                  -webkit-text-decoration-skip: none;
                  text-decoration-skip-ink: none;
                  vertical-align: baseline;
                  white-space: pre;
                  white-space: pre-wrap;
                "
                >http://support.apple.com/kb/HT1677</span
              ></a
            >
          </p>
        </li>
      </ul>
      <p><br /></p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Tau;έ&lambda;&omicron;&sigmaf;, &eta;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha; &mu;&alpha;&sigmaf;
          &pi;&alpha;&rho;&alpha;&mu;έ&nu;&epsilon;&iota;
          &pi;ά&nu;&tau;&omicron;&tau;&epsilon; &sigma;&tau;&eta;
          &delta;&iota;ά&theta;&epsilon;&sigma;ή &sigma;&alpha;&sigmaf;
          &gamma;&iota;&alpha;
          &omicron;&pi;&omicron;&iota;&alpha;&delta;ή&pi;&omicron;&tau;&epsilon;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&alpha;
          ή/&kappa;&alpha;&iota;
          &pi;&epsilon;&rho;&alpha;&iota;&tau;έ&rho;&omega;
          &delta;&iota;&epsilon;&upsilon;&kappa;&rho;ί&nu;&eta;&sigma;&eta;
          &kappa;&alpha;&iota; &epsilon;&rho;ώ&tau;&eta;&sigma;&eta;
          &epsilon;&pi;&iota;&theta;&upsilon;&mu;&epsilon;ί&tau;&epsilon;
          &nu;&alpha;
          &epsilon;&pi;&iota;&kappa;&omicron;&iota;&nu;&omega;&nu;ή&sigma;&epsilon;&tau;&epsilon;
          &mu;&alpha;&zeta;ί &mu;&alpha;&sigmaf;.&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
          padding: 0pt 0pt 6pt 0pt;
        "
      >
        &nbsp;
      </p>
      <p><br /></p>
      <p><br /></p>
    </v-container>
  </div>
</template>

<script>
import LegalHeader from "./LegalHeader.vue";
export default {
  components: { LegalHeader },
  name: "TermsOfUse",
  data() {
    return {};
  },
};
</script>

<style></style>
